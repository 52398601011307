import React from "react";
import styled from "styled-components";
import { prop } from "styled-tools";

export default props => (
  <React.Fragment>
    <div
      className="bk-content-area"
      style={{ maxWidth: props.maxWidth ? props.maxWidth : "inherit" }}
    >
      <div id="content-header" className="bk-content-header" />
      {props.children}
    </div>
  </React.Fragment>
);
