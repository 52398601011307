import React from "react";
import { Map } from "immutable";

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: Map()
    };
    this.update = this.update.bind(this);
    this.checkField = this.checkField.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  wDivRef = undefined;

  updateWindowDimensions() {
    if (this.wDivRef) {
      const rect = this.wDivRef.getBoundingClientRect();
      if (this.state.width !== rect.width) {
        this.setState({ width: rect.width });
      }
      if (this.state.top !== rect.top) {
        this.setState({ top: rect.top });
      }
    }
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    this.updateWindowDimensions();
    const renderAsTable =
      this.props.renderAsTable &&
      (this.props.insideTable || this.state.width > 500);
    const cr = (
      <CustomerRenderer
        t={this.props.t}
        updateCustomer={this.update}
        stateCustomer={this.props.stateCustomer}
        errors={this.state.errors}
        blur={(k, v) => this.checkField(k, v, this.props.stateCustomer)}
        showAddress={this.props.showAddress}
        renderAsTable={renderAsTable}
        noBoat={this.props.noBoat}
        boatRegister={this.props.boatRegister}
        disableEmail={this.props.disableEmail}
        repeatEmail={this.props.repeatEmail}
        autocomplete={this.props.autocomplete}
        requireAddress={this.props.requireAddress}
        boatOptional={this.props.boatOptional}
        askBoatSize={this.props.askBoatSize}
      />
    );
    let content = undefined;
    if (renderAsTable && !this.props.insideTable) {
      content = <table>{cr}</table>;
    } else {
      content = cr;
    }
    return (
      <div className="bk_placeDetails" ref={divRef => (this.wDivRef = divRef)}>
        {content}
      </div>
    );
  }

  update(field, value) {
    this.props.updateCustomer(field, value);
  }

  checkField(field, value, customer) {
    if ((value === "0" || value === 0) && field == "boatDepth") {
      return;
    }
    if (
      !value &&
      (field === "firstName" ||
        field === "lastName" ||
        field === "mobile" ||
        field === "email" ||
        field === "repeatEmail" ||
        (field === "boatRegister" && !this.props.boatOptional) ||
        (field === "boatName" && !this.props.boatOptional) ||
        (field === "boatType" && !this.props.boatOptional) ||
        (field === "boatLength" && !this.props.boatOptional) ||
        (field === "boatWidth" && !this.props.boatOptional) ||
        (field === "boatDepth" && !this.props.boatOptional) ||
        (this.props.requireAddress &&
          (field === "streetAddress" ||
            field === "town" ||
            field === "postOffice")))
    ) {
      this.setState({
        errors: this.state.errors.set(field, this.props.t("requiredFieldError"))
      });
    } else if (
      !this.props.disableEmail &&
      (field === "email" || field === "repeatEmail") &&
      !value.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))+$/
      )
    ) {
      this.setState({
        errors: this.state.errors.set(field, this.props.t("emailFormatError"))
      });
    } else if (field === "repeatEmail" && value !== customer.get("email")) {
      this.setState({
        errors: this.state.errors.set(field, this.props.t("emailRepeatError"))
      });
    } else {
      this.setState({
        errors: this.state.errors.remove(field)
      });
    }
  }
}

const err = (errors, field) =>
  errors.get(field) ? (
    <span className="bk_fieldError">{errors.get(field)}</span>
  ) : (
    undefined
  );

const fieldName = field => {
  switch (field) {
    case "boatName":
      return "boat";
    case "firstName":
      return "given-name";
    case "lastName":
      return "family-name";
    case "streetAddress":
      return "street-address";
    default:
      return field;
  }
};

const CustomerField = props => {
  const {
    t,
    c,
    updateCustomer,
    isFieldRequired,
    errors,
    blur,
    renderAsTable,
    col1Field,
    col2Field,
    col1FieldDisabled,
    col2FieldDisabled,
    autocomplete1 = "off",
    autocomplete2 = "off",
    col1Type = "text",
    col2Type = "text"
  } = props;
  const f1req = isFieldRequired(col1Field);
  const f2req = isFieldRequired(col2Field);

  const f1name = f1req ? addStar(t(col1Field)) : t(col1Field);
  let f2name;
  if (col1Field === "boatModel" && col2Field === "boatName") {
    f2name = f2req ? addStar(t("boatNameReg")) : t("boatNameReg");
  } else {
    f2name = f2req ? addStar(t(col2Field)) : t(col2Field);
  }

  if (renderAsTable) {
    return (
      <tr>
        <td>
          <label>{f1name}</label>
          <br />
          <input
            autocomplete={autocomplete1}
            name={fieldName(col1Field)}
            disabled={col1FieldDisabled}
            value={c.get(col1Field)}
            type={col1Type}
            // eslint-disable-next-line prettier/prettier
            onChange={e => col1FieldDisabled ? undefined : updateCustomer(col1Field, e.target.value)}
            onBlur={e => blur(col1Field, e.target.value)}
          />
          {err(errors, col1Field)}
        </td>
        {col2Field && (
          <React.Fragment>
            <td>
              <label>{f2name}</label>
              <br />
              <input
                autocomplete={autocomplete2}
                name={fieldName(col2Field)}
                disabled={col2FieldDisabled}
                value={c.get(col2Field)}
                type={col2Type}
                // eslint-disable-next-line prettier/prettier
                onChange={e => col2FieldDisabled ? undefined : updateCustomer(col2Field, e.target.value)}
                onBlur={e => blur(col2Field, e.target.value)}
              />
              {err(errors, col2Field)}
            </td>
          </React.Fragment>
        )}
      </tr>
    );
  } else {
    return (
      <React.Fragment>
        <center><label>{f1name}</label></center>
        <center>
          <input
            autocomplete={autocomplete1}
            name={fieldName(col1Field)}
            value={c.get(col1Field)}
            type={col1Type}
            disabled={col1FieldDisabled}
            onChange={e =>
              col1FieldDisabled
                ? undefined
                : updateCustomer(col1Field, e.target.value)
            }
            onBlur={e => blur(col1Field, e.target.value)}
          />
          {err(errors, col1Field)}
        </center>
        {col2Field && (
          <React.Fragment>
            <center><label>{f2name}</label></center>
            <center>
              <input
                autocomplete={autocomplete2}
                name={fieldName(col2Field)}
                value={c.get(col2Field)}
                type={col2Type}
                disabled={col2FieldDisabled}
                onChange={e =>
                  col2FieldDisabled
                    ? undefined
                    : updateCustomer(col2Field, e.target.value)
                }
                onBlur={e => blur(col2Field, e.target.value)}
              />
              {err(errors, col2Field)}
            </center>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
};

const CustomerRenderer = props => {
  const {
    t,
    updateCustomer,
    stateCustomer,
    errors,
    blur,
    showAddress,
    renderAsTable,
    noBoat,
    disableEmail,
    repeatEmail,
    autocomplete,
    requireAddress,
    askBoatSize,
    boatRegister,
    boatOptional
  } = props;
  const c = stateCustomer ? stateCustomer : {};
  const content = [];
  content.push(
    <CustomerField
      t={t}
      c={c}
      updateCustomer={updateCustomer}
      errors={errors}
      blur={blur}
      renderAsTable={renderAsTable}
      col1Field="firstName"
      col2Field="lastName"
      autocomplete1={autocomplete ? "given-name" : "off"}
      autocomplete2={autocomplete ? "family-name" : "off"}
      isFieldRequired={() => true}
    />
  );
  if (repeatEmail) {
    content.push(
      <CustomerField
        t={t}
        c={c}
        updateCustomer={updateCustomer}
        errors={errors}
        blur={blur}
        renderAsTable={renderAsTable}
        col1Field="email"
        col2Field="repeatEmail"
        col1Type="email"
        col2Type="email"
        autocomplete1={autocomplete ? "email" : "off"}
        autocomplete2={"off"}
        isFieldRequired={() => true}
      />
    );
    content.push(
      <CustomerField
        t={t}
        c={c}
        updateCustomer={updateCustomer}
        errors={errors}
        blur={blur}
        renderAsTable={renderAsTable}
        col1Field="mobile"
        col1Type="tel"
        autocomplete1={autocomplete ? "mobile tel" : "off"}
        isFieldRequired={() => true}
      />
    );
  } else {
    content.push(
      <CustomerField
        t={t}
        c={c}
        updateCustomer={updateCustomer}
        errors={errors}
        blur={blur}
        renderAsTable={renderAsTable}
        col1FieldDisabled={disableEmail}
        col1Field="email"
        col2Field="mobile"
        col1Type="email"
        col2Type="tel"
        autocomplete1={autocomplete ? "email" : "off"}
        autocomplete2={autocomplete ? "mobile tel" : "off"}
        isFieldRequired={() => true}
      />
    );
  }
  if (showAddress) {
    content.push(
      <CustomerField
        t={t}
        c={c}
        updateCustomer={updateCustomer}
        errors={errors}
        blur={blur}
        renderAsTable={renderAsTable}
        col1Field={"streetAddress"}
        col2Field={"postOffice"}
        autocomplete1={autocomplete ? "street-address" : "off"}
        autocomplete2={autocomplete ? "postal-code" : "off"}
        isFieldRequired={() => requireAddress}
      />
    );
    content.push(
      <CustomerField
        t={t}
        c={c}
        updateCustomer={updateCustomer}
        errors={errors}
        blur={blur}
        renderAsTable={renderAsTable}
        col1Field={"town"}
        autocomplete1={autocomplete ? "locality" : "off"}
        isFieldRequired={() => requireAddress}
      />
    );
  }
  if (!noBoat) {
    content.push(
      <CustomerField
        t={t}
        c={c}
        updateCustomer={updateCustomer}
        errors={errors}
        blur={blur}
        renderAsTable={renderAsTable}
        col1Field={boatRegister ? "boatRegister" : "boatModel"}
        col2Field={"boatName"}
        autocomplete1="off"
        autocomplete2="off"
        isFieldRequired={f => "boatModel" !== f && !boatOptional}
      />
    );

    const boatSizeInputs = (
      <React.Fragment>
        <div>
          <div style={{ display: "inline-block", verticalAlign: "top" }}>
            <label>{t("boatLength")}</label>
            <br />
            <input
              className="short"
              value={c.get("boatLength")}
              onChange={e =>
                updateCustomer(
                  "boatLength",
                  e.target.value.replace(",", ".").replace(/[^0-9\\.]/g, "")
                )
              }
              onBlur={e => blur("boatLength", e.target.value)}
            />
            <br />
            {err(errors, "boatLength")}
          </div>
          <div style={{ display: "inline-block", verticalAlign: "top" }}>
            <label>{t("boatWidth")}</label>
            <br />
            <input
              className="short"
              value={c.get("boatWidth")}
              onChange={e =>
                updateCustomer(
                  "boatWidth",
                  e.target.value.replace(",", ".").replace(/[^0-9.]/g, "")
                )
              }
              onBlur={e => blur("boatWidth", e.target.value)}
            />
            <br />
            {err(errors, "boatWidth")}
          </div>
          <div style={{ display: "inline-block", verticalAlign: "top" }}>
            <label>{t("boatDepth")}</label>
            <br />
            <input
              className="short"
              value={c.get("boatDepth")}
              onChange={e =>
                updateCustomer(
                  "boatDepth",
                  e.target.value.replace(",", ".").replace(/[^0-9.]/g, "")
                )
              }
              onBlur={e => {
                const value = e.target.value
                  .replace(",", ".")
                  .replace(/[^0-9.]/g, "");
                updateCustomer("boatDepth", value);
                blur("boatDepth", value);
              }}
            />
            <br />
            {err(errors, "boatDepth")}
          </div>
        </div>
        <br />
        {err(errors, "boatSize")}
      </React.Fragment>
    );
    const boatSelect = (
      <React.Fragment>
        <select
          onInput={event => updateCustomer("boatType", event.target.value)}
          onChange={event => updateCustomer("boatType", event.target.value)}
          onBlur={e => blur("boatType", e.target.value)}
        >
          <option value="" selected={!c.get("boatType")}>
            {t("select")}
          </option>
          <option value="SAIL" selected={c.get("boatType") === "SAIL"}>
            {t("typeSail")}
          </option>
          <option value="MOTOR" selected={c.get("boatType") === "MOTOR"}>
            {t("typeMotor")}
          </option>
          <option value="OTHER" selected={c.get("boatType") === "OTHER"}>
            {t("typeOther")}
          </option>
        </select>
        {err(errors, "boatType")}
      </React.Fragment>
    );
    if (renderAsTable) {
      content.push(
        <tr>
          {askBoatSize && (
            <td>
              <label>
                {boatOptional ? t("boatSize") : addStar(t("boatSize"))}
              </label>
              <br />
              {boatSizeInputs}
            </td>
          )}
          <td>
            <label>
              {boatOptional ? t("boatType") : addStar(t("boatType"))}
            </label>
            <br />
            {boatSelect}
          </td>
        </tr>
      );
    } else {
      if (askBoatSize) {
        content.push(
          <center>
            {boatOptional ? t("boatSize") : addStar(t("boatSize"))}
          </center>
        );
        content.push(<center>{boatSizeInputs}</center>);
      }
      content.push(
        <center>{boatOptional ? t("boatType") : addStar(t("boatType"))}</center>
      );
      content.push(<center>{boatSelect}</center>);
    }
  }
  return content;
};

function addStar(label) {
  if (!label || label.startsWith("*")) {
    return label;
  }
  return "*" + label;
}

export default Customer;
