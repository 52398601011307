import React from "react";
import BookkaaHeader from "../BookkaaHeader";
import LoginForm from "../LoginForm";
import LoginRequired from "../LoginRequired";
import Profile from "../Profile";
import {
  genericDelete,
  genericGet,
  genericPost
} from "../../services/axiosApi";
import BkModifyButton from "../layout/BkModifyButton";
import SeasonQueueForm from "../../xtra/SeasonQueueForm";

class SeasonQueue extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sellerId: undefined,
      areaId: props.location.area,
      busy: false,
      errorMsg: undefined,
      result: undefined,
      adding: false
    };
    const l = props.location;
    props.getTranslations(l.ctx, l.language, l.endpoint);
    if (!this.props.areas) {
      this.props.getAreas(l.language);
    }
    this.timer = null;
    this.reload = this.reload.bind(this);
    this.removeFromQueue = this.removeFromQueue.bind(this);
    this.reload();
  }

  componentDidCatch(e) {
    console.error("Failed: " + e + ", stack: " + e.stack);
  }

  reload() {
    const l = this.props.location;
    genericGet(l.language, l.area + "/queue", "booking-web", 5000).then(r => {
      this.setState({
        result: r.errorMessage ? undefined : r,
        errorMessage: r.errorMessage,
        adding: false
      });
    });
  }

  removeFromQueue(queue) {
    const l = this.props.location;
    genericDelete(
      l.language,
      l.area + "/queue/" + queue.id,
      "booking-web",
      5000
    ).then(r => {
      this.setState({
        result: r.errorMessage ? undefined : r,
        errorMessage: r.errorMessage
      });
    });
  }

  reserve(queue, place) {
    const l = this.props.location;
    genericPost(
      l.language,
      l.area + "/queue/" + queue.id + "/assign/" + place.productId,
      {},
      "booking-web"
    ).then(r => {
      this.setState({
        result: r.errorMessage ? undefined : r,
        errorMessage: r.errorMessage
      });
    });
  }

  render() {
    const location = this.props.location;
    const t = key => location.translate(location, key);

    const content = [];

    const error = this.state.errorMessage ? (
      <div className="bk_errorMessage" style={{ display: "block" }}>
        {this.state.errorMessage}
      </div>
    ) : this.state.okMessage ? (
      <div
        className="bk_okMessage"
        style={{ display: "block" }}
        onClick={() => this.setState({ okMessage: undefined })}
      >
        {this.state.okMessage}
      </div>
    ) : (
      undefined
    );
    if (error) {
      content.push(error);
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(
        () => this.setState({ errorMessage: undefined, okMessage: undefined }),
        20000
      );
    }
    if (this.state.adding) {
      content.push(
        <div style={{ border: "3px solid black" }}>
          <SeasonQueueForm {...this.props} close={() => this.reload()} />
        </div>
      );
    } else if (this.state.result && this.state.result.length === 0) {
      content.push(<h1>Ei asiakkaita jonossa</h1>);
    } else if (this.state.result) {
      const vals = [];
      const customerHead = (
        <tr>
          <th>{t("harbour")}</th>
          <th>Aika</th>
          <th>{t("boatType")}</th>
          <th>{t("report.firstName")}</th>
          <th>{t("report.lastName")}</th>
          <th>{t("email")}</th>
          <th>{t("mobile")}</th>
          <th>{t("boatWidth")}</th>
          <th>{t("boatLength")}</th>
          <th>{t("boatDepth")}</th>
          <th>Toiminto</th>
        </tr>
      );
      const placeHead = (
        <tr>
          <th colspan="3">Vapaana olevat sopivat</th>
          <th>{t("pier")}</th>
          <th>{t("place")}</th>
          <th>{t("placeType")}</th>
          <th>{t("price")}</th>
          <th>{t("boatWidth")}</th>
          <th>{t("boatLength")}</th>
          <th>{t("boatDepth")}</th>
          <td />
        </tr>
      );

      this.state.result.forEach(element => {
        vals.push(customerHead);
        vals.push(
          <tr>
            <td>{element.customer.areaName}</td>
            <td>{element.date}</td>
            <td>{element.customer.boatTypeStr}</td>
            <td>{element.customer.firstName}</td>
            <td>{element.customer.lastName}</td>
            <td>{element.customer.email}</td>
            <td>{element.customer.mobile}</td>
            <td>{element.customer.boatWidth}</td>
            <td>{element.customer.boatLength}</td>
            <td>{element.customer.boatDepth}</td>
            <td>
              <BkModifyButton
                buttonTitle={t("delete-button")}
                callback={() => this.removeFromQueue(element)}
              />
            </td>
          </tr>
        );
        if (element.freeMatching) {
          vals.push(placeHead);
          element.freeMatching.forEach(pl => {
            vals.push(
              <tr>
                <td colspan="3"></td>
                <td>{pl.pier}</td>
                <td>{pl.placeCode}</td>
                <td>{pl.placeTypeStr}</td>
                <td>{pl.placePrice}</td>
                <td>{pl.placeWidth}</td>
                <td>{pl.placeLength}</td>
                <td>{pl.placeDepth}</td>
                <td>
                  <BkModifyButton
                    buttonTitle={t("reserve-button")}
                    callback={() => this.reserve(element, pl)}
                  />
                </td>
              </tr>
            );
          });
        } else {
          vals.push(
            <tr>
              <td />
              <td colspan="8">Soveltuvia paikkoja ei vapaana</td>
            </tr>
          );
        }
      });

      content.push(<table className="bk-list">{vals}</table>);
    }

    return (
      <React.Fragment>
        <LoginRequired location={location} {...this.props.loginProps}>
          <BookkaaHeader
            location={location}
            {...this.props.headerProps}
            disableProfile={true}
          />
          <LoginForm location={location} {...this.props.loginProps} />
          <Profile location={location} {...this.props.profileProps}>
            {content}
            <br />
            {!this.state.adding && (
              <BkModifyButton
                buttonTitle={t("new-button")}
                callback={() => this.setState({ adding: true })}
              />
            )}
          </Profile>
        </LoginRequired>
      </React.Fragment>
    );
  }
}

export default SeasonQueue;
