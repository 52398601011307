import React from "react";
import Moment from "react-moment";
import Datetime from "react-datetime";
import "moment-timezone";

export const DateSelector = props => {
  const {
    language,
    value,
    isEdit,
    callback,
    enableEditCallback,
    isValidDate
  } = props;
  if (!isEdit) {
    const dateValue = value ? (
      <Moment format="DD.MM.YYYY">{value}</Moment>
    ) : (
      <time>{"00.00.0000"}</time>
    );
    if (enableEditCallback) {
      return (
        <a className="date-selector-link" onClick={enableEditCallback}>
          {dateValue}
        </a>
      );
    } else {
      return dateValue;
    }
  }
  return (
    <React.Fragment>
      <Datetime
        locale={language}
        displayTimeZone="Europe/Helsinki"
        value={value}
        timeFormat={false}
        dateFormat="DD.MM.YYYY"
        input={true}
        inputProps={{ disabled: true }}
        isValidDate={(currentDate, selectedDate) =>
          isValidDate ? isValidDate(currentDate, selectedDate) : true
        }
        onChange={moment => callback(moment)}
        closeOnSelect={true}
        open={undefined}
        disableOnClickOutside={false}
        renderInput={props => {
          return (
            <div>
              <input {...props} />
              <img
                alt=""
                onClick={() => callback(value)}
                src="/close-button.jpg"
                width="16"
              ></img>
            </div>
          );
        }}
      />
    </React.Fragment>
  );
};
