import React from "react";
import { CircleLoader } from "react-spinners";

export const ReportTable = props => {
  const { t, report, previousCb, nextCb, showBusinessName } = props;
  const rows = [];
  rows.push(
    <tr>
      {showBusinessName && <th>{t("businessName")}</th>}
      <th>{t("visitorCount")}</th>
      <th>{t("visitorDescription")}</th>
      <th>{t("date")}</th>
      <th>{t("endDate")}</th>
    </tr>
  );
  report.forEach((spData, idx) => {
    rows.push(
      <tr className={idx % 2 == 0 ? "td-even" : "td-odd"}>
        {showBusinessName && <td>{spData.businessName}</td>}
        <td>{spData.visitors}</td>
        <td>{spData.description}</td>
        <td>{spData.date}</td>
        <td>{spData.endDate}</td>
      </tr>
    );
  });
  const next = !!nextCb;
  const prev = !!previousCb;
  if (next || prev) {
    rows.push(
      <tr className="bk-datatable-navi">
        <td colspan="4">
          <center>
            {prev && (
              <a style={{ cursor: "pointer" }} onClick={previousCb}>
                &#xab; {t("back")}
              </a>
            )}
            <span style={{ width: "100%" }}>&nbsp;</span>
            {next && (
              <a style={{ cursor: "pointer" }} onClick={nextCb}>
                {t("forward")} &#xbb;
              </a>
            )}
          </center>
        </td>
      </tr>
    );
  }
  return <table className="bk-parking-list">{rows}</table>;
};
