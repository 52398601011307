import ax from "./axiosApi";
import { genericPost } from "./axiosApi";

export const forgotPassword = async (currentUrl, language, email) =>
  genericPost(
    language,
    "register/forgotPassword",
    {
      email: email,
      currentUrl: currentUrl
    },
    "um-web"
  );

export const confirmForgotPassword = async (language, email, pin, password) =>
  genericPost(
    language,
    "confirm/forgot",
    {
      email: email,
      pin: pin,
      password: password
    },
    "um-web"
  );

export const otpLogin = async (language, email, pin, sessionId, ctx, serviceEndpoint) => {
    var loginResponse = await ax().post(
      "/um-web/fi/login/otp/validate",
      {
        email: email,
        pin: pin,
        sessionId: sessionId
      },
      { withCredentials: true }
    );   
    
    let jwt = undefined;
    if (sessionId && loginResponse.data && loginResponse.data.loginData && loginResponse.data.loginData.JWT) {
        jwt = loginResponse.data.loginData.JWT;
        document.cookie = `bookkaa-jwt=${jwt};path=/;`;
    } else if (loginResponse.status < 200 || loginResponse.status > 299) {
      return {
        errorStatus: loginResponse.status
      }
    } else {
      return loginResponse.data;
    }

    var doneResponse = await ax().post(
      `/${ctx}/${language}/${serviceEndpoint}/loginDone`,
      loginResponse.data,
      {
        withCredentials: true,
        headers: { "Content-Type": "application/json" }
      }
    );
    console.log("loginDone returned status " + doneResponse.status + " and data: " + (doneResponse.data ? JSON.stringify(doneResponse.data) : "null"));
    if (doneResponse.status === 200) {
      return loginResponse.data;
    }
    return {
      errorStatus: doneResponse.status,
      jwt: jwt
    };
  }

export const loginPromise = async (
  email,
  password,
  ctx,
  language,
  serviceEndpoint
) => {
  let jwt = null;
  try {
    let loginResponse = await ax().post(
      "/um-web/fi/login/login",
      {
        email: email,
        password: password
      },
      { withCredentials: true }
    );

    if (loginResponse.status === 401) {
      console.log("Login failed: " + loginResponse.data.errorMessage);
      return {
        ctx: ctx,
        errorStatus: loginResponse.status,
        loginError: loginResponse.data.errorMessage
      };
    } else if (loginResponse.status !== 200) {
      console.log("Login failed with status: " + loginResponse.status);
      return {
        ctx: ctx,
        errorStatus: loginResponse.status
      };
    } else if (loginResponse.data.otpLogin) {
      console.log("Login requires additional OTP");
      return loginResponse.data;
    }


    jwt = loginResponse.data.JWT;
    document.cookie = `bookkaa-jwt=${jwt};path=/;`;

    let doneResponse = await ax().post(
      `/${ctx}/${language}/${serviceEndpoint}/loginDone`,
      loginResponse.data,
      {
        withCredentials: true
      }
    );
    if (doneResponse.status === 200) {
      return {
        user: doneResponse.data,
        jwt: jwt,
        ctx: ctx
      };
    }
    return {
      errorStatus: doneResponse.status,
      jwt: jwt
    };
  
  } catch (ex) {
    console.log("Login failed: " + JSON.stringify(ex));
    if (ex.response && ex.response.data && ex.response.data.errorMessage) {
      return {
        errorStatus: ex.response.status,
        loginError: ex.response.data.errorMessage,
        ctx: ctx
      };
    }
  }
  return {
    ctx: ctx,
    jwt: jwt
  };
};

export const checkLoginPromise = async (
  existingJwt,
  ctx,
  language = "fi",
  serviceEndpoint,
  loginData
) => {
  try {
    console.log("checkLoginPromise service called");
    if (!ctx) {
      console.log("No context when checking promise!");
    }
    if (existingJwt) {
      let loggedUser = await ax().post(
        `/${ctx}/${language}/${serviceEndpoint}/loginDone`,
        { JWT: existingJwt },
        {
          withCredentials: true,
          headers: {
            "Content-Type": "application/json"
          }
        }
      );
      if (
        loggedUser.status === 200 &&
        loggedUser.data &&
        loggedUser.data.userId
      ) {
        return {
          user: loggedUser.data,
          jwt: existingJwt,
          ctx: ctx,
          ...loginData
        };
      }
    }
    let token = await ax().get("/um-web/" + language + "/login/token", {
      withCredentials: true
    });

    if (token.status === 200) {
      let jwt = token.data.JWT;
      let loggedUser = await ax().post(
        `/${ctx}/${language}/${serviceEndpoint}/loginDone`,
        token.data,
        {
          withCredentials: true
        }
      );
      document.cookie = `bookkaa-jwt=${jwt};path=/;`;
      return {
        user: loggedUser.data,
        ctx: ctx,
        jwt: jwt,
        ...loginData
      };
    } else {
      return {
        status: token.status,
        ctx: undefined,
        jwt: undefined,
        ...loginData
      };
    }
  } catch (e) {
    document.cookie = "bookkaa-jwt=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    console.log("Failed login check: " + e);
    return {
      status: e.response ? e.response.status : 400,
      loginError: "Login failed",
      jwt: undefined,
      ctx: undefined,
      ...loginData
    };
  }
};

export const logout = async (ctx, language, endpoint) => {
  try {
    document.cookie = "bookkaa-jwt=;expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    await ax().delete(`/um-web/${language}/login/login`, {
      data: {},
      withCredentials: true,
      headers: {
        "Content-Type": "application/json"
      }
    });
    await ax().delete(`/${ctx}/${language}/${endpoint}/logoutDone`, {
      data: {},
      withCredentials: true,
      headers: {
        "Content-Type": "application/json"
      }
    });
    return {
      status: 200
    };
  } catch (e) {
    console.log("Failed to log out: " + e);
    return {
      status: 400,
      loginError: "Logout failed"
    };
  }
};
