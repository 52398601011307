import React from "react";
import { genericGet, genericPost } from "../../services/axiosApi";
import BookkaaHeader from "../BookkaaHeader";
import TableAndForm from "../layout/TableAndForm";
import LoginForm from "../LoginForm";
import LoginRequired from "../LoginRequired";
import Profile from "../Profile";
import swal from "sweetalert";
import BkModifyButton from "../layout/BkModifyButton";

class AdminUsers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      selectedItem: undefined,
      userFound: undefined,
      user: undefined,
      areaCode: props.areaCode,
      areaId: props.location.area,
      okMessage: undefined,
      errorMessage: undefined,
      sellerAccess: true
    };
    this.selectCallback = this.selectCallback.bind(this);
    this.loadUsers = this.loadUsers.bind(this);
    this.searchUser = this.searchUser.bind(this);
    this.addUser = this.addUser.bind(this);
  }

  addUser() {
    const user = this.state.user
      ? {
          ...this.state.user,
          groups: this.state.user.groups ? [...this.state.user.groups] : []
        }
      : {
          email: this.state.email,
          firstName: this.state.firstName,
          lastName: this.state.lastName,
          groups: []
        };
    if (this.state.sellerAccess) {
      user.groups.push(`SELLER_${this.props.sellerCode}`);
    } else {
      user.groups.push(`AREA_${this.props.areaCode}`);
    }
    return genericPost(
      this.props.location.language,
      "users/admin",
      user,
      "um-web"
    ).then(ret => {
      if (ret.errorMessage) {
        this.setState({ errorMessage: ret.errorMessage });
      } else {
        this.setState({ okMessage: this.props.t("saved") });
      }
    });
  }
  selectCallback(user) {
    this.setState({ selectedItem: user });
  }

  componentDidMount() {
    this.loadUsers();
  }

  loadUsers() {
    if (this.state.loading) {
      return;
    }
    const a = this.props.areaCode;
    const s = this.props.sellerCode;
    this.setState({ loading: true }, () => {
      genericGet(
        this.props.location.language,
        `users/group?AREA=${a}&SELLER=${s}`,
        "um-web",
        3000
      ).then(
        ret => {
          this.setState({
            errorMessage: ret.errorMessage,
            users: ret,
            loading: false
          });
        },
        reason => {
          this.setState({
            errorMessage: "Käyttäjien haku epäonnistui",
            loading: false
          });
        }
      );
    });
  }

  searchUser() {
    return genericGet(
      this.props.location.language,
      `users/find?email=${this.state.email}`,
      "um-web",
      5000
    ).then(
      ret => {
        if (ret.id) {
          this.setState({
            userFound: true,
            user: ret,
            loading: false,
            firstName: ret.firstName,
            lastName: ret.lastName,
            sellerAccess:
              ret.groups &&
              ret.groups.includes(`SELLER_${this.props.sellerCode}`)
          });
        } else {
          this.setState({
            loading: false,
            userFound: false,
            user: undefined,
            firstName: undefined,
            lastName: undefined
          });
        }
      },
      () =>
        this.setState({ loading: false, userFound: undefined, user: undefined })
    );
  }

  render() {
    const l = this.props.location;
    const t = this.props.t;
    const location = {
      ...l,
      ctx: "um-web",
      endpoint: "users",
      language: l.language
    };
    const errorDiv = [];

    if (this.state.errorMessage) {
      setTimeout(() => this.setState({ errorMessage: undefined }), 5000);
      errorDiv.push(
        <div
          className="bk_errorMessage sticky"
          style={{ display: "block" }}
          onClick={() => this.setState({ errorMessage: undefined })}
        >
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.okMessage) {
      setTimeout(() => this.setState({ okMessage: undefined }), 5000);
      errorDiv.push(
        <div
          className="bk_okMessage sticky"
          style={{ display: "block" }}
          onClick={() => this.setState({ okMessage: undefined })}
        >
          {this.state.okMessage}
        </div>
      );
    }
    const adminUserList = [];
    if (this.state.users && Array.isArray(this.state.users)) {
      this.state.users.forEach(u => {
        adminUserList.push(
          <tr>
            <td>{u.email}</td>
            <td>{u.firstName}</td>
            <td>{u.lastName}</td>
            <td>
              {u.groups.includes(`AREA_${this.props.areaCode}`) ? "X" : ""}
            </td>
            <td>
              {u.groups.includes(`SELLER_${this.props.sellerCode}`) ? "X" : ""}
            </td>
          </tr>
        );
      });
    }

    const content = [];
    content.push(
      <center>
        <div className="bk_placeDetails">
          <table>
            <tr>
              <td className="title" colspan="3">
                {t("addUserTitle")}
              </td>
            </tr>
            {this.state.userFound !== undefined && (
              <React.Fragment>
                <tr>
                  <th>{t("firstName")}</th>
                  <td>
                    <input
                      value={this.state.firstName}
                      onChange={e =>
                        this.setState({
                          firstName: e.target.value
                        })
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <th>{t("lastName")}</th>
                  <td>
                    <input
                      value={this.state.lastName}
                      onChange={e =>
                        this.setState({
                          lastName: e.target.value
                        })
                      }
                    />
                  </td>
                </tr>
              </React.Fragment>
            )}
            <tr>
              <th>{t("addUserEmail")}</th>
              <td>
                <input
                  value={this.state.email}
                  onChange={e =>
                    this.setState({
                      email: e.target.value,
                      userFound: undefined,
                      user: undefined
                    })
                  }
                />
              </td>
            </tr>
            <tr>
              <td>
                {this.state.userFound !== undefined && (
                  <select
                    onChange={e =>
                      this.setState({
                        sellerAccess: e.target.value === "SELLER"
                      })
                    }
                  >
                    <option selected={this.state.sellerAccess} value="SELLER">
                      {t("sellerAccess")}
                    </option>
                    <option selected={!this.state.sellerAccess} value="AREA">
                      {t("areaAccess")}
                    </option>
                  </select>
                )}
              </td>
              <td>
                {this.state.userFound === undefined && (
                  <BkModifyButton
                    disabled={this.state.loading}
                    buttonTitle={t("search")}
                    callback={this.searchUser}
                  />
                )}
                {this.state.userFound === true && (
                  <BkModifyButton
                    buttonTitle={t("addUserRight")}
                    callback={this.addUser}
                  />
                )}
                {this.state.userFound === false && (
                  <BkModifyButton
                    disabled={!this.state.firstName || !this.state.lastName}
                    buttonTitle={t("addUser")}
                    callback={this.addUser}
                  />
                )}
                <BkModifyButton
                  callback={this.props.closeCb}
                  buttonTitle={t("close-button")}
                />
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td className="title" colspan="5">
                {t("adminUsersTitle")}
              </td>
            </tr>
            <tr>
              <th>{t("email")}</th>
              <th>{t("firstName")}</th>
              <th>{t("lastName")}</th>
              <th>{t("areaAccess")}</th>
              <th>{t("sellerAccess")}</th>
            </tr>
            {adminUserList}
          </table>
        </div>
      </center>
    );

    return (
      <React.Fragment>
        <LoginRequired location={location} {...this.props.loginProps} />
        <BookkaaHeader location={location} {...this.props.headerProps} />
        <LoginForm location={location} {...this.props.loginProps} />
        <Profile location={location} {...this.props.profileProps}>
          {errorDiv}
          {content}
        </Profile>
      </React.Fragment>
    );
  }

  renderAllUsers() {
    const l = this.props.location;
    const t = key => l.translate(l, key);

    const location = { ctx: "um-web", endpoint: "users", language: l.language };

    let beforeForm = [];
    const CLOSE_BUTTON = {
      supportsMulti: true,
      callback: this.props.closeCb,
      title: t("button-close")
    };

    let listButtonArray = [CLOSE_BUTTON];

    return (
      <React.Fragment>
        <LoginRequired location={location} {...this.props.loginProps} />
        <BookkaaHeader location={location} {...this.props.headerProps} />
        <LoginForm location={location} {...this.props.loginProps} />
        <Profile location={location} {...this.props.profileProps}>
          <TableAndForm
            selectCallback={this.selectCallback}
            itemsBeforeForm={beforeForm}
            buttons={listButtonArray}
            formButtonArray={[]}
            multiSelect={false}
            {...this.props}
            location={location}
            search={
              this.state.selectedType
                ? `areaCode=${this.props.areaCode}&sellerCode=${this.props.sellerCode}`
                : undefined
            }
          />
        </Profile>
      </React.Fragment>
    );
  }
}
export default AdminUsers;
