import React from "react";
import LoginRequired from "../LoginRequired";
import LoginForm from "../LoginForm";
import BookkaaHeader from "../BookkaaHeader";
import PayoutsReport from "./PayoutsReport";

class PayoutsPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const l = this.props.location;

    return (
      <React.Fragment>
        <LoginRequired location={l} {...this.props.loginProps} />
        <LoginForm location={l} {...this.props.loginProps} />
        <BookkaaHeader
          location={l}
          {...this.props.headerProps}
          disableProfile={true}
        />
        <PayoutsReport {...this.props} />
      </React.Fragment>
    );
  }
}

export default PayoutsPage;
