import React from "react";
import axiosApi, { genericGet, genericPost } from "../../services/axiosApi";
import DynamicMap from "../admin/DynamicMap";
import Redirect from "react-router/Redirect";
import { CircleLoader } from "react-spinners";
import { askSize } from "../boatparking/BoatParking";
import BkModifyButton from "../layout/BkModifyButton";

class SeasonReservation extends React.Component {
  constructor(props) {
    super(props);
    let pier = undefined;
    if (props.location.file && props.location.file.length < 3) {
      pier = props.location.file;
    }
    this.state = {
      data: {},
      seasonId: undefined,
      seasonText: undefined,
      modalIsOpen: false,
      productId: undefined,
      customer: undefined,
      cart: undefined,
      width: 600,
      totalImageWidth: 0,
      errorMessage: undefined,
      pier: pier,
      reserveOngoing: false
    };
    // without this we don't have the right "this" reference in the callback
    this.closeModal = this.closeModal.bind(this);
    this.checkout = this.checkout.bind(this);
    this.closeShoppingCart = this.closeShoppingCart.bind(this);
    this.getFirst = this.getFirst.bind(this);

    props.checkLoggedIn(
      props.jwt,
      "booking-web",
      props.location.language,
      "reserve"
    );
    if (props.productId || props.subareaId) {
      var referrer = document.referrer;
      console.log("referrer url", referrer);
      if (referrer.indexOf("www.satamapaikka.com") === -1) {
        referrer = "SEASONCLIENT";
      }
      this.checkout(props.productId, props.subareaId, referrer);
    }
  }

  update() {
    console.log("Update called");
    this.props.getImageMap(
      this.props.location.language,
      this.props.location.area,
      null,
      "PUBLIC_SEASON"
    );
  }

  updateWindowDimensions() {
    if (this.state.width !== window.innerWidth) {
      this.setState({ width: window.innerWidth });
    }
  }

  componentDidMount() {
    this.update();
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  closeShoppingCart() {
    this.props.closeShoppingCart();
    this.setState({
      modalIsOpen: false,
      customer: undefined,
      cart: undefined
    });
  }

  render() {
    const l = this.props.location; // shortcut
    if (!l.area) {
      // TODO: render list of harbours
      console.log("Area not set");
      return null;
    }
    const t = key => l.translate(l, key);

    let errorMessage = "";
    if (this.state.errorMessage || this.props.paymentError) {
      console.log(
        `error: ${this.state.errorMessage}, paymentError: ${this.props.paymentError}`
      );
      errorMessage = this.state.errorMessage
        ? this.state.errorMessage
        : this.props.paymentError;
    }
    const errorDiv = [];
    if (errorMessage) {
      errorDiv.push(
        <div className="bk_errorMessage" style={{ display: "block" }} onClick={() => this.setState({ errorMessage: undefined })}>
          {errorMessage}
        </div>
      );
    }

    if (this.state.cart && this.state.cart.orderNumber) {
      return (
        <Redirect
          to={`/payReservation/${l.language}/${l.area}/${this.state.cart.orderNumber}`}
        />
      );
    }

    const maps = this.props.imageMap ? this.props.imageMap.subareas : undefined;
    const onlyOneMap = maps && maps.length === 1;

    const content = [];
    if (this.props.reserveFirst) {
      const boat = {...this.state.boat };
      const size = askSize(t, boat, (prop, val) => {
        boat[prop] = val;
        this.setState({ boat: boat }, () => {
          this.getFirst().then(r => this.setState({ price: r ? r.totalPrice : undefined }));
        });
      });
      const area = this.props.imageMap && this.props.imageMap.area ? this.props.imageMap.area  : {};
      const btn = <BkModifyButton 
          disabled={!this.state.boat || !this.state.boat.length || !this.state.boat.width}
          buttonTitle={t("reserve-button")} 
          callback={() => this.getFirst().then(r => this.checkout(r.id, r.subareaId, "SEASONCLIENT_ANY", 
            { boatLength: boat.length, boatWidth: boat.width, boatDepth: boat.depth }))} 
          busy={this.state.reserveOngoing} />;
      content.push(<table>
        <tr><td style={{width:"25px"}}/><td>{area.name}</td></tr>
        <tr><td/><td>{t("reserveFirstDescription")}</td></tr>
        <tr><td/><td>{size}</td></tr>
        <tr><td/><td>{btn}</td></tr>
        </table>);
    } else {
      content.push(<DynamicMap
        {...this.props}
        t={t}
        disableTitle={onlyOneMap}
        onClick={(a, sa) => {
          if (!this.state.reserveOngoing && (!a.holder || !a.holder.id)) {
            this.checkout(a.productId, undefined, "SEASONCLIENT");
          }
        }}
      />);
    }
    return (
      <div className="bkTabContent">
        {this.state.reserveOngoing && (
          <div className="centered">
            <CircleLoader />
          </div>
        )}
        {errorDiv}
        {content}
      </div>
    );
  }

  cancelPayment(a, sa) {
    const l = this.props.location;
    let pq = {
      areaId: l.area,
      productId: a.productId,
      subareaId: a.subareaId
    };
    axiosApi()
      .delete(
        `/booking-web/${l.language}/a${l.area}/reserve/cancelReservation`,
        pq,
        {
          withCredentials: true,
          timeout: 30000
        }
      )
      .then(response => {
        this.setState({
          modalIsOpen: false,
          customer: response.data.customer,
          cart: response.data,
          reserveOngoing: false
        });
        this.update();
      });
  }
  getFirst() {
    const l = this.props.location;
    const b = this.state.boat ? this.state.boat : {};
    return genericGet(l.language, `a${l.area}/reserve/any?boatLength=${b.length}&boatWidth=${b.width}`).then(
      (r) => { 
        this.setState({ reserveOngoing: false, errorMessage: r.errorMessage, firstPlace: r});
        return r;
      }
    );
  }
  
  checkout(productId, subareaId, referrer, boat) {
    const l = this.props.location;
    let pq = {
      areaId: l.area,
      productId: productId,
      subareaId: subareaId,
      referrer: referrer,
      boat: boat
    };
    this.setState({ reserveOngoing: true });
    genericPost(
      l.language,
      `a${l.area}/reserve/reserveSeasonPlace`,
      pq,
      "booking-web"
    ).then(
      data => {
        this.setState({
          modalIsOpen: false,
          customer: data.customer,
          cart: data,
          errorMessage: data.errorMessage,
          reserveOngoing: false
        });
        this.props.storeReferrer(data.orderNumber, referrer);
      }
    );
  }
}
export default SeasonReservation;
