import React from "react";
import ImageMapper from "react-image-mapper";
import { CircleLoader } from "react-spinners";

class DynamicMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hoveredItem: undefined,
      hoveredX: undefined,
      hoveredY: undefined,
      width: 600,
      top: 235
    };

    this.mouseMoveOutside = this.mouseMoveOutside.bind(this);
    this.clicked = this.clicked.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
    this.enterArea = this.enterArea.bind(this);
    this.leaveArea = this.leaveArea.bind(this);
    this.createImageMaps = this.createImageMaps.bind(this);
    this.getTipPosition = this.getTipPosition.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.getTooltip = this.getTooltip.bind(this);
  }

  imageDivRef = undefined;
  updateWindowDimensions() {
    if (this.imageDivRef) {
      const rect = this.imageDivRef.getBoundingClientRect();
      if (this.state.width !== rect.width) {
        this.setState({ width: rect.width });
      }
      if (this.state.top !== rect.top) {
        this.setState({ top: rect.top });
      }
    } else {
      console.debug("No image div");
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    this.updateWindowDimensions();
    if (this.state.loadingMap) {
      return <CircleLoader />;
    } else {
      const tooltip = this.getTooltip(this.state.hoveredItem);
      const imageMaps = this.createImageMaps(this.props.location);
      return (
        <React.Fragment>
          <div
            ref={divRef => (this.imageDivRef = divRef)}
            style={{ position: "relative" }}
          >
            {tooltip}
          </div>
          {this.state.width && (
            <div className="pier-dynamic-image">{imageMaps}</div>
          )}
        </React.Fragment>
      );
    }
  }

  getTooltip(hi) {
    if (!hi || !this.state.hoveredX) {
      return undefined;
    }
    const ttStyle = {
      ...this.getTipPosition(
        hi,
        this.state.hoveredX,
        this.state.hoveredY - this.state.top
      )
    };
    if (this.props.tooltipRenderer) {
      return this.props.tooltipRenderer(hi, ttStyle);
    }

    return (
      <span className="tooltip" style={ttStyle}>
        {hi.code && hi.name ? `${hi.code}: ${hi.name}` : hi.code ? hi.code : ""}
        <br />
        {hi.width && hi.length
          ? `${hi.length} x ${hi.width} ${hi.depth ? " x " + hi.depth : ""} m`
          : ""}
        <br />
        {hi.price} €
        <br />
        {hi.placeTypeStr}
      </span>
    );
  }

  createImageMaps(l) {
    const imageMaps = [];
    let maps = this.props.imageMap ? this.props.imageMap.subareas : undefined;
    if (this.props.selectedSubarea === 0) {
      maps = this.props.imageMap.wholeArea
        ? [this.props.imageMap.wholeArea]
        : [];
    } else if (
      (!maps || maps.length === 0) &&
      this.props.imageMap &&
      this.props.imageMap.wholeArea
    ) {
      maps = [this.props.imageMap.wholeArea];
    } else if (!maps || maps.length === 0) {
      console.log("No image map found");
      maps = [];
    }
    for (let i = 0; i < maps.length; i++) {
      if (
        this.props.selectedSubarea &&
        this.props.selectedSubarea !== maps[i].subarea
      ) {
        continue;
      }
      let maxW = maps[i].imageWidth;
      let minW = (maps.length > 1 && !maps[i].wide) ? (maxW > 800 ? 800 : maxW) : this.state.width;
      if (minW > maxW) {
        minW = maxW;
      }
      var divider = this.props.noScale ? 1 : maxW / minW;
      if (!maps[i].areas) {
        console.log("SKIPPING map to render nro " + i + ": no areas");
        continue;
      }
      const decycled = {
        name: maps[i].name,
        imageWidth: maps[i].imageWidth,
        imageHeight: maps[i].imageHeight
      };
      decycled.areas = [];
      maps[i].areas.forEach(e => {
        decycled.areas.push({
          productId: e.productId,
          placeId: e.placeId,
          subareaId: e.subareaId,
          shape: e.shape,
          placeType: e.placeType,
          placeTypeStr: e.placeTypeStr,
          productType: e.productType,
          productTypeStr: e.productTypeStr,
          coords: Array.from(e.coords),
          code: e.code,
          name: e.name,
          price: e.price,
          width: e.width,
          length: e.length,
          depth: e.depth,
          description: e.description,
          fillColor: e.fillColor,
          preFillColor: e.preFillColor,
          electricity: e.electricity,
          holderName: e.holderName
        });
      });
      //console.log("Map to render nro " + i + ": " + JSON.stringify(decycled));
      const ima = this.props.imageMap ? this.props.imageMap.area : undefined;
      const title =
        ima && maps[i].subareaCode
          ? `${ima.code} / ${ima.name} / laituri: ${maps[i].subareaCode}`
          : undefined;

      imageMaps.push(
        <div
          style={{ float: "left", padding: "10px" }}
          ref={ref => (maps[i].ref = ref)}
        >
          {!this.props.disableTitle && <strong>{title}</strong>}
          <MyImageMapper
            active={true}
            width={maps[i].imageWidth / divider}
            imgWidth={maps[i].imageWidth}
            src={`/booking-web/${l.language}/images/${l.area}_${maps[i].subarea}.png?h=${maps[i].hash}`}
            map={decycled}
            onLoad={() => this.load()}
            onClick={(area, _idx, event) => this.clicked(area, maps[i], event)}
            onMouseEnter={(area, _idx, event) => this.enterArea(area, event)}
            onMouseLeave={area => this.leaveArea(area)}
            onImageClick={evt => this.clickedOutside(evt)}
          />
        </div>
      );
    }
    return imageMaps;
  }

  clicked(area, subarea, event) {
    console.log("clicked: " + event);
    this.setState({
      hoveredItem: area,
      hoveredX: event.pageX + 30,
      hoveredY: event.pageY
    });
    if (this.props.onClick) {
      this.props.onClick(area, subarea, event);
      //     this.openPlaceDetails(area, subarea, false);
    }
  }
  clickedOutside(event) {
    if (!this.state.openedPlaceDetails) {
      this.setState({ hoveredItem: undefined });
    }
    if (this.props.onClick) {
      this.props.onClick(null, null, event);
      //     this.openPlaceDetails(area, subarea, false);
    }
  }
  mouseMoveOutside() {}
  enterArea(a, event) {
    if (this.props.onMouseEnter) {
      this.props.onMouseEnter(event);
    }
    if (!this.state.openedPlaceDetails) {
      this.setState({
        hoveredItem: a,
        hoveredX: event.clientX,
        hoveredY: event.clientY
      });
    }
  }
  leaveArea() {
    if (!this.state.openedPlaceDetails) {
      // console.log("leaveArea() called");
      this.setState({
        hoveredItem: undefined,
        hoveredX: undefined,
        hoveredY: undefined
      });
    }
  }
  load() {
    //console.log("load() called");
  }
  // NOTE: copied from SeasonClient.js: should be in some MapUtility class
  getTipPosition(area, hoveredX, hoveredY) {
    if (!area) return { top: 0, left: 0 };
    // Calculate centroid
    const y = hoveredY; //  - this.state.top;
    const x = hoveredX;
    return {
      top: `${y}px`,
      left: `${x}px`,
      width: "auto",
      position: "absolute"
    };
  }
}

export default DynamicMap;

class MyImageMapper extends React.Component {
  render() {
    const {
      active,
      width,
      imgWidth,
      src,
      map,
      onLoad,
      onClick,
      onMouseEnter,
      onMouseLeave,
      onImageClick
    } = this.props;
    return (
      <ImageMapper
        active={active}
        width={width}
        imgWidth={imgWidth}
        src={src}
        map={map}
        onLoad={onLoad}
        onClick={onClick}
        onMouseMove={this.props.onMouseMove}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onImageClick={onImageClick}
      />
    );
  }
  componentDidCatch(e) {
    console.log("Error: " + e);
  }
}
