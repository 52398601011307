import React from "react";
import PropTypes from "prop-types";
import BkModifyButton from "../components/layout/BkModifyButton";
import { CircleLoader } from "react-spinners";
import BookkaaHeader from "../components/BookkaaHeader";
import BkContentArea from "../components/layout/BkContentArea";
import moment from "moment";

class VsEnterCurrentVisitors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      visitors: undefined,
      start: 0,
      pageSize: 15,
      latestRequest: undefined
    };
    this.saveData = this.saveData.bind(this);
    this.reloadData = this.reloadData.bind(this);

    if (this.props.jwt) {
      this.reloadData(this.props.location.area);
    }
    if (
      !this.props.vallisaariVisitors &&
      this.props.vallisaariData &&
      !this.props.errorMessage
    ) {
      this.reloadData(true);
    }
  }

  componentDidUpdate(props, state) {
    if (state.start != this.state.start) {
      this.reloadData(true);
    }
  }

  render() {
    const t = k => this.props.location.translate(this.props.location, k);
    const content = [];
    if (this.props.errorMessage) {
      content.push(
        <div className="bk_errorMessage" style={{ display: "block" }}>
          {this.props.errorMessage}
        </div>
      );
    } else if (!this.props.vallisaariVisitors && this.props.location.sp) {
      this.reloadData(true);
    } else if (
      this.props.vallisaariVisitors &&
      this.props.vallisaariVisitors.reference == this.state.latestRequest &&
      this.props.vallisaariVisitors.reference != this.state.handledRequest
    ) {
      console.log("adding ok message");
      content.push(
        <div className="bk_okMessage" style={{ display: "block" }}>
          {t("reportSaved")}
        </div>
      );
      if (this.state.handledRequest !== this.state.latestRequest) {
        this.reloadData(true);
        this.setState({ handledRequest: this.state.latestRequest });
      }
    }
    if (!this.props.jwt) {
      content.push(<CircleLoader />);
    } else {
      content.push(
        <div
          className="harbour-and-season-container"
          style={{ fontSize: "1em", border: "dashed", padding: "20px" }}
        >
          <center>
            <h3>{t("visitors-content-header")}</h3>
          </center>
          <center>
            <span>*{t("visitorsArrived")}</span>
            <input
              className="small-input"
              value={this.state.arrived}
              onChange={e => this.setState({ arrived: e.target.value })}
            />
            <span>*{t("visitorsLeft")}</span>
            <input
              className="small-input"
              value={this.state.left}
              onChange={e => this.setState({ left: e.target.value })}
            />
          </center>
          <center>
            <BkModifyButton
              buttonTitle={t("save-button")}
              callback={() => this.saveData()}
            />
          </center>
        </div>
      );
    }

    if (Array.isArray(this.props.vallisaariVisitors)) {
      const rows = [];
      rows.push(
        <tr>
          <th>{t("day")}</th>
          <th>{t("time")}</th>
          <th>{t("arrivedCount")}</th>
          <th>{t("leftCount")}</th>
        </tr>
      );
      this.props.vallisaariVisitors.forEach((spData, idx) => {
        rows.push(
          <tr className={idx % 2 == 0 ? "td-even" : "td-odd"}>
            <td>{spData.day}</td>
            <td>{spData.time}</td>
            <td>{spData.arrived}</td>
            <td>{spData.left}</td>
          </tr>
        );
      });
      const next =
        this.props.vallisaariVisitors &&
        this.props.vallisaariVisitors.length == this.state.pageSize;
      const prev = this.state.start > 0;
      if (next || prev) {
        rows.push(
          <tr className="bk-datatable-navi">
            <td colspan="4">
              <center>
                {prev && (
                  <a
                    onClick={() =>
                      this.setState({
                        start:
                          this.state.start < this.state.pageSize
                            ? 0
                            : this.state.start - this.state.pageSize,
                        latestRequest: undefined
                      })
                    }
                  >
                    &#xab; {t("back")}
                  </a>
                )}
                <span style={{ width: "100%" }}>&nbsp;</span>
                {next && (
                  <a
                    onClick={() =>
                      this.setState({
                        start: this.state.start + this.state.pageSize,
                        latestRequest: undefined
                      })
                    }
                  >
                    {t("forward")} &#xbb;
                  </a>
                )}
              </center>
            </td>
          </tr>
        );
      }
      content.push(<table className="bk-parking-list">{rows}</table>);
    }

    return (
      <React.Fragment>
        <BookkaaHeader
          {...this.props.headerProps}
          location={this.props.location}
          disableProfile={true}
        />
        <BkContentArea maxWidth="1000px">{content}</BkContentArea>
      </React.Fragment>
    );
  }

  reloadData(loadReport) {
    console.log("Loading data");
    if (loadReport) {
      this.props.getVisitors(
        this.props.location,
        this.state.start,
        this.state.pageSize
      );
    } else {
      this.props.loadVallisaari(this.props.location);
    }
  }

  saveData() {
    const ref = Math.floor(Math.random() * 100000 + 1);
    this.setState({ latestRequest: ref });
    this.props.enterVisitors(this.props.location, {
      reference: ref,
      arrived: this.state.arrived,
      left: this.state.left
    });
  }

  componentDidMount() {
    this.props.ensureLoggedIn(
      this.props.jwt,
      this.props.location.ctx,
      this.props.location.language,
      "saari"
    );
  }
}
export default VsEnterCurrentVisitors;

VsEnterCurrentVisitors.propTypes = {
  vallisaariData: PropTypes.object,
  location: PropTypes.object.isRequired
};
