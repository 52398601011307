import React from "react";
import Modal from "react-modal";
import Datatable from "../datatable/Datatable";
import AlpacaForm from "../alpaca/Form";
import BkCreateButton from "../layout/BkCreateButton";
import BkModifyButton from "../layout/BkModifyButton";
import BkContentArea from "../layout/BkContentArea";
import { customStyles } from "../layout/ModalStyle";

class ServiceProviders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      dataUnhandled: null,
      modalIsOpen: false,
      updateModalIsOpen: false
    };
    // without this we don't have the right "this" reference in the callback
    this.setSelectedObject = this.setSelectedObject.bind(this);
    this.setUnhandledObject = this.setUnhandledObject.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveData = this.saveData.bind(this);
    this.accept = this.accept.bind(this);
    this.reject = this.reject.bind(this);
  }

  /*
  loadMenu() {
    if (location.ctx && !loginDialogVisible) {
      console.log("requesting menu");
      this.props.getMenu(location.ctx, location.language, location.endpoint);
    }
  }*/

  afterOpenModal() {}

  closeModal() {
    this.setState({ modalIsOpen: false });
  }
  afterOpenModal() {}

  closeUpdateModal() {
    this.setState({ updateModalIsOpen: false });
  }

  render() {
    return (
      <React.Fragment>
        <BkContentArea>
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel="Palvaluntarjoaja"
          >
            <AlpacaForm
              {...this.props}
              data={this.state.data}
              onRef={ref => (this.form = ref)}
            />
          </Modal>
          <Modal
            isOpen={this.state.updateModalIsOpen}
            onAfterOpen={this.afterOpenUpdateModal}
            onRequestClose={this.closeUpdateModal}
            style={customStyles}
            contentLabel="Palvaluntarjoaja"
          >
            <AlpacaForm
              {...this.props}
              data={this.state.data}
              onRef={ref => (this.form = ref)}
            />
          </Modal>
          <Datatable
            {...this.props}
            file="unhandledDatatable"
            selectCallback={this.setUnhandledObject}
          />
          <BkModifyButton
            {...this.props}
            callack={this.approve}
            buttonTitle="Hyväksy"
            disabled={this.state.dataUnhandled == null}
          />
          <BkModifyButton
            {...this.props}
            callack={this.reject}
            buttonTitle="Hylkää"
            disabled={this.state.dataUnhandled == null}
          />
          <Datatable {...this.props} selectCallback={this.setSelectedObject} />
          <BkCreateButton {...this.props} addDataCallack={this.addData} />
          <BkModifyButton
            {...this.props}
            callack={this.saveData}
            selected={this.state.data}
          />
        </BkContentArea>
      </React.Fragment>
    );
  }
  accept() {
    if (this.state.dataUnhandled) {
    }
  }
  reject() {}
  saveData() {}
  addData(obj) {}
  setSelectedObject(obj) {
    console.log(
      "Setting selected object: " +
        JSON.stringify(obj) +
        ", current: " +
        JSON.stringify(this.state.data)
    );
    this.setState(
      {
        data: obj,
        updateModalIsOpen: true
      },
      function() {
        console.log("State changed: " + JSON.stringify(this.state.data));
      }
    );
  }
  setUnhandledObject(obj) {
    console.log(
      "Setting selected unhandled object: " +
        JSON.stringify(obj) +
        ", current: " +
        JSON.stringify(this.state.dataUnhandled)
    );
    this.setState(
      {
        dataUnhandled: obj,
        modalIsOpen: true
      },
      function() {
        console.log(
          "State changed: " + JSON.stringify(this.state.dataUnhandled)
        );
      }
    );
  }
}
export default ServiceProviders;
