import React from "react";
import BookkaaHeader from "../BookkaaHeader";
import LoginForm from "../LoginForm";
import LoginRequired from "../LoginRequired";
import Profile from "../Profile";
import { genericGet, genericPost, genericPut } from "../../services/axiosApi";
import BkModifyButton, { getButtons } from "../layout/BkModifyButton";

class SeasonMassUpdate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sellerId: undefined,
      areaId: props.location.area,
      busy: false,
      errorMsg: undefined
    };
    const l = props.location;
    props.getTranslations(l.ctx, l.language, l.endpoint);
    if (!this.props.areas) {
      this.props.getAreas(l.language);
    }
    this.timer = null;
    this.uploadPlaces = this.uploadPlaces.bind(this);
    this.acceptUpdate = this.acceptUpdate.bind(this);
    this.pollResult = this.pollResult.bind(this);
  }

  componentDidCatch(e) {
    console.error("Failed: " + e + ", stack: " + e.stack);
  }

  pollResult(l, ref) {
    return genericGet(l.language, `seasonAdmin/places/import/result/${ref}`).then(r => {
      if (r.data || r.errorMessage) {
        clearTimeout(this.uploadTimer);
        this.uploadTimer = null;
      } else {
        this.uploadTimer = setTimeout(() => this.pollResult(l, ref), 1000);
      }
      this.setState({
        result: r.data ? r : undefined,
        errorMessage: r.errorMessage,
        busy: false
      });
    });
  }

  uploadPlaces(data) {
    const l = this.props.location;
    const formData = new FormData();
    formData.append("places", data, data.name);
    this.setState({ busy: true });
    return genericPost(
      l.language,
      `seasonAdmin/places/${l.area}/import.xlsx`,
      formData,
      "booking-web",
      "multipart/form-data",
      45000
    ).then(r => {
      const ref = r.ref;
      if (ref) {
        this.uploadTimer = setTimeout(() => this.pollResult(l, ref), 1000);
      } else {
        this.setState({
          errorMessage: r.errorMessage
        });
      }
    });
  }

  pollAccept(l, ref) {
    return genericGet(l.language, `seasonAdmin/places/accept/result/${ref}`).then(r => {
      if (r.data || r.errorMessage) {
        clearTimeout(this.uploadTimer);
        this.uploadTimer = null;
      } else {
        this.uploadTimer = setTimeout(() => this.pollAccept(l, ref), 1000);
      }
      this.setState({
        result: r,
        errorMessage: r.errorMessage,
        okMessage: r.okMessage,
        busy: false
      });
    });
  }

  acceptUpdate() {
    const l = this.props.location;
    const data = this.state.result;
    this.setState({ busy: true });
    return genericPut(
      l.language,
      `seasonAdmin/places/${l.area}/accept2`,
      data,
      "booking-web",
      "application/json",
      45000
    ).then(r => {
      const ref = r.ref;
      if (ref) {
        this.uploadTimer = setTimeout(() => this.pollAccept(l, ref), 1000);
      } else {
        this.setState({
          errorMessage: r.errorMessage
        });
      }
    });
  }

  render() {
    const location = this.props.location;
    const t = key => location.translate(location, key);

    const content = [];

    const error = this.state.errorMessage ? (
      <div className="bk_errorMessage" style={{ display: "block" }}>
        {this.state.errorMessage}
      </div>
    ) : this.state.okMessage ? (
      <div
        className="bk_okMessage"
        style={{ display: "block" }}
        onClick={() => this.setState({ okMessage: undefined })}
      >
        {this.state.okMessage}
      </div>
    ) : (
      undefined
    );
    if (error) {
      content.push(error);
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(
        () => this.setState({ errorMessage: undefined, okMessage: undefined }),
        20000
      );
    }
    const UPLOAD_PLACES_BUTTON = {
      supportsMulti: false,
      callback: this.uploadPlaces,
      fileUpload: true,
      title: t("upload-places-file"),
      fileExtensions: ".xlsx"
    };

    const buttons = getButtons([UPLOAD_PLACES_BUTTON], {});
    content.push(...buttons);

    var invalidData = true;
    if (this.state.result && this.state.result.headers) {
      invalidData = false;
      const heads = [];
      this.state.result.headers.forEach(element =>
        heads.push(<th>{element}</th>)
      );

      content.push(
        <table className="bk-list">
          <tr>{heads}</tr>
          <MassUpdateDiff data={this.state.result.data} invalidDataCb={v => invalidData = v} />
        </table>
      );

      content.push(<hr />);
      content.push(
        <BkModifyButton
          buttonTitle={t("accept-places-file")}
          disabled={invalidData}
          callback={() => this.acceptUpdate()}
        />
      );
    }

    const loadUrl = `/booking-web/${location.language}/seasonAdmin/places/${location.area}/export.xlsx`;
    return (
      <React.Fragment>
        <LoginRequired location={location} {...this.props.loginProps}>
          <BookkaaHeader
            location={location}
            {...this.props.headerProps}
            disableProfile={true}
          />
          <LoginForm location={location} {...this.props.loginProps} />
          <Profile location={location} {...this.props.profileProps}>
            <br />
            <p>{t("upload-places-info")}</p>
            <a href={loadUrl}>{t("download-places-file")}</a>
            <br />
            {content}
          </Profile>
        </LoginRequired>
      </React.Fragment>
    );
  }
}
export const MassUpdateDiff = props => {
  const vals = [];
  props.data.forEach(element =>
    vals.push(
      <tr
        style={
          element.deleted ? { textDecorationLine: "line-through" } : {}
        }
      >
        {element.deleted
          ? element.oldData.map((d, i) => {
              return <td>{d}</td>;
            })
          : element.newData.map((d, i) => {
              if (element.invalidFields[i]) {
                if (props.invalidDataCb) {
                  props.invalidDataCb(true);
                }
                return <td style={{ backgroundColor: "red" }}>{d}</td>;
              } else if (element.changedReadonlyFields[i]) {
                return <td style={{ backgroundColor: "blue" }}>{d}</td>;
              } else if (!element.oldData) {
                return <td style={{ backgroundColor: "green" }}>{d}</td>;
              } else if (element.oldData[i] !== d) {
                return <td style={{ backgroundColor: "yellow" }}>{d}</td>;
              } else {
                return <td>{d}</td>;
              }
            })}
      </tr>
    )
  );
  return vals;
}

export default SeasonMassUpdate;
