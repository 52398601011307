import React from "react";
import styled from "styled-components";
import { prop } from "styled-tools";

class BkCreateButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    console.log("Button clicked => " + this.props.callback);
    this.props.callback();
  }
  render() {
    return (
      <React.Fragment>
        <button
          className="ui-button ui-corner-all ui-widget"
          onClick={this.handleClick}
        >
          {this.props.buttonTitle ? this.props.buttonTitle : "Luo uusi"}
        </button>
      </React.Fragment>
    );
  }
}
export default BkCreateButton;
