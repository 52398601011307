import React from "react";

export const BoatOrPlaceSize = props => {
  const { length, width, depth } = props;
  console.log("len: " + length + ",wid" + width + ",dep=" + depth);
  return (
    <span>
      {length && <React.Fragment>{length} m</React.Fragment>}
      {length && width && " x "}
      {width && <React.Fragment>{width} m</React.Fragment>}
      {depth && <React.Fragment> x {depth} m</React.Fragment>}
    </span>
  );
};
