import React from "react";

export const Conditions = props => {
  const { agreedOn, toggleAgree, t, cartId, language, agreed, url } = props;

  let link = url
    ? url
    : `/booking-web/${language}/payment/conditions/${cartId}.pdf`;

  let checkbox = "";
  if (!agreedOn) {
    checkbox = (
      <React.Fragment>
        <label>
          <input
            type="checkbox"
            value="agreeTerms"
            onChange={toggleAgree}
            checked={agreed}
          />
          {t("agree")}{" "}
          <a target="_blank" rel="noopener noreferrer" href={link}>
            {t("terms")}
          </a>
        </label>
      </React.Fragment>
    );
  } else {
    // already agreed
    checkbox = (
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={`/booking-web/fi/payment/conditions/${this.props.cartId}`}
      >
        {t("previouslyAccepted")}
      </a>
    );
  }
  return checkbox;
};

export default Conditions;
