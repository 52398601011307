import React from "react";
import { Link } from "react-router-dom";
import BookkaaHeader from "./BookkaaHeader";
import Profile from "./Profile";
import { CircleLoader } from "react-spinners";

const location = {
  ctx: "booking-web",
  endpoint: "start",
  language: "fi"
};

const Harbour = props => {
  return props.visible ? (
    <li className="harbour">
      <Link to={`guest/${props.language}/${props.id}`}>{props.name}</Link>
    </li>
  ) : (
    <React.Fragment />
  );
};

const FrontPage = props => {
  window.location.href = "http://www.satamapaikka.com";
  return (
    <React.Fragment>
      <CircleLoader />
    </React.Fragment>
  );
  // TODO: list harbours maybe later?
  let harbours = [];
  if (props.frontPage && props.frontPage.harbours) {
    console.log("Frontpage: " + JSON.stringify(props.frontPage));
    for (let i = 0; i < props.frontPage.harbours.length; i++) {
      harbours.push(
        <Harbour language={props.language} {...props.frontPage.harbours[i]} />
      );
    }
  } else {
    console.log("No frontPage: " + JSON.stringify(props));
  }
  const admin = false;

  return (
    <React.Fragment>
      <BookkaaHeader location={location} {...props} />
      <Profile location={location} {...props} />
      {admin && (
        <div>
          <Link to="msg/fi/templates/templates.do">Viestikeskus</Link>
          {" | "}
          <Link to="um/fi/users/users.do">Käyttäjähallinta</Link>
          {" | "}
          <Link to="bk/fi/sellers/sellers.do">Myyjät</Link>
          {" | "}
          <Link to="bk/fi/areas/harbours.do">Satamat</Link>
          {" | "}
          <Link to="bk/fi/service/providers.do">Palveluntuottajat</Link>
          {" | "}
          <Link to="area/fi/2/reserve/season.do">Varaa</Link>
        </div>
      )}
      <ul className="harbourList">{harbours}</ul>
    </React.Fragment>
  );
};

export default FrontPage;
