import React from "react";
import Report from "./Report";
import { ReservationsTable } from "../boatparking/ReservationsTable";
import { ReservationModal } from "../boatparking/ReservationModal";

class ReservationsReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentMethod: undefined,
      orderType: undefined,
      openedReservation: undefined
    };
    this.openReservationDetails = this.openReservationDetails.bind(this);
  }

  openReservationDetails(reservation) {
    this.setState({
      openedReservation: reservation
    });
  }

  render() {
    const l = this.props.location;
    const t = key => l.translate(l, key);

    const extraFilters = (
      <React.Fragment>
        <center>
          <span>{t("orderType")}</span>
          <select
            onChange={e => {
              this.setState({ orderType: e.target.value });
            }}
          >
            <option value=""></option>
            <option value="DAY">{t("orderType.DAY")}</option>
            <option value="NIGHT">{t("orderType.NIGHT")}</option>
            <option value="GUEST">{t("orderType.GUEST")}</option>
          </select>
          <span>Maksutapa</span>
          <select
            onChange={e => {
              this.setState({ paymentMethod: e.target.value });
            }}
          >
            <option value=""></option>
            <option value="PAID">{t("payment.web")}</option>
            <option value="MARKED_PAID">{t("payment.admin")}</option>
          </select>
        </center>
        <ReservationModal
          isOpen={this.state.openedReservation}
          reservation={this.state.openedReservation}
          location={this.props.location}
          onClose={() => this.setState({ openedReservation: undefined })}
        />
      </React.Fragment>
    );
    return (
      <Report
        {...this.props}
        extraFilters={extraFilters}
        report="reservations"
        renderData={data => (
          <ReservationsTable
            t={t}
            orderType={this.state.orderType}
            paymentMethod={this.state.paymentMethod}
            reservations={data}
            openDetails={this.openReservationDetails}
            extended={true}
          />
        )}
      />
    );
  }
}

export default ReservationsReport;
