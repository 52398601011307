import { Map } from "immutable";
import service from "../services/place";

export const PREFIX = "PLACE_";

const defaultState = Map({});

export const getPlace = (area, language, placeId) => {
  return {
    type: PREFIX + "GET_PLACE",
    payload: {
      promise: service.getPlace(area, language, placeId)
    }
  };
};
export const getAreas = language => {
  return {
    type: PREFIX + "GET_AREAS",
    payload: {
      promise: service.getAreas(language)
    }
  };
};
export const getSellers = language => {
  return {
    type: PREFIX + "GET_SELLERS",
    payload: {
      promise: service.getSellers(language)
    }
  };
};
export default function placeReducer(state = defaultState, action) {
  const { type, payload } = action;
  if (type.indexOf(PREFIX) != 0) {
    return state; // not related to this service
  }
  if (payload && payload.errorMessage) {
    return state.set("errorMessage", payload.errorMessage);
  } else {
    state = state.remove("errorMessage");
  }
  switch (type) {
    case PREFIX + "GET_PLACE_FULFILLED":
      return state.set("place", payload);
    case PREFIX + "GET_AREAS_FULFILLED":
      return state.set("areas", payload);
    case PREFIX + "GET_SELLERS_FULFILLED":
      return state.set("sellers", payload);
    default:
      return state;
  }
}
