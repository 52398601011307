import React from "react";
import { genericGet, genericPost} from "../../services/axiosApi";
import BookkaaHeader from "../BookkaaHeader";
import OkErrorMessage from "../layout/OkErrorMessage";
import LoginForm from "../LoginForm";
import SeasonsComponent from "./SeasonsComponent";
import LoginRequired from "../LoginRequired";
import { EnumSelect } from "../EnumSelect";
import BkModifyButton from "../layout/BkModifyButton";
import { MapSelect } from "../MapSelect";
import { SeasonSelect } from "../SeasonSelect";

const PricingEditor = (props) => {
  const { t, uipOptions, seasons, update, save } = props;
  const uip = uipOptions.pricing;

  return <table>
    <tr>
      <td colSpan={4}>{uipOptions.narration}</td>
    </tr>
    <tr>
      <td>{t("description")}</td>
      <td colSpan={3}><input style={{ width: "100%"}} value={uip.description} onChange={(e) => update({...uip, description: e.target.value})}/></td>
    </tr>
    <tr>
      <td>{uipOptions.priceTitle}</td>
      <td><input type="number" value={uip.price} onChange={(e) => update({...uip, price: e.target.value})}/></td>
      <td>{t("season")}</td>
      <td><SeasonSelect seasons={seasons} selected={uip.pricingSeason} cb={(id) => update({...uip, pricingSeason: id})}/> </td>
    </tr>
    <tr>
      <td>{t("pricingType")}</td>
      <td>
      <MapSelect emptyLabel={t("empty")} optionsMap={uipOptions.types} selected={uip.pricingType} cb={(val) => update({...uip, pricingType: val})}/>
      </td>
      <td>{uipOptions.amountTitle}</td>
      <td>{uipOptions.amountTitle && <input type="number" value={uip.amount} onChange={(e) => update({...uip, amount: e.target.value})}/>}</td>
    </tr>
    <tr>
      <td>{t("usedForParkingPlace")}</td>
      <td><input type="checkbox" onChange={() => update({ ...uip, usedForParkingPlace: !uip.usedForParkingPlace})}></input></td>
      <td>{uipOptions.minimumUnitsTitle}</td>
      <td><input type="number" value={uip.minimumPaidUnits} onChange={(e) => update({...uip, minimumPaidUnits: e.target.value})}/></td>
    </tr>
    <tr>
      {uipOptions.days && Object.keys(uipOptions.days).length > 0 && <React.Fragment>
        <td>{t("days")}</td>
        <td>
          <MapSelect multiple={true} optionsMap={uipOptions.days} selected={uip.days} cb={(val) => update({...uip, days: Array.isArray(val) ? val : [val]})}/>
        </td>
      </React.Fragment>}
      {!uipOptions.days || Object.keys(uipOptions.days).length == 0 && <td colSpan={2}/>}
    </tr>
    <tr>
      <td colSpan={2}></td>
      <td><BkModifyButton buttonTitle={t("close-button")} callback={() => update()}/></td>
      <td><BkModifyButton buttonTitle={t("save-button")} callback={() => save(uip)} /></td>
    </tr>
  </table>;
}

const PlacePricingRenderer = (props) => {
  const { priceGroup, t, editCallback } = props;

  if (!priceGroup) {
    return <div/>;
  }

  const periods = priceGroup.periods;

  const header = [];
  
  const rows = [];

  header.push(<td>{t("places.title")}</td>);
  header.push(<td>{t("header.basePrice")}</td>);
  //  header.push(<td>{t("periods.title")}</td>);
  periods.forEach(period => {
    if (period.startDate && period.endDate) {
      header.push(<td>{period.startDate} - {period.endDate}</td>);
    } else {
    }
  });

  priceGroup.allProducts.forEach(apr => {
    let marker = <div style={{backgroundImage: apr.unit == "DAY" ? "url(/sun.png)": "url(/moon.png)", backgroundSize: "contain", width: "10px", height: "10px", display: "inline-block"}}/>
    const row = [];
    row.push(<td style={{border: "2px solid black", margin: "2px"}}>{t("place")}: {apr.code} {marker}</td>);
    row.push(<td style={{border: "2px solid black", margin: "2px"}}>{t("price")}: {apr.minPrice}</td>);

    periods.forEach(period => {
        let p = period.products.find(pr => pr.id === apr.id);
        if (p) {
          row.push(<td style={{border: "2px solid black", margin: "2px"}}>{t("price")}: {p.minPrice}</td>);
        } else {
          row.push(<td/>);
        }
    });
    rows.push(<tr>{row}</tr>);
  });

  return <React.Fragment>
    <PriceGroupRenderer priceGroup={priceGroup} t={t} editCallback={editCallback} />
    <table className="display">
          <tr>{header}</tr>
          {rows}
    </table></React.Fragment>;
}

const PriceGroupRenderer = (props) => {
  const { priceGroup, t, editCallback } = props;
  console.log("PriceGroup: " + priceGroup ? JSON.stringify(priceGroup) : "(no price group?!)")
  const title = <h2>{t("priceGroup")}: {priceGroup.group.name}</h2>;
  const pricings = priceGroup.group.pricings ? priceGroup.group.pricings.map(p => <li>{p.narration}&nbsp;<a href="update" onClick={(e)=>{e.preventDefault();editCallback(p);}}>{t("modify-button")}</a></li>) : <span>Ei hinnoittelurivejä</span>;
  return <div>{title}<ol>{pricings}</ol></div>;
}

class SimplePricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      priceGroups: [],
      seasons: [],
      edit: undefined
    };
    this.save = this.save.bind(this);
    this.reload = this.reload.bind(this);
    this.reload();
  }

  reload() {
    const l = this.props.location;
    genericGet(l.language, `a${l.area}/simplePricing/all`).then(r => {
        if (r.errorMessage) {
          this.setState({ errorMessage: r.errorMessage });
        } else {
          this.setState({ errorMessage: r.errorMessage, seasons: r.seasons.map(s => {return {...s, seasonId: s.id}; }), priceGroups: r.priceGroups, edit: undefined });
        }
    });
  }

  save(uip) {
    const l = this.props.location;
    genericPost(l.language, `${l.area}/pricing/createUpdate`, uip).then(r => 
      this.setState({ errorMessage: r.errorMessage, okMessage: r.okMessage, save: r.errorMessage ? this.state.save : undefined}, 
        () => this.reload()));
  }

  edit(uip) {
    if (!uip) {
       this.setState({ edit: undefined });
       return;
    }
    const l = this.props.location;
    genericPost(l.language, `${l.area}/simplePricing/options`, uip).then(r => 
      this.setState({ errorMessage: r.errorMessage, edit: r}));
  }

  render() {
    const l = this.props.location;
    const t = key => l.translate(l, key);

    return (
      <React.Fragment>
        <LoginRequired location={l} {...this.props.loginProps} />
        <LoginForm location={l} {...this.props.loginProps} />
        <BookkaaHeader
          location={l}
          {...this.props.headerProps}
          disableProfile={true}
          />
        <h2>{t("simple-pricings.title")}</h2>
        <OkErrorMessage 
            okMessage={this.state.okMessage} 
            errorMessage={this.state.errorMessage} 
            clearOk={() => this.setState({ okMessage: undefined})}
            clearError={() => this.setState({ errorMessage: undefined})} />
        <div><SeasonsComponent location={this.props.location} seasons={this.state.seasons} /></div>
        <br />
        {this.state.edit 
          ? <PricingEditor t={t} uipOptions={this.state.edit} seasons={this.state.seasons} 
          location={this.props.location} update={uip => this.edit(uip)} save={uip => this.save(uip)}/> 
          : this.state.priceGroups.map(pg => <div><PlacePricingRenderer priceGroup={pg} t={t} editCallback={(uip) => this.edit(uip)} /></div>)
        }
        <br />
      </React.Fragment>
    );
  }
}
export default SimplePricing;
