/*eslint array-element-newline: ["error", "never"]*/
import React from "react";
import PropTypes from "prop-types";
import BkModifyButton from "../layout/BkModifyButton";
import ReservationDetails from "./ReservationDetails";
import moment from "moment";
import { CircleLoader } from "react-spinners";
import { ui } from "jquery";
import ElectricityConsumption from "./ElectricityConsumption";
import { genericGet } from "../../services/axiosApi";
import swal from "@sweetalert/with-react";

const Reservation = props => {
  const {
    uic,
    location,
    cancel,
    markPaid,
    reserve,
    editReservation,
    electricity
  } = props;
  const t = key => location.translate(location, key);
  let className = "";
  let content = undefined;
  const reservable = !ui.freeSeason || ui.freeSeason === "FREED";
  if (uic.id > 0) {
    let markPaidButton = undefined;
    let resendReceiptButton = undefined;
    if (
      uic.reservationStatus === "PAID" ||
      uic.reservationStatus === "MARKED_PAID"
    ) {
      className = "paid";
      markPaidButton = (
        <BkModifyButton
          buttonTitle={t("modify-button")}
          callback={editReservation}
        />
      );
      if (uic.reservationStatus === "PAID") {
        resendReceiptButton = <BkModifyButton buttonTitle={t("resend-confirmation-button")} callback={() => 
          genericGet(location.language, `a${location.area}/adminReservations/receipt/${uic.cartId}`).then((res) => 
          swal({
              text: res.okMessage,
              icon: "info"
            }))
        }></BkModifyButton>;
      }
    } else if (uic.reservationStatus === "SEASON_OCCUPIED") {
      className = "pending";
    } else {
      className = "pending";
      markPaidButton = [];
      if (uic.reservationStatus === "IN_CART" /* TODO: type ADMIN */) {
        markPaidButton.push(<BkModifyButton
          buttonTitle={t("modify-button")}
          callback={editReservation}
        />);
      }
      markPaidButton.push(
        <BkModifyButton
          buttonTitle={t("mark-paid-button")}
          callback={markPaid}
        />
      );
    }
    const el = [];
    if (uic.dayReservation) {
      el.push(<div style={{backgroundImage: "url(/sun.png)", backgroundSize: "contain", width: "10px", height: "10px", position: "absolute"}} 
      title={uic.validFrom + " - " + uic.validUntil} tabIndex={0} />)
    } else {
      el.push(<div style={{backgroundImage: "url(/moon.png)", backgroundSize: "contain", width: "10px", height: "10px", position: "absolute"}} 
      title={uic.validFrom + " - " + uic.validUntil} tabIndex={0} />)
    }
    if (electricity) {
      el.push(<ElectricityConsumption uic={uic} location={location} />);
    }
    // has reservation
    content = (
      <React.Fragment>
        {el}
        <ReservationDetails reservation={uic} />
        <br />
        {t("ReservationStatus." + uic.reservationStatus)}
        <br />
        <BkModifyButton buttonTitle={t("free-button")} callback={cancel} />
        {markPaidButton}
        {resendReceiptButton}
      </React.Fragment>
    );
  } else {
    className = "";
    content = (
      <React.Fragment>
        {uic.placePrice} €
        <ProductTypeIcons
          parking={uic.parkingPlace}
          reservable={uic.reservable}
        />
        <br />
        {!!reserve && reservable && (
          <BkModifyButton
            buttonTitle={t("reserve-button")}
            callback={reserve}
          />
        )}
      </React.Fragment>
    );
  }
  return <div className={className}>{content}</div>;
};

const ProductTypeIcons = props => {
  const { reservable, parking } = props;

  return (
    <div style={{ float: "right" }}>
      {parking === true && <img src="/parking.png" alt="P" width="15" />}
      {reservable === true && <img src="/reservable.svg" alt="R" width="15" />}
    </div>
  );
};

export const ReservationCalendar = props => {
  const {
    location,
    reservationCalendar,
    start,
    cancelReservation,
    markPaid,
    reserve,
    editReservation,
    editPlace,
    selectedReservation
  } = props;

  if (!reservationCalendar || !reservationCalendar.codeToDays) {
    return (
      <center>
        <CircleLoader />
      </center>
    );
  }

  const t = key => location.translate(location, key);

  const rows = [];
  let even = false;
  const places = [];
  for (let p in reservationCalendar.codeToDays) {
    places.push(p);
  }
  places.sort();
  places.forEach(place => {
    const reservations = [];
    const freeDays = [];
    if (rows.length === 0) {
      const headerRow = [<th />];
      for (var i = 0; i < reservationCalendar.codeToDays[place].length; i++) {
        const day = moment(start).add(i, "days");
        let today = reservationCalendar.codeToDays[place][i].today;
        const cls =
          (today ? "today " : "") +
          (day.isoWeekday() === 6 || day.isoWeekday() === 7
            ? "sticky weekend"
            : "sticky");
        let dayLabel = "";
        if (day.isoWeekday() === 1) {
          dayLabel = t("monday.short");
        } else if (day.isoWeekday() === 2) {
          dayLabel = t("tuesday.short");
        } else if (day.isoWeekday() === 3) {
          dayLabel = t("wednesday.short");
        } else if (day.isoWeekday() === 4) {
          dayLabel = t("thursday.short");
        } else if (day.isoWeekday() === 5) {
          dayLabel = t("friday.short");
        } else if (day.isoWeekday() === 6) {
          dayLabel = t("saturday.short");
        } else if (day.isoWeekday() === 7) {
          dayLabel = t("sunday.short");
        }
        headerRow.push(
          <th className={cls}>
            {day.format("DD.MM.")}
            <br />
            {dayLabel}
          </th>
        );
      }
      rows.push(<tr className="sticky">{headerRow}</tr>);
    }
    const pushReservation = (title, resp, idx, colspan, today) => {
      const resDivs = [];
      if (title) {
        resDivs.push(<div className="small">{title}</div>);
      }
      const res = [...resp];
      if (
        (res[0].metric || res[0].parkingPlace) &&
        res[0].id > 0 &&
        !selectedReservation
      ) {
        // add empty reservation for "Reserve" button (sorry, a kludge)
        const emt = { ...res[0] };
        emt.id = undefined;
        res.push(emt);
      }
      res.forEach(uic =>
        resDivs.push(
          <Reservation
            electricity={reservationCalendar.electricity}
            uic={uic}
            location={location}
            cancel={() => cancelReservation({ ...uic, reservationId: uic.id })}
            reserve={(p_, event) =>
              reserve(
                uic,
                moment(start).add(idx, "days"),
                moment(start).add(idx + 1, "days"),
                event
              )
            }
            markPaid={() => markPaid({ ...uic, reservationId: uic.id })}
            editReservation={() =>
              editReservation({ ...uic, reservationId: uic.id })
            }
          />
        )
      );
      if (today && colspan === 1) {
        reservations.push(
          <td colspan={colspan} className="today">
            {resDivs}
          </td>
        );
      } else {
        reservations.push(<td colspan={colspan}>{resDivs}</td>);
      }
    };
    let prevRes = undefined;
    let parking = undefined;
    let reservable = undefined;
    // eslint-disable-next-line no-loop-func
    reservationCalendar.codeToDays[place].forEach((puic, idx) => {
      const today = puic.today;
      const rss = reservationCalendar.codeToDaysReservations[place][idx];
      const uic = rss[0];
      const tit = puic.metricDescription;
      parking |= puic.parkingPlace;
      reservable |= puic.reservable;

      uic.productCode = place;
      // only display one td for longer reservation
      let doNotPush = false;
      if (prevRes) {
        if (!uic.id || uic.id !== prevRes.id) {
          let colspan = idx - prevRes.idx;
          pushReservation(tit, [prevRes], prevRes.idx, colspan, prevRes.today);
          prevRes = undefined;
        } else if (idx === reservationCalendar.codeToDays[place].length - 1) {
          let colspan = 1 + idx - prevRes.idx;
          pushReservation(tit, [prevRes], prevRes.idx, colspan, prevRes.today);
          doNotPush = true;
        } else {
          // same id, do not push
          doNotPush = true;
        }
      }
      if (
        selectedReservation &&
        uic.productId === selectedReservation.productId
      ) {
        freeDays.push(
          uic.id === selectedReservation.id ? (
            <td>
              <BkModifyButton
                buttonTitle={t("free-button")}
                callback={() =>
                  cancelReservation(
                    { ...uic, reservationId: uic.id },
                    uic.dayNr
                  )
                }
              />
            </td>
          ) : (
            <td />
          )
        );
      }
      if (doNotPush) {
        // do nothing
      } else if (
        uic.id &&
        !uic.metric &&
        !uic.parkingPlace &&
        idx < reservationCalendar.codeToDays[place].length - 1
      ) {
        prevRes = uic;
        prevRes.idx = idx;
        prevRes.today = today;
      } else {
        pushReservation(tit, rss, idx, 1, today);
      }
    });

    const puic = reservationCalendar.codeToDays[place][0];
    const placeStr = puic.placeTypeStr;
    const placeSizeStr = puic.placeWidth
      ? `${puic.placeLength}x${puic.placeWidth}x${puic.placeDepth}`
      : undefined;
    
    const thClass = puic.openToPublic ? "norightpadding" : "norightpadding notbookable-indicator";

    rows.push(
      <tr className={even ? "td-even" : "td-odd"}>
        <th className={thClass}>
          <table>
            <tr>
              <td style={{ width: "100%", border: "none" }}>
                {place}
                <ProductTypeIcons parking={parking} reservable={reservable} />
                <br />
                {placeStr && <span className="small">{placeStr}</span>}
                <br />
                {placeSizeStr && <span className="small">{placeSizeStr}</span>}
              </td>
              {editPlace && (
                <td
                  style={{ cursor: "pointer", border: "none" }}
                  onClick={() => editPlace(puic)}
                >
                  &#9881;
                </td>
              )}
            </tr>
          </table>
        </th>
        {reservations}
      </tr>
    );
    if (selectedReservation) {
      rows.push(
        <tr className={even ? "td-even" : "td-odd"}>
          <th className="norightpadding" />
          {freeDays}
        </tr>
      );
    }
    even = !even;
  });
  return (
    <center>
      <table className="reservationCalendar bk-parking-list">{rows}</table>
    </center>
  );
};

export default ReservationCalendar;

ReservationCalendar.propTypes = {
  reservationCalendar: PropTypes.object.isRequired,
  location: {
    ctx: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    area: PropTypes.string.isRequired
  }
};
