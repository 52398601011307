import { genericGet, genericPost } from "./axiosApi";

const loadVallisaari = location => {
  return genericGet(location.language, "saari/applications", "booking-web");
};

const getVsReport = (location, start, pageSize, startDate, endDate, provider) => {
  let qp = start !== undefined ? `?start=${start}` : "";
  if (start !== undefined && pageSize) {
    qp = qp + `&pageSize=${pageSize}`;
  }
  if (startDate) {
    qp = qp + "&startDate=" + startDate;
  }
  if (endDate) {
    qp = qp + "&endDate=" + endDate;
  }
  if (provider) {
    qp = qp + "&provider=" + provider;
  }
  return genericGet(
    location.language,
    `${location.sp ? "s" + location.sp : ""}a${
      location.area
    }/saari/report${qp}`,
    "booking-web"
  );
};

const enterVsReport = (location, data) => {
  return genericPost(
    location.language,
    `s${location.sp}a${location.area}/saari/report`,
    data,
    "booking-web"
  );
};

const getContract = location => {
  return genericGet(
    location.language,
    `s${location.sp}a${location.area}/saari/contract`,
    "booking-web"
  );
};

const getEmailUsers = location => {
  return genericGet(location.language, "service/users", "booking-web");
};

const sendEmail = (location, data) => {
  const lang = location.language;
  return genericPost(lang, "service/users/email", data, "booking-web");
};

const loadMinimumVisitors = (location, day) => {
  if (!day) {
    return getVisitorsNow(location);
  }
  return genericGet(
    location.language,
    `s${location.sp}a${location.area}/saari/visitors/${day.format(
      "YYYY-MM-DD"
    )}`,
    "booking-web"
  );
};

const getVisitors = (location, start, pageSize) => {
  let qp = start !== undefined ? `?start=${start}` : "";
  if (start !== undefined && pageSize) {
    qp = qp + `&pageSize=${pageSize}`;
  }
  return genericGet(
    location.language,
    `${location.sp ? "s" + location.sp : ""}a${
      location.area
    }/saari/visitors${qp}`,
    "booking-web"
  );
};

const getVisitorsNow = location => {
  return genericGet(
    location.language,
    `a${location.area}/saari/visitors/now`,
    "booking-web"
  );
};

const enterVisitors = (location, data) => {
  return genericPost(
    location.language,
    `s${location.sp}a${location.area}/saari/visitors`,
    data,
    "booking-web"
  );
};

export default {
  loadVallisaari,
  getVsReport,
  enterVsReport,
  getVisitors,
  getVisitorsNow,
  enterVisitors,
  getContract,
  loadMinimumVisitors,
  getEmailUsers,
  sendEmail
};
