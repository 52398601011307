import React from "react";
import { genericPost, genericPut } from "../../services/axiosApi";
import BookkaaHeader from "../BookkaaHeader";
import BkModifyButton from "../layout/BkModifyButton";
import TableAndForm from "../layout/TableAndForm";
import LoginForm from "../LoginForm";
import LoginRequired from "../LoginRequired";
import Profile from "../Profile";
import DynamicMap from "./DynamicMap";
import AreaHeader from "./AreaHeader";
import OkErrorMessage from "../layout/OkErrorMessage";

class AreaImages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: undefined,
      startX: undefined,
      startY: undefined,
      endX: undefined,
      endY: undefined,
      numPlaces: 1,
      addedPlaces: undefined
    };
    this.selectCallback = this.selectCallback.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.uploadMap = this.uploadMap.bind(this);
    this.imageClicked = this.imageClicked.bind(this);
    this.generatePlaces = this.generatePlaces.bind(this);
    this.savePlaces = this.savePlaces.bind(this);
    this.drawCanvas = this.drawCanvas.bind(this);
  }

  drawCanvas(canvas) {
    const s = this.state;
    if (!canvas) {
      return;
    }
    let ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    if (s.startX && s.startY) {
      ctx.beginPath();
      ctx.moveTo(s.startX, s.startY);
      if (s.endX && s.endY) {
        ctx.lineWidth = 5;
        ctx.lineStyle = "rgba(100,100,100,0.8)";
        ctx.lineTo(s.endX, s.endY);
      } else {
        ctx.lineWidth = 2;
        ctx.lineStyle = "rgba(255,255,0,0.5)";
        ctx.lineTo(s.x, s.y);
      }
      ctx.stroke();
    }
  }
  savePlaces() {
    const l = this.props.location;
    const mapId = this.state.selectedItem.id;
    return genericPut(
      l.language,
      `images/${mapId}/areas`,
      this.state.addedPlaces,
      "booking-web"
    ).then(r => {
      if (r.errorMessage) {
        this.setState({ errorMessage: r.errorMessage });
      } else {
        this.setState({
          addedPlaces: undefined,
          startX: undefined,
          startY: undefined,
          numPlaces: undefined,
          selectedItem: undefined
        });
      }
    });
  }

  generatePlaces() {
    if (
      this.state.startX &&
      this.state.startY &&
      this.state.endX &&
      this.state.endY &&
      this.state.numPlaces
    ) {
      const num = this.state.numPlaces;
      const xd = this.state.endX - this.state.startX;
      const yd = this.state.endY - this.state.startY;
      const addX = num == 1 ? 0 : xd / (num - 1);
      const addY = num == 1 ? 0 : yd / (num - 1);
      const radius = this.state.radius;
      const areas = [];
      for (let i = 0; i < num; i++) {
        let x = Math.round(this.state.startX + i * addX);
        let y = Math.round(this.state.startY + i * addY);
        console.log(`X: ${x} Y: ${y}`);
        areas.push({
          shape: "circle",
          fillColor: "rgba(255,0,255,0.5)",
          preFillColor: "rgba(255,0,255,0.5)",
          coords: [x, y, radius],
          code: `X${i}`,
          subareaId: this.state.selectedItem.subareaId,
          productId: 0,
          placeId: 0,
          name: ""
        });
      }
      this.setState({
        addedPlaces: areas,
        radius: radius
      });
    }
  }
  uploadImage(data) {
    const l = this.props.location;
    console.log(data);
    const formData = new FormData();
    formData.append("image", data, data.name);
    genericPost(
      l.language,
      `images/${this.state.selectedItem.id}/image`,
      formData,
      "booking-web",
      "multipart/form-data"
    ).then(r => {
      this.setState({
        selectedItem: undefined
      });
    });
  }

  uploadMap(data) {
    const l = this.props.location;
    const formData = new FormData();
    formData.append("imagemap", data, data.name);
    genericPost(
      l.language,
      `images/${this.state.selectedItem.id}/map`,
      formData,
      "booking-web",
      "multipart/form-data"
    ).then(r => {
      this.setState({
        selectedItem: undefined
      });
    });
  }

  selectCallback(item) {
    const l = this.props.location;
    this.setState({ selectedItem: item });
    if (item) {
      this.props.getImageMap(
        l.language,
        l.area,
        null,
        item.season ? "SEASON_PLACE" : "BOAT_PARKING",
        null,
        null
      );
    }
  }

  imageClicked(x, y, start) {
    if (start) {
      if (this.state.addedPlaces && this.state.selectedItem) {
        this.selectCallback(this.state.selectedItem);
      }
      this.setState({
        x: x,
        y: y,
        startX: x,
        startY: y,
        endX: undefined,
        endY: undefined,
        numPlaces: undefined,
        addedPlaces: undefined
      });
    } else {
      this.setState({
        endX: x,
        endY: y,
        numPlaces:
          Math.abs(this.state.startX - x) < 5 &&
          Math.abs(this.state.startY - y) < 5
            ? 1
            : undefined
      });
    }
    console.log("clicked: (" + x + "," + y + ")");
  }

  render() {
    const l = this.props.location;
    const t = key => l.translate(l, key);
    const location = {
      language: l.language,
      endpoint: "a" + l.area + "/images",
      ctx: "booking-web",
      translations: l.translations,
      translate: l.translate
    };

    let beforeForm = [];
    const UPLOAD_IMAGE_BUTTON = {
      supportsMulti: false,
      callback: this.uploadImage,
      fileUpload: true,
      title: t("upload-image-file"),
      disabledCallback: b => !this.state.selectedItem
    };
    const UPLOAD_MAP_BUTTON = {
      supportsMulti: false,
      callback: this.uploadMap,
      fileUpload: true,
      title: t("upload-map-file"),
      disabledCallback: b => !this.state.selectedItem
    };

    const CLOSE_BUTTON = {
      supportsMulti: true,
      callback: this.props.closeCb,
      title: t("close-button")
    };

    let buttonArray = [UPLOAD_IMAGE_BUTTON, UPLOAD_MAP_BUTTON, CLOSE_BUTTON];

    let imageMap = undefined;
    let width = 1000;
    let height = 700;
    if (this.state.selectedItem && this.props.imageMap) {
      imageMap = { ...this.props.imageMap };
      imageMap.subareas = [...imageMap.subareas];
      imageMap.subareas.forEach((element, idx) => {
        if (element.subarea === this.state.selectedItem.subareaId) {
          if (this.state.addedPlaces) {
            let areas = [...element.areas, ...this.state.addedPlaces];
            imageMap.subareas[idx].areas = areas;
          }
          width = element.imageWidth;
          height = element.imageHeight;
        }
      });
      if (!this.state.selectedItem.subareaId && this.props.imageMap.wholeArea) {
        const element = this.props.imageMap.wholeArea;
        if (this.state.addedPlaces) {
          let areas = [...element.areas, ...this.state.addedPlaces];
          imageMap.wholeArea.areas = areas;
        }
        width = element.imageWidth;
        height = element.imageHeight;
      }
      // eslint-disable-next-line prettier/prettier
      if (!this.state.selectedItem.subareaId && this.props.imageMap.wholeArea && this.props.imageMap.wholeArea.imageWidth) {
        width = this.props.imageMap.wholeArea.imageWidth;
        height = this.props.imageMap.wholeArea.imageHeight;
      }
    }
    const url = this.state.selectedItem
      ? `/booking-web/images/${this.state.selectedItem.id}/imagemap_export.csv`
      : undefined;
    return (
      <React.Fragment>
        <LoginRequired {...this.props.loginProps} location={location}>
          <BookkaaHeader location={location} {...this.props.headerProps} />
          <LoginForm location={location} {...this.props.loginProps} />
          <Profile location={location} {...this.props.profileProps}>
            <AreaHeader areaName={this.props.areaName} />
            <OkErrorMessage okMessage={this.state.okMessage} errorMessage={this.state.errorMessage}
              clearOk={()=>this.setState({okMessage:undefined})} clearError={()=>this.setState({errorMessage:undefined})} />
            <TableAndForm
              selectCallback={this.selectCallback}
              onRef={ref => (this.tableAndForm = ref)}
              itemsBeforeForm={beforeForm}
              buttons={buttonArray}
              formButtonArray={[]}
              closeDialogCallback={this.dialogClosed}
              multiSelect={false}
              {...this.props}
              location={location}
            />
            {url && (
              <div style={{ padding: "10px" }}>
                <a href={url}>export.csv</a>
              </div>
            )}
            {imageMap && (
              <React.Fragment>
                <div>
                  <canvas
                    onMouseMove={e => {
                      if (this.state.startX && !this.state.endX) {
                        this.setState({
                          x: e.nativeEvent.offsetX,
                          y: e.nativeEvent.offsetY
                        });
                      }
                    }}
                    onMouseDown={e =>
                      this.imageClicked(
                        e.nativeEvent.offsetX,
                        e.nativeEvent.offsetY,
                        true
                      )
                    }
                    onMouseUp={e =>
                      this.imageClicked(
                        e.nativeEvent.offsetX,
                        e.nativeEvent.offsetY,
                        false
                      )
                    }
                    ref={ref => this.drawCanvas(ref)}
                    style={{
                      margin: "10px 10px 10px 10px",
                      position: "absolute",
                      zIndex: "2"
                    }}
                    width={width}
                    height={height}
                  />
                  <DynamicMap
                    {...this.props}
                    noScale={true}
                    disableTitle={true}
                    imageMap={imageMap}
                    t={t}
                    selectedSubarea={
                      this.state.selectedItem.subareaId
                        ? this.state.selectedItem.subareaId
                        : 0
                    }
                  />
                </div>
                <div>
                  {this.state.endX && (
                    <React.Fragment>
                      <div>
                        <table className="bk_placeDetails">
                          <tr>
                            <td>{t("number-of-places")}</td>
                            <td>
                              <input
                                class="small-input"
                                value={this.state.numPlaces}
                                onChange={e =>
                                  this.setState({ numPlaces: e.target.value })
                                }
                              />
                            </td>
                            <td>{t("radius")}</td>
                            <td>
                              <input
                                class="small-input"
                                value={this.state.radius}
                                onChange={e =>
                                  this.setState({
                                    radius: e.target.value
                                  })
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <div style={{ display: "flex" }}>
                                {this.state.addedPlaces &&
                                  this.state.addedPlaces.map((p, idx) => (
                                    <input
                                      class="small-input"
                                      value={p.code}
                                      onChange={e => {
                                        let ap = [...this.state.addedPlaces];
                                        ap[idx] = {
                                          ...p,
                                          code: e.target.value
                                        };
                                        this.setState({ addedPlaces: ap });
                                      }}
                                    />
                                  ))}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td colspan="4">
                              <BkModifyButton
                                disabled={
                                  !this.state.numPlaces || !this.state.radius
                                }
                                buttonTitle={t("button-add-places")}
                                callback={this.generatePlaces}
                              />
                              <BkModifyButton
                                disabled={!this.state.addedPlaces}
                                buttonTitle={t("button-save")}
                                callback={this.savePlaces}
                              />
                            </td>
                          </tr>
                        </table>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </React.Fragment>
            )}
          </Profile>
        </LoginRequired>
      </React.Fragment>
    );
  }
}

export default AreaImages;
