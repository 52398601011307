import React from "react";
import { genericGet, genericPost} from "../../services/axiosApi";
import BookkaaHeader from "../BookkaaHeader";
import BkModifyButton from "../layout/BkModifyButton";
import OkErrorMessage from "../layout/OkErrorMessage";
import LoginForm from "../LoginForm";
import Profile from "../Profile";

const SimpleAccessCodesRenderer = (props) => {
  const { acs, setCode } = props;
  const codes = [];
  console.log("ACS: " + JSON.stringify(acs));
  let cols = 2;
  if (acs.codes.length > 100) {
    cols = 6;
    for (let i = 0; i < acs.codes.length; i+=3) {
      const c1 = acs.codes[i];
      const c2 = acs.codes.length > i+1 ? acs.codes[i+1] : {};
      const c3 = acs.codes.length > i+2 ? acs.codes[i+2] : {};
      codes.push(
        <tr>
          <td>{c1.day}</td><td><input style={{width: "70px"}} value={c1.code} onChange={e => setCode(c1, e.target.value)} /></td>
          <td>{c2.day}</td><td>{c2.day && (<input style={{width: "70px"}} value={c2.code} onChange={e => setCode(c2, e.target.value)} />)}</td>
          <td>{c3.day}</td><td>{c3.day && (<input style={{width: "70px"}} value={c3.code} onChange={e => setCode(c3, e.target.value)} />)}</td>
        </tr>);
    }
  } else if (acs.codes.length > 30) {
    cols = 4;
    for (let i = 0; i < acs.codes.length; i+=2) {
      const c1 = acs.codes[i];
      const c2 = acs.codes.length > i+1 ? acs.codes[i+1] : {};
      codes.push(
        <tr>
          <td>{c1.day}</td><td><input style={{width: "70px"}} value={c1.code} onChange={e => setCode(c1, e.target.value)} /></td>
          <td>{c2.day}</td><td>{c2.day && (<input style={{width: "70px"}} value={c2.code} onChange={e => setCode(c2, e.target.value)} />)}</td>
        </tr>);
    }
  } else {
    acs.codes.forEach(c => codes.push(<tr><td>{c.day}</td><td><input style={{width: "70px"}} value={c.code} onChange={e => setCode(c, e.target.value)} /></td></tr>));
  }
  return (<table className="display">
          <tr><th colSpan={cols}>Koodiryhmä: {acs.codeType}</th></tr>
          {codes}
        </table>);
}

class SimpleAccessCodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessCodeType: this.props.guest ? "guest" : "season"
    };
    this.save = this.save.bind(this);
    this.reload = this.reload.bind(this);
    this.reload();
  }

  reload() {
    const l = this.props.location;
    const type = this.state.accessCodeType;
    genericGet(l.language, `${l.area}/accessCode/simple/${type}`).then(r => 
        this.setState({ errorMessage: r.errorMessage, codes: r.codes}));
  }

  save() {
    const l = this.props.location;
    const type = this.state.accessCodeType;
    genericPost(l.language, `${l.area}/accessCode/simple/${type}`, this.state.codes).then(r => 
      this.setState({ errorMessage: r.errorMessage, okMessage: r.okMessage, codes: r.codes ? r.codes : this.state.codes}));
  }


  render() {
    const l = this.props.location;
    const t = key => l.translate(l, key);

    const CLOSE_BUTTON = {
      supportsMulti: true,
      callback: this.props.closeCb,
      title: t("close-button")
    };


    const error = this.state.errorMessage ? (
      <div className="bk_errorMessage">{this.state.errorMessage}</div>
    ) : (
      undefined
    );
    if (this.state.errorMessage) {
      setTimeout(() => this.setState({ errorMessage: undefined }), 5000);
    }


    return (
      <React.Fragment>
        <BookkaaHeader location={l} {...this.props.headerProps} />
        <LoginForm location={l} {...this.props.loginProps} />
        <Profile location={l} {...this.props.profileProps}>
          {error}
          <h2>{this.props.guest ? t("access-codes.guest.title") : t("access-codes.season.title")}</h2>
          <OkErrorMessage 
              okMessage={this.state.okMessage} 
              errorMessage={this.state.errorMessage} 
              clearOk={() => this.setState({ okMessage: undefined})}
              clearError={() => this.setState({ errorMessage: undefined})} />
          <div className="bk-list">
            {this.state.codes && (
                this.state.codes.map(c => (<SimpleAccessCodesRenderer acs={c} setCode={(sc, newCode) => {
                  sc.code = (newCode && newCode.length > 0) ? newCode : undefined;
                  this.setState({ codes: [...this.state.codes ]});
                }} />)))}
          </div>
          <br />
          <BkModifyButton
            buttonTitle={t("save-button")}
            callback={this.save}
          />
        </Profile>
      </React.Fragment>
    );
  }
}
export default SimpleAccessCodes;
