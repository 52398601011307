import { Map, List } from "immutable";
import reservationService from "../services/reservation";

const defaultState = Map({
  areaOptions: undefined,
  seasonPlaces: undefined,
  cart: List()
});

export const populateAreaOptions = language => {
  return {
    type: "POPULATE_OPTIONS",
    payload: {
      promise: reservationService.areaOptions(language)
    }
  };
};

export const checkReservationStatuses = (
  language,
  areaId,
  subareaId,
  productType
) => {
  return {
    type: "CHECK_RESERVATION_STATUSES",
    payload: {
      promise: reservationService.checkReservationStatuses(
        language,
        areaId,
        subareaId,
        productType
      )
    }
  };
};

/**
 * Gets products with reservation status set.
 *
 * @param {string} language
 * @param {number} areaId - area where to check reservations / place id when ONE_PLACE
 * @param {number} subareaId - OPTIONAL subarea (pier) id
 * @param {number} productType - ONE_PLACE, PUBLIC_SEASON, SEASON_PLACE, GUEST_PLACE, GUEST_ADMIN or BOAT_PARKING for now
 * @param {date} from - OPTIONAL from (only date part, as UTC) to calculate reservations
 * @param {date} until - OPTIONAL until (only date part, as UTC) to calculate reservations
 */
export const getImageMap = (
  language,
  areaId,
  subareaId,
  productType,
  from,
  until
) => {
  return {
    type: "GET_IMAGE_MAP",
    payload: {
      promise: reservationService.getImageMap(
        language,
        areaId,
        subareaId,
        productType,
        from,
        until
      )
    }
  };
};

export const reserveSeasonPlace = (language, areaId, productId, seasonId) => {
  return {
    type: "RESERVE_SEASON_PLACE",
    payload: {
      promise: reservationService.reserveSeasonPlace(
        language,
        areaId,
        productId,
        seasonId
      )
    },
    meta: {
      productId: productId,
      seasonId: seasonId
    }
  };
};

export const storeReferrer = (orderNumber, referrer) => {
  return {
    type: "STORE_REFERRER",
    payload: {
      orderNumber: orderNumber,
      referrer: referrer
    }
  };
};

export const pay = (language, cartId, additional, customer) => {
  return {
    type: "PAY",
    payload: {
      promise: reservationService.pay(language, cartId, additional, customer)
    },
    meta: {
      language: language,
      cartId: cartId
    }
  };
};

export const retrieveOrder = (language, orderNumber) => {
  return {
    type: "RETRIEVE_ORDER",
    payload: {
      promise: reservationService.retrieveOrder(language, orderNumber)
    },
    meta: {
      language: language,
      orderNumber: orderNumber
    }
  };
};

export const checkOrder = (language, orderNumber) => {
  return {
    type: "CHECK_ORDER",
    payload: {
      promise: reservationService.checkOrder(language, orderNumber)
    },
    meta: {
      language: language,
      cartId: orderNumber
    }
  };
};

export const showShoppingCart = (language, cartId) => {
  return {
    type: "SHOW_SHOPPING_CART",
    payload: {
      promise: reservationService.getCart(language, cartId)
    },
    meta: {
      cartId: cartId
    }
  };
};

export const closeShoppingCart = () => {
  return {
    type: "CLOSE_SHOPPING_CART"
  };
};

export const fetchProductDetails = (areaId, language, placeId, from, until) => {
  return {
    type: "FETCH_PLACE_DETAILS",
    payload: {
      promise: reservationService.fetchProductDetails(
        areaId,
        language,
        placeId,
        from,
        until
      )
    },
    meta: {
      language: language,
      data: { areaId, placeId: placeId, from: from, until: until }
    }
  };
};

export const storeProductDetails = (language, data) => {
  return {
    type: "STORE_PLACE_DETAILS",
    payload: {
      promise: reservationService.storeProductDetails(language, data)
    },
    meta: {
      language: language,
      data: data
    }
  };
};

export const reserveForCustomer = (language, data) => {
  return {
    type: "ADMIN_RESERVE",
    payload: {
      promise: reservationService.reserveForCustomer(language, data)
    },
    meta: {
      language: language,
      data: data
    }
  };
};

const basicService = (func, area, language, data, type) => {
  return {
    type: type,
    payload: {
      promise: func(area, language, data)
    },
    meta: {
      language: language,
      data: data
    }
  };
};
export const searchCustomer = (area, language, searchData, patrol) => {
  return basicService(
    reservationService.searchCustomer,
    area,
    language,
    { search: searchData, patrol: patrol, reference: new Date().getTime() },
    "SEARCH_CUSTOMER"
  );
};

export const registerByReservation = (area, language, data) => {
  return basicService(
    reservationService.registerByReservation,
    area,
    language,
    data,
    "REGISTER_BY_RESERVATION"
  );
};

export const getAvailableProducts = (area, language, data) => {
  return basicService(
    reservationService.getAvailableProducts,
    area,
    language,
    data,
    "AVAILABLE_PRODUCTS"
  );
};

export const guardPatrolReserve = (area, language, data) => {
  return basicService(
    reservationService.guardPatrolReserve,
    area,
    language,
    data,
    "GUARD_PATROL_RESERVE"
  );
};

export const cancelReservationById = (area, language, id) => {
  return basicService(
    reservationService.cancelReservationById,
    area,
    language,
    id,
    "CANCEL_RESERVATION_BY_ID"
  );
};
export const markReservationPaidById = (
  area,
  language,
  id,
  reservationStatus
) => {
  return basicService(
    reservationService.markReservationPaidById,
    area,
    language,
    id,
    "MARK_RESERVATION_BY_ID"
  );
};
// cancel guard watch reservation:
export const cancelReservation = (area, language, data) => {
  return basicService(
    reservationService.cancelReservation,
    area,
    language,
    data,
    "CANCEL_RESERVATION"
  );
};

export const guestReservationCalendar = (areaId, language, data) => {
  return basicService(
    reservationService.guestReservationCalendar,
    areaId,
    language,
    data,
    "RESERVATION_CALENDAR_GUEST"
  );
};
export const updateReservation = (area, language, data) => {
  return basicService(
    reservationService.updateReservation,
    area,
    language,
    data,
    "UPDATE_RESERVATION"
  );
};
export const boatParkAdmin = (area, language, data) => {
  return basicService(
    reservationService.boatParkAdmin,
    area,
    language,
    data,
    "BOAT_PARK_ADMIN"
  );
};
export const boatParkReport = (language, area, from, until) => {
  return basicService(
    reservationService.adminParkingList,
    language,
    area,
    { from: from, until: until },
    "BOAT_PARK_ADMIN_REPORT"
  );
};
export const guestReservationReport = (area, language, from, until, seller) => {
  return basicService(
    reservationService.guestReservationReport,
    area,
    language,
    { from: from, until: until },
    "RESERVATION_ADMIN_REPORT"
  );
};

export const addLogEntry = (area, language, data) => {
  return basicService(
    reservationService.addLogEntry,
    area,
    language,
    data,
    "ADD_LOG_ENTRY"
  );
};

export const getLogEntries = (area, language, data) => {
  return basicService(
    reservationService.getLogEntries,
    area,
    language,
    data,
    "GET_LOG_ENTRIES"
  );
};

export default function reservationReducer(state = defaultState, action) {
  const { type, payload } = action;
  if (
    type == "GET_IMAGE_MAP_FULFILLED" &&
    payload &&
    payload.errorStatus != 401
  ) {
    // don't clear error
  } else if (payload && payload.errorMessage) {
    if (payload.errorStatus == 401) {
      state = state
        .set("errorMessage", payload.errorMessage)
        .set("loginRequired", true);
    } else {
      state = state
        .set("errorMessage", payload.errorMessage)
        .remove("loginRequired");
    }
  } else if (payload) {
    state = state.remove("loginRequired").remove("errorMessage");
  }
  switch (type) {
    case "STORE_REFERRER_FULFILLED":
      return state.set("orderReferrer", payload);
    case "UPDATE_RESERVATION_FULFILLED":
      if (!payload.errorMessage) {
        return state.set("updatedReservation", payload);
      }
      return state;
    case "RESERVATION_ADMIN_REPORT_FULFILLED":
      if (!payload.errorMessage) {
        return state.set("reservationReport", payload);
      }
      return state;
    case "RESERVATION_CALENDAR_GUEST_FULFILLED":
      return state.set("guestCalendar", payload);
    case "BOAT_PARK_ADMIN_FULFILLED":
      if (!payload.errorMessage) {
        return state.set("boatParkAdminData", payload);
      }
      return state;
    case "BOAT_PARK_ADMIN_REPORT_FULFILLED":
      if (!payload.errorMessage) {
        return state.set("boatParkAdminReport", payload);
      }
      return state;
    case "ADD_LOG_ENTRY_FULFILLED":
    case "GET_LOG_ENTRIES_FULFILLED":
      if (!payload.errorMessage) {
        return state.set("patrolLog", payload);
      }
      return state;
    case "CLOSE_CHECKOUT":
      return state
        .remove("cart")
        .remove("errorMessage")
        .remove("paymentError");
    case "SET_SEASON_RESERVATION_STATUS_FULFILLED":
      if (!payload.errorMessage) {
        return state.set("updatedCustomer", payload);
      }
      return state;
    case "CHECK_RESERVATION_STATUSES_FULFILLED":
      if (!payload.errorMessage) {
        return state.set("seasonPlaces", payload);
      }
      return state;
    case "POPULATE_OPTIONS_FULFILLED":
      if (!payload.errorMessage) {
        return state.set("areaOptions", payload);
      }
      return state;
    case "RESERVE_SEASON_PLACE_PENDING":
      return state; //TODO, state for spinner
    case "RESERVE_SEASON_PLACE_REJECTED":
      console.log(
        "Reserve place REJECTED response: " + JSON.stringify(payload)
      );
      // TODO: retry if possible
      return state;
    case "RESERVE_SEASON_PLACE_FULFILLED":
      console.log("Reserve place response: " + JSON.stringify(payload));
      if (!payload.errorMessage) {
        return state.set("cart", state.cart.push(payload));
      }
      return state;
    case "ADMIN_RESERVE_FULFILLED":
      console.log("Admin reserve place response: " + JSON.stringify(payload));
      return state;
    case "SEARCH_CUSTOMER_FULFILLED":
      console.log("Search response: " + JSON.stringify(payload));
      if (!payload.errorMessage) {
        return state.set("customers", payload);
      }
      return state;
    case "GET_IMAGE_MAP_FULFILLED":
      if (!payload.errorMessage) {
        return state.set("imageMap", payload);
      }
      return state;
    case "SHOW_SHOPPING_CART_FULFILLED":
      if (!payload.errorMessage) {
        return state
          .set("cart", payload)
          .remove("error")
          .remove("errorMessage");
      }
      return state;
    case "PAY_PENDING":
      return state.remove("paymentError");
    case "PAY_FULFILLED":
      if (payload.errorStatus) {
        return state.set("paymentError", payload);
      } else {
        return state.set("paymentData", payload).remove("paymentError");
      }
    case "CHECK_ORDER_FULFILLED":
      if (payload.errorMessage) {
        return state;
      } else {
        return state.set("orderData", payload).remove("error");
      }
    case "RETRIEVE_ORDER_FULFILLED":
      if (payload.errorMessage) {
        return state;
      } else {
        return state.set("orderForPayment", payload).remove("error");
      }
    case "FETCH_PLACE_DETAILS_FULFILLED":
      if (payload.errorMessage) {
        return state;
      } else {
        return state
          .set("placeDetails", payload ? payload : {})
          .remove("error");
      }
    case "STORE_PLACE_DETAILS_FULFILLED":
      if (!payload.errorMessage) {
        return state.set("placeDetails", payload ? payload : {});
      }
      return state;
    case "AVAILABLE_PRODUCTS_FULFILLED":
      if (!payload.errorMessage) {
        return state.set("availableProducts", payload);
      }
      return state;
    case "GUARD_PATROL_RESERVE_FULFILLED":
      if (!payload.errorMessage) {
        return state
          .set("availableProducts", payload.products)
          .set("guardPatrolReserved", payload);
      }
      return state;
    case "CANCEL_RESERVATION_FULFILLED":
      if (!payload.errorMessage) {
        return state.set("availableProducts", payload.products);
      }
      return state;
    case "MARK_RESERVATION_BY_ID_FULFILLED":
      return state;
    case "CANCEL_RESERVATION_BY_ID_FULFILLED":
      return state;
    case "REGISTER_BY_RESERVATION_FULFILLED":
      if (!payload.errorMessage) {
        return state.remove("orderForPayment");
      }
      return state;
    case "CLOSE_SHOPPING_CART":
      return state
        .remove("cart")
        .remove("orderForPayment")
        .remove("orderData")
        .remove("error");
    case "CLEAR_ALL_ERRORS":
      return state.remove("error").remove("paymentError");
    default:
      return state;
  }
}
