import React from "react";
import PropTypes from "prop-types";
import BkModifyButton from "./layout/BkModifyButton";
import { SeasonPlace } from "./reservation/SeasonPlace";
import Customer from "./boatparking/Customer";
import { Map } from "immutable";
import { genericDelete, genericGet, genericPost } from "../services/axiosApi";
import { shallowEqual } from "recompose";
import Redirect from "react-router/Redirect";
import BkCustomerFooter from "./CustomerFooter";
import { freeSeasonPlaceAsGuest } from "./boatparking/FreedSeasonPlace";

const uiState = {
  places: "places",
  invoices: "invoices",
  account: "account"
};

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uiState: uiState.places,
      showActiveSeasonPlaces: true,
      showOldSeasonPlaces: false,
      showActiveGuestPlaces: true,
      showOldGuestPlaces: false,
      saveUserOngoing: false,
      customer: Map(
        this.props.user && this.props.user.userId ? this.props.user : {}
      )
    };
    this.renderPlaces = this.renderPlaces.bind(this);
    this.renderAccount = this.renderAccount.bind(this);
    this.saveUser = this.saveUser.bind(this);
    this.loadReceipt = this.loadReceipt.bind(this);
    this.getSeasonPlaceFree = this.getSeasonPlaceFree.bind(this);
  }

  loadReceipt(place) {
    genericGet(
      this.props.location.language,
      "profile/receipt/" + place.cartId,
      "booking-web"
    ).then(ret => {
      this.setState({
        errorMessage: ret.errorMessage,
        okMessage: ret.okMessage
      });
    });
  }

  componentDidUpdate(prevProps) {
    console.log("did update: " + JSON.stringify(this.props.userProfile));
    if (
      this.props &&
      this.props.user &&
      (!prevProps ||
        !prevProps.user ||
        !shallowEqual(prevProps.user, this.props.user))
    ) {
      this.setState({ customer: Map(this.props.user) });
    } else if (
      !this.props.userProfile &&
      prevProps &&
      prevProps.userProfile &&
      this.state.orderNumber
    ) {
      this.setState({ orderArea: undefined, orderNumber: undefined });
    }
  }

  render() {
    const l = this.props.location;
    const t = key => l.translate(l, key);
    if (t("close-button") === "close-button") {
      this.props.getTranslations(l.ctx, l.language, l.endpoint);
    }
    if (!this.props.userProfile) {
      return <React.Fragment>{this.props.children}</React.Fragment>;
    }
    if (this.state.orderNumber) {
      const on = this.state.orderNumber;
      const oa = this.state.orderArea;
      this.props.closeProfile();
      return (
        <Redirect
          to={`/payReservation/${l.language}/${oa}/${on}`}
          push={true}
        />
      );
    }
    const places = this.renderPlaces(l, t);
    const userData = this.renderAccount(l, t);
    const messages = [];
    if (this.state.okMessage) {
      setTimeout(() => this.setState({ okMessage: undefined }), 5000);
      messages.push(
        <div
          className="bk_okMessage sticky"
          style={{ display: "block" }}
          onClick={() => this.setState({ okMessage: undefined })}
        >
          {this.state.okMessage}
        </div>
      );
    }
    if (this.state.errorMessage) {
      setTimeout(() => this.setState({ errorMessage: undefined }), 5000);
      messages.push(
        <div className="bk_errorMessage sticky" style={{ display: "block" }}>
          {this.state.errorMessage}
        </div>
      );
    }
    return (
      <div className="bkProfile">
        {messages}
        <div className="bkProfile-leftColumn">{places}</div>
        <div className="bkProfile-rightColumn">{userData}</div>
        <div style={{ clear: "both" }}></div>
        <BkCustomerFooter
          harbours={
            this.props.frontPage ? this.props.frontPage.harbours : undefined
          }
          area={this.props.location.area}
          gdprTitle={t("gdprLink")}
        />
      </div>
    );
  }

  renderAccount(l, t) {
    return (
      <div className="togglecontainer">
        <section>
          <div className="single_toggle">
            <div className="bk-special-heading">
              <h3 className="column-title">{t("profile.user-data")}</h3>
              <div className="special-heading-border">
                <div className="special-heading-inner-border" />
              </div>
            </div>
            <br style={{ clear: "both" }} />
            <Customer
              t={t}
              autocomplete={true}
              updateCustomer={(fieldName, value) => {
                this.setState({
                  customer: this.state.customer.set(fieldName, value)
                });
              }}
              stateCustomer={this.state.customer}
              renderAsTable={true}
              showAddress={true}
              noBoat={true}
              {...this.props}
            />
            <BkModifyButton
              buttonTitle={t("save-button")}
              callback={this.saveUser}
              busy={this.state.saveUserOngoing}
            />
          </div>
        </section>
      </div>
    );
  }
  saveUser() {
    const l = this.props.location;
    this.setState({ saveUserOngoing: true });
    genericPost(
      l.language,
      "register/updateProfile",
      this.state.customer,
      "um-web"
    ).then(ret => {
      this.setState({ saveUserOngoing: false, okMessage: ret.okMessage });
      this.props.ensureLoggedIn(
        null,
        l.ctx,
        l.language,
        l.endpoint,
        this.state.customer.get("email")
      );
      console.log("Returned: " + JSON.stringify(ret));
      genericPost(
        l.language,
        "profile/" + ret.loginData.user.userId,
        ret,
        "booking-web"
      );
    });
  }
  renderPlaces(l, t) {
    const userProfile = this.props.userProfile;

    let activeSeasonPlaces = [];
    if (userProfile.activeSeasonPlaces) {
      for (let i = 0; i < userProfile.activeSeasonPlaces.length; i++) {
        const p = userProfile.activeSeasonPlaces[i];
        activeSeasonPlaces.push(
          <SeasonPlace
            place={p}
            loadReceipt={this.loadReceipt}
            pay={() =>
              this.setState({ orderNumber: p.orderNumber, orderArea: p.areaId })
            }
            language={l.language}
          />
        );
        if (Array.isArray(p.freed)) {
          activeSeasonPlaces.push(this.getSeasonPlaceFree(p, l, t));
        }
      }
    }

    let oldSeasonPlaces = [];
    if (userProfile.previousSeasonPlaces) {
      for (let i = 0; i < userProfile.previousSeasonPlaces.length; i++) {
        oldSeasonPlaces.push(
          <SeasonPlace
            place={userProfile.previousSeasonPlaces[i]}
            loadReceipt={this.loadReceipt}
            pay={() => {}}
            language={this.props.location.language}
          />
        );
      }
    }

    return (
      <React.Fragment>
        <div className="togglecontainer">
          <section>
            <div className="single_toggle">
              <div className="bk-special-heading">
                <h3 className="column-title">{t("profile.my-season")}</h3>
                <div className="special-heading-border">
                  <div className="special-heading-inner-border" />
                </div>
              </div>
              {(activeSeasonPlaces.length > 0 ||
                oldSeasonPlaces.length > 0) && (
                <React.Fragment>
                  <ToggleData
                    title={t("profile.active-season")}
                    open={this.state.showActiveSeasonPlaces}
                    toggle={() =>
                      this.setState({
                        showActiveSeasonPlaces: !this.state
                          .showActiveSeasonPlaces
                      })
                    }
                    data={activeSeasonPlaces}
                  />
                  <ToggleData
                    title={t("profile.old-season")}
                    open={this.state.showOldSeasonPlaces}
                    toggle={() =>
                      this.setState({
                        showOldSeasonPlaces: !this.state.showOldSeasonPlaces
                      })
                    }
                    data={oldSeasonPlaces}
                  />
                </React.Fragment>
              )}
              {activeSeasonPlaces.length === 0 &&
                oldSeasonPlaces.length === 0 && (
                  <p>{t("profile.no-season-places")}</p>
                )}
            </div>
          </section>
        </div>
      </React.Fragment>
    );
  }

  getSeasonPlaceFree(place, location, t) {
    return ( 
      <React.Fragment>
        <br />
        <table>
          <tr>
            <td colSpan={2}>
              {t("profile.set-free-title")}
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
            <BkModifyButton buttonTitle={t("profile.set-free-button")} callback={() =>  
              freeSeasonPlaceAsGuest({ id: place.productId, validFrom: place.seasonStart }, location, 
                  this.state.startCancel, this.state.endCancel,
                  false, // isAdmin
                  (startCancel, endCancel) => this.setState({ startCancel: startCancel, endCancel: endCancel }),
                  (closeCb) => {
                    this.props.openProfile(location.language);
                    closeCb();
                  })} />
            </td>
          </tr>
          { place.freed.map(free => 
          <tr>
            <td>{free}</td>
            <td><BkModifyButton buttonTitle={t("delete-button")} callback={() => 
              genericDelete(location.language, "profile/free/" + place.productId + "/" + free.replace(/ /g, '')).then(() => {
                this.props.openProfile(location.language);
              })
              } /></td>
          </tr>)}
        </table>
      </React.Fragment>);
  }
  
}
export default Profile;


const ToggleData = props => {
  return (
    <React.Fragment>
      <h3 className="toggleTitle" onClick={props.toggle}>
        {props.title}{" "}
        {props.open && <span className="uncollapsed-arrow">&nbsp;</span>}
        {!props.open && <span className="collapsed-arrow">&nbsp;</span>}
      </h3>
      {props.open && <div className="toggled-details">{props.data}</div>}
    </React.Fragment>
  );
};

Profile.propTypes = {
  ensureLoggedIn: PropTypes.func.isRequired,
  closeProfile: PropTypes.func.isRequired,
  pay: PropTypes.func.isRequired,
  user: PropTypes.any,
  location: {
    ctx: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired
  }
};
