import React from "react";

export const MapSelect = props => {
  // eslint-disable-next-line prettier/prettier
  const { cb, optionsMap, selected, filter, emptyLabel, multiple } = props;
  const vals = [];
  if (emptyLabel) {
    if (selected) {
      vals.push(<option value={undefined}>{emptyLabel}</option>);
    } else {
      vals.push(
        <option value={undefined} selected="selected">
          {emptyLabel}
        </option>
      );
    }
  }
  Object.entries(optionsMap).map(([keyValue, optionText]) => {
    if (keyValue && (!filter || filter(keyValue))) {
      if (!selected && vals.length == 0) {
        console.log("No selected, calling the cb with: " + keyValue);
        cb(keyValue);
      }
      if (selected === keyValue || (Array.isArray(selected) && selected.includes(keyValue))) {
        vals.push(
          <option value={keyValue} selected="selected">
            {optionText}
          </option>
        );
      } else {
        vals.push(
          <option value={keyValue}>{optionText}</option>
        );
      }
    }
  });
  return (
    <select multiple={multiple} onChange={e => {
      if (multiple) {
        let items = [];
        for (let i=0; i<e.target.options.length;i++) {
          if (e.target.options.item(i).selected) {
            items.push(e.target.options.item(i).value);
          }
        }
        cb(items);
      } else {
        cb(e.target.value);
      }
    }}>
      {vals}
    </select>
  );
};
