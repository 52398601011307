import { Map } from "immutable";
import vallisaariService from "../services/vallisaari";

const defaultState = Map({
  vallisaariData: undefined
});

const PRE = "VS.";

export const loadVallisaari = location => {
  return {
    type: PRE + "GET_ISLAND_DATA",
    payload: {
      promise: vallisaariService.loadVallisaari(location)
    }
  };
};

// eslint-disable-next-line prettier/prettier
export const getVsReport = (location, start, pageSize, startDate, endDate, provider) => {
  return {
    type: PRE + "GET_REPORT",
    payload: {
      // eslint-disable-next-line prettier/prettier
      promise: vallisaariService.getVsReport(location, start, pageSize, startDate, endDate, provider)
    }
  };
};
export const enterVsReport = (location, data) => {
  return {
    type: PRE + "ENTER_REPORT",
    payload: {
      promise: vallisaariService.enterVsReport(location, data)
    }
  };
};

export const getVisitorsNow = location => {
  return {
    type: PRE + "GET_VISITORS_NOW",
    payload: {
      promise: vallisaariService.getVisitorsNow(location)
    }
  };
};

export const getVisitors = (location, start, pageSize) => {
  return {
    type: PRE + "GET_VISITORS",
    payload: {
      promise: vallisaariService.getVisitors(location, start, pageSize)
    }
  };
};

export const loadMinimumVisitors = (location, day) => {
  return {
    type: PRE + "GET_VISITORS_AT",
    payload: {
      promise: vallisaariService.loadMinimumVisitors(location, day)
    }
  };
};

export const enterVisitors = (location, data) => {
  return {
    type: PRE + "ENTER_VISITORS",
    payload: {
      promise: vallisaariService.enterVisitors(location, data)
    }
  };
};

export const sendEmail = (location, data) => {
  return {
    type: PRE + "SEND_EMAIL",
    payload: {
      promise: vallisaariService.sendEmail(location, data)
    }
  };
};

export const getEmailUsers = location => {
  return {
    type: PRE + "GET_EMAIL_USERS",
    payload: {
      promise: vallisaariService.getEmailUsers(location)
    }
  };
};

export default function vallisaariReducer(state = defaultState, action) {
  const { type, payload } = action;
  if (!type.startsWith(PRE)) {
    return state;
  }
  if (payload && payload.errorMessage) {
    return state.set("errorMessage", payload.errorMessage);
  } else if (payload && payload.errorStatus) {
    return state.set("errorStatus", "Odottamaton virhe, yritä uudestaan");
  }

  switch (type) {
    case PRE + "GET_ISLAND_DATA_FULFILLED":
      return state.set("vallisaariData", payload).remove("errorMessage");
    case PRE + "GET_REPORT_FULFILLED":
      return state.set("vallisaariReport", payload).remove("errorMessage");
    case PRE + "ENTER_REPORT_FULFILLED":
      return state
        .set("vallisaariReportCreated", payload)
        .remove("errorMessage");
    case PRE + "GET_VISITORS_FULFILLED":
      return state.set("vallisaariVisitors", payload).remove("errorMessage");
    case PRE + "GET_VISITORS_AT_FULFILLED":
      return state.set("vallisaariVisitorsAt", payload).remove("errorMessage");
    case PRE + "GET_VISITORS_NOW_FULFILLED":
      return state.set("realtimeVisitors", payload).remove("errorMessage");
    case PRE + "ENTER_VISITORS_FULFILLED":
      return state.set("vallisaariVisitors", payload).remove("errorMessage");
    case PRE + "GET_EMAIL_USERS_FULFILLED":
    case PRE + "SEND_EMAIL_FULFILLED":
      return state.set("emailUsers", payload).remove("errorMessage");
    default:
      return state;
  }
}
