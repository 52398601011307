import React from "react";
import moment from "moment";
import BkContentArea from "../components/layout/BkContentArea";
import { CircleLoader } from "react-spinners";
import BookkaaHeader from "../components/BookkaaHeader";
import { ReportTable } from "./ReportTable";
import BkModifyButton from "../components/layout/BkModifyButton";

class VallisaariAdminEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      latestRequest: undefined,
      showRecipients: false
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.jwt != this.props.jwt) {
      this.props.getEmailUsers(this.props.location);
      this.props.getVisitorsNow(this.props.location);
    }
  }

  render() {
    const t = k => this.props.location.translate(this.props.location, k);
    const atTheIsland =
      this.props.realtimeVisitors !== undefined
        ? `${t("presentNowHeader")}: ${this.props.realtimeVisitors}`
        : undefined;

    const content = [];
    if (this.props.errorMessage) {
      content.push(
        <div className="bk_errorMessage" style={{ display: "block" }}>
          {this.props.errorMessage}
        </div>
      );
    } else if (this.props.emailUsers && this.props.emailUsers.result) {
      console.log("adding ok message");
      content.push(
        <div className="bk_okMessage" style={{ display: "block" }}>
          {this.props.emailUsers.result}
        </div>
      );
    }
    if (!this.props.jwt) {
      content.push(<CircleLoader />);
    }

    if (atTheIsland) {
      content.push(<div className="bk-standOutDiv">{atTheIsland}</div>);
    }
    content.push(<br />);

    content.push(
      <div>
        <label className="alpaca-control-label">{t("emailSubject")}</label>
        <input
          type="text"
          style={{ width: "400px" }}
          onChange={e => this.setState({ subject: e.target.value })}
          value={this.state.subject}
        />
      </div>
    );
    content.push(
      <div>
        <label className="alpaca-control-label">{t("emailContent")}</label>
        <textarea
          rows="10"
          style={{ width: "400px" }}
          onChange={e => this.setState({ message: e.target.value })}
          value={this.state.message}
        />
      </div>
    );
    content.push(<br />);

    if (this.props.emailUsers && Array.isArray(this.props.emailUsers.users)) {
      content.push(
        <a
          style={{ cursor: "pointer" }}
          onClick={() => {
            this.setState({
              showRecipients: !this.state.showRecipients
            });
          }}
        >
          <span>
            {this.props.emailUsers.users.length} {t("recipientCount")}{" "}
            {this.state.showRecipients && <span>&#x23F6;</span>}
            {!this.state.showRecipients && <span>&#x23F7;</span>}
          </span>
        </a>
      );
    }

    if (
      this.state.showRecipients &&
      this.props.emailUsers &&
      Array.isArray(this.props.emailUsers.users)
    ) {
      const rows = [];
      rows.push(
        <tr>
          <th>{t("email")}</th>
          <th>{t("firstName")}</th>
          <th>{t("lastName")}</th>
          <th>{t("businessId")}</th>
          <th>{t("businessName")}</th>
        </tr>
      );
      this.props.emailUsers.users.forEach((spUser, idx) => {
        rows.push(
          <tr className={idx % 2 == 0 ? "td-even" : "td-odd"}>
            <td>{spUser.email}</td>
            <td>{spUser.firstName}</td>
            <td>{spUser.lastName}</td>
            <td>{spUser.companyId}</td>
            <td>{spUser.companyName}</td>
          </tr>
        );
      });
      content.push(<table className="bk-parking-list">{rows}</table>);
    }

    if (this.props.emailUsers && this.props.emailUsers.users) {
      content.push(
        <center>
          <BkModifyButton
            buttonTitle={t("send-button")}
            disabled={!this.state.subject || !this.state.message}
            callback={() =>
              this.props.sendEmail(this.props.location, {
                subject: this.state.subject,
                message: this.state.message,
                users: this.props.emailUsers.users
              })
            }
          />
        </center>
      );
    }

    return (
      <React.Fragment>
        <BookkaaHeader
          {...this.props.headerProps}
          location={this.props.location}
          disableProfile={true}
        />
        <BkContentArea maxWidth="1000px">{content}</BkContentArea>
      </React.Fragment>
    );
  }

  componentDidMount() {
    this.props.ensureLoggedIn(
      this.props.jwt,
      this.props.location.ctx,
      this.props.location.language,
      "saari"
    );
    if (this.props.jwt) {
      this.props.getEmailUsers(this.props.location);
      this.props.getVisitorsNow(this.props.location);
    }
  }
}
export default VallisaariAdminEmail;
