import React from "react";
import { Link } from "react-router-dom";
import BkContentArea from "../layout/BkContentArea";
import PropTypes from "prop-types";

class Payment extends React.Component {
  componentDidMount() {
    if (this.props && this.props.location) {
      this.props.checkOrder(
        this.props.location.language,
        this.props.orderNumber
      );
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.orderData &&
      this.props.orderData.orderNumber !== this.props.orderNumber
    ) {
      this.props.checkOrder(
        this.props.location.language,
        this.props.orderNumber
      );
    }
  }
  render() {
    const t = key => this.props.location.translate(this.props.location, key);

    if (this.props.paymentData) {
      console.log("Payment data: " + JSON.stringify(this.props.paymentData));
    }

    if (this.props.paymentDone) {
      console.log("Payment done? " + this.props.orderNumber);
      let backLink = "";
      if (this.props.orderData) {
        let paid = false;
        if (this.props.orderData.status === "PAID") {
          console.log("Order has been paid!");
          if (
            this.props.orderData.successUrl &&
            (this.props.orderData.successUrl.startsWith("http") ||
              this.props.orderData.successUrl.startsWith("/"))
          ) {
            window.location.href = this.props.orderData.successUrl;
          }
          paid = true;
        } else if (this.props.orderData.successUrl) {
          console.log("SuccessUrl: " + this.props.orderData.successUrl);
          if (this.props.orderData.successUrl === "SEASONCLIENT") {
            window.location.href =
              "/area/" +
              this.props.location.language +
              "/" +
              this.props.orderData.areaId +
              "/reserve";
          } else if (this.props.orderData.successUrl === "PROFILE") {
            window.location.href = "/profile/" + this.props.location.language;
          } else {
            window.location.href = this.props.orderData.successUrl;
          }
        }
        backLink =
          this.props.orderData.cart &&
          this.props.orderData.cart.type === "PAYMENT_LINK" ? (
            <React.Fragment>
              <Link
                to={`/area/${this.props.location.language}/${this.props.location.area}/reserve/season.do`}
              >
                Takaisin varauskarttaan
              </Link>
              <br />
            </React.Fragment>
          ) : (
            []
          );
        if (paid) {
          return (
            <BkContentArea>
              <h1>{this.props.orderData.title}</h1>
              <p>{this.props.orderData.text}</p>
              <p>{this.props.orderData.paymentDetails}</p>
              {backLink}
              <br />
              <br />
              <a href="http://www.satamapaikka.com">www.satamapaikka.com</a>
            </BkContentArea>
          );
        } else if (this.props.orderData.status === "WAITING_FOR_PAYMENT") {
          console.debug("Waiting for payment!");
          const co = this.props.checkOrder; // constants because "this" doesn't work inside timout callback
          const l = this.props.location.language;
          const o = this.props.orderNumber;
          setTimeout(function() {
            co(l, o);
          }, 3000);
          return (
            <BkContentArea>
              <h1>{this.props.orderData.title}</h1>
              <p>{this.props.orderData.text}</p>
            </BkContentArea>
          );
        } else if (
          this.props.orderData.status === "CANCELLED_BEFORE_SENT" ||
          this.props.orderData.status === "CANCELLED_BY_PROVIDER" ||
          this.props.orderData.status === "NOT_PAID"
        ) {
          console.debug("Payment cancelled!");
          return (
            <BkContentArea>
              <h1>{this.props.orderData.title}</h1>
              <p>{this.props.orderData.text}</p>
            </BkContentArea>
          );
        } else {
          console.debug("Not paid!");
          return (
            <BkContentArea>
              <h1>{this.props.orderData.title}</h1>
              <p>{this.props.orderData.text}</p>
            </BkContentArea>
          );
        }
      }
      return null;
    } else if (!this.props.paymentData) {
      return null;
    } else if (this.props.paymentData.paymentUrl) {
      console.log(
        "About to follow payment link: " + this.props.paymentData.paymentUrl
      );
      window.location.href = this.props.paymentData.paymentUrl;
      return null;
    }
    return (
      <React.Fragment>
        <div />
      </React.Fragment>
    );
  }
}
export default Payment;

Payment.propTypes = {
  paymentData: PropTypes.any,
  paymentDone: PropTypes.bool,
  location: {
    ctx: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired
  }
};
