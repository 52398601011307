import React, { Component } from "react";
import { Switch, Router, Route } from "react-router";
import "./App.css";
import "./bookkaa.css";
import createBrowserHistory from "history/createBrowserHistory";
import SeasonReservation from "./components/reservation/SeasonClient";
import Watch from "./components/reservation/Watch";
import ServiceProviders from "./components/msgcenter/ServiceProviders";
import BookkaaHeader from "./components/BookkaaHeader";
import LoginForm from "./components/LoginForm";
import RegisterForm from "./components/RegisterForm";
import LoginRequired from "./components/LoginRequired";
import Profile from "./components/Profile";
import Payment from "./components/payment/Payment";
import FrontPage from "./components/FrontPage";
import queryString from "query-string";
import PayReservation from "./components/payment/PayReservation";
import TableAndForm from "./components/layout/TableAndForm";
import WatchAdmin from "./components/admin/WatchAdmin";
import BoatParking from "./components/boatparking/BoatParking";
import PatrolLog from "./components/reservation/PatrolLog";
import BoatParkAdmin from "./components/boatparking/BoatParkAdmin";
import Admin from "./components/boatparking/Admin";
import Vallisaari from "./valli/Vallisaari";
import ServiceProviderAdmin from "./valli/ServiceProviderAdmin";
import VsEnterData from "./valli/VsEnterData";
import VsEnterCurrentVisitors from "./valli/VsEnterCurrentVisitors";
import VallisaariReport from "./valli/VallisaariReport";
import VallisaariAdminEmail from "./valli/VallisaariAdminEmail";
import PayoutsPage from "./components/admin/PayoutsPage";
import SeasonAdmin from "./components/admin/SeasonAdmin";
import SeasonCustomers from "./components/admin/SeasonCustomers";
import Areas from "./components/admin/Areas";
import { ClientUi } from "./components/layout/ClientUi";
import KokkolaForm from "./xtra/KokkolaForm";
import Messages from "./components/admin/Messages";
import BkCustomerFooter from "./components/CustomerFooter";
import ShitSystem from "./components/payment/ShitSystem";
import MessageOrders from "./components/admin/MessageOrders";
import AdhocMessages from "./components/admin/AdhocMessage";
import SeasonMassUpdate from "./components/admin/SeasonMassUpdate";
import SeasonQueueForm from "./xtra/SeasonQueueForm";
import SeasonQueue from "./components/admin/SeasonQueue";
import SeasonAdditionals from "./components/admin/SeasonAdditionals";
import SimpleAccessCodes from "./components/admin/SimpleAccessCodes";
import HarbourMessages from "./components/admin/HarbourMessages";
import BookkaaInternal from "./components/admin/BookkaaInternal";
import SimplePricing from "./components/admin/SimplePricing";
import Electricity from "./components/admin/Electricity";
import SelectOutlet from "./components/reservation/SelectOutlet";

export const history = createBrowserHistory();

class App extends Component {
  state = {
    error: undefined,
  };

  componentDidMount() {
    this.props.initialize("fi");
  }

  componentDidCatch(e) {
    this.setState((state, props) => {
      return {
        error: e
      };
    });
  }

  render() {
    const { error } = this.state;

    if (error) {
      return (
        <div>
          <h2>Oh noes! Something went wrong: {error.message} </h2>
        </div>
      );
    }
    const translations = this.props.translations ? this.props.translations : {};
    const commonProps = {
      translations: translations,
      getTranslations: this.props.getTranslations,
      dtColumnsMap: this.props.dtColumnsMap,
      dtSettingsMap: this.props.dtSettingsMap,
      getDtColumns: this.props.getDtColumns,
      getDtSettings: this.props.getDtSettings,
      checkLoggedIn: this.props.checkLoggedIn,
      frontPage: this.props.frontPage,
      user: this.props.user,
      jwt: this.props.jwt
    };

    const loginProps = {
      getTranslations: this.props.getTranslations,
      doLogin: this.props.doLogin,
      otpLogin: this.props.otpLogin,
      closeLogin: this.props.closeLogin,
      ensureLoggedIn: this.props.ensureLoggedIn,
      checkLoggedIn: this.props.checkLoggedIn,
      loginRequired: this.props.loginRequired,
      openRegisterDialog: this.props.openRegisterDialog,
      openForgotPassword: this.props.openForgotPassword,
      confirmForgotPassword: this.props.confirmForgotPassword,
      loginDialogVisible: this.props.loginDialogVisible,
      loginError: this.props.loginError,
      forgotPassword: this.props.forgotPassword,
      forgotPasswordLinkSent: this.props.forgotPasswordLinkSent,
      openLoginDialog: this.props.openLoginDialog,
      loginBusy: this.props.loginBusy,
      user: this.props.user,
      jwt: this.props.jwt,
      loginData: this.props.loginData
    };

    if (loginProps.loginError) {
      console.log(
        "loginProps.loginError: " + JSON.stringify(loginProps.loginError)
      );
    }

    const paymentProps = {
      closeShoppingCart: this.props.closeShoppingCart,
      paymentError: this.props.paymentError,
      paymentData: this.props.paymentData,
      reservationError: this.props.reservationError,
      pay: this.props.pay,
      storeReferrer: this.props.storeReferrer,
      orderReferrer: this.props.orderReferrer
    };

    const headerProps = {
      doLogin: this.props.doLogin,
      doLogout: this.props.doLogout,
      loginDialogVisible: this.props.loginDialogVisible,
      logOut: this.props.logOut,
      getTranslations: this.props.getTranslations,
      openProfile: this.props.openProfile,
      closeProfile: this.props.closeProfile,
      getMenu: this.props.getMenu,
      menuJson: this.props.menuJson,
      user: this.props.user,
      openLoginDialog: this.props.openLoginDialog,
      ensureLoggedIn: this.props.ensureLoggedIn,
      userProfile: this.props.userProfile
    };

    const profileProps = {
      getTranslations: this.props.getTranslations,
      ensureLoggedIn: this.props.ensureLoggedIn,
      openLoginDialog: this.props.openLoginDialog,
      openProfile: this.props.openProfile,
      closeProfile: this.props.closeProfile,
      frontPage: this.props.frontPage,
      user: this.props.user,
      userProfile: this.props.userProfile,
      ...paymentProps
    };

    const guestProps = {
      errorMessage: this.props.reservationError,
      headerProps: headerProps,
      checkOrder: this.props.checkOrder,
      orderData: this.props.orderData,
      boatParkWizard: this.props.boatParkWizard,
      boatParkWiz: this.props.boatParkWiz,
      guestCalendar: this.props.guestCalendar,
      guestReservationCalendar: this.props.guestReservationCalendar,
      ...profileProps,
      ...paymentProps,
      ...commonProps
    };

    const adminProps = {
      ensureLoggedIn: this.props.ensureLoggedIn,
      openLoginDialog: this.props.openLoginDialog,
      headerProps: headerProps,
      loginProps: loginProps,
      profileProps: profileProps,
      ...paymentProps,
      ...commonProps,
      areas: this.props.areas,
      sellers: this.props.sellers,
      getAreas: this.props.getAreas,
      getSellers: this.props.getSellers
    };

    const vsProps = {
      ensureLoggedIn: this.props.ensureLoggedIn,
      openLoginDialog: this.props.openLoginDialog,
      vallisaariData: this.props.vallisaariData,
      vallisaariReport: this.props.vallisaariReport,
      vallisaariVisitors: this.props.vallisaariVisitors,
      realtimeVisitors: this.props.realtimeVisitors,
      vallisaariReportCreated: this.props.vallisaariReportCreated,
      loadVallisaari: this.props.loadVallisaari,
      getVsReport: this.props.getVsReport,
      enterVsReport: this.props.enterVsReport,
      getVisitors: this.props.getVisitors,
      enterVisitors: this.props.enterVisitors,
      errorMessage: this.props.vallisaariError,
      headerProps: headerProps,
      dtProps: {
        dtEndpoint: "service",
        dtSettingsMap: this.props.dtSettingsMap,
        dtColumnsMap: this.props.dtColumnsMap,
        getDtColumns: this.props.getDtColumns,
        getDtSettings: this.props.getDtSettings
      }
    };

    return (
      <div className="App">
        <RegisterForm
          ctx="um-web"
          endpoint="login"
          {...this.props}
          language="fi"
          errorMessage={this.props.loginError}
        />
        <Payment paymentData={this.props.paymentData} />
        <Router history={history}>
          <Switch>
            <Route
              path="/[^(\.csv|\.pdf|\.xlsx)]^"
              exact
              render={props => {
                return <FrontPage {...this.props} language="fi" />;
              }}
            />
            <Route
              path="/kokkola"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "profile",
                  props,
                  this.props
                );
                location.language = "fi";
                return <KokkolaForm location={location} {...commonProps} />;
              }}
            />
            <Route
              path="/queue/:language/:area"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "queue",
                  props,
                  this.props
                );
                return <SeasonQueueForm location={location} {...commonProps} />;
              }}
            />
            <Route
              path="/pay/:language/:area?/payment/return.do"
              //?AUTHCODE=:authcode&RETURN_CODE=:return&ORDER_NUMBER=:orderNumber&SETTLED=:settled"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "profile",
                  props,
                  this.props
                );
                const values = queryString.parse(props.location.search);
                const title = location.translate(location, "headerPayment");
                return (
                  <React.Fragment>
                    <BookkaaHeader
                      location={location}
                      {...headerProps}
                      disableLogin={true}
                      headerSelectorContent={title}
                    />
                    <Profile location={location} {...profileProps}>
                      <Payment
                        paymentDone={true}
                        errorMessage={this.props.paymentError}
                        checkOrder={this.props.checkOrder}
                        orderData={this.props.orderData}
                        orderNumber={values.ORDER_NUMBER}
                        location={location}
                        openLoginDialog={this.props.openLoginDialog}
                      />
                    </Profile>
                  </React.Fragment>
                );
              }}
            />
            <Route
              path="/park/:language/:area?"
              exact
              render={props => {
                // prettier-ignore
                const location = this.getLocation("booking-web", "boatpark", props, this.props);
                const values = queryString.parse(props.location.search);
                console.log("Route props => values: " + JSON.stringify(values));
                // prettier-ignore
                return (
                    <React.Fragment>
                      <BoatParking location={location} {...guestProps} orderNumber={values.ORDER_NUMBER} />
                    </React.Fragment>
                  );
              }}
            />
            <Route
              path="/guest/:language/:area?"
              exact
              render={props => {
                // prettier-ignore
                const location = this.getLocation("booking-web", "boatpark", props, this.props);
                const values = queryString.parse(props.location.search);
                console.log("Route props => values: " + JSON.stringify(values));
                // prettier-ignore
                return (
                    <BoatParking location={location} {...guestProps} orderNumber={values.ORDER_NUMBER} guest={true} />
                );
              }}
            />
            <Route
              path="/septic/:language/:area?"
              exact
              render={props => {
                // prettier-ignore
                const location = this.getLocation("booking-web", "septic", props, this.props);
                const values = queryString.parse(props.location.search);
                console.log("Route props => values: " + JSON.stringify(values));
                // prettier-ignore
                return (
                    <ShitSystem location={location} {...guestProps} orderNumber={values.ORDER_NUMBER}  />
                );
              }}
            />
            <Route
              path="/log/:language/:area/:pwd"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "log",
                  props,
                  this.props
                );
                return (
                  <React.Fragment>
                    <PatrolLog
                      location={location}
                      errorMessage={this.props.reservationError}
                      {...headerProps}
                      {...paymentProps}
                      {...commonProps}
                      patrolLog={this.props.patrolLog}
                      addLogEntry={this.props.addLogEntry}
                      getLogEntries={this.props.getLogEntries}
                      pwd={props.match.params.pwd}
                    />
                  </React.Fragment>
                );
              }}
            />
            <Route
              path="/area/:language/:area/reserve"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "reserve",
                  props,
                  this.props
                );
                let headers = (
                  <BookkaaHeader location={location} {...headerProps} />
                );
                let first =  undefined;
                let productId = undefined;
                let subareaId = undefined;
                if (props.location.search) {
                  const values = queryString.parse(props.location.search);
                  if (values.view) {
                    headers = [];
                  }
                  first = values.first;
                  if (values.place) {
                    productId = values.place;
                  }
                  if (values.pier) {
                    subareaId = values.pier;
                  }
                }
                return (
                  <React.Fragment>
                    {headers}
                    <LoginForm location={location} {...loginProps} />
                    <Profile location={location} {...profileProps}>
                      <SeasonReservation
                        ctx="booking-web"
                        endpoint="reserve"
                        location={location}
                        errorMessage={this.props.reservationError}
                        {...paymentProps}
                        {...commonProps}
                        reserveFirst={first}
                        productId={productId}
                        subareaId={subareaId}
                        language={props.match.params.language}
                        ensureLoggedIn={this.props.ensureLoggedIn}
                        openLoginDialog={this.props.openLoginDialog}
                        getImageMap={this.props.getImageMap}
                        imageMap={this.props.imageMap}
                      />
                    </Profile>
                    <BkCustomerFooter
                      harbours={
                        this.props.frontPage
                          ? this.props.frontPage.harbours
                          : undefined
                      }
                      area={location.area}
                      gdprTitle={location.translate(location, "gdprLink")}
                    />
                  </React.Fragment>
                );
              }}
            />
            <Route
              path="/admin/:language/:area/parking/:file"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "parkingAdmin",
                  props,
                  this.props
                );
                return (
                  <LoginRequired {...loginProps} location={location}>
                    <BoatParkAdmin
                      ctx="booking-web"
                      endpoint="parkingAdmin"
                      location={location}
                      errorMessage={this.props.reservationError}
                      headerProps={headerProps}
                      loginProps={loginProps}
                      {...paymentProps}
                      {...commonProps}
                      cancelReservationById={this.props.cancelReservationById}
                      boatParkReport={this.props.boatParkReport}
                      boatParkAdmin={this.props.boatParkAdmin}
                      boatParkAdminData={this.props.boatParkAdminData}
                      language={props.match.params.language}
                      ensureLoggedIn={this.props.ensureLoggedIn}
                      openLoginDialog={this.props.openLoginDialog}
                      getImageMap={this.props.getImageMap}
                      imageMap={this.props.imageMap}
                      boatParkAdminReport={this.props.boatParkAdminReport}
                    />
                  </LoginRequired>
                );
              }}
            />
            <Route
              path="/admin/:language/payouts"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "parkingAdmin",
                  props,
                  this.props
                );
                return (
                  <LoginRequired {...loginProps} location={location}>
                    <PayoutsPage
                      location={location}
                      //errorMessage={this.props.reservationError}
                      {...adminProps}
                      payouts={this.props.payouts}
                      getPayouts={this.props.getPayouts}
                    />
                  </LoginRequired>
                );
              }}
            />
            <Route
              path="/admin/:language/:area/reservations"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "parkingAdmin",
                  props,
                  this.props
                );
                return (
                  <LoginRequired {...loginProps} location={location}>
                    <Admin
                      ctx="booking-web"
                      endpoint="parkingAdmin"
                      location={location}
                      errorMessage={this.props.reservationError}
                      {...adminProps}
                      payouts={this.props.payouts}
                      getPayouts={this.props.getPayouts}
                      guestReservationReport={this.props.guestReservationReport}
                      reservationReport={this.props.reservationReport}
                      placeDetails={this.props.placeDetails}
                      fetchProductDetails={this.props.fetchProductDetails}
                      storeProductDetails={this.props.storeProductDetails}
                      cancelReservationById={this.props.cancelReservationById}
                      markReservationPaidById={
                        this.props.markReservationPaidById
                      }
                      updateReservation={this.props.updateReservation}
                      updatedReservation={this.props.updatedReservation}
                      boatParkReport={this.props.boatParkReport}
                      boatParkAdmin={this.props.boatParkAdmin}
                      boatParkAdminData={this.props.boatParkAdminData}
                      language={props.match.params.language}
                      getImageMap={this.props.getImageMap}
                      imageMap={this.props.imageMap}
                      boatParkAdminReport={this.props.boatParkAdminReport}
                    />
                  </LoginRequired>
                );
              }}
            />
            <Route
              path="/admin/:language/:area/season"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "seasonAdmin",
                  props,
                  this.props
                );
                return (
                  <LoginRequired {...loginProps} location={location}>
                    <SeasonAdmin
                      ctx="booking-web"
                      endpoint="seasonAdmin"
                      location={location}
                      errorMessage={this.props.reservationError}
                      {...adminProps}
                      placeDetails={this.props.placeDetails}
                      fetchProductDetails={this.props.fetchProductDetails}
                      storeProductDetails={this.props.storeProductDetails}
                      cancelReservationById={this.props.cancelReservationById}
                      markReservationPaidById={
                        this.props.markReservationPaidById
                      }
                      updateReservation={this.props.updateReservation}
                      updatedReservation={this.props.updatedReservation}
                      boatParkReport={this.props.boatParkReport}
                      boatParkAdmin={this.props.boatParkAdmin}
                      boatParkAdminData={this.props.boatParkAdminData}
                      language={props.match.params.language}
                      getImageMap={this.props.getImageMap}
                      imageMap={this.props.imageMap}
                    />
                  </LoginRequired>
                );
              }}
            />
            <Route
              path="/outlet/:language/:reference"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "outlet",
                  props,
                  this.props
                );
                return (
                    <SelectOutlet location={location} reference={props.match.params.reference} {...guestProps} />
                );

              }}
            />
            <Route
              path="/admin/:language/:area/electricity"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "electricity",
                  props,
                  this.props
                );
                return (
                  <LoginRequired {...loginProps} location={location}>
                    <Electricity location={location} {...adminProps} />
                  </LoginRequired>
                );
              }}
            />
            <Route
              path="/admin/:language/:area/seasonAdditional"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "seasonAdmin",
                  props,
                  this.props
                );
                return (
                  <LoginRequired {...loginProps} location={location}>
                    <SeasonAdditionals location={location} {...adminProps} />
                  </LoginRequired>
                );
              }}
            />
            <Route
              path="/admin/:language/:area/codes/:type"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "accessCode",
                  props,
                  this.props
                );
                return (
                  <LoginRequired {...loginProps} location={location}>
                    <SimpleAccessCodes location={location} {...adminProps} guest={props.match.params.type == "guest"} />
                  </LoginRequired>
                );
              }}
            />
            <Route
              path="/admin/:language/:area/queue"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "queue",
                  props,
                  this.props
                );
                return (
                  <LoginRequired {...loginProps} location={location}>
                    <SeasonQueue
                      location={location}
                      {...adminProps}
                      profileProps={profileProps}
                    />
                  </LoginRequired>
                );
              }}
            />
            <Route
              path="/admin/:language/:area/customers/:file?"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "seasonAdmin",
                  props,
                  this.props
                );
                return (
                  <LoginRequired {...loginProps} location={location}>
                    <SeasonCustomers
                      ctx="booking-web"
                      endpoint="seasonAdmin"
                      location={location}
                      errorMessage={this.props.reservationError}
                      {...adminProps}
                      updatedCustomer={this.props.updatedCustomer}
                      reserveForCustomer={this.props.reserveForCustomer}
                      customers={this.props.customers}
                      searchCustomer={this.props.searchCustomer}
                      cancelReservationById={this.props.cancelReservationById}
                      markReservationPaidById={
                        this.props.markReservationPaidById
                      }
                      fetchProductDetails={this.props.fetchProductDetails}
                      placeDetails={this.props.placeDetails}
                      storeProductDetails={this.props.storeProductDetails}
                      updateReservation={this.props.updateReservation}
                      updatedReservation={this.props.updatedReservation}
                      profileProps={profileProps}
                    />
                  </LoginRequired>
                );
              }}
            />
            <Route
              path="/admin/:language/:area/massUpdate"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "seasonAdmin",
                  props,
                  this.props
                );
                return (
                  <LoginRequired {...loginProps} location={location}>
                    <SeasonMassUpdate
                      location={location}
                      {...adminProps}
                      profileProps={profileProps}
                    />
                  </LoginRequired>
                );
              }}
            />
            <Route
              path="/admin/:language/bookkaa"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "bookkaa",
                  props,
                  this.props
                );
                return (
                  <BookkaaInternal 
                    headerProps={headerProps}
                    loginProps={loginProps}
                    profileProps={profileProps}
                    {...this.props}
                    location={location}
                  />
                );
              }}
            />
            <Route
              path="/admin/:language/areas"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "areas",
                  props,
                  this.props
                );
                return (
                  <Areas
                    headerProps={headerProps}
                    loginProps={loginProps}
                    profileProps={profileProps}
                    {...this.props}
                    getImageMap={this.props.getImageMap}
                    imageMap={this.props.imageMap}
                    location={location}
                  />
                );
              }}
            />
            <Route
              path="/profile/:language"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "reserve",
                  props,
                  this.props
                );
                if (!profileProps.userProfile) {
                  this.props.openProfile(location.language);
                }

                return (
                  <ClientUi>
                    <LoginRequired {...loginProps} location={location} />
                    <BookkaaHeader location={location} {...headerProps} />
                    <Profile location={location} {...profileProps} />
                  </ClientUi>
                );
              }}
            />
            <Route
              path="/payReservation/:language/:area/:orderNumber"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "reserve",
                  props,
                  this.props
                );
                console.log(
                  "PayReservations params: " +
                    JSON.stringify(props.match.params)
                );
                let ofp = this.props.orderForPayment;
                let orderNr =
                  ofp != null && ofp.orderNr
                    ? ofp.orderNr
                    : props.match.params.orderNumber;
                return (
                  <ClientUi>
                    <PayReservation
                      headerProps={headerProps}
                      loginProps={loginProps}
                      profileProps={profileProps}
                      openLoginDialog={this.props.openLoginDialog}
                      orderNumber={orderNr}
                      orderData={this.props.orderData}
                      retrieveOrder={this.props.retrieveOrder}
                      orderForPayment={ofp}
                      getImageMap={this.props.getImageMap}
                      imageMap={this.props.imageMap}
                      {...commonProps}
                      {...paymentProps}
                      location={location}
                    />
                  </ClientUi>
                );
              }}
            />
            <Route
              path="/area/:language/:area/reserveWatch/:file"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "reserve",
                  props,
                  this.props
                );
                if (props.match.params.file === "admin.do") {
                  const values = queryString.parse(props.location.search);
                  let headers = true;
                  if (values.page_id) {
                    console.log("From Dashboard, disable headers");
                    headers = false;
                  }
                  console.log("Guard watch admin");
                  return (
                    <WatchAdmin
                      {...commonProps}
                      headers={headers}
                      language={props.match.params.language}
                      headerProps={headerProps}
                      loginProps={loginProps}
                      openLoginDialog={this.props.openLoginDialog}
                      {...this.props}
                      location={location}
                    />
                  );
                }
                return (
                  <Watch
                    language={props.match.params.language}
                    headerProps={headerProps}
                    loginProps={loginProps}
                    openLoginDialog={this.props.openLoginDialog}
                    {...this.props}
                    location={location}
                  />
                );
              }}
            />
            <Route
              path="/admin/:language/:area?/adhoc"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "seasonAdmin",
                  props,
                  this.props
                );
                return <AdhocMessages location={location} {...adminProps} />;
              }}
            ></Route>
            <Route
              path="/admin/:language/:area/messages"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "seasonAdmin",
                  props,
                  this.props
                );
                return <HarbourMessages location={location} {...adminProps} />;
              }}
            ></Route>
            <Route
              path="/admin/:language/:area/prices"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "simplePricing",
                  props,
                  this.props
                );
                return <SimplePricing location={location} {...adminProps} />;
              }}
            ></Route>
            <Route
              path="/admin/:language/messages"
              exact
              render={props => {
                const location = this.getLocation(
                  "messagecenter-web",
                  "templates",
                  props,
                  this.props
                );
                return <Messages location={location} {...adminProps} />;
              }}
            ></Route>
            <Route
              path="/admin/:language/:area?/messageOrders"
              exact
              render={props => {
                const location = this.getLocation(
                  "messagecenter-web",
                  "order",
                  props,
                  this.props
                );
                return <MessageOrders location={location} {...adminProps} />;
              }}
            ></Route>
            <Route
              path="/vs/:language/:sp/:file"
              exact
              render={props => {
                const location = this.getLocation(
                  "booking-web",
                  "saari",
                  props,
                  this.props
                );
                const vsData = {
                  ...vsProps,
                  jwt: this.props.jwt,
                  location: {
                    ...location,
                    area: vsProps.vallisaariData
                      ? vsProps.vallisaariData.area
                      : location.area,
                    sp: vsProps.vallisaariData
                      ? vsProps.vallisaariData.spId
                      : undefined
                  },
                  vallisaariVisitors: this.props.vallisaariVisitors,
                  vallisaariVisitorsAt: this.props.vallisaariVisitorsAt,
                  loadMinimumVisitors: this.props.loadMinimumVisitors
                };
                const vsAdmin = {
                  realtimeVisitors: this.props.realtimeVisitors,
                  getVisitorsNow: this.props.getVisitorsNow,
                  getEmailUsers: this.props.getEmailUsers,
                  sendEmail: this.props.sendEmail,
                  emailUsers: this.props.emailUsers
                };
                if (props.match.params.file === "data") {
                  return (
                    <LoginRequired {...loginProps} location={location}>
                      <VsEnterData {...vsData} />
                    </LoginRequired>
                  );
                } else if (props.match.params.file === "visitors") {
                  return (
                    <LoginRequired {...loginProps} location={location}>
                      <VsEnterCurrentVisitors {...vsData} />
                    </LoginRequired>
                  );
                } else if (props.match.params.file === "providers") {
                  return (
                    <LoginRequired {...loginProps} location={location}>
                      <ServiceProviderAdmin {...vsData} {...vsAdmin} />
                    </LoginRequired>
                  );
                } else if (props.match.params.file === "report") {
                  return (
                    <LoginRequired {...loginProps} location={location}>
                      <VallisaariReport {...vsData} {...vsAdmin} />
                    </LoginRequired>
                  );
                } else if (props.match.params.file === "email") {
                  return (
                    <LoginRequired {...loginProps} location={location}>
                      <VallisaariAdminEmail {...vsData} {...vsAdmin} />
                    </LoginRequired>
                  );
                } else {
                  return (
                    <LoginRequired {...loginProps} location={location}>
                      <Vallisaari {...vsData} />
                    </LoginRequired>
                  );
                }
              }}
            />
            <Route
              path="/:ctx/:language/:area/:endpoint/:file"
              exact
              render={props => {
                console.log("Params: " + JSON.stringify(props.match.params));
                return this.renderNormal(
                  props,
                  headerProps,
                  loginProps,
                  profileProps,
                  commonProps,
                  this.props
                );
              }}
            />
            <Route
              path="/:ctx/:language/:endpoint/:file"
              exact
              render={props =>
                this.renderNormal(
                  props,
                  headerProps,
                  loginProps,
                  profileProps,
                  commonProps,
                  this.props
                )
              }
            />
          </Switch>
        </Router>
      </div>
    );
  }

  getLocation(ctx, ep, rprops, cprops) {
    return {
      ctx: ctx,
      endpoint: ep,
      language: rprops.match.params.language,
      area: rprops.match.params.area,
      file: rprops.match.params.file,
      translations: cprops && cprops.translations ? cprops.translations : {},
      translate: (location, source) => {
        if (
          location.translations &&
          location.translations.texts &&
          location.translations.texts[source]
        ) {
          return location.translations.texts[source];
        } else {
          return source;
        }
      }
    };
  }

  renderNormal(
    props,
    headerProps,
    loginProps,
    profileProps,
    commonProps,
    allProps
  ) {
      let ctx;
      console.log(`Context: ${props.match.params.ctx}`);
      switch (props.match.params.ctx) {
        case "msg":
          ctx = "messagecenter-web";
          break;
        case "bk":
          ctx = "booking-web";
          break;
        case "um":
          ctx = "um-web";
          break;
        case "booking-web":
          console.log("Unknown context: " + props.match.params.ctx);
          return <div />;
        default:
          console.log("Unknown context2: " + props.match.params.ctx);
      }
      let location = this.getLocation(
        ctx,
        props.match.params.endpoint,
        props,
        allProps
      );

      if (ctx === "booking-web" && props.match.params.endpoint === "service") {
        return (
          <LoginRequired {...loginProps} location={location}>
            <BookkaaHeader location={location} {...headerProps} />
            <LoginForm location={location} {...loginProps} />
            <Profile location={location} {...profileProps}>
              <ServiceProviders {...allProps} location={location} />
            </Profile>
          </LoginRequired>
        );
      } else {
        return (
          <LoginRequired {...loginProps} location={location}>
            <BookkaaHeader location={location} {...headerProps} />
            <LoginForm location={location} {...loginProps} />
            <Profile location={location} {...profileProps}>
              <TableAndForm
                {...allProps}
                location={location}
                formButtonArray={[]}
                buttons={[]}
              />
            </Profile>
          </LoginRequired>
        );
      }
    }
  }

export default App;
