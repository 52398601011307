import React from "react";
import BkModifyButton from "./layout/BkModifyButton";
import BkContentArea from "./layout/BkContentArea";
import Modal from "react-modal";
import { customStyles } from "./layout/ModalStyle";
import { BUSY_LOGIN, BUSY_FORGOT } from "../ducks/login";
import { CircleLoader } from "react-spinners";
import menu from "../services/menu";
import { genericPost } from "../services/axiosApi";
import OkErrorMessage from "./layout/OkErrorMessage";

export const PinField = props => {
  const { t, render, value, pinCb } = props;
  if (render) {
    return (
      <div>
        <label className="label">{t("pin")}</label>
        <input
          name="pin"
          type="tel"
          value={value}
          onChange={e => pinCb(e.target.value)}
        ></input>
      </div>
    );
  }
  return null;
};

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginChecked: 0,
      email:
        props.loginData && props.loginData.email
          ? props.loginData.email
          : props.user && props.user.email
          ? props.user.email
          : "",
      password: "",
      pin: "",
      forgotOpen: false,
      translations: {}
    };
    this.login = this.login.bind(this);
    this.update = this.update.bind(this);
    this.openRegister = this.openRegister.bind(this);
    this.forgotPassword = this.forgotPassword.bind(this);
    this.confirmForgot = this.confirmForgot.bind(this);
    this.translate = this.translate.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);

    menu
      .transPromise("um-web", props.location.language, "login")
      .then(trans => {
        this.setState({ translations: trans });
      });
  }

  // shortcuts to static stuff
  p = {
    loc: this.props.location,
    lang: this.props.location ? this.props.location.language : "fi",
    ctx: this.props.location ? this.props.location.ctx : "booking-web",
    ep: this.props.location ? this.props.location.endpoint : "start",
    busy: this.props.loginBusy
  };

  translate(source) {
    if (
      this.state.translations &&
      this.state.translations.texts &&
      this.state.translations.texts[source]
    ) {
      return this.state.translations.texts[source];
    } else {
      return source;
    }
  }

  interval = null;

  login() {
    if (this.state.otpSent) {
      console.log("Try to log in with OTP");
      this.props.otpLogin(
        this.props.location.language,
        this.state.email,
        this.state.pin,
        this.state.sessionId,
        this.p.ctx,
        this.p.ep
      );
    } else {
      this.props.doLogin(
        this.state.email,
        this.state.password,
        this.p.ctx,
        this.p.lang,
        this.p.ep
      );
      this.setState({ forgotOpen: false });
    }
  }
  openRegister() {
    console.log("Opening registration dialog");
    this.props.openRegisterDialog();
  }

  forgotPassword() {
    this.setState({ forgotOpen: true });
    if (this.state.email) {
      const currentUrl = window.location.href;
      this.props.openForgotPassword(currentUrl, this.p.lang, this.state.email);
    }
  }

  confirmForgot() {
    this.props.confirmForgotPassword(
      this.props.location.language,
      this.state.email,
      this.state.pin,
      this.state.password
    );
    this.setState({
      forgotOpen: false
    });
  }

  update() {
    let ch = this.state.loginChecked + 1;
    if (this.state.email === "" && this.props.user && this.props.user.email) {
      this.setState({ loginChecked: ch, email: this.props.user.email });
    } else {
      this.setState({ loginChecked: ch });
    }
    if (ch > 1 && ch % 16) {
      this.props.ensureLoggedIn(
        this.props.jwt,
        this.p.ctx,
        this.p.lang,
        this.p.ep
      );
    }
  }
  componentDidMount() {
    if (this.props.jwt && this.interval === null) {
      this.interval = setInterval(() => this.update(), 1200000);
    } else if (!this.props.jwt && this.interval !== null) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }
  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null;
  }

  afterOpenModal() {
    console.log("modal opened");
  }

  closeModal() {
    console.log("modal closed");
  }

  render() {
    if (
      !this.state.email &&
      this.props.loginData &&
      this.props.loginData.email
    ) {
      this.setState({ email: this.props.loginData.email });
    } else if (!this.state.email && this.props.user && this.props.user.email) {
      this.setState({ email: this.props.user.email });
    }
    const t = this.translate;
    if (this.props.loginDialogVisible) {
      let message = this.props.loginError;
      if (
        this.props.loginData &&
        this.props.loginData.otpLogin &&
        !this.state.otpSent
      ) {
        if (!this.state.sendingOtp) {
          genericPost(
            this.props.location.language,
            "login/otp/email",
            { email: this.props.loginData.email, sessionId: this.props.loginData.sessionId },
            "um-web"
          ).then(
            r =>
              r.errorStatus
                ? this.setState({ otpSent: false, sendingOtp: false })
                : this.setState({ otpSent: true, sendingOtp: false }),
            r => this.setState({ otpSent: false, sendingOtp: false }) // rejected
          );
          this.setState({ sendingOtp: true, sessionId: this.props.loginData.sessionId });
        }
      } else if (this.props.forgotPassword && !message) {
        if (this.props.forgotPasswordLinkSent) {
          message = "Salasanan vaihto-ohjeet lähetetty sähköpostiisi.";
        } else {
          message = `Syötä sähköpostiosoitteesi ja paina '${t("reset-pwd-button")}'.`;
        }
      }
      console.log(
        `rendering login, location: ${this.p.ctx +
          "/" +
          this.p.lang +
          "/" +
          this.p.ep}, errorMsg: ${this.props.loginError}`
      );
      const errorMsg = this.props.loginError;
      const pwSent = this.props.forgotPasswordLinkSent || this.state.otpSent;
      const instructionText = this.state.otpSent
        ? t("otp-login-info")
        : pwSent
        ? t("forgot-password-info")
        : this.state.forgotOpen
        ? t("forgot-enter-email")
        : t("login-dialog-info");
      if (
        instructionText === "login-dialog-info" ||
        (!this.state.otpSent &&
          this.props.loginData &&
          this.props.loginData.otpLogin)
      ) {
        return <CircleLoader />;
      }
      return (
        <React.Fragment>
          <BkContentArea>
            <Modal
              isOpen={this.props.loginDialogVisible}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={this.closeModal}
              style={customStyles}
              className="ui-widget-content"
              contentLabel="Kirjaudu"
            >
              <div>
                <form>
                  <div className="bk_contentHeader"></div>
                  <OkErrorMessage 
                    clearOk={() => this.setState({ okMessage: undefined})}
                    okMessage={this.state.okMessage}
                    errorMessage={errorMsg}
                  />

                  <div className="bkWidget bkLoginForm">
                    <div className="row">{instructionText}</div>
                    <div className="row">
                      <PinField
                        t={t}
                        render={pwSent}
                        value={this.state.pin}
                        pinCb={v => this.setState({ pin: v })}
                      />
                      <div>
                        <label className="label">{t("email")}</label>
                        <input
                          name="email"
                          type="email"
                          value={this.state.email}
                          onChange={e => {
                            this.setState({ email: e.target.value });
                          }}
                          onKeyPress={this.onKeyPress}
                        ></input>
                      </div>
                      {!this.state.otpSent &&
                        (pwSent || !this.state.forgotOpen) && (
                          <div>
                            <label className="label">{t("password")}</label>
                            <input
                              disabled={this.state.forgotOpen && !pwSent}
                              name="password"
                              type="password"
                              onChange={e => {
                                this.setState({ password: e.target.value });
                              }}
                              onKeyPress={this.onKeyPress}
                            ></input>
                          </div>
                        )}
                    </div>
                  </div>
                </form>
              </div>
              {!this.state.forgotOpen && !pwSent && (
                <React.Fragment>
                  <BkModifyButton
                    language={this.p.lang}
                    callback={this.login}
                    buttonTitle={t("loginButton")}
                    busy={this.p.busy.get(BUSY_LOGIN)}
                    disabled={!this.state.password || !this.state.email}
                  />
                  <BkModifyButton
                    language={this.p.lang}
                    callback={this.openRegister}
                    buttonTitle={t("register-button")}
                  />
                </React.Fragment>
              )}
              {(this.state.forgotOpen || pwSent) && !this.state.otpSent && (
                <BkModifyButton
                  language={this.p.lang}
                  callback={this.confirmForgot}
                  buttonTitle={t("confirm-forgot-button")}
                  disabled={
                    !this.state.pin || !this.state.password || !this.state.email
                  }
                />
              )}
              {this.state.otpSent && (
                <BkModifyButton
                  language={this.p.lang}
                  callback={this.login}
                  buttonTitle={t("loginButton")}
                  busy={this.p.busy.get(BUSY_LOGIN)}
                />
              )}
              {!this.state.otpSent && (
                <BkModifyButton
                  language={this.p.lang}
                  callback={this.forgotPassword}
                  buttonTitle={t("reset-pwd-button")}
                  busy={this.p.busy.get(BUSY_FORGOT)}
                  disabled={pwSent}
                />
              )}
            </Modal>
          </BkContentArea>
        </React.Fragment>
      );
    } else {
      return null;
    }
  }
  onKeyPress(e) {
    if (
      e.which === 13 &&
      this.state.email &&
      this.state.password &&
      !this.state.forgotOpen
    ) {
      this.login();
    }
  }
}

export default LoginForm;
