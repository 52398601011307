import React from "react";
import { genericDelete, genericPost, genericPut } from "../../services/axiosApi";
import BookkaaHeader from "../BookkaaHeader";
import TableAndForm from "../layout/TableAndForm";
import LoginForm from "../LoginForm";
import LoginRequired from "../LoginRequired";
import Profile from "../Profile";
import OkErrorMessage from "../layout/OkErrorMessage";
import AreaHeader from "./AreaHeader";

class Pricing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGroup: undefined,
      selectedPricing: undefined,
      selectedReservationUnit: undefined
    };
    this.selectCallback = this.selectCallback.bind(this);
    this.groupSelectCallback = this.groupSelectCallback.bind(this);
    this.unitSelectCallback = this.unitSelectCallback.bind(this);
    this.deleteGroup = this.deleteGroup.bind(this);
    this.deletePricing = this.deletePricing.bind(this);
    this.deleteUnit = this.deleteUnit.bind(this);
    this.attachUnit = this.attachUnit.bind(this);
    this.unattachUnit = this.unattachUnit.bind(this);
  }

  selectCallback(item) {
    this.setState({ selectedPricing: item });
  }
  groupSelectCallback(item) {
    this.setState({ selectedGroup: item });
  }
  unitSelectCallback(item) {
    this.setState({ selectedReservationUnit: item });
  }

  attachUnit(group, unit) {
    const l = this.props.location;
    return genericPut(l.language, "reservationUnit/" + unit.id + "/group", { priceGroupId: group.id }).then(() => {
      if (this.groupTableAndForm) {
        this.groupTableAndForm.reloadData();
        this.setState({selectedGroup: undefined})
      }
    });
  }

  unattachUnit(group, unit) {
    const l = this.props.location;
    return genericDelete(l.language, "reservationUnit/" + unit.id + "/group/" + group.id).then(() => {
      if (this.groupTableAndForm) {
        this.groupTableAndForm.reloadData();
        this.setState({selectedGroup: undefined})
      }
    });
  }

  deleteGroup(item) {
    const l = this.props.location;
    return genericDelete(
      l.language,
      "priceGroup/" + item.id,
      "booking-web"
    ).then(() => {
      this.setState({ selectedGroup: undefined, errorMessage: undefined });
      return true;
    });
  }

  deletePricing(item) {
    const l = this.props.location;
    return genericDelete(l.language, "pricing/" + item.id, "booking-web").then(
      () => {
        this.setState({ selectedPricing: undefined, errorMessage: undefined });
        return true;
      }
    );
  }

  deleteUnit(item) {
    const l = this.props.location;
    return genericDelete(l.language, "reservationUnit/" + item.id, "booking-web").then(
      () => {
        this.setState({ selectedReservationUnit: undefined, errorMessage: undefined });
        return true;
      }
    );
  }
  render() {
    const l = this.props.location;
    const t = key => l.translate(l, key);
    const codesLoc = { ...this.props.location };
    codesLoc.endpoint = "a" + l.area + "/pricing";
    codesLoc.area = undefined;
    const unitsLoc = { ...this.props.location };
    unitsLoc.endpoint = "a" + l.area + "/reservationUnit";
    unitsLoc.area = undefined;
    const groupsLoc = { ...codesLoc };
    groupsLoc.endpoint = "a" + l.area + "/priceGroup";

    let beforeForm = [];
    const CLOSE_BUTTON = {
      supportsMulti: true,
      callback: this.props.closeCb,
      title: "Sulje"
    };

    const DELETE_GROUP = {
      supportsMulti: false,
      callback: this.deleteGroup,
      title: t("delete-button"),
      disabledCallback: b =>
        !this.state.selectedGroup || !this.state.selectedGroup.id
    };

    const DELETE_PRICING = {
      supportsMulti: false,
      callback: this.deletePricing,
      title: t("delete-button"),
      disabledCallback: b =>
        !this.state.selectedPricing || !this.state.selectedPricing.id
    };

    const DELETE_UNIT = {
      supportsMulti: false,
      callback: this.deleteUnit,
      title: t("delete-button"),
      disabledCallback: b =>
        !this.state.selectedReservationUnit || !this.state.selectedReservationUnit.id
    };

    let listButtonArray = [CLOSE_BUTTON];

    return (
      <React.Fragment>
        <LoginRequired {...l} {...this.props.loginProps} />
        <BookkaaHeader location={l} {...this.props.headerProps} />
        <LoginForm location={l} {...this.props.loginProps} />
        <Profile location={l} {...this.props.profileProps}>
          <AreaHeader areaName={this.props.areaName} />
          <OkErrorMessage okMessage={this.state.okMessage} errorMessage={this.state.errorMessage} clearOk={()=>this.setState({okMessage:undefined})} clearError={()=>this.setState({errorMessage:undefined})} />
          <h2>{t("reservationUnit.title")}</h2>
          <TableAndForm
            selectCallback={this.unitSelectCallback}
            formButtonArray={[DELETE_UNIT]}
            multiSelect={false}
            {...this.props}
            endpoint={unitsLoc.endpoint}
            location={unitsLoc}
            postSaveCallback={() => { 
              if (this.groupTableAndForm) {
                this.groupTableAndForm.reloadData();
              }
            }}
          />
          <div>
          <h2>{t("priceGroup.title")}</h2>
          <TableAndForm
            selectCallback={this.groupSelectCallback}
            itemsBeforeForm={beforeForm}
            buttons={listButtonArray}
            formButtonArray={[DELETE_GROUP]}
            multiSelect={false}
            {...this.props}
            endpoint={groupsLoc.endpoint}
            location={groupsLoc}
            onRef={(g) => this.groupTableAndForm = g}
          /></div>
          {this.state.selectedGroup && (<div>
            {this.state.selectedGroup.attachedUnits.map(u => <label>
              <input type="checkbox" checked={true} onClick={() => this.unattachUnit(this.state.selectedGroup, u)}></input>{u.description}
            </label>)}
            {this.state.selectedGroup.unattachedUnits.map(u => <label>
              <input type="checkbox" checked={false} onClick={() => this.attachUnit(this.state.selectedGroup, u)}></input>{u.description}
            </label>)}
          </div>
          )}
          <h2>{t("pricing.title")}</h2>
          {this.state.selectedGroup && (
            <TableAndForm
              selectCallback={this.selectCallback}
              formButtonArray={[DELETE_PRICING]}
              multiSelect={false}
              {...this.props}
              endpoint={codesLoc.endpoint}
              location={codesLoc}
              search={
                this.state.selectedGroup
                  ? `priceGroup.id=${this.state.selectedGroup.id}`
                  : undefined
              }
              preSaveCallback={data => {
                return {
                  ...data,
                  priceGroupId: this.state.selectedGroup.id
                };
              }}
            />
          )}
        </Profile>
      </React.Fragment>
    );
  }
}
export default Pricing;
