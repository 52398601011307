import React from "react";

export const ItemType = {
    ID: 'id',
    CODE: 'code'
}
/**
 * 
 * @param subareas subarea objects. at least id and code must be present.
 * @param cb called with an array of objects specified by itemType (default: code). NOTE: also when not multiselect uses array!
 * @param selected
 * @param {boolean} multiple multiselect; by default true
 * @param {ItemType} itemType in selected array + given as parameter to cb; subarea.id or subarea.code
 * @returns 
 */
const SubareaSelect = props => {
    const { subareas, cb, selected, multiple = true, itemType = ItemType.CODE } = props;
    if (!subareas || !Array.isArray(subareas) || subareas.length === 0) {
      console.log("No subareas: " + subareas);
      return null;
    }
    const vals = [];
    subareas.forEach(sa => {
      let item = itemType === ItemType.CODE ? sa.code : itemType === ItemType.ID ? sa.id : sa;
      if (selected && selected.includes(item)) {
        vals.push(
          <option value={itemType === ItemType.CODE ? sa.code : sa.id} selected="selected">
            {sa.code}
          </option>
        );
      } else {
        vals.push(<option value={itemType === ItemType.CODE ? sa.code : sa.id}>{sa.code}</option>);
      }
    });
    return (
      <select multiple={multiple} style={props.style} onChange={e => {
        if (multiple) {
            let items = [];
            for (let i=0; i<e.target.options.length;i++) {
              if (e.target.options.item(i).selected) {
                items.push(e.target.options.item(i).value);
              }
            }
            cb(items);
        } else {
              if (!e.target.value) {
                cb([]);
              } else {
                cb([e.target.value]);
              }
        }
        }}>
        {vals}
      </select>);
  };

  export default SubareaSelect;