import React from "react";
import PropTypes from "prop-types";
import LoginRequired from "../LoginRequired";
import LoginForm from "../LoginForm";
import BookkaaHeader from "../BookkaaHeader";
import BkModifyButton from "../layout/BkModifyButton";
import moment from "moment";
import ReservationDetails from "../boatparking/ReservationDetails";
import { CircleLoader } from "react-spinners";
import DynamicMap from "./DynamicMap";
import SeasonCustomers from "./SeasonCustomers";
import reservationService from "../../services/reservation";
import SeasonsComponent from "./SeasonsComponent";

const uiState = {
  dynamicMap: "dynamicMap",
  initializing: "initializing",
  place: "place"
};

class SeasonAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.setUpdateInterval = this.setUpdateInterval.bind(this);
    this.update = this.update.bind(this);
    this.openPlaceDetails = this.openPlaceDetails.bind(this);
    this.cancelReservation = this.cancelReservation.bind(this);
    this.renderCancelReservation = this.renderCancelReservation.bind(this);
    this.markReservationPaid = this.markReservationPaid.bind(this);
    this.savePlaceAndProducts = this.savePlaceAndProducts.bind(this);
    this.renderPlaceDetails = this.renderPlaceDetails.bind(this);
    this.sellerFromArea = this.sellerFromArea.bind(this);
    this.saveDone = this.saveDone.bind(this);

    this.state = {
      loadingMap: true,
      loadingReport: true,
      key: 1,
      reportStart: moment().startOf("month"),
      reportEnd: moment().endOf("month"),
      onlyFree: false,
      customers: [],
      customersNr: 0,
      customer: undefined,
      saveOngoing: false,
      uiState: uiState.initializing,
      nextState: undefined,
      defaultPlaceType: "BB_SHAFT",
      defaultProductType: "PLACE_SEASON",
      refreshRateSeconds: 30,
      areaId: props.location.area
    };
    const l = props.location;
    props.getTranslations(l.ctx, l.language, l.endpoint);
    if (!this.props.areas) {
      this.props.getAreas(l.language);
    }
    if (!this.props.sellers) {
      this.props.getSellers(l.language);
    }
  }
  update() {
    const l = this.props.location;
    const ui_state = this.state.uiState;

    const updateMap =
      !this.props.imageMap ||
      this.props.imageMap.area.id !== this.props.location.area;

    if (
      ui_state === uiState.dynamicMap ||
      ui_state === uiState.initializing ||
      updateMap
    ) {
      this.props.getImageMap(
        l.language,
        l.area,
        null,
        "SEASON_PLACE",
        null,
        null
      );
    } else {
      console.log("uiState: " + ui_state + " => " + uiState[ui_state]);
    }
  }

  savePlaceAndProducts(data) {
    const language = this.props.language;
    const reqId = Math.random() * 100000;
    this.setState({ saveOngoing: reqId });
    data.areaId = this.props.location.area;
    data.requestId = reqId;
    if (!data.subareaId) {
      data.subareaId = this.state.subareaId;
    }
    const rt = this.state.realtime;
    const start = rt ? undefined : moment(this.state.start);
    const end = rt ? undefined : moment(this.state.end);
    this.props.storeProductDetails(language, data, start, end);
    let pt = this.state.defaultProductType;
    data.products.forEach(v => {
      pt = v.type;
    });
    if (data.placeType) {
      this.setState({
        defaultPlaceType: data.placeType,
        defaultProductType: pt
      });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.loadingMap &&
      this.props.imageMap !== prevProps.imageMap &&
      this.props.imageMap
    ) {
      const state =
        this.state.uiState === uiState.initializing
          ? uiState.dynamicMap
          : this.state.uiState;
      this.setState({ loadingMap: false, uiState: state });
    }
    if (
      this.state.loadingReport &&
      this.props.reservationReport !== prevProps.reservationReport &&
      this.props.reservationReport
    ) {
      this.setState({ loadingReport: false });
    }
    if (
      this.state.pendingReservation &&
      this.props.updatedReservation &&
      this.state.pendingReservation.reference ===
        this.props.updateReservation.reference
    ) {
      console.log("Reservation updated");
      this.setState({
        pendingReservation: undefined,
        customer: undefined,
        openedPlaceDetails: undefined,
        hoveredItem: undefined,
        okMessage: this.props.updatedReservation.okMessage
      });
      this.update();
    } else if (
      this.state.pendingReservation &&
      this.props.boatParkAdminData &&
      this.state.pendingReservation.reference ==
        this.props.boatParkAdminData.reference
    ) {
      console.log("Reservation done");

      this.setState({
        pendingReservation: undefined,
        customer: undefined,
        openedPlaceDetails: undefined,
        uiState: this.state.nextState
          ? this.state.nextState
          : uiState.dynamicMap,
        nextState: undefined,
        hoveredItem: undefined,
        okMessage: this.props.boatParkAdminData.okMessage
      });
      this.update();
    }
    if (
      this.state.saveOngoing &&
      this.props.errorMessage &&
      !prevProps.errorMessage
    ) {
      this.setState({ saveOngoing: false });
    }
    if (
      this.state.saveOngoing &&
      this.props.placeDetails &&
      // eslint-disable-next-line eqeqeq
      this.state.saveOngoing == this.props.placeDetails.requestId
    ) {
      this.saveDone(this.props.placeDetails); // TODO: remove when save not done through redux anymore
    }
    if (
      this.props.jwt !== prevProps.jwt ||
      this.props.location.area !== prevProps.location.area
    ) {
      this.update();
    }
  }

  saveDone(placeDetails) {
    console.log(`Save done, requestId: ${placeDetails.requestId}`);
    this.props.getImageMap(
      this.props.location.language,
      this.props.location.area,
      null,
      "SEASON_PLACE",
      null,
      null
    );
    return this.setState({
      errorMessage: placeDetails.errorMessage,
      saveOngoing: false,
      editMode: false,
      placeDetails: placeDetails,
      openedPlaceDetails: placeDetails,
      uiState: uiState.place,
      nextState: undefined
    });
  }

  componentDidMount() {
    this.props.ensureLoggedIn(
      this.props.jwt,
      this.props.location.ctx,
      this.props.location.language,
      "parkingAdmin"
    );

    this.update();
    this.interval = setInterval(() => this.update(), 60000);
  }

  componentWillUnmount() {
    this.setUpdateInterval();
  }

  setUpdateInterval(refreshRateSeconds) {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    if (refreshRateSeconds) {
      this.interval = setInterval(
        () => this.update(),
        refreshRateSeconds * 1000
      );
    }
  }

  cancelReservation(reservation) {
    console.debug(`Cancelling reservation: ${JSON.stringify(reservation)}`);
    this.props.cancelReservationById(
      this.props.location.area,
      this.props.location.language,
      reservation.reservationId
    );
    this.setState({ reservationToCancel: undefined });
    this.update();
  }
  markReservationPaid(reservation) {
    console.debug(`Marking reservation paid: ${JSON.stringify(reservation)}`);
    this.props.markReservationPaidById(
      this.props.location.area,
      this.props.location.language,
      reservation.reservationId,
      "MARKED_PAID"
    );
    this.update();
  }
  openPlaceDetails(area, subarea, editMode) {
    reservationService
      .fetchProductDetails(
        this.props.location.area,
        this.props.location.language,
        area.placeId
      )
      .then(ret =>
        this.setState({
          placeDetails: ret,
          openedPlaceDetails: ret.holder || ret.reservationStatus ? ret : area,
          errorMessage: ret.errorMessage,
          okMessage: undefined,
          hoveredItem: undefined,
          subareaId: area.subareaId ? area.subareaId : subarea.subarea,
          editMode: editMode,
          nextState: undefined,
          uiState: uiState.place
        })
      );
  }
  render() {
    const l = this.props.location; // shortcut
    const t = key => l.translate(l, key);

    if (!l.area) {
      // TODO: render list of harbours
      console.log("Area not set");
      return null;
    }

    let errorDiv = [];
    if (this.props.errorMessage) {
      errorDiv.push(
        <div className="bk_errorMessage sticky" style={{ display: "block" }}>
          {this.props.errorMessage}
        </div>
      );
    } else if (this.state.errorMessage) {
      setTimeout(() => this.setState({ errorMessage: undefined }), 5000);
      errorDiv.push(
        <div
          className="bk_errorMessage sticky"
          style={{ display: "block" }}
          onClick={() => this.setState({ errorMessage: undefined })}
        >
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.okMessage) {
      setTimeout(() => this.setState({ okMessage: undefined }), 5000);
      errorDiv.push(
        <div
          className="bk_okMessage sticky"
          style={{ display: "block" }}
          onClick={() => this.setState({ okMessage: undefined })}
        >
          {this.state.okMessage}
        </div>
      );
    }

    let areaName = "";
    if (this.props.imageMap && this.props.imageMap.area) {
      areaName = this.props.imageMap.area.name;
    }
    let tabContent = undefined;
    switch (this.state.uiState) {
      case uiState.place:
        const placeDetails = this.state.openedPlaceDetails ? (
          this.renderPlaceDetails(t)
        ) : (
          <CircleLoader />
        );
        tabContent = <div className="guest-place-details">{placeDetails}</div>;
        break;
      case uiState.dynamicMap:
        tabContent = (
          <DynamicMap
            {...this.props}
            t={t}
            disableTitle={true}
            onClick={(a, sa) => this.openPlaceDetails(a, sa, false)}
            tooltipRenderer={(hi, ttStyle) => {
              return (
                <span className="tooltip" style={ttStyle}>
                  {hi.code && hi.name
                    ? `${hi.code}: ${hi.name}`
                    : hi.code
                    ? hi.code
                    : ""}
                  <br />
                  {hi.width && hi.length
                    ? `${hi.length} x ${hi.width} ${
                        hi.depth ? " x " + hi.depth : ""
                      } m`
                    : ""}
                  <br />
                  {hi.price} €
                  <br />
                  {hi.placeTypeStr}
                  {hi.holderName && (
                    <React.Fragment>
                      <br />
                      {hi.holderName}
                    </React.Fragment>
                  )}
                </span>
              );
            }}
          />
        );
        break;
      default:
        tabContent = <CircleLoader />;
    }

    const content = (
      <React.Fragment>
        <LoginRequired location={l} {...this.props.loginProps} />
        <LoginForm location={l} {...this.props.loginProps} />
        <BookkaaHeader
          location={l}
          {...this.props.headerProps}
          disableProfile={true}
        />
        <div
          className="harbour-and-season-container"
          style={{ marginBottom: "5px" }}
        >
          <CustomAreaHeader location={this.props.location} areaName={areaName} />
        </div>
        {errorDiv}
        <div className="bkTab">
          <div className="bkTabContent">{tabContent}</div>
        </div>
      </React.Fragment>
    );

    return content;
  }

  sellerFromArea(areaId) {
    let seller = undefined;
    this.props.areas.forEach(a => {
      if (a.id == areaId) {
        seller = a.seller;
      }
    });
    return seller;
  }

  renderCancelReservation(t, reservation) {
    return (
      <React.Fragment>
        <center className="title" style={{ fontWeight: "bold" }}>
          {t("confirmFree")}
        </center>
        <center>
          {reservation.validFrom} - {reservation.validUntil}
        </center>
        <center>
          <ReservationDetails reservation={reservation} />
        </center>
        <center>
          <BkModifyButton
            buttonTitle={t("free-button")}
            callback={() => this.cancelReservation(reservation)}
          />
          <BkModifyButton
            buttonTitle={t("close-button")}
            callback={() => this.setState({ reservationToCancel: undefined })}
          />
        </center>
      </React.Fragment>
    );
  }

  renderPlaceDetails() {
    let defaults = {
      ...this.state.placeDefaults,
      placeType: this.state.defaultPlaceType,
      productType: this.state.defaultProductType
    };
    return (
      <SeasonCustomers
        {...this.props}
        defaults={defaults}
        placeDetails={this.state.placeDetails}
        openedData={this.state.openedPlaceDetails}
        subareaId={this.state.subareaId}
        closeCallback={() => {
          console.log("closeCallback() called");
          this.setState({
            openedPlaceDetails: undefined,
            hoveredItem: undefined,
            editData: undefined,
            editMode: false,
            saveOngoing: false,
            uiState: uiState.dynamicMap
          });
          this.props.getImageMap(
            this.props.location.language,
            this.props.location.area,
            null,
            "SEASON_PLACE",
            null,
            null
          );
        }}
        savePlaceAndProducts={data => {
          const reqId = Math.random() * 100000;
          data.areaId = this.state.areaId;
          data.requestId = reqId;
          this.setState({
            okMessage: undefined,
            errorMessage: undefined,
            saveOngoing: reqId,
            openedPlaceDetails: undefined,
            placeDefaults: {
              ...data,
              id: undefined,
              productId: undefined,
              reservations: undefined,
              products: data.products && data.products ? [...data.products] : []
            }
          });
          return reservationService
            .storeProductDetails(this.props.location.language, data)
            .then(ret => this.saveDone(ret));
        }}
      />
    );
  }
}

export default SeasonAdmin;

const CustomAreaHeader = (props) =>  (<div className="harbour-title">
<table>
  <tr>
    <td>
      <h3
        style={{
          display: "inline-block",
          marginTop: "10px",
          marginBottom: "5px"
        }}
      >
        {props.areaName}
      </h3>
      <SeasonsComponent
        location={props.location}
        guest={false}
      />
    </td>
    <td>
      <img
        className="season-legend"
        alt="legend"
        src="/booking-web/fi/images/legend/season.png"
      />
    </td>
  </tr>
</table>
</div>);

SeasonAdmin.propTypes = {
  ensureLoggedIn: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  location: {
    ctx: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    area: PropTypes.string.isRequired
  }
};
