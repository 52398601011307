import React from "react";
import PropTypes from "prop-types";
import BkModifyButton from "../components/layout/BkModifyButton";
import Form from "../components/alpaca/Form";
import { CircleLoader } from "react-spinners";
import { shallowEqual } from "recompose";
import BookkaaHeader from "../components/BookkaaHeader";
import BkContentArea from "../components/layout/BkContentArea";
import { createUpdate } from "../services/bkRest";
import ax from "../services/axiosApi";
import Modal from "react-modal/lib/components/Modal";
import vallisaariService from "../services/vallisaari";

const HtmlToReactParser = require("html-to-react").Parser;

export const contractState = {
  new: "new",
  expired: "expired",
  valid: "valid",
  pending: "pending"
};

class Vallisaari extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contractState: this.contractState(props.vallisaariData),
      conditions: null,
      pendingApplication: {},
      activeContract: {},
      dataUnhandled: null,
      modalIsOpen: false,
      updateModalIsOpen: false,
      contractDetails: undefined,
      conditionsOpen: false // approve conditions dialog open
    };
    this.openContractDetails = this.openContractDetails.bind(this);
    this.renderApplication = this.renderApplication.bind(this);
    this.saveData = this.saveData.bind(this);
    this.reloadData = this.reloadData.bind(this);
    this.openApproveConditions = this.openApproveConditions.bind(this);

    if (this.props.jwt) {
      this.reloadData();
    }
  }

  htmlToReactParser = new HtmlToReactParser();

  contractState(bd) {
    if (bd && bd.validContract) {
      return contractState.valid;
    } else if (bd && bd.pendingContract) {
      return contractState.pending;
    } else if (bd && bd.expiredContract) {
      return contractState.expired;
    } else if (bd) {
      return contractState.new;
    }
    return undefined;
  }

  componentDidUpdate(prevProps) {
    if (!shallowEqual(prevProps.jwt, this.props.jwt)) {
      console.log("Loading Vallisaari data");
      this.props.loadVallisaari(this.props.location);
    }
    const cs = this.contractState(this.props.vallisaariData);
    if (cs !== this.state.contractState) {
      console.log("Updating contract state to: " + cs);
      this.setState({ contractState: cs });
    }
  }

  render() {
    const t = k => this.props.location.translate(this.props.location, k);
    let content = "";
    if (!this.props.jwt) {
      content = <CircleLoader />;
    }

    let conditions = "";
    if (this.state.conditionsOpen) {
      conditions = this.conditionsDialog();
    }
    if (this.state.contractDetails) {
      content = this.renderApplication();
    } else {
      let buttonTitle = "";
      let title = "";
      let body = "";
      if (this.state.contractState === contractState.valid) {
        title = t("contractStateValid.title");
        body = t("contractStateValid.body");
        buttonTitle = t("button.view-contract");
      } else if (this.state.contractState === contractState.pending) {
        title = t("contractStatePending.title");
        body = t("contractStatePending.body");
        buttonTitle = t("button.view-contract");
      } else if (this.state.contractState === contractState.expired) {
        title = t("contractStateExpired.title");
        body = t("contractStateExpired.body");
        buttonTitle = t("button.renew-contract");
      } else if (this.state.contractState === contractState.new) {
        title = t("contractStateNew.title");
        body = t("contractStateNew.body");
        buttonTitle = t("button.new-contract");
      }
      content = (
        <React.Fragment>
          <h3>{title}</h3>
          <p>{body}</p>
          <BkModifyButton
            buttonTitle={buttonTitle}
            callback={() => this.openContractDetails()}
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <BookkaaHeader
          {...this.props.headerProps}
          location={this.props.location}
          disableProfile={true}
        />
        <BkContentArea>
          {conditions}
          {content}
        </BkContentArea>
      </React.Fragment>
    );
  }

  openContractDetails() {
    if (!this.props.vallisaariData) {
      console.log("No data loaded, requesting service data");
      this.props.loadVallisaari(this.props.location);
    } else if (this.state.contractState === contractState.valid) {
      let data = { ...this.props.vallisaariData.pendingApplication };
      data.id = undefined;
      if (this.props.vallisaariData.validContract) {
        data = this.props.vallisaariData.activeApplication;
      }
      if (!data) {
        data = {};
      }
      console.log(
        `validContract: ${
          this.props.vallisaariData.validContract
        } Setting data: ${JSON.stringify(data)}`
      );
      this.setState({
        contractSent: false,
        contractDetails: data,
        contractState: this.contractState(this.props.vallisaariData)
      });
    } else if (this.state.contractState === contractState.pending) {
      this.setState({
        contractSent: true,
        contractDetails: this.props.vallisaariData.pendingApplication
      });
    } else if (this.state.contractState === contractState.new) {
      this.setState({
        contractSent: false,
        contractDetails: {}
      });
    }
  }

  form = undefined;

  reloadData() {
    console.log("Loading data");
    this.props.loadVallisaari(this.props.location);
  }

  openApproveConditions() {
    const data = this.form.getData();
    console.log("Opening conditions, data: " + JSON.stringify(data));
    if (!this.state.conditions) {
      ax()
        .get("/static-web/MH/sp_conditions_fi.html", {
          withCredentials: true
        })
        .then(ret => {
          const data = this.htmlToReactParser.parse(ret.data);
          this.setState({ conditions: data });
        });
    }
    this.setState({ conditionsOpen: true, dataToAccept: data });
  }
  saveData() {
    const data = { ...this.state.dataToAccept };
    console.log("Saving, data: " + JSON.stringify(data));
    this.setState({ saveBusy: true });
    const cp = this;
    const loc = {
      ctx: "booking-web",
      language: this.props.location.language,
      endpoint: "application",
      area: this.props.vallisaariData.area
    };
    data.startDate = undefined;
    createUpdate(data, loc, this.reloadData)
      .then(() => {
        cp.setState({
          saveBusy: false,
          conditionsOpen: false,
          contractDetails: undefined
        });
        this.reloadData();
      })
      .catch(() => {
        cp.setState({ saveBusy: false });
      });
  }

  conditionsDialog() {
    const t = k => this.props.location.translate(this.props.location, k);
    const conditions = this.state.conditions ? (
      this.state.conditions
    ) : (
      <CircleLoader />
    );
    return (
      <React.Fragment>
        <Modal
          isOpen={true}
          className="ui-widget-content scrollable"
          contentLabel=""
        >
          {conditions}
          <BkModifyButton
            rendered={this.state.conditions}
            busy={this.state.saveBusy}
            buttonTitle={t("button.new-contract-conditions")}
            callback={this.saveData}
          />
        </Modal>
      </React.Fragment>
    );
  }

  renderApplication() {
    const loc = this.props.location;
    const t = k => loc.translate(loc, k);
    const formKey = loc.ctx + loc.language + loc.endpoint;
    const readonly =
      this.state.contractState !== contractState.expired &&
      this.state.contractState !== contractState.new;
    console.log(
      `Contract state: ${this.state.contractState} => readonly: ${readonly}`
    );
    return (
      <React.Fragment>
        <div>
          <Form
            readonly={readonly}
            key={formKey}
            ctx="booking-web"
            language={loc.language}
            endpoint="application"
            data={this.state.contractDetails}
            // alpacaSettings={ass2}
            onRef={ref => {
              if (ref !== undefined) {
                this.form = ref;
                console.log("ref updated: " + ref);
              }
            }}
          ></Form>
          {!readonly && (
            <BkModifyButton
              callback={() => this.openApproveConditions()}
              buttonTitle={t("button.new-contract")}
            />
          )}
          <BkModifyButton
            callback={() => this.setState({ contractDetails: undefined })}
            buttonTitle={t("close-button")}
          />
          {this.state.contractState === contractState.valid && (
            <React.Fragment>
              <BkModifyButton
                buttonTitle={t("loadContractPdf")}
                callback={() => {
                  console.log("requesting contract");
                  vallisaariService.getContract(this.props.location).then(r => {
                    if (r && r.errorMessage) {
                    } else {
                      this.setState({ contractSent: true });
                    }
                  });
                }}
              />
              {this.state.contractSent && (
                <span>{t("loadContractPdfSuccess")}</span>
              )}
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
}
export default Vallisaari;

Vallisaari.propTypes = {
  vallisaariData: PropTypes.object,
  location: PropTypes.object.isRequired
};
