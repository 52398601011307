import React from "react";
import BookkaaHeader from "../BookkaaHeader";
import TableAndForm from "../layout/TableAndForm";
import LoginForm from "../LoginForm";
import LoginRequired from "../LoginRequired";
import Profile from "../Profile";
import AreaHeader from "./AreaHeader";

class Subareas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.selectCallback = this.selectCallback.bind(this);
  }

  selectCallback(area) {
    this.setState({ selectedItem: area });
  }

  render() {
    const location = { ...this.props.location };
    location.endpoint = "subareas";

    let beforeForm = [];
    const CLOSE_BUTTON = {
      supportsMulti: true,
      callback: this.props.closeCb,
      title: "Sulje"
    };

    let listButtonArray = [];
    if (this.props.closeCb) {
      listButtonArray.push(CLOSE_BUTTON);
    }

    return (
      <React.Fragment>
        <LoginRequired {...this.props.location} {...this.props.loginProps} />
        <BookkaaHeader location={location} {...this.props.headerProps} />
        <LoginForm location={location} {...this.props.loginProps} />
        <Profile location={location} {...this.props.profileProps}>
          <AreaHeader areaName={this.props.areaName} />
          <TableAndForm
            selectCallback={this.selectCallback}
            onRef={ref => (this.tableAndForm = ref)}
            itemsBeforeForm={beforeForm}
            buttons={listButtonArray}
            formButtonArray={[]}
            closeDialogCallback={this.dialogClosed}
            multiSelect={false}
            {...this.props}
            location={location}
          />
        </Profile>
      </React.Fragment>
    );
  }
}
export default Subareas;
