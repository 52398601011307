import React from "react";
import { CircleLoader } from "react-spinners";

export const Payouts = props => {
  const { t, payouts } = props;

  if (payouts === undefined || !Array.isArray(payouts)) {
    return <CircleLoader />;
  }

  const rows = [];

  if (Array.isArray(payouts) && payouts.length === 0) {
    rows.push(
      <tr>
        <td colspan="11">
          <hr />
        </td>
      </tr>
    );
  }
  var odd = false;
  var sumTotal = 0.0;
  var sumVat = 0.0;
  var sumNights = 0;
  payouts.forEach(p => {
    const cn = odd ? "td-odd" : "td-even";
    odd = !odd;

    rows.push(
      <tr>
        <td className={cn}>{p.seller}</td>
        <td className={cn}>{p.subMerchantId}</td>
        <td className={cn}>{p.area}</td>
        <td className={cn}>{p.orderNumber}</td>
        <td className={cn}>{p.product}</td>
        <td className={cn}>{p.days}</td>
        <td className={cn}>{p.orderType}</td>
        <td className={cn}>{p.totalAmount}</td>
        <td className={cn}>{p.vatAmount}</td>
        <td className={cn}>{p.paymentCreated}</td>
        <td className={cn}>{p.payoutPaid}</td>
      </tr>
    );
    if (p.totalAmount) {
      sumTotal += p.totalAmount;
    }
    if (p.vatAmount) {
      sumVat += p.vatAmount;
    }
    sumNights += parseInt(p.days);
  });
  return (
    <table className="bk-parking-list">
      <tr>
        <th>{t("seller")}</th>
        <th>{t("subMerchantId")}</th>
        <th>{t("area")}</th>
        <th>{t("orderNumber")}</th>
        <th>{t("product")}</th>
        <th>{t("nights")}</th>
        <th>{t("orderType")}</th>
        <th>{t("total")}</th>
        <th>{t("vat")}</th>
        <th>{t("paid")}</th>
        <th>{t("payoutDone")}</th>
      </tr>
      {rows}
      <tr>
        <td className="sum">{t("report.total")}</td>
        <td className="sum"></td>
        <td className="sum"></td>
        <td className="sum"></td>
        <td className="sum"></td>
        <td className="sum">{sumNights}</td>
        <td className="sum"></td>
        <td className="sum">{sumTotal.toFixed(2)}</td>
        <td className="sum">{sumVat.toFixed(2)}</td>
        <td className="sum"></td>
        <td className="sum"></td>
      </tr>
    </table>
  );
};
