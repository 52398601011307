import { genericGet } from "./axiosApi";
import moment from "moment";

const getPayouts = async (language, from, until, sellerId, areaId) => {
  return genericGet(
    language,
    `adminReporting/payouts?from=${
      from ? moment(from).format("YYYY-MM-DD") : ""
    }&until=${until ? moment(until).format("YYYY-MM-DD") : ""}&sellerId=${
      sellerId ? sellerId : ""
    }&areaId=${areaId ? areaId : ""}`,
    "booking-web",
    180000 // 3 minutes timeout
  );
};

export default { getPayouts };
