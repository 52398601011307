import React from "react";
import PropTypes from "prop-types";
import LoginRequired from "../LoginRequired";
import LoginForm from "../LoginForm";
import BookkaaHeader from "../BookkaaHeader";
import { Map } from "immutable";
import BkModifyButton from "../layout/BkModifyButton";
import Customer from "./Customer";
import moment from "moment";
import DateRangeSelector from "./DateRangeSelector";
import BkTab from "../layout/BkTab";
import ReservationCalendar from "./ReservationCalendar";
import { CircleLoader } from "react-spinners";
import ReservationsReport from "../admin/ReservationsReport";
import PayoutsReport from "../admin/PayoutsReport";
import DynamicMap from "../admin/DynamicMap";
import { PlaceDetailsRenderer } from "../admin/PlaceDetailsRenderer";
import swal from "@sweetalert/with-react";
import reservationService from "../../services/reservation";
import SeasonsComponent from "../admin/SeasonsComponent";
import { freeSeasonPlaceAsGuest } from "./FreedSeasonPlace";
import ProductInformationAdmin from "../admin/ProductInformationAdmin";
import { DateSelector } from "./DateSelector";
import { genericGet, genericPost } from "../../services/axiosApi";

const uiState = {
  dynamicMap: "dynamicMap",
  reservationCalendar: "reservationCalendar",
  administerPlaces: "administerPlaces",
  initializing: "initializing",
  reporting: "reporting",
  payouts: "payouts"
};

class Admin extends React.Component {
  constructor(props) {
    super(props);
    this.setUpdateInterval = this.setUpdateInterval.bind(this);
    this.update = this.update.bind(this);
    this.openPlaceDetails = this.openPlaceDetails.bind(this);
    this.markReservationPaid = this.markReservationPaid.bind(this);
    this.reserve = this.reserve.bind(this);
    this.savePlaceAndProducts = this.savePlaceAndProducts.bind(this);
    this.copyPlaceDetails = this.copyPlaceDetails.bind(this);
    this.renderCustomerPage = this.renderCustomerPage.bind(this);
    this.renderDateSelector = this.renderDateSelector.bind(this);
    this.renderPlaceDetails = this.renderPlaceDetails.bind(this);
    this.setStartEnd = this.setStartEnd.bind(this);
    this.sellerFromArea = this.sellerFromArea.bind(this);
    this.adminFreeSeasonPlaceAsGuest = this.adminFreeSeasonPlaceAsGuest.bind(this);
    this.updatePrice = this.updatePrice.bind(this);

    this.state = {
      loadingMap: true,
      loadingReport: true,
      key: 1,
      defaultTimes: true,
      start: moment()
        .startOf("week"),
      end: moment()
        .startOf("week")
        .add(7, "days"),
      reportStart: moment().startOf("month"),
      reportEnd: moment().endOf("month"),
      realtime: true,
      onlyFree: false,
      customers: [],
      subareas: [],
      customersNr: 0,
      customer: undefined,
      saveOngoing: false,
      uiState: uiState.initializing,
      nextState: undefined,
      defaultPlaceType: "BB_SHAFT",
      defaultProductType: "PLACE_GUEST",
      refreshRateSeconds: 30,
      areaId: props.location.area
    };
    const l = props.location;
    props.getTranslations(l.ctx, l.language, l.endpoint);
    if (!this.props.areas) {
      this.props.getAreas(l.language);
    }
    if (!this.props.sellers) {
      this.props.getSellers(l.language);
    }
  }
  update(
    rt = this.state.realtime,
    startDate,
    endDate,
    ui_state = this.state.uiState
  ) {
    const l = this.props.location;
    const reports =
      ui_state == uiState.reporting || ui_state == uiState.payouts;
    const start = /*rt ? null :*/ moment(
      startDate === undefined
        ? reports
          ? this.state.reportStart
          : this.state.start
        : startDate
    ).format("YYYY-MM-DD");
    const end = /*rt ? null :*/ moment(
      endDate === undefined
        ? reports
          ? this.state.reportEnd
          : this.state.end
        : endDate
    ).format("YYYY-MM-DD");

    if (
      ui_state == uiState.dynamicMap ||
      ui_state == uiState.reservationCalendar ||
      ui_state == uiState.initializing
    ) {
      this.props.getImageMap(
        l.language,
        l.area,
        null,
        "GUEST_ADMIN",
        null,
        null
      );
      return reservationService
        .adminReservationCalendar(l.area, l.language, start, end)
        .then(ret =>
          this.setState({
            errorMessage: ret.errorMessage,
            reservationCalendar: ret.errorMessage
              ? this.state.reservationCalendar
              : ret
          })
        );
    } else if (ui_state == uiState.reporting) {
      if (endDate) {
        this.setState({ loadingReport: true });
        this.props.guestReservationReport(
          this.state.areaId,
          l.language,
          start,
          end,
          this.state.sellerId
        );
      }
    } else {
      console.log("uiState: " + ui_state + " => " + uiState[ui_state]);
    }
  }

  savePlaceAndProducts(data) {
    const language = this.props.language;
    const reqId = Math.random() * 100000;
    this.setState({ saveOngoing: reqId });
    data.areaId = this.props.location.area;
    data.requestId = reqId;
    if (!data.subareaId) {
      data.subareaId = this.state.subareaId;
    }
    const rt = this.state.realtime;
    const start = rt ? undefined : moment(this.state.start);
    const end = rt ? undefined : moment(this.state.end);
    const promise = reservationService.storeProductDetails(
      language,
      data,
      start,
      end
    );
    let pt = this.state.defaultProductType;
    data.products.forEach(v => {
      pt = v.type;
    });
    return promise.then(ret => {
      if (ret.errorMessage) {
        this.setState({ placeDetailsError: ret.errorMessage });
      } else {
        this.setState(
          {
            placeDetailsError: undefined,
            openedPlaceDetails: undefined,
            hoveredItem: undefined,
            editData: undefined,
            editMode: false,
            defaultPlaceType: data.placeType,
            defaultProductType: pt,
            placeDefaults: {
              ...data,
              id: undefined,
              productId: undefined,
              reservations: undefined,
              products: data.products && data.products ? [...data.products] : []
            }
          },
          this.update
        );
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      this.state.reservationCalendar &&
      this.state.uiState === uiState.initializing
    ) {
      this.setState({ uiState: uiState.reservationCalendar });
    }
    if (
      this.state.loadingMap &&
      this.props.imageMap !== prevProps.imageMap &&
      this.props.imageMap
    ) {
      this.setState({ loadingMap: false });
    }
    if (
      this.state.loadingReport &&
      this.props.reservationReport !== prevProps.reservationReport &&
      this.props.reservationReport
    ) {
      this.setState({ loadingReport: false });
    }
    if (
      this.state.pendingReservation &&
      this.props.updatedReservation &&
      this.state.pendingReservation.reference ===
        this.props.updateReservation.reference
    ) {
      console.log("Reservation updated");
      this.setState({
        pendingReservation: undefined,
        customer: undefined,
        openedPlaceDetails: undefined,
        hoveredItem: undefined,
        okMessage: this.props.updatedReservation.okMessage
      });
      this.update();
    } else if (
      this.state.pendingReservation &&
      this.props.boatParkAdminData &&
      this.state.pendingReservation.reference ==
        this.props.boatParkAdminData.reference
    ) {
      console.log("Reservation done");

      this.setState({
        pendingReservation: undefined,
        customer: undefined,
        openedPlaceDetails: undefined,
        uiState: this.state.nextState
          ? this.state.nextState
          : this.state.uiState,
        nextState: undefined,
        hoveredItem: undefined,
        okMessage: this.props.boatParkAdminData.okMessage
      });
      this.update();
    }
    if (
      this.props.placeDetails &&
      this.state.saveOngoing &&
      // eslint-disable-next-line eqeqeq
      this.state.saveOngoing == this.props.placeDetails.requestId
    ) {
      this.setState({
        saveOngoing: false,
        editMode: false,
        openedPlaceDetails: this.props.placeDetails,
        dateOpen: false,
        uiState: this.state.nextState
          ? this.state.nextState
          : this.state.uiState,
        nextState: undefined
      });
    } else if (
      this.state.openedPlaceDetails &&
      this.props.placeDetails &&
      this.state.editData &&
      !this.state.editData.placeType &&
      this.props.placeDetails.placeId !== 0 &&
      this.props.placeDetails.placeId === this.state.editData.placeId
    ) {
      /// this.copyPlaceDetails(this.props.placeDetails);
    }
    if (
      this.props.jwt !== prevProps.jwt ||
      this.props.location.area !== prevProps.location.area
    ) {
      this.update();
    }
    if (
      !this.state.subareas &&
      this.props.imageMap &&
      this.props.imageMap.subareaCodes
    ) {
      const sac = this.props.imageMap.subareaCodes;
      const subareas = Object.keys(sac).map(key => {
        return { id: sac[key], code: key };
      });
      this.setState({ subareas: subareas });
      return;
    }
    if (
      !this.state.selectedArea &&
      this.props.imageMap &&
      this.props.imageMap.subareas &&
      this.props.imageMap.subareas.length > 0 &&
      this.props.imageMap.subareas[0].areas[0].code
    ) {
      this.setState({
        selectedArea: this.props.imageMap.subareas[0].areas[0].code
      });
    }
  }

  componentDidMount() {
    this.props.ensureLoggedIn(
      this.props.jwt,
      this.props.location.ctx,
      this.props.location.language,
      "parkingAdmin"
    );

    this.update();
    this.interval = setInterval(() => this.update(), 60000);
  }

  componentWillUnmount() {
    this.setUpdateInterval();
  }

  setUpdateInterval(refreshRateSeconds) {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    if (refreshRateSeconds) {
      this.interval = setInterval(
        () => this.update(),
        refreshRateSeconds * 1000
      );
    }
  }

  markReservationPaid(reservation) {
    console.debug(`Marking reservation paid: ${JSON.stringify(reservation)}`);
    return reservationService
      .markReservationPaidById(
        this.props.location.area,
        this.props.location.language,
        reservation.reservationId,
        "MARKED_PAID"
      )
      .then(this.update);
  }
  adminFreeSeasonPlaceAsGuest(reservation) {
    const l = this.props.location;
    freeSeasonPlaceAsGuest(reservation, l, 
      this.state.startCancel, this.state.endCancel,
      true, // isAdmin
      (startCancel, endCancel) => this.setState({ startCancel: startCancel, endCancel: endCancel }),
      (closeCb) => {
        reservationService
        .adminReservationCalendar(
          l.area,
          l.language,
          this.state.start.format("YYYY-MM-DD"),
          this.state.end.format("YYYY-MM-DD")
        )
        .then(ret =>
          this.setState(
            {
              startCancel: undefined,
              endCancel: undefined,
              errorMessage: ret.errorMessage,
              selectedReservation: undefined,
              reservationCalendar: ret.errorMessage
                ? this.state.reservationCalendar
                : ret
            },
            () => closeCb()
          )
        )
        .catch(() => closeCb())
      });
  }
  openPlaceDetails(area, mapPlace, editMode) {
    const from = undefined; //this.state.realtime ? undefined : this.state.start;
    const until = undefined; //this.state.realtime ? undefined : this.state.end;
    this.props.fetchProductDetails(
      this.props.location.area,
      this.props.location.language,
      area.placeId,
      from,
      until
    );

    this.setState({
      okMessage: undefined,
      hoveredItem: undefined,
      openedPlaceDetails: {...area, totalPrice: area.placePrice },
      dateOpen: false,
      selectedArea: area.code,
      subareaId: area.subareaId ? area.subareaId : mapPlace.subarea,
      editMode: editMode
    });
  }
  render() {
    const l = this.props.location; // shortcut
    const t = key => l.translate(l, key);

    if (!l.area) {
      // TODO: render list of harbours
      console.log("Area not set");
      return null;
    }

    let errorDiv = [];
    if (this.props.errorMessage) {
      console.log(`error: ${this.props.errorMessage}`);
      errorDiv.push(
        <div className="bk_errorMessage sticky" style={{ display: "block" }}>
          {this.props.errorMessage}
        </div>
      );
    } else if (this.state.okMessage) {
      setTimeout(this.setState({ okMessage: undefined }), 5000);
      errorDiv.push(
        <div
          className="bk_okMessage sticky"
          style={{ display: "block" }}
          onClick={() => this.setState({ okMessage: undefined })}
        >
          {this.state.okMessage}
        </div>
      );
    }

    let areaName = "";
    if (
      this.state.reservationCalendar &&
      this.state.reservationCalendar.areaName
    ) {
      areaName = this.state.reservationCalendar.areaName;
    } else if (this.props.imageMap && this.props.imageMap.area) {
      areaName = this.props.imageMap.area.name;
    }
    const fixedPosition = {
      position: "relative",
      width: "fit-content",
      marginTop: "auto",
      height: "fit-content",
      overflow: "scroll",
      marginLeft: "auto",
      marginRight: "auto"
    };
    const ttStyle = fixedPosition;

    let tooltipContainerStyle = this.state.openedPlaceDetails || !this.state.hoveredItem ? 
      {} : { top: "50%", left: "50%", position: "fixed", zIndex: "50" };

    const tooltip = this.state.openedPlaceDetails ? (
      this.state.customer ? (
        <div style={fixedPosition}>
          {this.renderCustomerPage(t)}
        </div>
      ) : (
        <div style={fixedPosition}>
          {this.renderPlaceDetails()}
        </div>
      )
    ) : this.state.hoveredItem ? (
      <span className="tooltip" style={ttStyle}>
        {this.state.hoveredItem.code && this.state.hoveredItem.name
          ? `${this.state.hoveredItem.code}: ${this.state.hoveredItem.name}`
          : this.state.hoveredItem.code
          ? this.state.hoveredItem.code
          : ""}
        <br />
        {this.state.hoveredItem.width && this.state.hoveredItem.length
          ? `${this.state.hoveredItem.length} x ${this.state.hoveredItem.width} m`
          : ""}
      </span>
    ) : (
      undefined
    );

    let tabContent = undefined;
    switch (this.state.uiState) {
      case uiState.reservationCalendar:
        if (!this.state.reservationCalendar) {
          tabContent = <CircleLoader />;
          break;
        }
        const editAccess =
          this.props.loginProps.user.allowedTasks.indexOf("PLACE_MANAGE") != -1;
        const startDate =
          this.state.reservationCalendar && this.state.reservationCalendar.start
            ? moment(this.state.reservationCalendar.start)
            : this.state.start;
        const nxt = this.state.reservationCalendar.nextActiveDate;
        const prv = this.state.reservationCalendar.previousActiveDate;
        if (nxt || prv) {
          let dt = moment(nxt ? nxt : prv).startOf("week");
          tabContent = (
            <React.Fragment>
              <br />
              <br />
              <center>
                <a
                  href="showMore"
                  onClick={e => {
                    e.preventDefault();
                    this.setState(
                      {
                        start: dt,
                        end: moment(dt).add(8, "days")
                      },
                      () => this.update(false)
                    );
                  }}
                >
                  {nxt
                    ? t("no-active-places-next")
                    : t("no-active-places-prev")}
                </a>
              </center>
            </React.Fragment>
          );
        } else {
          tabContent = (
            <ReservationCalendar
              location={this.props.location}
              reservationCalendar={this.state.reservationCalendar}
              selectedReservation={this.state.selectedReservation}
              start={startDate}
              cancelReservation={(r, day) => {
                let duration = moment(r.validUntil, "YYYY-MM-DD").diff(
                  moment(r.validFrom, "YYYY-MM-DD"),
                  "days"
                );
                console.log(
                  "Duration: " +
                    duration +
                    " " +
                    r.validFrom +
                    " - " +
                    r.validUntil
                );
                if (!day && r.reservationStatus === "SEASON_OCCUPIED") {
                  return this.adminFreeSeasonPlaceAsGuest(r);
                  // this.setState({ selectedReservation: r });
                  //return;
                }
                return swal({
                  title: t("confirmFree"),
                  text: day
                    ? "Varaus perutaan päivältä: " +
                      moment(startDate)
                        .add(day - 1, "days")
                        .format("DD.MM.YYYY")
                    : "Koko varaus peruutetaan, väliltä " +
                      r.validFrom +
                      " - " +
                      r.validUntil +
                      (duration > 1
                        ? ". Jos haluat poistaa vain osan varauksesta, päivitä varauksen alku- ja/tai loppupäivää."
                        : "."),
                  icon: "warning",
                  buttons: [
                    t("cancel-button"),
                    { text: "Ok", closeModal: false }
                  ],
                  dangerMode: true
                }).then(
                  willDo => {
                    if (willDo) {
                      return reservationService
                        .cancelReservationById(
                          this.props.location.area,
                          this.props.location.language,
                          r.reservationId,
                          day && r.reservationStatus === "SEASON_OCCUPIED"
                            ? moment(startDate)
                                .add(day - 1, "days")
                                .format("YYYY-MM-DD")
                            : undefined
                        )
                        .then(ret =>
                          reservationService
                            .adminReservationCalendar(
                              l.area,
                              l.language,
                              this.state.start.format("YYYY-MM-DD"),
                              this.state.end.format("YYYY-MM-DD")
                            )
                            .then(ret =>
                              this.setState(
                                {
                                  errorMessage: ret.errorMessage,
                                  selectedReservation: undefined,
                                  reservationCalendar: ret.errorMessage
                                    ? this.state.reservationCalendar
                                    : ret
                                },
                                () => swal.close()
                              )
                            )
                            .catch(() => {
                              swal.stopLoading();
                              swal.close();
                            })
                        );
                    } else {
                      this.setState({ selectedReservation: undefined });
                    }
                  },
                  () => this.setState({ selectedReservation: undefined })
                );
              }}
              markPaid={this.markReservationPaid}
              reserve={(place, from, until, event) =>
                this.setState({
                  customer: Map({ confirmed: "true" }),
                  openedPlaceDetails: { ...place, totalPrice: place.placePrice},
                  dateOpen: false,
                  okMessage: undefined,
                  hoveredItem: place,
                  hoveredX: event.pageX,
                  hoveredY: event.pageY,
                  editData: { code: place.productCode },
                  reservationStart: from,
                  reservationEnd: until,
                  days: until.diff(from, "days"),
                  nextState: uiState.reservationCalendar
                })
              }
              editReservation={reservation => {
                let c = Map()
                  .set("firstName", reservation.firstName)
                  .set("lastName", reservation.lastName)
                  .set("email", reservation.email)
                  .set("mobile", reservation.mobile)
                  .set("boatType", reservation.boatType)
                  .set("boatName", reservation.boatName)
                  .set("boatModel", reservation.boatModel)
                  .set("boatLength", reservation.boatLength)
                  .set("boatWidth", reservation.boatWidth)
                  .set("boatDepth", reservation.boatDepth)
                  .set("reservationId", reservation.reservationId)
                  .set("placeId", reservation.placeId)
                  .set("customerId", reservation.customerId)
                  .set("productId", reservation.productId);
                this.setState({
                  customer: c,
                  okMessage: undefined,
                  reservationStart: moment(reservation.validFrom),
                  reservationEnd: moment(reservation.validUntil),
                  openedPlaceDetails: reservation
                });

                console.log("Edit: " + JSON.stringify(reservation));
              }}
              editPlace={
                editAccess
                  ? place => {
                      console.log("Edit: " + JSON.stringify(place));
                      this.openPlaceDetails(place, {}, true);
                    }
                  : undefined
              }
            />
          );
        }
        break;
      case uiState.dynamicMap:
        tabContent = (
          <DynamicMap
            {...this.props}
            t={t}
            onClick={(a, sa) => this.openPlaceDetails(a, sa, false)}
          />
        );
        break;
      case uiState.reporting:
        tabContent = <ReservationsReport {...this.props} />;
        break;
      case uiState.payouts:
        tabContent = <PayoutsReport {...this.props} />;
        break;
      default:
        tabContent = <CircleLoader />;
    }

    const tabOptions = [
      uiState.reservationCalendar,
      uiState.dynamicMap,
      uiState.reporting,
      uiState.payouts
    ];
    const dateSelector = this.renderDateSelector(t);
    const content = (
      <React.Fragment>
        <LoginRequired location={l} {...this.props.loginProps} />
        <LoginForm location={l} {...this.props.loginProps} />
        <BookkaaHeader
          location={l}
          {...this.props.headerProps}
          disableProfile={true}
        />
        <div className="harbour-and-season-container">
          <div className="harbour-title">
            <div className="title-and-date-selector">
              <h3 style={{ display: "inline-block" }}>{areaName}</h3>
              <span className="bk_spacer">&nbsp;</span>
              {dateSelector}
            </div>
            <div style={{ display: "flex" }}>
              <SeasonsComponent location={this.props.location} guest={true} />
              <ProductInformationAdmin location={this.props.location} guest={true} />
            </div>
          </div>
        </div>
        {errorDiv}
        <BkTab
          location={l}
          options={tabOptions}
          selected={this.state.uiState}
          callback={val => {
            this.setState({
              uiState: val,
              okMessage: undefined,
              hoveredItem: undefined
            });
            this.update(
              this.state.realtime,
              val == uiState.reporting || val == uiState.payouts
                ? this.state.reportStart
                : this.state.start,
              val == uiState.reporting ? this.state.reportEnd : this.state.end,
              uiState[val]
            );
          }}
        >
          <div style={tooltipContainerStyle}>
            {tooltip}
          </div>
          {!tooltip && tabContent}
        </BkTab>
      </React.Fragment>
    );

    return content;
  }

  sellerFromArea(areaId) {
    let seller = undefined;
    this.props.areas.forEach(a => {
      if (a.id == areaId) {
        seller = a.seller;
      }
    });
    return seller;
  }

  renderPlaceDetails() {
    if (this.props.placeDetails) {
      console.log(
        "Render place details: " + JSON.stringify(this.props.placeDetails)
      );
    } else {
      console.log("no details to render");
    }

    return (
      <PlaceDetailsRenderer
        errorMessage={this.state.placeDetailsError}
        location={this.props.location}
        defaults={this.state.placeDefaults}
        user={this.props.user}
        season={false}
        simple={!this.props.placeDetails || !this.props.placeDetails.editAccess}
        subareaId={this.state.subareaId}
        placeDetails={this.props.placeDetails}
        openedPlaceDetails={this.state.openedPlaceDetails}
        closeCallback={() => {
          this.setState({
            openedPlaceDetails: undefined,
            hoveredItem: undefined,
            editData: undefined,
            editMode: false,
            placeDetailsError: undefined
          });
          this.update();
        }}
        savePlaceAndProducts={this.savePlaceAndProducts}
      />
    );
  }

  renderDateSelector(t) {
    if (this.state.uiState != uiState.reservationCalendar) {
      return undefined;
    }
    return (
      <React.Fragment>
        <span style={{ fontSize: "1.0em" }}>
          <a
            onClick={() =>
              this.setStartEnd(
                false,
                this.state.start.subtract(1, "week"),
                this.state.end.subtract(1, "week")
              )
            }
          >
            &#xab; {t("previousWeek")}
          </a>
          <DateRangeSelector
            pastDateAllowed={true}
            location={this.props.location}
            start={this.state.start}
            end={this.state.end}
            startDateCallback={v => {
              const dt = moment(this.state.end).startOf("day");
              const diffToEnd = dt.diff(v.startOf("day"), "days");
              console.log("diffToEnd: " + diffToEnd);
              let end = this.state.end;
              if (diffToEnd > 14) {
                end = moment(v).add(14, "days");
              } else if (diffToEnd < 5) {
                end = moment(v).add(5, "days");
              }
              this.setStartEnd(false, v, end);
            }}
            endDateCallback={v => {
              const dt = moment(this.state.start).startOf("day");
              const diffToStart = dt.diff(v.startOf("day"), "days");
              console.log("diffToStart: " + diffToStart);
              let start = this.state.start;
              if (diffToStart < -14) {
                console.log("setting start -14 from end");
                start = moment(v).subtract(14, "days");
              } else if (diffToStart > -5) {
                console.log("setting start -5 from end");
                start = moment(v).subtract(5, "days");
              } else {
                console.log("keeping start");
              }
              this.setStartEnd(false, start, v);
            }}
          >
            {false && (
              <span className="date-selector-link">
                {t("dateSelector.realtime")}
              </span>
            )}
          </DateRangeSelector>
          <a
            onClick={() =>
              this.setStartEnd(
                false,
                this.state.start.add(1, "weeks"),
                this.state.end.add(1, "weeks")
              )
            }
          >
            {t("nextWeek")} &#xbb;
          </a>
        </span>
      </React.Fragment>
    );
  }

  setStartEnd(realtime, start, end) {
    this.setState({
      realtime: realtime,
      start: start,
      end: end
    });
    this.update(realtime, start, end, this.state.uiState);
  }

  copyPlaceDetails(area) {
    const details = this.props.placeDetails;
    const place =
      details && details.placeId === area.placeId ? details : undefined;

    const editData = {};
    const products = [];
    editData.products = products;
    editData.placeTypeStr = area.placeTypeStr;
    editData.code = area.code;
    editData.subareaId = area.subareaId;
    if (place && place.placeId) {
      place.products.forEach(e => {
        products.push({
          productId: e.productId,
          type: e.type,
          typeStr: e.typeStr,
          name: e.name,
          season: e.season,
          price: e.price,
          currentPrice: e.price,
          code: e.code,
          parkingPlace: e.parkingPlace,
          reservations: e.reservations ? [...e.reservations] : []
        });
      });
      editData.placeId = place.placeId;
      editData.areaId = place.areaId;
      editData.placeType = place.placeType;
      editData.placeTypeStr = place.placeTypeStr;
      editData.width = place.width;
      editData.length = place.length;
      editData.depth = place.depth;
      editData.name = place.name;
      editData.code = place.code;
      editData.subarea = place.subarea;
      editData.subareaId = place.subareaId;
    } else {
      console.log("No place to copy");
      editData.placeId = area.placeId;
      if (!area.placeId) {
        // not created
        products.push({
          code: area.code,
          season: {},
          reservations: [],
          type: this.state.defaultProductType
        });
      }
    }
    if (!editData.areaId) {
      editData.areaId = this.props.location.area;
    }
    if (!editData.placeType) {
      editData.placeType = this.state.defaultPlaceType;
    }
    /// this.setState({ editData: editData });
    return editData;
  }

  renderCustomerPage(t) {
    const customer = this.state.customer;
    const placeSelector = [];
    if (this.state.customer.get("reservationId")) {
      placeSelector.push(<center>{t("placeCode")}</center>);

      const ctv = this.state.reservationCalendar.codeToDays;
      const options = Object.keys(ctv).map(key => (
        <option
          value={ctv[key][0].productId}
          selected={customer.get("productId") == ctv[key][0].productId}
        >
          {key}
        </option>
      ));

      placeSelector.push(
        <center>
          <select
            onChange={e =>
              this.setState({
                customer: this.state.customer.set("productId", e.target.value)
              })
            }
            onInput={e =>
              this.setState({
                customer: this.state.customer.set("productId", e.target.value)
              })
            }
          >
            {options}
          </select>
        </center>
      );
    }
    return (
      <div style={{ marginRight: "15px" }}>
        <center>
          {this.state.openedPlaceDetails.placeTypeStr}{" "}
          {this.state.openedPlaceDetails.code}
        </center>
        {(this.state.customer.get("unit") != "DAY") && (
          <center style={{ minWidth: "300px" }}>
            <DateRangeSelector
              beforeStartDate={<div>{t("starts")}</div>}
              beforeEndDate={<div>{t("ends")}</div>}
              editMode={true}
              pastDateAllowed={!!this.state.customer.get("reservationId")}
              location={this.props.location}
              start={this.state.reservationStart}
              end={this.state.reservationEnd}
              startDateCallback={(s, e) => {
                this.setState({
                  reservationStart: s,
                  reservationEnd: e
                }, this.updatePrice);
              }}
              endDateCallback={(e, s) =>
                this.setState({
                  reservationEnd: e,
                  reservationStart: s
                }, this.updatePrice)
              }
            />
          </center>
        )}
        {(this.state.customer.get("unit") == "DAY") && (<React.Fragment>
          <center>{t("starts")}</center>
          <center style={{ minWidth: "300px" }}>
            <DateSelector
              isEdit={this.state.dateOpen}
              enableEditCallback={() => this.setState({dateOpen: true})}
              location={this.props.location}
              value={this.state.reservationStart}
              callback={(moment) => {
                this.setState({
                  reservationStart: moment,
                  reservationEnd: moment,
                  dateOpen: false
                }, this.updatePrice);
              }}
            />
          </center>
        </React.Fragment>)}
        {placeSelector}
        <React.Fragment>
          <center>{t("boatLength")}</center>
          <center>
            <input
              value={this.state.customer.get("boatLength")}
              onChange={e =>
                this.setState({
                  customer: this.state.customer.set(
                    "boatLength",
                    e.target.value.replace(",", ".")
                  )
                }, this.updatePrice)
              }
            ></input>
          </center>
          <center>{t("boatWidth")}</center>
          <center>
            <input
              value={this.state.customer.get("boatWidth")}
              onChange={e =>
                this.setState({
                  customer: this.state.customer.set(
                    "boatWidth",
                    e.target.value.replace(",", ".")
                  )
                }, this.updatePrice)
              }
            ></input>
          </center>
        </React.Fragment>
        <Customer
          t={t}
          updateCustomer={(fieldName, value) => {
            this.setState({
              customer: this.state.customer.set(fieldName, value)
            });
            console.debug(`Updated customer ${fieldName} to ${value}`);
          }}
          stateCustomer={this.state.customer}
        />
        {!this.state.customer.get("reservationId") && (
          <React.Fragment>
            <center>{t("paid")}</center>
            <center>
              <input
                type="checkbox"
                checked={this.state.customer.get("confirmed") === "true"}
                onChange={event =>
                  this.setState({
                    customer: this.state.customer.set(
                      "confirmed",
                      event.target.checked ? "true" : "false"
                    )
                  })
                }
              />
            </center>
          </React.Fragment>
        )}
        {this.state.openedPlaceDetails.dayPlaceDuringDay && <center>
          <select 
            style={{ margin: "5px" }}
            onChange={e =>
              this.setState({
                customer: this.state.customer.set(
                  "unit",
                  e.target.value
                ),
                dateOpen: false
              }, this.updatePrice)
            }>
              <option value="NIGHT">Vuorokausi</option>
              <option value="DAY">Päivä</option>
            </select>
        </center>}
        <center>{t("price")}</center>
        <center>{this.state.openedPlaceDetails.totalPrice}</center>
        <center>
          <BkModifyButton
            disabled={!this.state.customer.get("firstName")}
            buttonTitle={t("save-button")}
            callback={this.reserve}
          />
          <BkModifyButton
            buttonTitle={t("close-button")}
            callback={() =>
              this.setState({
                customer: undefined,
                openedPlaceDetails: undefined,
                hoveredItem: undefined
              })
            }
          />
        </center>
      </div>
    );
  }

  updatePrice() {
    if (!this.state.openedPlaceDetails) {
      return;
    }
    const code = (this.state.editData && this.state.editData.code) ? this.state.editData.code : this.state.openedPlaceDetails.code;
    let w = this.reservationObject(code, this.state.customer, this.state.reservationStart, this.state.reservationEnd);
    if (!w.customer.get("firstName")) {
      w.customer = w.customer.set("firstName", "");
    }
    genericPost(this.props.location.language, `parkingAdmin/price/${this.state.openedPlaceDetails.productId}`, w, "booking-web", "application/json").then(r => {
      const openedPlaceDetails = {...this.state.openedPlaceDetails};
      openedPlaceDetails.totalPrice = r.totalPrice;
      this.setState({ openedPlaceDetails: openedPlaceDetails});
    })
  }

  reserve() {
    const l = this.props.location;
    const from = moment(this.state.reservationStart).format("YYYY-MM-DD");

    const responseHandler = ret => {
      if (ret.errorMessage) {
        this.setState({ errorMessage: ret.errorMessage });
      } else {
        console.log("Reservation updated");
        this.setState({
          customer: undefined,
          openedPlaceDetails: undefined,
          hoveredItem: undefined,
          okMessage: ret.okMessage
        });
        this.update();
      }
    };

    if (this.state.customer && this.state.customer.get("reservationId")) {
      let c = this.state.customer
        .set("reference", Math.random() * 1000)
        .set("validFrom", from)
        .set(
          "validUntil",
          moment(this.state.reservationEnd).format("YYYY-MM-DD")
        );
      // update reservation
      return reservationService
        .updateReservation(l.area, l.language, c)
        .then(responseHandler);
    } else {
      let w = this.reservationObject(this.state.editData.code, this.state.customer, this.state.reservationStart, this.state.reservationEnd);
      return reservationService
        .boatParkAdmin(l.area, l.language, w)
        .then(responseHandler);
    }
  }

  reservationObject(code, customer, reservationStart, reservationEnd) {
    const from = moment(reservationStart).format("YYYY-MM-DD");
    let w = {};
    w.reference = Math.random() * 1000;
    w.confirmed = customer.get("confirmed") === "true";
    w.dayParking = customer.get("unit") === "DAY";
    w.customer = customer;
    w.days = moment(reservationEnd).diff(
      moment(reservationStart),
      "days"
    );
    w.subareaCode = code;
    w.start = from;
    return w;
  }
}

export default Admin;

Admin.propTypes = {
  ensureLoggedIn: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  location: {
    ctx: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    area: PropTypes.string.isRequired
  }
};
