import React from "react";
import {
  genericDelete,
  genericGet,
  genericPost
} from "../../services/axiosApi";
import BookkaaHeader from "../BookkaaHeader";
import BkModifyButton from "../layout/BkModifyButton";
import TableAndForm from "../layout/TableAndForm";
import LoginForm from "../LoginForm";
import LoginRequired from "../LoginRequired";
import Profile from "../Profile";
import AreaHeader from "./AreaHeader";
import OkErrorMessage from "../layout/OkErrorMessage";

class AccessCodes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedType: undefined,
      selectedCode: undefined,
      multiEditMode: false
    };
    this.selectCallback = this.selectCallback.bind(this);
    this.codeSelectCallback = this.codeSelectCallback.bind(this);
    this.deleteCode = this.deleteCode.bind(this);
    this.saveAccessCodeText = this.saveAccessCodeText.bind(this);
    this.openTextarea = this.openTextarea.bind(this);
  }

  saveAccessCodeText() {
    genericPost(
      "fi",
      `accessCode/bulk/${this.state.selectedType.id}`,
      { accessCodeText: this.state.accessCodeText },
      "booking-web"
    ).then(ret => {
      this.setState({
        errorMessage: ret.errorMessage,
        accessCodeText: ret.errorMessage ? this.state.accessCodeText : undefined
      });
    });
  }
  openTextarea(item) {
    genericGet(
      "fi",
      `accessCode/bulk/${this.state.selectedType.id}`,
      "booking-web",
      15000
    ).then(ret => {
      console.log(`Returned: ${JSON.stringify(ret)}`);
      this.setState({
        errorMessage: ret.errorMessage,
        accessCodeText: ret.codes
      });
    });
  }

  deleteCode(item) {
    const l = this.props.location;
    return genericDelete(
      l.language,
      "accessCode/" + item.id,
      "booking-web"
    ).then(() => {
      this.setState({ selectedCode: undefined, errorMessage: undefined });
      return true;
    });
  }

  selectCallback(item) {
    this.setState({ selectedType: item });
  }

  codeSelectCallback(item) {
    this.setState({ selectedCode: item });
  }

  render() {
    const l = this.props.location;
    const t = key => l.translate(l, key);
    const typesLoc = { ...this.props.location };
    typesLoc.endpoint = "a" + l.area + "/accessCodeType";
    typesLoc.area = undefined;

    const codesLoc = { ...this.props.location };
    codesLoc.endpoint = "a" + l.area + "/accessCode";
    codesLoc.area = undefined;

    let beforeForm = [];
    const CLOSE_BUTTON = {
      supportsMulti: true,
      callback: this.props.closeCb,
      title: t("close-button")
    };

    const DELETE_CODE = {
      supportsMulti: false,
      callback: this.deleteCode,
      title: t("delete-button"),
      disabledCallback: b =>
        !this.state.selectedCode || !this.state.selectedCode.id
    };

    const OPEN_TXT = {
      supportsMulti: false,
      callback: this.openTextarea,
      title: t("mass-edit-button"),
      disabledCallback: b => !this.state.selectedType
    };

    let listButtonArray = [CLOSE_BUTTON, OPEN_TXT];

    let codeListButtonArray = [];

    return (
      <React.Fragment>
        <LoginRequired location={l} {...l} {...this.props.loginProps} />
        <BookkaaHeader location={l} {...this.props.headerProps} />
        <LoginForm location={l} {...this.props.loginProps} />
        <Profile location={l} {...this.props.profileProps}>
          <AreaHeader areaName={this.props.areaName} />
          <OkErrorMessage okMessage={this.state.okMessage} errorMessage={this.state.errorMessage}
            clearOk={()=>this.setState({okMessage:undefined})} clearError={()=>this.setState({errorMessage:undefined})} />
          <h2>{t("accessCodeTypes.title")}</h2>
          <TableAndForm
            selectCallback={this.selectCallback}
            itemsBeforeForm={beforeForm}
            buttons={listButtonArray}
            formButtonArray={[]}
            multiSelect={false}
            {...this.props}
            endpoint={typesLoc.endpoint}
            location={typesLoc}
          />
          <h2>{t("accessCodes.title")}</h2>
          {this.state.selectedType && !this.state.accessCodeText && (
            <TableAndForm
              selectCallback={this.codeSelectCallback}
              itemsBeforeForm={beforeForm}
              buttons={codeListButtonArray}
              formButtonArray={[DELETE_CODE]}
              multiSelect={false}
              {...this.props}
              endpoint={codesLoc.endpoint}
              location={codesLoc}
              search={
                this.state.selectedType
                  ? `codeType.id=${this.state.selectedType.id}`
                  : undefined
              }
              preSaveCallback={data => {
                return {
                  ...data,
                  codeTypeId: this.state.selectedType.id
                };
              }}
            />
          )}
          {this.state.selectedType && this.state.accessCodeText && (
            <React.Fragment>
              <textarea
                className="responsive"
                rows="50"
                onChange={e =>
                  this.setState({ accessCodeText: e.target.value })
                }
                value={this.state.accessCodeText}
              />
              <BkModifyButton
                buttonTitle={t("save-button")}
                callback={this.saveAccessCodeText}
              />
            </React.Fragment>
          )}
        </Profile>
      </React.Fragment>
    );
  }
}
export default AccessCodes;
