import React from "react";
import { genericGet } from "../../services/axiosApi";

class ElectricityConsumption extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      consumption: undefined,
      power: undefined
    };
  }

  render() {
    const productId = this.props.uic.productId;
    const reservationId = this.props.uic.id;
    // const t = key => this.props.location.translate(this.props.location, key);

    return (
      <div
        className="consumption-info"
        onClick={() => {
          genericGet(
            this.props.location.language,
            `parkingAdmin/electricity/${productId}/consumption/${reservationId}`,
            "booking-web"
          ).then(ret =>
            this.setState({
              consumption: !ret.totalConsumption ? 0.0 : ret.totalConsumption,
              power: !ret.activePower ? 0 : ret.activePower
            })
          );
        }}
      >
        <div className="consumption-data">
          {this.state.consumption === undefined
            ? "click to update"
            : this.state.consumption + " kWh"}
        </div>
      </div>
    );
  }
}

export default ElectricityConsumption;
