import React from "react";
import BookkaaMenu from "./BookkaaMenu";
import PropTypes from "prop-types";
import BkModifyButton from "./layout/BkModifyButton";

const UserInformation = props => {
  function logout() {
    props.doLogout(props.location);
  }
  const l = props.location; // shortcut
  const t = key => l.translate(l, key);
  const user = props.user;
  if (!user) {
    if (props.disableLogin) {
      return null;
    }
    return (
      <div id="loginInfoContainer" className="loginInfoContainer">
        <BkModifyButton
          callback={() => {
            if (props.otpLogin) {
              console.log("Requesting OTP login");
            }
            props.ensureLoggedIn(
              props.jwt,
              props.location.ctx,
              props.location.language,
              props.location.endpoint,
              props.loginEmail,
              props.otpLogin
            );
          }}
          buttonTitle={t("loginButton")}
        />
      </div>
    );
  }
  return (
    <div id="loginInfoContainer" className="loginInfoContainer">
      {!props.disableProfile && !props.userProfile && (
        <BkModifyButton
          callback={() => props.openProfile(props.location.language)}
          buttonTitle={t("userProfileButt")}
        />
      )}
      {props.userProfile && (
        <BkModifyButton
          callback={() => props.closeProfile()}
          buttonTitle={t("close-button")}
        />
      )}
      <BkModifyButton callback={logout} buttonTitle={t("logoutButton")} />
    </div>
  );
};

const BookkaaHeader = props => {
  const loc = props.location;
  const version =
    loc.translations && loc.translations.version
      ? loc.translations.version
      : undefined;
  const img = `/${loc.ctx}/${loc.language}${loc.area ? "/" + loc.area : ""}/${
    loc.endpoint
  }/logo.png`;
  return (
    <React.Fragment>
      <div>
        <div id="header" className="bk-header-bar">
          <img src={img} alt="" />
          {version && (
            <div style={{ fontSize: "6px", float: "right" }}>{version}</div>
          )}
          <UserInformation {...props} />
        </div>
        {!props.userProfile && props.user && (
          <BookkaaMenu
            location={loc}
            loginDialogVisible={props.loginDialogVisible}
            menuJson={props.menuJson}
            getMenu={props.getMenu}
            areaId={props.areaId}
            getTranslations={props.getTranslations}
            headerSelectorContent={props.headerSelectorContent}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default BookkaaHeader;

BookkaaHeader.propTypes = {
  disableLogin: PropTypes.bool,
  openProfile: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  ensureLoggedIn: PropTypes.func.isRequired,
  getMenu: PropTypes.func.isRequired,
  loginDialogVisible: PropTypes.bool.isRequired,
  loginEmail: PropTypes.string,
  menuJson: PropTypes.any,
  user: PropTypes.any,
  areaId: PropTypes.number,
  location: {
    ctx: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    area: PropTypes.number
  }
};
