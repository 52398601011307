import React from "react";

export const SearchListControls = props => {
  const {
    pageSize,
    first,
    last,
    total,
    location,
    prevCb,
    nextCb,
    pageCb
  } = props;

  const t = key => location.translate(location, key);
  const content = [];
  const next = last && total > last + 1;
  const prev = first > 0;
  if (!prev && !next) {
    return content;
  }
  const pages = total / pageSize;
  const currentPage = first / pageSize;

  const pageSelector = [];
  if (pages < 3 || !pageCb) {
    pageSelector.push(<span style={{ width: "100%" }}>&nbsp;</span>);
  } else {
    const pageSelect = [];
    const min = currentPage < 5 ? 0 : currentPage - 5;
    const max = min + 10 > pages ? pages : min + 10;
    for (let i = min; i < max; i++) {
      if (i === currentPage) {
        pageSelect.push(
          <span className="pageSelectorActive">&nbsp;{i + 1} &nbsp;</span>
        );
      } else {
        pageSelect.push(<a onClick={() => pageCb(i)}>&nbsp;{i + 1} &nbsp;</a>);
      }
    }
    pageSelector.push(<span style={{ width: "100%" }}>{pageSelect}</span>);
  }
  content.push(
    <React.Fragment>
      {prev && <a onClick={prevCb}>&#xab; {t("back")}</a>}
      {pageSelector}
      {next && <a onClick={nextCb}>{t("forward")} &#xbb;</a>}
    </React.Fragment>
  );
  return content;
};
