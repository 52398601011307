import ax from "./axiosApi";

const getSettings = (ctx, language, serviceEndpoint) => {
  return ax()
    .get(`/${ctx}/${language}/${serviceEndpoint}/alpaca/settings_gen.js`, {
      withCredentials: true
    })
    .then(ret =>
      patchDatasources(ret, `/${ctx}/${language}/${serviceEndpoint}/`)
    )
    .catch(function(error) {
      console.log(error);
      return {
        error: error
      };
    });
};

function patchDatasources(ret, urlPrefix) {
  if (ret.status !== 200) {
    return {
      error: ret
    };
  }
  if (ret.data && ret.data.options) {
    patch(ret.data.options, urlPrefix);
  }
  return ret.data;
}
function patch(obj, urlPrefix) {
  for (var prop in obj) {
    if (typeof obj === "object" && obj[prop]) {
      if (prop === "dataSource") {
        console.log("patching datasource: " + obj[prop]);
        if (
          obj[prop].startsWith("../application") &&
          urlPrefix.endsWith("/application")
        ) {
          obj[prop] = urlPrefix + obj[prop].substring(14);
        } else {
          obj[prop] = urlPrefix + obj[prop];
        }
      }
      patch(obj[prop], urlPrefix);
    }
  }
  return obj;
}

export default {
  getSettings
};
