import React from "react";
import PropTypes from "prop-types";
import BkModifyButton from "../layout/BkModifyButton";
import Datetime from "react-datetime";
import LoginForm from "../LoginForm";
import BookkaaHeader from "../BookkaaHeader";
import BkContentArea from "../layout/BkContentArea";
import { Map } from "immutable";
import { shallowEqual } from "recompose";
import WatchCalendar from "./WatchCalendar";

class Watch extends React.Component {
  constructor(props) {
    super(props);
    this.reserve = this.reserve.bind(this);
    this.isValidDate = this.isValidDate.bind(this);
    this.renderDay = this.renderDay.bind(this);
    let prods = this.getProds(props);
    this.state = {
      reserving: false,
      dates: Map(prods)
    };
  }

  getProds(props) {
    let prods = {};
    if (props.availableProducts && props.availableProducts instanceof Array) {
      props.availableProducts.forEach(p => {
        // console.log(`${p.at} has ${p.freeProducts.length} free products`);
        prods[`${p.at}`] = p.freeProducts;
      });
    } else if (props.availableProducts) {
      console.log("Not an array: " + JSON.stringify(props.availableProducts));
    } else {
      console.log("No products");
    }
    return prods;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!shallowEqual(nextProps, this.props)) {
      let prods = this.getProds(nextProps);
      console.log(
        "Will receive props adding products => " + JSON.stringify(prods)
      );
      this.setState({
        dates: this.state.dates.merge(Map(prods))
      });
    }
  }

  reserve() {
    let l = this.props.location;
    console.log("Reserve called! Current date: " + this.state.selectedDate);
    this.props.guardPatrolReserve(l.area, l.language, {
      type: "GUARD_WATCH",
      selectedDate: this.state.selectedDate,
      productCode: this.state.dates.get(`${this.state.selectedDate}`)[0]
    });
  }

  isValidDate(currentDate, selectedDate) {
    var yesterday = Datetime.moment().subtract(1, "day");
    if (currentDate.isAfter(yesterday)) {
      if (this.state.dates) {
        let prods = this.state.dates.get(`${currentDate}`);
        if (prods === undefined || prods.length === 0) {
          return false;
        } else {
          return true;
        }
      } else {
        console.log("'dates' not set ");
      }
    }
    return false;
  }

  render() {
    if (this.props.guardPatrolReserved) {
      window.location.href = this.props.guardPatrolReserved.returnUrl;
    }
    let location = this.props.location;
    let errorDiv = [];
    if (this.props.reservationError) {
      errorDiv.push(
        <div className="bk_errorMessage" style={{ display: "block" }}>
          {this.props.reservationError}
        </div>
      );
    }

    // Login would be required, but for now Pasila login used.
    // <LoginRequired {...this.props.location} {...this.props.loginProps} />

    let content = (
      <React.Fragment>
        <BookkaaHeader
          disableLogin={true}
          location={location}
          {...this.props.headerProps}
        />
        <LoginForm location={location} {...this.props.loginProps} />
        <BkContentArea>
          <h1>Vahtivuoron varaus</h1>
          {errorDiv}
          <WatchCalendar
            isValidDate={this.isValidDate}
            renderDay={(p, cd, sd) => this.renderDay(this.state, p, cd, sd)}
            dateChanged={moment => this.setState({ selectedDate: moment })}
            getAvailableProducts={this.props.getAvailableProducts}
            location={this.props.location}
          />
          <BkModifyButton
            disabled={!this.state.selectedDate}
            buttonTitle="Vahvista varaus"
            callback={() => this.reserve()}
            busy={this.state.reserving}
          />
          <BkModifyButton buttonTitle="Peruuta" callback={this.cancel} />
        </BkContentArea>
      </React.Fragment>
    );

    return content;
  }
  renderDay(state, props, currentDate, selectedDate) {
    if (state.dates === undefined) {
      console.log(`No dates! Reservations: ${this.state.reservations}`);
    }
    let prods = state.dates.get(`${currentDate}`);
    if (prods !== undefined) {
      // console.log(`Date ${currentDate} has ${prods.length} products`);
      if (prods.length > 0) {
        return <div class="freeProductsOnDate">{prods.length} vapaana</div>;
      }
    }
    return "";
  }
  cancel() {
    // TODO: nicer
    window.location.href =
      "https://bookkaa.satamapaikka.com/fi/users/edit_profile";
  }
}

export default Watch;

Watch.propTypes = {
  ensureLoggedIn: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  customer: PropTypes.any.isRequired,
  location: {
    ctx: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired
  }
};
