import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Root from "./Root";
import { BrowserRouter } from "react-router-dom";
//import registerServiceWorker from "./registerServiceWorker";
import { Provider } from "react-redux";
import { createStore } from "./utils/redux";
import { getMiddlewares, getReducers, getEnhancers } from "./config/redux";

const store = createStore(getReducers(), getMiddlewares(), getEnhancers());

function render(Component, rootElement, method = "render") {
  ReactDOM[method](<Component store={store} />, rootElement);
}

const rootElement = document.getElementById("root");
render(Root, rootElement, "render");

//registerServiceWorker();
