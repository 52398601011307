import React from "react";
import BkModifyButton from "../layout/BkModifyButton";

export const SeasonPlace = props => {
  const place = props.place;
  let button = "";
  if (place.status === "IN_CART" || place.status === "PAYMENT_LINK_SENT") {
    button = (
      <BkModifyButton
        buttonTitle="Maksa"
        callback={() => props.pay(props.language, place.cartId)}
      />
    );
  } else if (place.status === "PAID" || place.status === "INVOICE_PAID") {
    button = (
      <BkModifyButton
        buttonTitle="Lataa kuitti"
        callback={() => props.loadReceipt(place)}
      />
    );
  } else if (place.status === "INVOICED") {
    button = (
      <BkModifyButton
        buttonTitle="Lataa lasku"
        callback={() => props.loadReceipt(place)}
      />
    );
  }
  const description = [];
  place.description.split("\n").forEach(element => {
    description.push(element);
    description.push(<br />);
  });
  return (
    <React.Fragment>
      <p>{description}</p>
      <p>{place.paymentDescription}</p>
      {button}
    </React.Fragment>
  );
};
