import React from "react";
import { CircleLoader } from "react-spinners";
import { genericGet, genericPost } from "../../services/axiosApi";
import BkModifyButton from "../layout/BkModifyButton";

const ProductClassRow = props => 
  <tr>
    <th>{props.title}</th>
    <td>
      {props.edit && <textarea value={props.row.fi.information} onChange={(e) => { props.row.fi.information = e.target.value; props.cb(); }} /> }
      {!props.edit && <p>{props.row.fi.information}</p>}
     </td>
    <td>
      {props.edit && <textarea value={props.row.en.information} onChange={(e) => { props.row.en.information = e.target.value; props.cb(); }} /> }
      {!props.edit && <p>{props.row.en.information}</p>}
    </td>
    <td>
      {props.edit && <textarea value={props.row.sv.information} onChange={(e) => { props.row.sv.information = e.target.value; props.cb(); }} /> }
      {!props.edit && <p>{props.row.sv.information}</p>}
    </td>
  </tr>;

const emptyInfo = type => {
  return { 
    fi: { information: "", language: "fi", productClass: type }, 
    en: { information: "", language: "en", productClass: type }, 
    sv: { information: "", language: "sv", productClass: type } 
  };
};

class ProductInformationAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getInfos = this.getInfos.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.getInfos();
  }

  save() {
    const language = this.props.location.language;
    const area = this.props.location.area;
    const d = this.state.data;
    const reqs = [];
    Object.keys(d).forEach(cls => 
      Object.keys(d[cls]).forEach(lan => {
        const dd = d[cls][lan];
        if (dd && dd.id) { // update
          reqs.push(genericPost(language, `a${area}/productInfo/${dd.id}`, dd));
        } else if (dd) { // create
          reqs.push(genericPost(language, `a${area}/productInfo/new`, dd));
        }
      })
    );
    Promise.all(reqs).then(() => this.getInfos());
  }
  getInfos() {
    const language = this.props.location.language;
    const area = this.props.location.area;
    genericGet(language, `a${area}/productInfo/all`).then(r => {
      if (Array.isArray(r)) {
        const data = {};
        r.forEach((i) => {
          if (!data[i.productClass]) {
            data[i.productClass] = { };
          }
          data[i.productClass][i.language] = i;
        });
        if (!data.GUEST_RESERVATION) {
          data.GUEST_RESERVATION = emptyInfo("GUEST_RESERVATION");
        }
        if (!data.NIGHT_PAYMENT) {
          data.NIGHT_PAYMENT = emptyInfo("NIGHT_PAYMENT");
        }
        if (!data.DAY_PAYMENT) {
          data.DAY_PAYMENT = emptyInfo("DAY_PAYMENT");
        }
        console.log("Data: " + JSON.stringify(data));
        this.setState({ infos: r, data: data, edit: false });
      } else {
        console.log("Failed to get infos");
      }
    });
  }

  render() {
    const l = this.props.location; // shortcut
    const t = key => l.translate(l, key);

    const content = [];

    const data = this.state.data;
    if (this.state.edit && data) {
      return (
        <div className="harbour-seasons-modal" style={{ width: "auto" }}>
          <p>{t("productInfo-edit-description")}</p>
          <table className="season-table">
            <tr>
              <th />
              <th>FI</th>
              <th>EN</th>
              <th>SV</th>
            </tr>
            <ProductClassRow title={t("productClass.prebook")} 
              row={data.GUEST_RESERVATION} cb={() => this.setState({ data: {...data} })} edit={true} />
            <ProductClassRow title={t("productClass.liteNight")} 
              row={data.NIGHT_PAYMENT} cb={() => this.setState({ data: {...data} })} edit={true} />
            <ProductClassRow title={t("productClass.day")} 
              row={data.DAY_PAYMENT} cb={() => this.setState({ data: {...data} })} edit={true} />
            <tr>
              <td colspan="4">
                <div style={{ float: "right" }}>
                  <BkModifyButton
                    buttonTitle={t("modify-button")}
                    callback={this.save}
                  />
                  <BkModifyButton
                    buttonTitle={t("close-button")}
                    callback={() => this.setState({ edit: undefined })}
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
      );
    } else if (data) {
      return (
        <div className="harbour-seasons">
          <table><tr><td>
            <a onClick={e => {
              e.preventDefault();
              this.setState({ edit: true });
            }}>Tuotetiedot</a>
          </td></tr></table>
        </div>
      );
    } else {
      return <CircleLoader />;
    }
  }
}

export default ProductInformationAdmin;
