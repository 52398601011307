import React from "react";
import Link from "react-router-dom/Link";

const LanguageSelection = (props) => {
  const { language, getLink, open, getTranslations, toggleOpen } = props;
  if (open) {
    const supportedLangs = ['fi','sv','en'];
    return (
      <div className="languageSelector ls-Open">
        <ol>
          {supportedLangs.map(lang => <li>
            <Link to={getLink(lang)} 
                  onclick={() => getTranslations(lang)}>
              <div>{lang.toUpperCase()}</div>
            </Link>
          </li>)}
        </ol>
      </div>
    );
  } else {
    return (
      <div className="languageSelector ls-Closed" onClick={toggleOpen}>
        {language.toUpperCase()}
      </div>
    );
  }
};

export default LanguageSelection;