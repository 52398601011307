import React from "react";
import AlpacaForm from "./alpaca/Form";
import BkModifyButton from "./layout/BkModifyButton";
import BkContentArea from "./layout/BkContentArea";
import Modal from "react-modal";
import { customStyles } from "./layout/ModalStyle";

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.register = this.register.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }
  form = null;

  register() {
    console.log("Try to register");
    let data = this.form.getData();
    data.currentUrl = window.location.href;
    this.props.doRegister(
      data,
      this.props.ctx,
      this.props.language,
      this.props.endpoint
    );
  }

  afterOpenModal() {
    console.log("modal opened");
  }

  closeModal() {
    console.log("modal closed");
    this.props.openLoginDialog();
  }

  render() {
    if (this.props.registerDialogVisible) {
      let data = {};
      if (typeof registerDialogVisible === "string") {
        data.email = this.props.registerDialogVisible;
      }
      console.log("rendering registration");
      return (
        <React.Fragment>
          <BkContentArea>
            <Modal
              isOpen={this.props.registerDialogVisible}
              onAfterOpen={this.afterOpenModal}
              onRequestClose={this.closeModal}
              style={customStyles}
              className="ui-widget-content"
              contentLabel="Rekisteröidy"
            >
              <AlpacaForm
                language={this.props.language}
                ctx="um-web"
                endpoint="register"
                onRef={ref => (this.form = ref)}
                errorMsg={this.props.errorMessage}
                data={data}
              />
              <BkModifyButton
                language={this.props.language}
                callback={this.register}
                buttonTitle="Rekisteröidy"
              />
              <BkModifyButton
                language={this.props.language}
                callback={this.closeModal}
                buttonTitle="Sulje"
              />
            </Modal>
          </BkContentArea>
        </React.Fragment>
      );
    } else {
      return <div />;
    }
  }
}

export default RegisterForm;
