import React from "react";
import { genericDelete } from "../../services/axiosApi";
import DateRangeSelector from "./DateRangeSelector";
import swal from "@sweetalert/with-react";
import moment from "moment";

export const freeSeasonPlaceAsGuest = (reservation, location, startCancelParam, endCancelParam, isAdmin, setStartEndCallback, afterDeleteCallback) => {
    const l = location; // shortcut
    const t = key => l.translate(l, key);
    const rStart = moment(reservation.validFrom).startOf("day");
    const defStart = rStart.isBefore(moment().startOf("day")) ? moment().startOf("day") : rStart;
    let startCancel = startCancelParam ? startCancelParam : defStart;
    let endCancel = endCancelParam ? endCancelParam : defStart;
    return swal({
      title: "Vapauta vieraspaikaksi",
      text:
        "Vapauta kausipaikka annetulla aikavälillä varattavaksi vieraspaikkana.",
      icon: "info",
      buttons: {
        cancel: t("cancel-button"),
        ok: "Ok"
      },
      content: (
        <div>
          <DateRangeSelector
            pastDateAllowed={false}
            sameDayAllowed={true}
            location={location}
            initialStart={startCancel}
            initialEnd={endCancel}
            startDateCallback={v => {
              const diffToEnd = endCancel.diff(v.startOf("day"), "days");
              if (diffToEnd < 0) {
                endCancel = moment(v);
              }
              startCancel = v.startOf("day");
              setStartEndCallback(startCancel, endCancel);
            }}
            endDateCallback={v => {
              const diffToStart = startCancel.diff(v.startOf("day"), "days");
              if (diffToStart > 0) {
                startCancel = moment(v);
              }
              endCancel = v;
              setStartEndCallback(startCancel, endCancel);
            }}
          ></DateRangeSelector>
        </div>
      )
    }).then(willDo => {
      const from = startCancel;
      const until = moment(endCancel).add(1, "days");
      if (willDo === "ok") {
        const url = isAdmin
          ? `parkingAdmin/reservation/${reservation.id}?from=${from.format("YYYY-MM-DD")}&until=${until.format("YYYY-MM-DD")}`
          : `profile/order/${reservation.id}?from=${from.format("YYYY-MM-DD")}&until=${until.format("YYYY-MM-DD")}`;
        return genericDelete(l.language, url).then(() => afterDeleteCallback(() => swal.close()));
      } else {
        setStartEndCallback(undefined, undefined);
      }
    });
  };