import React from "react";
import PropTypes from "prop-types";
import Checkout from "./Checkout";
import BookkaaHeader from "../BookkaaHeader";
import LoginForm from "../LoginForm";
import Profile from "../Profile";
import BkContentArea from "../layout/BkContentArea";
import { CircleLoader } from "react-spinners";
import { genericPost } from "../../services/axiosApi";
import BkCustomerFooter from "../CustomerFooter";

class PayReservation extends React.Component {
  constructor(props) {
    super(props);
    if (props.orderNumber && !props.orderForPayment) {
      console.log(
        `Checking order ${props.orderNumber} with language ${props.location.language}`
      );
      props.retrieveOrder(props.location.language, props.orderNumber);
    }
    this.closeShoppingCart = this.closeShoppingCart.bind(this);
    this.doRegister = this.doRegister.bind(this);
    this.setPasswordForRegistration = this.setPasswordForRegistration.bind(
      this
    );
  }
  closeShoppingCart() {
    console.log("Closing cart");
    let promise = undefined;
    if (
      this.props.orderForPayment &&
      this.props.orderForPayment.cartType === "SELF"
    ) {
      promise = genericPost(
        this.props.location.language,
        "boatparking/cancel/" + this.props.orderNumber,
        {},
        "booking-web"
      );
    } else {
      promise = Promise.resolve();
    }

    promise.then(() => {
      if (this.props.orderForPayment && this.props.orderForPayment.returnUrl) {
        window.location.href = this.props.orderForPayment.returnUrl;
      } else if (
        this.props.orderReferrer &&
        this.props.orderReferrer.orderNumber === this.props.orderNumber
      ) {
        if (this.props.orderReferrer.referrer.startsWith("http")) {
          window.location.href = this.props.orderReferrer.referrer;
        } else if (this.props.orderReferrer.referrer === "SEASONCLIENT") {
          window.location.href =
            "/area/" +
            this.props.location.language +
            "/" +
            this.props.location.area +
            "/reserve";
        } else if (this.props.orderReferrer.referrer === "PROFILE") {
          window.location.href = "/profile/" + this.props.location.language;
        } else {
          this.props.history.goBack();
        }
      } else if (this.props.history) {
        this.props.history.goBack();
      } else {
        window.history.go(-1);
      }
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.loginProps.jwt !== prevProps.loginProps.jwt ||
      (this.props.orderNumber &&
        !this.props.orderForPayment &&
        prevProps.orderNumber !== this.props.orderNumber &&
        prevProps.orderForPayment !== this.props.orderForPayment)
    ) {
      this.props.checkLoggedIn(
        this.props.jwt,
        "booking-web",
        this.props.location.language,
        "reserve"
      );
      this.props.retrieveOrder(
        this.props.location.language,
        this.props.orderNumber
      );
    }
  }
  doRegister() {
    this.props.loginProps.doRegister({
      orderNumber: this.props.orderNumber,
      password: this.state.password
    });
  }
  setPasswordForRegistration(event) {
    this.setState({ password: event.target.value });
  }
  render() {
    const t = key => this.props.location.translate(this.props.location, key);

    let order = this.props.orderForPayment;
    let content = [];
    let spinner = false;
    if (order) {
      switch (order.status) {
        case "CAN_REGISTER":
        // eslint-disable-next-line no-fallthrough
        case "NEED_TO_LOGIN":
        // eslint-disable-next-line no-fallthrough
        case "LOGGED_IN":
          content.push(
            <Checkout
              {...this.props.orderForPayment}
              {...this.props}
              closeShoppingCart={this.closeShoppingCart}
            />
          );
          break;
        case "ALREADY_PAID":
          content.push(
            <Checkout
              paid={true}
              {...this.props.orderForPayment}
              {...this.props}
              closeShoppingCart={() => {}}
            />
          );
          break;
        default:
          content.push(<p>{order.statusText} </p>);
      }
    } else if (this.props.reservationError) {
      content.push(
        <div className="bk_errorMessage" style={{ display: "block" }}>
          {this.props.reservationError}
        </div>
      );
    } else {
      if (order) {
        console.log("Oder: " + JSON.stringify(order));
      } else {
        console.log("No order. Error: " + this.props.reservationError);
      }
      spinner = true;
    }
    if (spinner) {
      content.push(
        <center>
          <CircleLoader size="50" />
        </center>
      );
    }
    const areaName =
      this.props.orderForPayment && this.props.orderForPayment.area
        ? this.props.orderForPayment.area.name
        : "";
    return (
      <React.Fragment>
        <BookkaaHeader
          //disableLogin={!allowLogin}
          loginEmail={
            order && order.customer ? order.customer.email : undefined
          }
          otpLogin={order && order.cartType === "PAYMENT_LINK"}
          location={this.props.location}
          {...this.props.headerProps}
          headerSelectorContent={areaName}
        />
        <LoginForm location={this.props.location} {...this.props.loginProps} />
        <Profile location={this.props.location} {...this.props.profileProps}>
          <BkContentArea>{content}</BkContentArea>
        </Profile>
        <BkCustomerFooter
          harbours={
            this.props.frontPage ? this.props.frontPage.harbours : undefined
          }
          area={this.props.location.area}
          gdprTitle={t("gdprLink")}
        />
      </React.Fragment>
    );
  }
}
export default PayReservation;

PayReservation.propTypes = {
  ensureLoggedIn: PropTypes.func.isRequired,
  pay: PropTypes.func.isRequired,
  cancelPayment: PropTypes.func.isRequired,
  retrieveOrder: PropTypes.func.isRequired,
  orderForPayment: PropTypes.any,
  location: {
    ctx: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired
  }
};
