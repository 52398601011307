import React from "react";
import PropTypes from "prop-types";
import BkModifyButton from "../layout/BkModifyButton";
import LoginRequired from "../LoginRequired";
import LoginForm from "../LoginForm";
import BookkaaHeader from "../BookkaaHeader";
import BkContentArea from "../layout/BkContentArea";
import ImageMapper from "react-image-mapper";
import Modal from "react-modal/lib/components/Modal";
import { customStyles } from "../layout/ModalStyle";
import Customer from "./Customer";
import { Map } from "immutable";
import BkFooter from "../BookkaaFooter";
import DatePicker from "react-date-picker";
import moment from "moment/min/moment-with-locales";
import { ReservationsTable } from "./ReservationsTable";
import { ReservationModal } from "./ReservationModal";

class BoatParkAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.autoCompleteChange = this.autoCompleteChange.bind(this);
    this.autoCompleteSelect = this.autoCompleteSelect.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.update = this.update.bind(this);
    this.updateReport = this.updateReport.bind(this);
    this.openDetails = this.openDetails.bind(this);
    this.cancelReservation = this.cancelReservation.bind(this);
    this.clicked = this.clicked.bind(this);
    this.clickedOutside = this.clickedOutside.bind(this);
    this.openReservation = this.openReservation.bind(this);
    this.reserve = this.reserve.bind(this);

    this.state = {
      key: 1,
      onlyFree: false,
      customers: [],
      customersNr: 0,
      openedDetails: undefined,
      customer: Map(),
      reportFrom: moment()
        .clone()
        .subtract(1, "months"),
      reportUntil: moment().clone()
    };
  }
  imageDivRef = undefined;

  update() {
    this.props.getImageMap(
      this.props.location.language,
      this.props.location.area,
      null,
      "BOAT_PARKING"
    );
  }
  updateReport(from, until) {
    this.setState({
      reportFrom: from,
      reportUntil: until
    });
    this.props.boatParkReport(
      this.props.location.language,
      this.props.location.area,
      from,
      until
    );
  }

  componentShouldUpdate(nextProps) {}

  componentDidUpdate(prevProps) {
    if (
      this.props.jwt !== prevProps.jwt ||
      this.props.location.area !== prevProps.location.area
    ) {
      this.update();
    }
    if (
      !this.state.selectedArea &&
      this.props.imageMap &&
      this.props.imageMap.subareas &&
      Array.isArray(this.props.imageMap.subareas) &&
      this.props.imageMap.subareas[0].areas &&
      Array.isArray(this.props.imageMap.subareas[0].areas) &&
      this.props.imageMap.subareas[0].areas[0].code
    ) {
      this.setState({
        selectedArea: this.props.imageMap.subareas[0].areas[0].code
      });
    }
  }

  componentDidMount() {
    this.props.ensureLoggedIn(
      this.props.jwt,
      this.props.location.ctx,
      this.props.location.language,
      "parkingAdmin"
    );

    this.update();
    this.updateReport(this.state.reportFrom, this.state.reportUntil);
    this.interval = setInterval(() => this.update(), 30000);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    if (this.state.width !== window.innerWidth) {
      //      this.setState({ width: window.innerWidth });
    }
    if (this.imageDivRef) {
      const rect = this.imageDivRef.getBoundingClientRect();
      if (this.state.width !== rect.width) {
        this.setState({ width: rect.width });
      }
    } else {
      console.debug("No image div");
    }
  }
  cancelReservation(reservation) {
    console.debug(`Cancelling reservation: ${JSON.stringify(reservation)}`);
    this.props.cancelReservationById(
      this.props.location.area,
      this.props.location.language,
      reservation.reservationId
    );
  }
  openDetails(reservation) {
    this.setState({
      openedDetails: reservation
    });
  }
  render() {
    let location = this.props.location;
    const t = key => (imageMap.translations ? imageMap.translations[key] : key);

    let errorDiv = [];
    if (this.props.reservationError) {
      errorDiv.push(
        <div className="bk_errorMessage" style={{ display: "block" }}>
          {this.props.reservationError}
        </div>
      );
    }

    const l = this.props.location; // shortcut
    if (!l.area) {
      // TODO: render list of harbours
      console.log("Area not set");
      return null;
    }
    let errorMessage = "";
    if (this.state.errorMessage || this.props.paymentError) {
      console.log(
        `error: ${this.state.errorMessage}, paymentError: ${this.props.paymentError}`
      );
      errorMessage = this.state.errorMessage
        ? this.state.errorMessage
        : this.props.paymentError;
    }

    let minW = this.state.width;
    let maxW =
      this.props.imageMap && this.props.imageMap.subareas
        ? this.props.imageMap.subareas[0].imageWidth
        : undefined;

    if (minW < 350 && maxW > 350) {
      minW = 350;
    }

    var divider = maxW / minW;

    let maps = this.props.imageMap ? this.props.imageMap.subareas : undefined;
    const imageMap = this.props.imageMap ? this.props.imageMap : {};

    let areaName = "";
    if (!maps || maps.length === 0) {
      console.log("No image map found");
      maps = [];
    }
    if (this.props.imageMap && this.props.imageMap.area) {
      areaName = this.props.imageMap.area.name;
    }
    let imageMaps = [];
    let areaCodes = [];
    for (let i = 0; i < maps.length; i++) {
      for (let e = 0; e < maps[i].areas.length; e++) {
        const pl = maps[i].areas[e];
        areaCodes.push(
          <option
            selected={this.state.selectedArea === pl.code}
            value={pl.code}
          >
            {pl.code}
          </option>
        );
      }
      const map = {
        name: maps[i].name,
        imageWidth: maps[i].imageWidth,
        areas: []
      };
      maps[i].areas.forEach(e => {
        map.areas.push({
          ...e
        });
      });

      imageMaps.push(
        <div style={{ float: "left" }} ref={ref => (maps[i].ref = ref)}>
          <ImageMapper
            width={maps[i].imageWidth / divider}
            imgWidth={maps[i].imageWidth}
            src={`/booking-web/${l.language}/images/${l.area}_${maps[i].subarea}.png?h=${maps[i].hash}`}
            map={map}
            onLoad={() => this.load()}
            onClick={area => this.clicked(area)}
            onMouseEnter={area => this.enterArea(area, maps[i])}
            onMouseLeave={area => this.leaveArea(area)}
            onImageClick={evt => this.clickedOutside(evt)}
          />
        </div>
      );
    }

    let table = (
      <ReservationsTable
        t={t}
        reservations={
          this.props.imageMap ? this.props.imageMap.reservations : []
        }
        openDetails={this.openDetails}
        cancelReservation={this.cancelReservation}
        extended={false}
      />
    );

    let reportTable = (
      <ReservationsTable
        t={t}
        reservations={
          this.props.boatParkAdminReport
            ? this.props.boatParkAdminReport.reservations
            : []
        }
        openDetails={this.openDetails}
        extended={true}
      />
    );

    let reportTableSelector = (
      <React.Fragment>
        <span>
          <strong>Maksetut paikat</strong>
        </span>
        <span>&nbsp;</span> Alkupäivä:
        <DatePicker
          format="dd.MM.yyyy"
          clearIcon={null}
          value={this.state.reportFrom.toDate()}
          onChange={date => {
            this.updateReport(moment(date), this.state.reportUntil);
          }}
        />
        Loppupäivä:
        <DatePicker
          format="dd.MM.yyyy"
          clearIcon={null}
          value={this.state.reportUntil.toDate()}
          onChange={date => {
            this.updateReport(this.state.reportFrom, moment(date));
          }}
        />
      </React.Fragment>
    );

    const cmp = this;
    let d = this.state.openedDetails ? this.state.openedDetails : {};

    let content = (
      <React.Fragment>
        <BookkaaHeader
          location={location}
          {...this.props.headerProps}
          disableProfile={true}
          headerSelectorContent={areaName}
        />
        <LoginForm location={location} {...this.props.loginProps} />
        <LoginRequired location={location} {...this.props.loginProps} />

        <BkContentArea>
          <ReservationModal
            isOpen={this.state.openedDetails}
            onClose={() => cmp.setState({ openedDetails: undefined })}
            reservation={d}
            location={this.props.location}
          />
          <Modal
            isOpen={this.state.boatParkingWiz && this.props.imageMap}
            onRequestClose={() => cmp.setState({ boatParkingWiz: undefined })}
            style={customStyles}
            className="ui-widget-content"
            contentLabel=""
          >
            <div style={{ marginRight: "15px" }}>
              <Customer
                t={key =>
                  imageMap.translations ? imageMap.translations[key] : key
                }
                updateCustomer={(fieldName, value) =>
                  this.setState({
                    customer: this.state.customer.set(fieldName, value)
                  })
                }
                stateCustomer={this.state.customer}
              />
              <center>
                {imageMap.translations
                  ? imageMap.translations.days
                  : "Vuorokausia"}
              </center>
              <center>
                <input
                  type="number"
                  min="1"
                  placeholder="Vuorokausia"
                  onChange={e =>
                    this.setState({
                      days: e.target.value
                    })
                  }
                />
              </center>
              <center>
                <BkModifyButton
                  disabled={!this.state.days || !this.state.customer}
                  buttonTitle="Tallenna"
                  callback={this.reserve}
                />
              </center>
            </div>
          </Modal>

          <div className="bk_boatParkAdmin">
            <div className="bk_leftcolumn">
              <span>
                <strong>Parhaillaan varatut paikat</strong>
              </span>
              {table}
              <hr />
              <span>{reportTableSelector}</span>
              <br />
              {reportTable}
            </div>
            <div
              className="bk_rightcolumn"
              ref={divRef => (this.imageDivRef = divRef)}
            />
            <div className="bk_rightcolumn">
              <center>
                <select
                  className="areaSelector"
                  onChange={e =>
                    this.setState({ selectedArea: e.target.value })
                  }
                >
                  {areaCodes}
                </select>
                <BkModifyButton
                  disabled={!this.state.selectedArea}
                  buttonTitle="Varaa"
                  callback={() => this.openReservation(this.state.selectedArea)}
                />
              </center>
              <div className="pier-dynamic-image">{imageMaps}</div>
            </div>
          </div>
          <BkFooter />
        </BkContentArea>
      </React.Fragment>
    );

    return content;
  }

  openReservation(code) {
    this.setState({
      boatParkingWiz: {
        areaCode: this.props.imageMap.area.code,
        subareaCode: code,
        customer: {}
      }
    });
  }
  reserve() {
    const l = this.props.location;
    let w = this.state.boatParkingWiz;
    w.customer = this.state.customer;
    w.days = this.state.days;
    this.props.boatParkAdmin(l.area, l.language, w).then(() => {
      this.update();
      this.setState({ boatParkingWiz: undefined });
    });
  }
  clicked(area) {
    this.setState({ hoveredItem: area, selectedArea: area.code });
  }
  clickedOutside() {
    this.setState({ hoveredItem: undefined });
  }
  enterArea() {}
  leaveArea() {}

  load() {
    console.log("load() called");
  }
  autoCompleteChange(event) {
    let val = event.target.value;
    this.setState({ autoCompleteValue: val });
    this.props.searchCustomer(
      this.props.location.area,
      this.props.location.language,
      val,
      true
    );
  }
  autoCompleteSelect(item) {
    console.log(`Selected customer ${item}`);
    let c = JSON.parse(item);
    this.setState({
      selectedCustomer: c,
      autoCompleteValue: `${c.firstName} ${c.lastName} (${c.email})`
    });
  }
  // NOTE: copied from SeasonClient.js: should be in some MapUtility class
  getTipPosition(area, hoveredX, divider) {
    if (!area) return { top: 0, left: 0 };
    // Calculate centroid
    const y = area.coords[1] / divider;
    const x =
      area.shape === "circle"
        ? hoveredX + (area.coords[0] + area.coords[2] * 2) / divider
        : hoveredX + area.coords[2] / divider;
    return { top: `${y}px`, left: `${x}px`, width: "250px" };
  }
}

export default BoatParkAdmin;

BoatParkAdmin.propTypes = {
  ensureLoggedIn: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  location: {
    ctx: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    area: PropTypes.string.isRequired
  }
};
