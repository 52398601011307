import { Map } from "immutable";
import service from "../services/reporting";

export const PREFIX = "REPORT_";

const defaultState = Map({});

export const getPayouts = (language, from, until, sellerId, areaId) => {
  return {
    type: PREFIX + "PAYOUTS",
    payload: {
      promise: service.getPayouts(language, from, until, sellerId, areaId)
    }
  };
};
export default function reportsReducer(state = defaultState, action) {
  const { type, payload } = action;
  if (type.indexOf(PREFIX) != 0) {
    return state; // not related to this service
  }
  if (payload && payload.errorMessage) {
    return state.set("errorMessage", payload.errorMessage);
  } else {
    state = state.remove("errorMessage");
  }
  switch (type) {
    case PREFIX + "PAYOUTS_FULFILLED":
      return state.set("payouts", payload);
    default:
      return state;
  }
}
