import { connect } from "react-redux";
import App from "../App";
import {
  doLogin,
  otpLogin,
  doLogout,
  openLoginDialog,
  closeLogin,
  ensureLoggedIn,
  checkLoggedIn,
  openForgotPassword,
  confirmForgotPassword,
  openRegisterDialog,
  doRegister,
  LOGIN_VISIBLE,
  LOGIN_JWT,
  LOGIN_ERROR,
  LOGIN_CTX,
  LOGIN_USER,
  REGISTER_VISIBLE
} from "../ducks/login";
import {
  loadVallisaari,
  getVsReport,
  enterVsReport,
  getVisitors,
  getVisitorsNow,
  loadMinimumVisitors,
  enterVisitors,
  getEmailUsers,
  sendEmail
} from "../ducks/vallisaari";
import {
  getImageMap,
  reserveSeasonPlace,
  showShoppingCart,
  closeShoppingCart,
  pay,
  checkOrder,
  retrieveOrder,
  reserveForCustomer,
  registerByReservation,
  searchCustomer,
  getAvailableProducts,
  guardPatrolReserve,
  cancelReservation,
  cancelReservationById,
  markReservationPaidById,
  guestReservationCalendar,
  boatParkAdmin,
  updateReservation,
  boatParkReport,
  guestReservationReport,
  fetchProductDetails,
  storeProductDetails,
  getLogEntries,
  addLogEntry,
  storeReferrer
} from "../ducks/reservations";
import {
  getMenu,
  getTranslations,
  openProfile,
  closeProfile,
  initialize
} from "../ducks/menu";
import { getPlace, getAreas, getSellers } from "../ducks/place";
import { getPayouts } from "../ducks/reporting";
import { getDtColumns, getDtSettings } from "../ducks/datatable";
import { withRouter } from "react-router";
import { compose } from "recompose";

export default compose(
  withRouter,
  connect(
    state => ({
      lastLoginContext: state.login.get(LOGIN_CTX),
      loginDialogVisible: state.login.get(LOGIN_VISIBLE),
      loginCheckOngoing: state.login.get("loginCheckOngoing"),
      registerDialogVisible: state.login.get(REGISTER_VISIBLE),
      forgotPasswordLinkSent: state.login.get("forgotPasswordLinkSent"),
      forgotPassword: state.login.get("forgotPassword"),
      jwt: state.login.get(LOGIN_JWT),
      user: state.login.get(LOGIN_USER),
      loginData: state.login.get("loginData"),
      loginError: state.login.get(LOGIN_ERROR),
      menuJson: state.menu.get("menuJson"),
      translations: state.menu.get("transJson"),
      dtColumnsMap: state.datatable.get("columns"),
      dtSettingsMap: state.datatable.get("settings"),
      loginRequired: state.reservations.get("loginRequired"),
      imageMap: state.reservations.get("imageMap"),
      paymentData: state.reservations.get("paymentData"),
      paymentError: state.reservations.get("paymentError"),
      reservationError: state.reservations.get("errorMessage"),
      orderData: state.reservations.get("orderData"),
      customers: state.reservations.get("customers"),
      updatedCustomer: state.reservations.get("updatedCustomer"),
      orderForPayment: state.reservations.get("orderForPayment"),
      userProfile: state.menu.get("userProfile"),
      frontPage: state.menu.get("frontPage"),
      availableProducts: state.reservations.get("availableProducts"),
      guardPatrolReserved: state.reservations.get("guardPatrolReserved"),
      boatParkWiz: state.reservations.get("boatParkWiz"),
      guestCalendar: state.reservations.get("guestCalendar"),
      boatParkAdminData: state.reservations.get("boatParkAdminData"),
      updatedReservation: state.reservations.get("updatedReservation"),
      boatParkAdminReport: state.reservations.get("boatParkAdminReport"),
      reservationCalendar: state.reservations.get("reservationCalendar"),
      placeDetails: state.reservations.get("placeDetails"),
      reservationReport: state.reservations.get("reservationReport"),
      orderReferrer: state.reservations.get("orderReferrer"),
      // valli
      vallisaariData: state.vallisaari.get("vallisaariData"),
      vallisaariReport: state.vallisaari.get("vallisaariReport"),
      vallisaariReportCreated: state.vallisaari.get("vallisaariReportCreated"),
      realtimeVisitors: state.vallisaari.get("realtimeVisitors"),
      vallisaariVisitors: state.vallisaari.get("vallisaariVisitors"),
      vallisaariVisitorsAt: state.vallisaari.get("vallisaariVisitorsAt"),
      vallisaariError: state.vallisaari.get("errorMessage"),
      emailUsers: state.vallisaari.get("emailUsers"),
      // basic data
      place: state.place.get("place"),
      areas: state.place.get("areas"),
      sellers: state.place.get("sellers"),
      // reporting
      payouts: state.reporting.get("payouts"),
      // busy indicators
      loginBusy: state.login.get("busy"),
      reserveBusy: state.reservations.get("busy"),
      patrolLog: state.reservations.get("patrolLog")
      // debugState: outputState(state)
    }),
    {
      doLogin,
      otpLogin,
      closeLogin,
      doLogout,
      ensureLoggedIn,
      checkLoggedIn,
      doRegister,
      openLoginDialog,
      openRegisterDialog,
      openForgotPassword,
      confirmForgotPassword,
      getMenu,
      getTranslations,
      getDtColumns,
      getDtSettings,
      getImageMap,
      reserveSeasonPlace,
      showShoppingCart,
      closeShoppingCart,
      openProfile,
      closeProfile,
      pay,
      checkOrder,
      retrieveOrder,
      reserveForCustomer,
      registerByReservation,
      searchCustomer,
      getAvailableProducts,
      guardPatrolReserve,
      cancelReservation,
      cancelReservationById,
      markReservationPaidById,
      guestReservationCalendar,
      boatParkAdmin,
      updateReservation,
      boatParkReport,
      guestReservationReport,
      fetchProductDetails,
      storeProductDetails,
      getLogEntries,
      addLogEntry,
      initialize,
      loadVallisaari,
      getVsReport,
      enterVsReport,
      getVisitorsNow,
      getVisitors,
      getEmailUsers,
      sendEmail,
      loadMinimumVisitors,
      enterVisitors,
      getPlace,
      getAreas,
      getSellers,
      getPayouts,
      storeReferrer
    }
  )
)(App);
