import React from "react";
import BookkaaHeader from "../BookkaaHeader";
import TableAndForm from "../layout/TableAndForm";
import LoginForm from "../LoginForm";
import LoginRequired from "../LoginRequired";
import Profile from "../Profile";
import AreaHeader from "./AreaHeader";
import OkErrorMessage from "../layout/OkErrorMessage";
import BkModifyButton, { getButtons } from "../layout/BkModifyButton";
import DatePicker from "react-date-picker";
import { genericPost } from "../../services/axiosApi";
import moment from "moment";
import Datatable from "../datatable/Datatable";

class Seasons extends React.Component {
  constructor(props) {
    super(props);
    this.state = { sellerSeasons: false, reloadTable: undefined }
    this.selectCallback = this.selectCallback.bind(this);
    this.multiSelectCallback = this.multiSelectCallback.bind(this);
    this.updateSeasons = this.updateSeasons.bind(this);
  }

  selectCallback(item) {
    this.setState({ selectedItem: item, selectedItems: undefined });
  }

  multiSelectCallback(item) {
    if (Array.isArray(item)) {
      this.setState({ selectedItems: item, selectedItem: undefined });
    } else {
      this.setState({ selectedItems: [item], selectedItem: undefined });
    }
  }

  updateSeasons() {
    const l = this.props.location;
    const data = {
      from: this.state.from,
      until: this.state.until,
      seasonIds: this.state.updateSeasons.map(s => s.id)
    };
    genericPost(l.language, `a${l.area}/sellerSeasons/updateDates`, data, "booking-web").then((v) => {
      this.setState({ okMessage: v.okMessage, errorMessage: v.errorMessage, updateSeasons: undefined });
    })
  }

  render() {
    const location = { ...this.props.location };
    const t = key => location.translate(location, key);
    if (this.state.sellerSeasons) {
      location.endpoint = "sellerSeasons"
    } else {
      location.endpoint = "seasons";
    }

    let beforeForm = [];
    const CLOSE_BUTTON = {
      supportsMulti: true,
      callback: this.props.closeCb,
      title: "Sulje"
    };

    let listButtonArray = [];
    if (this.props.closeCb) {
      listButtonArray.push(CLOSE_BUTTON);
    }
    if (this.state.sellerSeasons) {
      listButtonArray.push({
        supportsMulti: true,
        title: "Muuta kausia",
        disabledCallback: () => !this.state.selectedItems,
        callback: () => this.setState({ updateSeasons: this.state.selectedItems, selectedItems: undefined })
      });
    }
    listButtonArray.push({
      supportsMulti: true,
      title: this.state.sellerSeasons ? "Sataman kaudet" : "Kaikki kaudet",
      callback: () => this.setState({ sellerSeasons: !this.state.sellerSeasons })
    });

    return (
      <React.Fragment>
        <LoginRequired {...this.props.location} {...this.props.loginProps} />
        <BookkaaHeader location={location} {...this.props.headerProps} />
        <LoginForm location={location} {...this.props.loginProps} />
        <Profile location={location} {...this.props.profileProps}>
          <AreaHeader areaName={this.props.areaName} />
          <OkErrorMessage okMessage={this.state.okMessage} errorMessage={this.state.errorMessage}
            clearOk={()=>this.setState({okMessage:undefined})} clearError={()=>this.setState({errorMessage:undefined})} />
          { this.state.updateSeasons && <React.Fragment>
            <table>
              <tr>
                <th>Kaudet alkaa</th><th>Kaudet päättyy</th>
              </tr>
              <tr>
                <td><DatePicker
                  format="dd.MM.yyyy"
                  clearIcon={null}
                  value={this.state.from ? this.state.from.toDate() : undefined}
                  onChange={date => {
                    this.setState({ from: moment(date) });
                  }}
                /></td>
                <td><DatePicker
                  format="dd.MM.yyyy"
                  clearIcon={null}
                  value={this.state.until ? this.state.until.toDate() : undefined}
                  onChange={date => {
                    this.setState({ until: moment(date) });
                  }}
                /></td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <BkModifyButton buttonTitle={t("cancel-button")} callback={() => this.setState({ updateSeasons: undefined })} />
                  <BkModifyButton buttonTitle={t("modify-button")} callback={this.updateSeasons} />
                </td>
              </tr>
            </table>  
          </React.Fragment>
          }{ !this.state.updateSeasons && this.state.sellerSeasons && <Datatable 
                {...this.props} 
                location={location} 
                selectCallback={this.multiSelectCallback}
                multiSelect={true}>
                  {getButtons(listButtonArray, this.state.data, this)}
                </Datatable>}
            { !this.state.updateSeasons && !this.state.sellerSeasons && <TableAndForm
            selectCallback={this.selectCallback}
            itemsBeforeForm={beforeForm}
            buttons={listButtonArray}
            formButtonArray={[]}
            {...this.props}
            location={location}
          />}          
        </Profile>
      </React.Fragment>
    );
  }
}
export default Seasons;
