import React from "react";
import BkModifyButton from "../layout/BkModifyButton";
import { genericGet, genericPost } from "../../services/axiosApi";
import { CircleLoader } from "react-spinners";
import BkContentArea from "../layout/BkContentArea";
import LanguageSelection from "../boatparking/LanguageSelection";
import OkErrorMessage from "../layout/OkErrorMessage";


class SelectOutlet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reference: props.reference,
            loading: false,
            selectedOutlet: undefined
        };
        this.retrieveOrder = this.retrieveOrder.bind(this);
        this.selectOutlet = this.selectOutlet.bind(this);
        this.retrieveOrder();
        const l = props.location;
        this.props.getTranslations(l.ctx, l.language, l.endpoint);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const l = nextProps.location;
        const p = this.props.location;
        if (
          p.area !== l.area ||
          p.language !== l.language ||
          nextProps.jwt !== this.props.jwt
        ) {
          this.props.getTranslations("booking-web", l.language, "outlet");
        }
        this.setState({ languageSelectionOpen: false });
    }

    selectOutlet() {
        this.setState({ loading: true });
        genericPost(this.props.location.language, "outlet/selectable/" + this.props.reference, this.state.selectedOutlet, 
            "booking-web", "application/json", 15000).then(ret => 
            this.setState({ 
                loading: false, 
                errorMessage: ret.errorMessage, 
                okMessage: ret.okMessage, 
                outlets: ret.okMessage ? undefined : this.state.outlets 
            })
        );
    }

    retrieveOrder() {
        if (this.state.loading) {
            console.log("Already loading...");
            return;
        }
        this.setState({ loading: true });
        genericGet(this.props.location.language, "outlet/selectable/" + this.props.reference, "booking-web", 15000).then(ret => 
            this.setState({ loading: false, outlets: ret.outlets ? ret.outlets : undefined, errorMessage: ret.errorMessage})
        );
    }

    render() {
        if (!this.state.outlets) {
            return <CircleLoader/>;
        }
        const divStyle = {
            fontSize: "xx-large",
            cursor: "pointer",
            margin: "2px",
            border: "2px solid",
            width: "200px",
        };
        const location = { ...this.props.location };
        const t = key => location.translate(location, key);
        const items = this.state.outlets.map(o => <div style={{...divStyle, backgroundColor: o == this.state.selectedOutlet ? "aquamarine" : "inherit"}}>
            <a style={{display:"block"}} onClick={() => this.setState({selectedOutlet: o})}>
                {o.outletIdentification}
            </a></div>);
        const reference = this.props.reference;
        return (<React.Fragment>
            <BkContentArea>
            <center>
                <div className="harbour-and-season-container">
                    <div className="bookkaaLogoHeader">
                        <LanguageSelection
                            language={this.props.location.language}
                            getLink={(lang) => `/outlet/${lang}/${reference}`}
                            open={this.state.languageSelectionOpen}
                            getTranslations={(lang) => console.log("Vaihdetaan kieli: " + lang)}
                            toggleOpen={() =>
                                this.setState({
                                    languageSelectionOpen: !this.state.languageSelectionOpen
                                })
                            }
                        />
                    </div>
                    <div className="harbour-title">
                        <center>{this.state.areaName}</center>
                    </div>
                    <OkErrorMessage okMessage={this.state.okMessage} errorMessage={this.state.errorMessage}
                        clearOk={()=>this.setState({okMessage:undefined})} clearError={()=>this.setState({errorMessage:undefined})} />
                    <p style={{fontSize: "x-large"}}>{t("selectOutlet.helpText")}</p>
                    {items}
                    <BkModifyButton disabled={!this.state.selectedOutlet} buttonTitle={t("button-select-outlet")}
                    callback={this.selectOutlet} />
                </div>
            </center>
            <div className="bk-guest-rental-footer">
                &copy; 2024 bookkaa.fi |&nbsp;
                <a
                href="http://www.satamapaikka.com/kayttoehdot2/"
                target="_blank"
                rel="noopener noreferrer"
                >
                {t("conditionsLink")}
                </a>
            </div>
            </BkContentArea>
        </React.Fragment>);
    }
}

const ElectricOutletItem = (props) => {
    return (
    <tr onClick={props.selectCallback}>
      <td>{props.outletIdentification}</td>
    </tr>);
  }
  

export default SelectOutlet;