import React from "react";
import BookkaaHeader from "../BookkaaHeader";
import LoginForm from "../LoginForm";
import LoginRequired from "../LoginRequired";
import Profile from "../Profile";
import { genericGet, genericPost } from "../../services/axiosApi";
import { CircleLoader } from "react-spinners";
import { AddonDetails } from "../places/ProductDetails";
import BkModifyButton from "../layout/BkModifyButton";

class SeasonAdditionals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      data: undefined,
      seasons: [],
      subareas: [],
      errorMessage: undefined
    };
    this.saveData = this.saveData.bind(this);
    this.loadData = this.loadData.bind(this);
    this.getSeasons = this.getSeasons.bind(this);
    this.getSubareas = this.getSubareas.bind(this);
    const l = props.location;
    props.getTranslations(l.ctx, l.language, l.endpoint);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const l = this.props.location;
    this.getSeasons();
    this.getSubareas();
    return genericGet(
      l.language,
      "/seasonAdmin/additional?area=" + l.area
    ).then(ret =>
      this.setState({ list: ret, data: undefined, editMode: false })
    );
  }

  saveData() {
    const location = this.props.location;
    return genericPost(
      location.language,
      "/seasonAdmin/additional?area=" + location.area,
      this.state.data
    ).then(ret => this.setState({ editMode: false, data: ret }));
  }

  getSeasons() {
    const language = this.props.location.language;
    const area = this.props.location.area;
    genericGet(language, `parkingAdmin/${area}/seasons/season`).then(r => {
      if (Array.isArray(r)) {
        r.forEach(s => (s.seasonId = s.id));
        this.setState({ seasons: r });
      } else {
        console.log("Failed to get seasons");
      }
    });
  }

  getSubareas() {
    const language = this.props.location.language;
    const area = this.props.location.area;
    genericGet(language, `subareas/codes/${area}`).then(r => {
      if (Array.isArray(r)) {
        this.setState({ subareas: r });
      } else {
        console.log("Failed to get subareas");
      }
    });
  }

  render() {
    const location = this.props.location;
    const t = key => location.translate(location, key);
    const content = [];

    if (this.state.data && this.state.seasons) {
      content.push(
        <center>
          <div className="bk_placeDetails">
            <AddonDetails
              data={this.state.data}
              editMode={this.state.editMode}
              location={this.props.location}
              seasons={this.state.seasons}
              subareas={this.state.subareas}
              cb={data => this.setState({ data: data })}
            />
            <br />
            {this.state.editMode && (
              <BkModifyButton
                buttonTitle={t("save-button")}
                callback={() => this.saveData()}
              />
            )}
            {!this.state.editMode && (
              <BkModifyButton
                buttonTitle={t("modify-button")}
                callback={() => this.setState({ editMode: true })}
              />
            )}
            <BkModifyButton
              buttonTitle={t("close-button")}
              callback={() => this.loadData()}
            />
          </div>
        </center>
      );
    } else if (this.state.list) {
      content.push(
        <SeasonAdditionalList
          openCallback={d => this.setState({ data: d })}
          t={t}
          products={this.state.list}
        />
      );
      content.push(<br />);
      content.push(
        <BkModifyButton
          buttonTitle={t("new-button")}
          callback={() => this.setState({ data: {} })}
        />
      );
    } else {
      content.push(<CircleLoader />);
    }

    return (
      <React.Fragment>
        <LoginRequired location={location} {...this.props.loginProps} />
        <BookkaaHeader
          location={location}
          {...this.props.headerProps}
          disableProfile={true}
        />
        <LoginForm location={location} {...this.props.loginProps} />
        <Profile location={location} {...this.props.profileProps}>
          {content}
        </Profile>
      </React.Fragment>
    );
  }
}

const SeasonAdditionalList = props => {
  const { openCallback, t, products } = props;
  let odd = false;

  const rows = [];
  rows.push(
    <tr>
      <th>{t("productCode")}</th>
      <th>{t("productName")}</th>
      <th>{t("productType")}</th>
      <th>{t("basePrice")}</th>
      <th>{t("vat")}</th>
      <th />
    </tr>
  );

  if (products && Array.isArray(products)) {
    products.forEach(p => {
      const cn = odd ? "td-odd" : "td-even";
      odd = !odd;

      rows.push(
        <tr>
          <td className={cn}>{p.code}</td>
          <td className={cn}>{p.description}</td>
          <td className={cn}>{t("ProductType." + p.type)}</td>
          <td className={cn}>{p.price}</td>
          <td className={cn}>{p.vatPercentage}</td>
          <td className={cn}>
            <a onClick={() => openCallback(p)}>{t("open-place-link")}</a>
          </td>
        </tr>
      );
    });
  }

  return <table className="bk-list">{rows}</table>;
};
export default SeasonAdditionals;
