import React from "react";
import PropTypes from "prop-types";
import { genericPost } from "../../services/axiosApi";
import { CircleLoader } from "react-spinners";
import moment from "moment";
import { SearchListControls } from "../layout/SearchListControls";

function mapKey(ctx, language, endpoint, file) {
  return ctx + "-" + language + "-" + endpoint + "-" + file;
}

class Datatable extends React.Component {
  constructor(props) {
    super(props);
    const loc = this.props.location;
    const endpoint = this.props.dtEndpoint
      ? this.props.dtEndpoint
      : this.props.location.endpoint;
    console.log(
      `Ctx: '${loc.ctx}' lang: '${loc.language}' ep: '${endpoint}' file: '${props.dataFile}'`
    );
    const { dtSettingsMap, dtColumnsMap, file } = this.props;
    const ctx = this.props.location.ctx;
    const language = this.props.location.language;
    const dtSettKey = mapKey(ctx, language, endpoint);
    const dtLang = dtSettingsMap ? dtSettingsMap.get(dtSettKey) : undefined;
    const dtKey = mapKey(ctx, language, endpoint, file);
    const dtCols = dtColumnsMap ? dtColumnsMap.get(dtKey) : undefined;
    this.state = {
      columnsKey: dtKey,
      settingsKey: dtSettKey,
      columns: dtCols,
      settings: dtLang,
      data: {},
      first: 0,
      last: 0,
      pageSize: 30,
      selectedItems: [],
      order: {
        column: 1,
        dir: "asc"
      }
    };
    if (this.props.onRef) {
      this.props.onRef(this);
    }
    this.reload = this.reload.bind(this);
  }

  reload(first, newPageSize, orderParam) {
    if (this.state.loading && this.state.loading.add(1, 'minutes').isAfter(moment())) {
      console.log("Reload: still loading");
      return;
    }
    const order = orderParam ? orderParam : this.state.order;
    const loc = this.props.location;
    const ep = this.props.dtEndpoint ? this.props.dtEndpoint : loc.endpoint;
    var url;
    if (!loc.ctx || !ep) {
      console.log("Context or endpoint not set!");
      return;
    }
    if (loc.area) {
      url = `a${loc.area}/${ep}/${this.props.dataFile}`;
    } else {
      url = `${ep}/${this.props.dataFile}`;
    }
    if (this.props.search) {
      url += "?search=" + this.props.search;
      console.debug("Added search parameter to URL: ", url);
    }

    const pageSize = newPageSize ? newPageSize : this.state.pageSize;

    if (this.state.columns && this.state.settings) {
      this.setState({ loading: moment(), order: order });
      const request = {
        columns: this.state.columns.map((v, _idx, _arr) => ({
          data: v.data,
          searchable: v.searchable
        })),
        start: first,
        length: pageSize,
        order: [ order ],
        search: {
          regex: false,
          value: this.state.globalSearch
        }
      };
      genericPost(loc.language, url, request, loc.ctx).then(ret => {
        if (ret.errorStatus === 403) {
          this.props.openLoginDialog();
          this.setState({ loading: false, pageSize: pageSize, selectedItems: [] });
        } else if (!ret.data) {
          this.setState({ loading: false, errorMessage: ret.errorMessage, pageSize: pageSize, selectedItems: [] });
        } else {
          this.setState({ response: ret, loading: false, first: first, last: first + ret.data.length, pageSize: pageSize, selectedItems: [] });
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { dtSettingsMap, dtColumnsMap, jwt } = this.props;

    const dtLang = dtSettingsMap
      ? dtSettingsMap.get(this.state.settingsKey)
      : undefined;
    const dtCols = dtColumnsMap
      ? dtColumnsMap.get(this.state.columnsKey)
      : undefined;

    let jwtChanged = jwt !== prevProps.jwt;

    if (!dtCols) {
      if (
        jwtChanged ||
        (jwt && !dtColumnsMap.get("error." + this.state.columnsKey))
      ) {
        console.log(
          "Columns not present, JWT present and no error; loading columns"
        );
        this.reloadColumns();
      }
    }
    if (!dtLang) {
      if (
        jwtChanged ||
        (jwt && !dtSettingsMap.get("error." + this.state.settingsKey))
      ) {
        console.log(
          "Settings not present, JWT present and no error; loading settings"
        );
        this.reloadSettings();
      }
    }

    if (dtLang && dtCols && (!this.state.columns || !this.state.settings)) {
      console.log("Setting state");
      this.setState({
        columns: dtCols,
        settings: dtLang,
        order: { column: 1, dir: "asc" } // TODO: first ordereable column...
      });
    } else if (
      this.state.columns &&
      this.state.settings &&
      (!prevState.columns || !prevState.settings)
    ) {
      console.log("Loading/creating table");
      this.reload(0);
    } else if (this.state.columns && this.state.settings) {
      if (!this.state.response || jwtChanged) {
        console.log("Updating because jwt changed or response object null");
        this.reload(0);
      } else if (this.props.reloadTable !== prevProps.reloadTable) {
        console.log("Updating because reloadTable prop changed");
        this.reload(0);
      } else if (this.props.search !== prevProps.search) {
        console.log("Updating because search prop changed");
        this.reload(0);
      } else {
        console.log("No update needed, settings and columns done");
      }
    } else {
      console.log(
        `Not setting state, settings: '${dtLang}' (key ${this.state.settingsKey}) cols: '${dtCols}' (key ${this.state.columnsKey}) state.columns: '${this.state.columns}' state.settings: '${this.state.settings}'`
      );
      this.reload(this.state.first);
    }
  }

  reloadSettings() {
    console.log("reload settings");
    const { getDtSettings, location, dtEndpoint } = this.props;
    const endpoint = dtEndpoint ? dtEndpoint : location.endpoint;

    if (!getDtSettings) {
      console.log("Datatable initializing functions not defined");
      return;
    } else if (!location || !endpoint || !location.ctx) {
      console.log("location.endpoint / location.ctx not defined");
      return;
    }
    getDtSettings(location.ctx, location.language, endpoint);
  }

  reloadColumns() {
    console.log("reload columns");
    const { getDtColumns, location, file, dtEndpoint } = this.props;
    const endpoint = dtEndpoint ? dtEndpoint : location.endpoint;
    if (!getDtColumns) {
      console.log("Datatable initializing functions not defined");
      return;
    } else if (!location || !endpoint || !location.ctx) {
      console.log("location.endpoint / location.ctx not defined");
      return;
    }
    getDtColumns(location.ctx, location.language, endpoint, file);
  }

  componentDidMount() {
    console.log("Datatable.componentDidMount");

    this.reloadSettings();
    this.reloadColumns();
  }

  selectCallback(item, add, deselect) {
    if (!this.props.multiSelect) {
      console.log("selecting");
      this.setState({ selectedItems: deselect ? [] : [item] }, () => {
        if (this.props.selectCallback) {
          console.log("calling select callback");
          this.props.selectCallback(deselect ? undefined : item)
        }
      });
    return;
    }
    let items = [];
    if (Array.isArray(item)) {
      items = item;
    } else if (item && add) {
      if (this.state.selectedItems) {
        let skip = false;
        this.state.selectedItems.forEach(i =>
          i === item ? (skip = true) : items.push(i)
        );
        if (!skip) {
          items.push(item);
        }
      } else {
        items.push(item);
      }
    } else if (item) {
      items.push(item);
    }
    this.setState({ selectedItems: items }, () => {
      if (this.props.selectCallback) {
        this.props.selectCallback(items)
      }
    });
  }

  renderData(data, column) {
    const value = data[column.data];

    if (column.type && column.type === "boolean") {
      return value
      ? <input type="checkbox" checked="checked" disabled="disabled" />
      : <input type="checkbox" disabled="disabled" />;
    } else {
      return <span>{value}</span>;
    }
  }

  render() {
    if (!this.props.location || !this.props.location.endpoint) {
      return null;
    }
    const t = key => this.props.location.translate(this.props.location, key);

    if (this.props.onRef) {
      this.props.onRef(this);
    }

    let loading = this.state.loading;
    if (!this.state.columns || !this.state.settings) {
      console.log("Datatable settings not loaded yet, cannot render");
      return <CircleLoader />;
    }
    let chld = loading ? [] : this.props.children;

    const header = (
      <tr>
        {this.state.columns.map((c, idx, _arr) => (
          <th
            className={"bt-header" + (c.searchable ? " bt-searchable" : " ")}
            onClick={c.orderable ? () => this.reload( this.state.first, this.state.last,
              {
                column: idx, 
                dir: (this.state.order.column !== idx || this.state.order.dir !== "asc") ? "asc" : "desc" 
              }
            ) : () => {}
            }>
              {c.orderable && <a href="#" className={"sort-by" + (this.state.order.column === idx ? " bt-order-" + this.state.order.dir : "")}>{c.title}</a>}
              {!c.orderable && <span>{c.title}</span>}
          </th>
        ))}
      </tr>
    );
    const prevCb = () =>
    this.reload(
      this.state.first < this.state.pageSize
        ? 0
        : this.state.first - this.state.pageSize
    );
    const nextCb = () => this.reload(this.state.last + 1);
    const pageCb = p => this.reload(this.state.pageSize * p);

    console.log(`First: ${this.state.first} Last: ${this.state.last} Total: ${this.state.response ? this.state.response.recordsFiltered : 0}` );

    const data =
      !this.state.response ? <CircleLoader /> : this.state.first === 0 && this.state.last === 0 ? (
        <td colSpan={this.state.columns.length}>{this.state.settings.emptyTable}</td>
      ) : (
        this.state.response.data.map((d, idx, _arr) => (
          <tr onClick={(e) => 
              this.selectCallback(d,  e.ctrlKey, this.state.selectedItems.indexOf(d) >= 0 && e.ctrlKey)}>
            {this.state.columns.map(c => (
              <td className={(idx % 2 === 0 ? "td-even" : "td-odd") + (this.state.selectedItems.indexOf(d) >= 0 ? " selected" : "")}>{this.renderData(d, c)}</td>
            ))}
          </tr>
        ))
      );
    const footer = (this.state.first === 0 && this.state.last < 20) ? "" : <tr><td colSpan={this.state.columns.length}>
      <center>
        <span>
            {t("show")}
            &nbsp;
            <select
              onChange={e => {
                this.reload(0, e.target.value);
              }}
            >
              {[20, 30, 50, 100, 200, 500].map(n => (
                // eslint-disable-next-line eqeqeq
                <option value={n} selected={this.state.pageSize == n}>
                  {n}
                </option>
              ))}
            </select>
            &nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        <SearchListControls             
            pageSize={this.state.pageSize}
            first={this.state.first}
            last={this.state.last}
            total={this.state.response ? this.state.response.recordsFiltered : 0}
            location={this.props.location}
            prevCb={prevCb}
            nextCb={nextCb}
            pageCb={pageCb} />
        </center>
      </td></tr>;

    return (
      <React.Fragment>
        <div className="bk-list">
          <table className="display">
            <tr>
              <td colspan={this.state.columns.length} style={{textAlign: "right"}}>
                <input onChange={(e) => {
                      clearTimeout(this.timer);
                      this.setState({ globalSearch: e.target.value })
                      this.timer = setTimeout(() => this.reload(0), 500);
                    }} value={this.state.globalSearch}
                  placeholder={t("search")}/>
              </td>
            </tr>
            {header}
            {data}
            {footer}
          </table>
        </div>
        {chld}
      </React.Fragment>
    );
  }
  /*
  renderCheckbox() {
    return function(data, type, row, meta) {
      if (type === "display") {
        return data
          ? '<input type="checkbox" checked=checked disabled=disabled />'
          : '<input type="checkbox" disabled=disabled/>';
      }

      // Search, order and type can use the original data
      return data;
    };
  }
  renderNumber() {
    return function(data, type, row, meta) {
      if (type === "display") {
        return data ? '<div class="bk-number-field">' + data + "</div>" : "";
      }
      // Search, order and type can use the original data
      return data;
    };
  }*/
}

Datatable.propTypes = {
  location: {
    ctx: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired
  },
  dataFile: PropTypes.string,
  onRef: PropTypes.func,
  search: PropTypes.array,
  dtEndpoint: PropTypes.string, // overrides location.endpoint for Datatable backend calls
  dtSettingsMap: PropTypes.instanceOf(Map).isRequired,
  dtColumnsMap: PropTypes.instanceOf(Map).isRequired,
  getDtColumns: PropTypes.func.isRequired,
  getDtSettings: PropTypes.func.isRequired,
  openLoginDialog: PropTypes.func.isRequired
};
Datatable.defaultProps = {
  dataFile: "datatable"
};

export default Datatable;
