import { Map } from "immutable";
import menuService from "../services/menu";

export const MENU_JSON = "menuJson";
export const TRANSLATIONS_JSON = "transJson";

const defaultState = Map({
  menuJson: undefined
});

export const initialize = language => {
  return {
    type: "GET_INIT_DATA",
    payload: {
      promise: menuService.frontPage(language)
    }
  };
};

export const getMenu = (ctx, language, serviceEndpoint) => {
  return {
    type: "GET_MENU_JSON",
    payload: {
      promise: menuService.menuPromise(ctx, language, serviceEndpoint)
    }
  };
};
export const getTranslations = (ctx, language, endpoint) => {
  return {
    type: "GET_TRANS_JSON",
    payload: {
      promise: menuService.transPromise(ctx, language, endpoint)
    }
  };
};
export const openProfile = language => {
  return {
    type: "OPEN_PROFILE",
    payload: {
      promise: menuService.myProfilePromise(language)
    }
  };
};

export const closeProfile = language => {
  return {
    type: "CLOSE_PROFILE"
  };
};

export default function menuReducer(state = defaultState, action) {
  const { type, payload } = action;
  switch (type) {
    case "GET_INIT_DATA_FULFILLED":
      return state.set("frontPage", payload);
    case "GET_MENU_JSON":
      return state;
    case "GET_TRANS_JSON_FULFILLED":
      return state.set(TRANSLATIONS_JSON, payload);
    case "GET_MENU_JSON_FULFILLED":
      return state.set(MENU_JSON, payload.menuJson);
    case "OPEN_PROFILE_FULFILLED":
      console.log("Fulfilled open");
      if (payload.userProfile) {
        return state.remove("userProfile").set("userProfile", payload.userProfile);
      } else {
        console.log("No profile in payload!");
      }
      return state;
    case "CLOSE_PROFILE":
      return state.remove("userProfile");
    case "OPEN_PROFILE_PENDING":
      console.log("Initiated open");
    default:
      return state;
  }
}
