import React from "react";
import Modal from "react-modal";
import Datatable from "../components/datatable/Datatable";
import AlpacaForm from "../components/alpaca/Form";
import BkModifyButton from "../components/layout/BkModifyButton";
import BkContentArea from "../components/layout/BkContentArea";
import { customStyles } from "../components/layout/ModalStyle";
import BookkaaHeader from "../components/BookkaaHeader";
import axiosApi from "../services/axiosApi";

class ServiceProviderAdmin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      showExpired: false,
      dataUnhandled: null,
      modalIsOpen: false,
      updateModalIsOpen: false
    };
    // without this we don't have the right "this" reference in the callback
    this.reloadData = this.reloadData.bind(this);
    this.setSelectedObject = this.setSelectedObject.bind(this);
    this.setUnhandledObject = this.setUnhandledObject.bind(this);
    this.closeUpdateModal = this.closeUpdateModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveData = this.saveData.bind(this);
    this.accept = this.accept.bind(this);
    this.reject = this.reject.bind(this);

    if (this.props.jwt) {
      this.reloadData();
    }
  }

  componentDidMount() {
    this.props.ensureLoggedIn(
      this.props.jwt,
      this.props.location.ctx,
      this.props.location.language,
      "service"
    );

    this.props.getVisitorsNow(this.props.location);

    /*this.interval = setInterval(
      () => this.props.getVisitorsNow(this.props.location),
      60000
    );*/
  }

  reloadData() {
    console.log("Loading data");
    this.props.loadVallisaari(this.props.location);
    this.props.getVisitorsNow(this.props.location);
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
    this.props.getVisitorsNow(this.props.location);
  }

  closeUpdateModal() {
    this.setState({ updateModalIsOpen: false });
    this.props.getVisitorsNow(this.props.location);
  }

  render() {
    console.log("Include: " + this.state.includeExpired);
    const msgs = [];
    if (this.state.errorMessage) {
      msgs.push(
        <div className="bk_errorMessage" style={{ display: "block" }}>
          {this.state.errorMessage}
        </div>
      );
    } else if (this.state.message) {
      msgs.push(
        <div className="bk_okMessage" style={{ display: "block" }}>
          {this.state.message}
        </div>
      );
    }

    const loc = this.props.location;
    const t = k => loc.translate(loc, k);
    const atTheIsland =
      this.props.realtimeVisitors !== undefined
        ? `${t("presentNowHeader")}: ${this.props.realtimeVisitors}`
        : undefined;
    return (
      <React.Fragment>
        <BookkaaHeader
          {...this.props.headerProps}
          location={this.props.location}
          disableProfile={true}
        />
        <BkContentArea>
          <div className="bk-standOutDiv">{atTheIsland}</div>
          <br />
          <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.closeModal}
            style={customStyles}
            contentLabel={t("serviceProviderDetailsTitle")}
          >
            {msgs}
            <AlpacaForm
              ctx="booking-web"
              language={loc.language}
              endpoint="application"
              data={this.state.dataUnhandled}
            />
            <br />
            <BkModifyButton
              {...this.props}
              callback={() => this.accept()}
              buttonTitle={t("button.approve")}
              disabled={this.state.dataUnhandled == null}
            />
            <BkModifyButton
              {...this.props}
              callback={() => this.reject()}
              buttonTitle={t("button.reject")}
              disabled={this.state.dataUnhandled == null}
            />
            <BkModifyButton
              {...this.props}
              callback={() => this.closeModal()}
              buttonTitle={t("close-button")}
            />
            <br />
          </Modal>
          {msgs}
          <div className="header">{t("bk-unapproved-applications-header")}</div>
          <Datatable
            location={this.props.location}
            {...this.props.dtProps}
            dataFile="unhandledDatatable"
            jwt={this.props.jwt}
            selectCallback={this.setUnhandledObject}
          />
          <Modal
            isOpen={this.state.updateModalIsOpen}
            onRequestClose={this.closeUpdateModal}
            style={customStyles}
            contentLabel={t("serviceProviderDetailsTitle")}
          >
            {msgs}
            <AlpacaForm
              ctx="booking-web"
              language={loc.language}
              endpoint="application"
              data={this.state.data}
            />
            <BkModifyButton
              {...this.props}
              buttonTitle={t("close-button")}
              callback={this.closeUpdateModal}
            />
          </Modal>
          <br />
          <div className="header">{t("bk-approved-applications-header")}</div>
          <input
            type="checkbox"
            checked={this.state.includeExpired}
            onChange={() =>
              this.setState({ includeExpired: !this.state.includeExpired })
            }
          />
          <span>{t("includeExpiredCB")}</span>
          <Datatable
            location={this.props.location}
            {...this.props.dtProps}
            jwt={this.props.jwt}
            selectCallback={this.setSelectedObject}
            search={
              this.state.includeExpired ? "includeExpired=true" : undefined
            }
          />
        </BkContentArea>
      </React.Fragment>
    );
  }
  accept() {
    if (!this.state.dataUnhandled) {
      console.error("No data to save!");
    }
    const ep = `/booking-web/${this.props.location.language}/service/approve`;
    axiosApi()
      .post(ep, this.state.dataUnhandled, {
        withCredentials: true
      })
      .then(ret => {
        if (ret.data && ret.data.message) {
          this.setState({
            modalIsOpen: false,
            message: ret.data.message,
            errorMessage: undefined
          });
        } else if (ret.data && ret.data.errorMessage) {
          this.setState({ errorMessage: ret.errorMessage });
        }
        this.reloadData();
      });
  }
  reject() {
    const ep = `/booking-web/${this.props.location.language}/service/reject`;
    axiosApi()
      .post(ep, this.state.dataUnhandled, {
        withCredentials: true
      })
      .then(ret => {
        if (ret.data && ret.data.message) {
          this.setState({
            modalIsOpen: false,
            message: ret.data.message,
            errorMessage: undefined
          });
        } else if (ret.data && ret.data.errorMessage) {
          this.setState({ errorMessage: ret.errorMessage });
        }
        this.reloadData();
      });
  }
  saveData() {}
  addData(obj) {}
  setSelectedObject(obj) {
    console.log(
      "Setting selected object: " +
        JSON.stringify(obj) +
        ", current: " +
        JSON.stringify(this.state.data)
    );
    this.setState(
      {
        data: obj,
        updateModalIsOpen: true,
        message: undefined,
        errorMessage: undefined
      },
      function() {
        console.log("State changed: " + JSON.stringify(this.state.data));
      }
    );
  }
  setUnhandledObject(obj) {
    console.log(
      "Setting selected unhandled object: " +
        JSON.stringify(obj) +
        ", current: " +
        JSON.stringify(this.state.dataUnhandled)
    );
    this.setState(
      {
        dataUnhandled: obj,
        modalIsOpen: true,
        message: undefined,
        errorMessage: undefined
      },
      function() {
        console.log(
          "State changed: " + JSON.stringify(this.state.dataUnhandled)
        );
      }
    );
  }
}
export default ServiceProviderAdmin;
