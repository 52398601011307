import React from "react";

const ReservationDetails = props => {
  const { reservation } = props;
  const uic = reservation;
  return (
    <React.Fragment>
      {uic.firstName} {uic.lastName}
      <br />
      {uic.email}
      <br />
      {uic.mobile}
      <br />
      {uic.boatName}{" "}
      {uic.boatTypeStr && (uic.boatName || uic.boatLength || uic.boatWidth) && (
        <React.Fragment>({uic.boatTypeStr}) </React.Fragment>
      )}
      {uic.boatLength && <React.Fragment>{uic.boatLength}</React.Fragment>}
      {uic.boatWidth && <React.Fragment>x{uic.boatWidth}</React.Fragment>}
      {uic.boatDepth && <React.Fragment>x{uic.boatDepth}</React.Fragment>}
      {(uic.boatLength || uic.boatWidth || uic.boatDepth) && (
        <React.Fragment> m</React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ReservationDetails;
