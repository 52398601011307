import ax from "./axiosApi";

const getColumns = (ctx, language = "fi", serviceEndpoint, file) => {
  let columnsFile = file ? file : "datatable/columns_gen.json";
  return ax()(`/${ctx}/${language}/${serviceEndpoint}/${columnsFile}`, {
    withCredentials: true
  })
    .then(ret => ret.data)
    .catch(function(error) {
      return {
        error: error
      };
    });
};

const getLanguage = (ctx, language = "fi", serviceEndpoint) => {
  return ax()
    .get(`/${ctx}/${language}/${serviceEndpoint}/datatable/language.json`, {
      withCredentials: true
    })
    .then(ret => {
      if (ret.status === 200) {
        return ret.data;
      } else {
        let data = ret.data;
        data.error = ret;
        return data;
      }
    })
    .catch(function(error) {
      return {
        error: error
      };
    });
};

export default {
  getColumns,
  getLanguage
};
