import React from "react";
import ReactDOM from "react-dom";
import alpacaService from "../../services/alpaca";
import jQuery from "jquery";
import PropTypes from "prop-types";
import { shallowEqual } from "recompose";
import "alpaca/dist/alpaca/jqueryui/alpaca.css";
import "jquery-ui/themes/base/core.css";
import "jquery-ui/themes/base/selectable.css";
import "jquery-ui";

window.$ = window.jQuery = jQuery;
const $ = jQuery;
//require("jqueryui");
$.alpaca = require("alpaca/dist/alpaca/jqueryui/alpaca");

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ctx: props.ctx,
      settings: {},
      componentRef: null,
      errorMsg: null,
      rendered: false
    };
    this.setAlpacaSettings = this.setAlpacaSettings.bind(this);
    this.refreshSchema = this.refreshSchema.bind(this);
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }
  refreshSchema() {
    let el = ReactDOM.findDOMNode(this.refs.alpacaForm);
    if (el) {
      let alp = $(el).alpaca("get");
      if (alp) {
        alp.refresh();
        console.log("refreshed schema");
      }
    }
  }
  getData() {
    let el = ReactDOM.findDOMNode(this.refs.alpacaForm);
    if (el) {
      let alp = $(el).alpaca("get");
      if (alp) {
        let val = alp.getValue();
        console.log("getData returning a value"); //: " + JSON.stringify(val));
        return val;
      }
    }
    console.log("getData returning null :(");
    return null;
  }
  shouldComponentUpdate(nProps, nState) {
    let ret =
      nProps.filterSettings !== this.props.filterSettings ||
      nProps.doFilter !== this.props.doFilter ||
      nProps.jwt !== this.props.jwt ||
      nProps.ctx !== this.props.ctx ||
      nProps.language !== this.props.language ||
      nProps.endpoint !== this.props.endpoint ||
      nProps.errorMsg !== this.props.errorMsg ||
      !shallowEqual(nProps.data, this.props.data);
    console.log("Alpaca shouldComponentUpdate returning: " + ret);
    return ret;
  }
  componentDidMount() {
    let componentRef = this.refs.alpacaForm;
    let thisComponent = this;
    console.log(
      "didMount, ctx: " + this.props.ctx + ", endpoint: " + this.props.endpoint
    );

    let el = ReactDOM.findDOMNode(this.refs.alpacaForm);
    let data = this.props.data;
    if (
      this.props.alpacaSettings &&
      this.state.settings !== this.props.alpacaSettings
    ) {
      thisComponent.setAlpacaSettings(el, this.props.alpacaSettings, data);
      thisComponent.setState({
        componentRef: componentRef,
        settings: this.props.alpacaSettings
      });
    } else {
      alpacaService
        .getSettings(this.props.ctx, this.props.language, this.props.endpoint)
        .then(function(settings) {
          if (settings.error) {
            console.log("Problem getting settings");
            if (settings.error.status === 403) {
              thisComponent.props.ensureLoggedIn(
                thisComponent.props.jwt,
                thisComponent.props.ctx,
                thisComponent.props.language,
                thisComponent.props.endpoint
              );
            }
          } else {
            console.log(
              "Settings done, trying to init alpaca: " +
                thisComponent.props.ctx +
                "/" +
                thisComponent.props.endpoint
            );
          }
          thisComponent.setAlpacaSettings(el, settings, data);
          thisComponent.setState({
            componentRef: componentRef,
            settings: settings
          });
        });
    }
  }
  componentWillUnmount() {
    if (this.props.onRef) {
      this.props.onRef(undefined);
    }
    var el = this.refs.alpacaForm;
    $(el).alpaca("destroy");
    console.log("unmmount: " + this.ctx + "/" + this.endpoint);
  }
  render() {
    if (!this.state.settings || !this.state.settings.schema) {
      console.log("Render called but settings not ready");
    } else {
      // console.log("Rendering form: " + JSON.stringify(this.state.settings));
    }
    if (this.props.onRef) {
      this.props.onRef(this);
    }

    let el = ReactDOM.findDOMNode(this.refs.alpacaForm);
    let alp = $(el).alpaca("get");
    if (this.props.data == null && alp) {
      alp.setValue({});
      console.log("Setting empty data");
    } else if (alp) {
      alp.setValue(this.props.data);
      console.log("Setting data with id: " + this.props.data.id);
    }

    let errorMsg = this.state.errorMsg
      ? this.state.errorMsg
      : this.props.errorMsg;

    // TODO: dialog title from language settings
    return (
      <React.Fragment>
        <div className="ui-widget-content">
          <div className="bk_contentHeader" />
          <div
            className="bk_errorMessage"
            style={{ display: errorMsg ? "block" : "none" }}
          >
            {errorMsg}
          </div>
          <div title="">
            <form ref="alpacaForm" />
          </div>
        </div>
      </React.Fragment>
    );
  }

  setAlpacaSettings(el, settings, data) {
    if (this.props.filterSettings) {
      settings = this.props.filterSettings(settings);
      // console.log("Filtered Alpaca settings: " + JSON.stringify(settings));
    }
    let thisProps = this.props;
    let obj = this;
    let alData = {
      data: data,
      schema: settings.schema,
      options: settings.options,
      view: settings.view,
      error: function(err) {
        console.log("Error: " + JSON.stringify(err));
        if (err.data && err.data.errorMsg) {
          obj.setState({ errorMsg: err.data.errorMsg });
        }
      },
      postRender: function(field) {
        if (thisProps.alpacaPostRender) {
          thisProps.alpacaPostRender(field);
        }
      }
    };

    if (this.props.readonly) {
      alData.schema.readonly = this.props.readonly;
    }

    $(el).alpaca(alData);
  }
}

Form.propTypes = {
  ctx: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired,
  data: PropTypes.object,
  errorMsg: PropTypes.string,
  filterSettings: PropTypes.func
};
export default Form;
