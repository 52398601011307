import { genericPost } from "./axiosApi";

export const registerPromise = async (user, ctx, language, serviceEndpoint) => {
  let response = await genericPost("fi", "register/register", user, "um-web");
  if (response.errorMessage) {
    console.log("Login failed with status: " + response.errorStatus);
    return {
      ...response,
      loginError: response.errorMessage
    };
  } else if (!response.loginData) {
    console.log("Registration failed (no JWT) with status: " + response.errorStatus);
    return {
      ...response,
      loginError: response.errorMessage
    };
  }
  let jwt = response.loginData.JWT;
  console.log("JWT found: " + !!jwt);
  return genericPost(language, serviceEndpoint + "/loginDone", response.loginData, ctx).then(doneResponse => {
    console.log("Posted login");
    if (!doneResponse.errorStatus) {
      return {
        user: doneResponse,
        jwt: jwt
      };
    }
    return {
      errorStatus: doneResponse.errorStatus,
      jwt: jwt
    };
  });
};
