import React from "react";
import moment from "moment";
import BkContentArea from "../components/layout/BkContentArea";
import { CircleLoader } from "react-spinners";
import BookkaaHeader from "../components/BookkaaHeader";
import { ReportTable } from "./ReportTable";
import { DateSelector } from "../components/boatparking/DateSelector";

class VallisaariReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      visitors: undefined,
      startReport: 0,
      startVisitors: 0,
      pageSize: 30,
      pageSizeReport: 30,
      latestRequest: undefined,
      showVisitorDetails: false,
      reportStartDate: moment().startOf("year"),
      reportEndDate: moment(),
      reportProvider: undefined
    };
    this.reloadData = this.reloadData.bind(this);

    if (this.props.jwt) {
      this.reloadData(this.props.location.area);
    }
    if (
      !this.props.vallisaariVisitors &&
      this.props.vallisaariData &&
      !this.props.errorMessage
    ) {
      this.reloadData(true);
    }
  }

  componentDidUpdate(props, state) {
    if (
      state.startVisitors != this.state.startVisitors ||
      state.startReport != this.state.startReport
    ) {
      this.reloadData(true);
    }
  }

  render() {
    const t = k => this.props.location.translate(this.props.location, k);
    const atTheIsland =
      this.props.realtimeVisitors !== undefined
        ? `${t("presentNowHeader")}: ${this.props.realtimeVisitors}`
        : undefined;

    const content = [];
    if (this.props.errorMessage) {
      content.push(
        <div className="bk_errorMessage" style={{ display: "block" }}>
          {this.props.errorMessage}
        </div>
      );
    } else if (!this.props.vallisaariVisitors && this.props.location.sp) {
      this.reloadData(true);
    } else if (
      this.props.vallisaariVisitors &&
      this.props.vallisaariVisitors.reference == this.state.latestRequest &&
      this.props.vallisaariVisitors.reference != this.state.handledRequest
    ) {
      console.log("adding ok message");
      content.push(
        <div className="bk_okMessage" style={{ display: "block" }}>
          {t("reportSaved")}
        </div>
      );
      if (this.state.handledRequest !== this.state.latestRequest) {
        this.reloadData(true);
        this.setState({ handledRequest: this.state.latestRequest });
      }
    }
    if (!this.props.jwt) {
      content.push(<CircleLoader />);
    }

    if (atTheIsland) {
      content.push(
        <a
          style={{ cursor: "pointer" }}
          onClick={() => {
            this.setState({
              showVisitorDetails: !this.state.showVisitorDetails
            });
          }}
        >
          <span>
            {atTheIsland}{" "}
            {this.state.showVisitorDetails && <span>&#x23F6;</span>}
            {!this.state.showVisitorDetails && <span>&#x23F7;</span>}
          </span>
        </a>
      );
    }

    if (
      Array.isArray(this.props.vallisaariVisitors) &&
      this.state.showVisitorDetails
    ) {
      const rows = [];
      rows.push(
        <tr>
          <th>{t("day")}</th>
          <th>{t("time")}</th>
          <th>{t("arrivedCount")}</th>
          <th>{t("leftCount")}</th>
          <th>{t("businessName")}</th>
        </tr>
      );
      this.props.vallisaariVisitors.forEach((spData, idx) => {
        rows.push(
          <tr className={idx % 2 == 0 ? "td-even" : "td-odd"}>
            <td>{spData.day}</td>
            <td>{spData.time}</td>
            <td>{spData.arrived}</td>
            <td>{spData.left}</td>
            <td>{spData.businessName}</td>
          </tr>
        );
      });
      const prev = this.state.start > 0;
      const next =
        this.props.vallisaariVisitors &&
        this.props.vallisaariVisitors.length == this.state.pageSize;
      if (next || prev) {
        rows.push(
          <tr className="bk-datatable-navi">
            <td colspan="4">
              <center>
                {prev && (
                  <a
                    onClick={() =>
                      this.setState({
                        startVisitors:
                          this.state.startVisitors < this.state.pageSize
                            ? 0
                            : this.state.startVisitors - this.state.pageSize,
                        latestRequest: undefined
                      })
                    }
                  >
                    &#xab; {t("back")}
                  </a>
                )}
                <span style={{ width: "100%" }}>&nbsp;</span>
                {next && (
                  <a
                    onClick={() =>
                      this.setState({
                        start: this.state.startVisitors + this.state.pageSize,
                        latestRequest: undefined
                      })
                    }
                  >
                    {t("forward")} &#xbb;
                  </a>
                )}
              </center>
            </td>
          </tr>
        );
      }
      content.push(<table className="bk-parking-list">{rows}</table>);
    }
    content.push(
      <div
        className="harbour-and-season-container"
        style={{ fontSize: "1em", border: "dashed", padding: "20px" }}
      >
        <div style={{ layout: "flex" }}>
          <div>
            Organisaatio:
            <input
              className="smallInput"
              value={this.state.reportProvider}
              onChange={e => {
                this.setState({ reportProvider: e.target.value });
              }}
            ></input>
          </div>
          <div style={{ margin: "10px" }}>
            <span style={{ margin: "10px" }}> {t("date")}</span>
            <DateSelector
              language={this.props.location.language}
              value={this.state.reportStartDate}
              isEdit={this.state.editStartDate}
              enableEditCallback={() => this.setState({ editStartDate: true })}
              callback={v => {
                this.setState({
                  reportStartDate: v,
                  editStartDate: false
                });
              }}
            />
            <span style={{ margin: "10px" }}> {t("endDate")}</span>
            <DateSelector
              language={this.props.location.language}
              value={this.state.reportEndDate}
              isEdit={this.state.editEndDate}
              enableEditCallback={() => this.setState({ editEndDate: true })}
              callback={v => {
                this.setState({
                  reportEndDate: v,
                  editEndDate: false
                });
              }}
            />
          </div>
          <button onClick={() => this.reloadData(true)}>{t("search")}</button>
        </div>
      </div>
    );
    if (this.props.vallisaariReport) {
      content.push(
        <ReportTable
          t={t}
          showBusinessName={true}
          report={this.props.vallisaariReport}
          previousCb={
            this.state.startReport <= 0
              ? undefined
              : () =>
                  this.setState({
                    startReport:
                      this.state.startReport < this.state.pageSize
                        ? 0
                        : this.state.startReport - this.state.pageSize,
                    latestRequest: undefined
                  })
          }
          nextCb={
            !this.props.vallisaariReport ||
            this.props.vallisaariReport.length < this.state.pageSize
              ? undefined
              : () => {
                  this.setState({
                    startReport: this.state.startReport + this.state.pageSize,
                    latestRequest: undefined
                  });
                  console.log("Next");
                }
          }
        />
      );
    }

    return (
      <React.Fragment>
        <BookkaaHeader
          {...this.props.headerProps}
          location={this.props.location}
          disableProfile={true}
        />
        <BkContentArea maxWidth="1000px">{content}</BkContentArea>
      </React.Fragment>
    );
  }

  reloadData(loadReport) {
    console.log("Loading data");
    let location = { ...this.props.location };
    location.sp = undefined;
    this.props.getVisitorsNow(location);
    if (loadReport) {
      this.props.getVisitors(
        location,
        this.state.startVisitors,
        this.state.pageSizeVisitors
      );
      this.props.getVsReport(
        location,
        this.state.startReport,
        this.state.pageSizeReport,
        this.state.reportStartDate.format("YYYY-MM-DD"),
        this.state.reportEndDate.format("YYYY-MM-DD"),
        this.state.reportProvider
      );
    } else {
      this.props.loadVallisaari(this.props.location);
    }
  }

  componentDidMount() {
    this.props.ensureLoggedIn(
      this.props.jwt,
      this.props.location.ctx,
      this.props.location.language,
      "saari"
    );
  }
}
export default VallisaariReport;
