import { genericPost, genericGet, genericDelete } from "./axiosApi";

const getPlace = async (areaId, language, placeId) => {};

const getAreas = async language => {
  return genericGet(language, "areas");
};
const getSellers = async language => {
  return genericGet(language, "areas/sellers");
};

export default { getPlace, getAreas, getSellers };
