import ax from "./axiosApi";

const menuPromise = async (ctx, language, serviceEndpoint) => {
  try {
    let resource = `/${ctx}/${language}/${serviceEndpoint}/user_menu_re.json`;
    let response = await ax().get(resource, { withCredentials: true });

    if (response.status === 200) {
      console.log("Got menu: " + response.data);
      return {
        menuJson: response.data
      };
    } else {
      console.log("Menu request failed with status: " + response.status);
      return {
        errorStatus: response.status,
        errorMessage: response.data.errorMessage
      };
    }
  } catch (ex) {
    console.log("Failed to get menu: " + JSON.stringify(ex));
    if (ex.response && ex.response.data && ex.response.data.errorMessage) {
      return {
        errorStatus: ex.response.status,
        errorMessage: ex.response.data.errorMessage
      };
    }
  }
  console.log("Could not get menu");
  return {
    errorStatus: "error"
  };
};

const transPromise = async (ctx, language, endpoint) => {
  try {
    let resource = endpoint
      ? `/${ctx}/${language}/${endpoint}/translations_gen.json`
      : `/${ctx}/${language}/message/translations_gen.json`;
    let response = await ax().get(resource, { withCredentials: true });

    if (response.status === 200) {
      console.log("Got translations: " + response.data);
      return response.data;
    } else {
      console.log("Trans request failed with status: " + response.status);
      return {
        errorStatus: response.status,
        errorMessage: response.data.errorMessage
      };
    }
  } catch (ex) {
    console.log("Failed to get translations: " + JSON.stringify(ex));
    if (ex.response && ex.response.data && ex.response.data.errorMessage) {
      return {
        errorStatus: ex.response.status,
        errorMessage: ex.response.data.errorMessage
      };
    }
  }
  console.log("Could not get translations");
  return {
    errorStatus: "error"
  };
};

const myProfilePromise = async language => {
  try {
    let resource = `/booking-web/${language}/profile/userProfile.json`;
    let response = await ax().get(resource, { withCredentials: true });

    if (response.status === 200) {
      console.log("Got profile");
      return {
        userProfile: response.data
      };
    } else if (response.status !== 200) {
      console.log("User profile fetch failed with status: " + response.status);
      return {
        errorStatus: response.status
      };
    }
  } catch (ex) {
    console.log("Failed to get profile: " + JSON.stringify(ex));
    if (ex.response && ex.response.data && ex.response.data.errorMessage) {
      return {
        errorStatus: ex.response.status,
        errorMessage: ex.response.data.errorMessage
      };
    }
  }
  return {
    errorStatus: "error"
  };
};

const frontPage = async language => {
  try {
    let resource = `/booking-web/${language}/start/frontPage.json`;
    let response = await ax().get(resource, { withCredentials: true });

    if (response.status === 200) {
      console.log("Got profile: " + response.data);
      return response.data;
    } else if (response.status !== 200) {
      console.log("User profile fetch failed with status: " + response.status);
      return {
        errorStatus: response.status
      };
    }
  } catch (ex) {
    console.log("Failed to get profile: " + JSON.stringify(ex));
    if (ex.response && ex.response.data && ex.response.data.errorMessage) {
      return {
        errorStatus: ex.response.status,
        errorMessage: ex.response.data.errorMessage
      };
    }
  }
  return {
    errorStatus: "error"
  };
};

export default {
  menuPromise,
  transPromise,
  myProfilePromise,
  frontPage
};
