/* eslint-disable no-useless-constructor */
import React from "react";

class OkErrorMessage extends React.Component {

    render() {
        const {okMessage, errorMessage, clearOk, clearError} = this.props;


        if (errorMessage) {
            if (clearError) {
                clearTimeout(this.errorTimeout);
                this.errorTimeout = setTimeout(() => clearError(), 5000);
            }
            return (<div
                className="bk_errorMessage sticky"
                style={{ display: "block" }}
                onClick={() => clearError ? clearError() : {}}
                >
                {errorMessage}
                </div>
            );
        } else if (okMessage) {
            if (clearOk) {
                clearTimeout(this.okTimeout);
                this.okTimeout = setTimeout(() => clearOk(), 5000);
            }
            return (<div
                className="bk_okMessage sticky"
                style={{ display: "block" }}
                onClick={() => clearOk ? clearOk() : {}}
                >
                {okMessage}
                </div>
            );
        }
        return null;
    }

    componentWillUnmount() {
        clearTimeout(this.errorTimeout);
        clearTimeout(this.okTimeout);
        this.errorTimeout = undefined;
        this.okTimeout = undefined;
      }
        
}

export default OkErrorMessage;