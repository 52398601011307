import React from "react";
import { Payouts } from "./Payouts";
import Report from "./Report";

class PayoutsReport extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const l = this.props.location;
    const t = key => l.translate(l, key);

    return (
      <Report
        {...this.props}
        report="payouts"
        renderData={data => <Payouts t={t} payouts={data} />}
      />
    );
  }
}

export default PayoutsReport;
