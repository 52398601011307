import React from "react";

export const BkTab = props => {
  const { location, options, selected, callback, children } = props;
  const t = key => location.translate(location, key);

  const opts = [];
  options.forEach(element => {
    if (element === selected) {
      opts.push(<span className="bkTab_selected">{t("tab." + element)}</span>);
    } else {
      opts.push(
        <span onClick={() => callback(element)}>{t("tab." + element)}</span>
      );
    }
  });

  return (
    <React.Fragment>
      <div className="bkTab">
        <div className="bkTabOptions">{opts}</div>
        <div className="bkTabContent">{children}</div>
      </div>
    </React.Fragment>
  );
};

export default BkTab;
