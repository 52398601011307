import React from "react";
import PropTypes from "prop-types";
import BkModifyButton from "../components/layout/BkModifyButton";
import { CircleLoader } from "react-spinners";
import BookkaaHeader from "../components/BookkaaHeader";
import BkContentArea from "../components/layout/BkContentArea";
import moment from "moment";
import { DateSelector } from "../components/boatparking/DateSelector";
import { shallowEqual } from "recompose";
import { ReportTable } from "./ReportTable";

class VsEnterData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: moment(),
      endDate: moment(),
      visitors: undefined,
      description: undefined,
      start: 0,
      pageSize: 15,
      latestRequest: undefined
    };
    this.saveData = this.saveData.bind(this);
    this.reloadData = this.reloadData.bind(this);

    if (this.props.jwt) {
      this.reloadData(this.props.location.area);
    }
    if (
      !this.props.vallisaariReport &&
      this.props.vallisaariData &&
      !this.props.errorMessage
    ) {
      this.reloadData(true);
      this.props.loadMinimumVisitors(this.props.location, this.state.date);
    }
  }

  componentDidUpdate(props, state) {
    if (state.start != this.state.start) {
      this.reloadData(true);
    }
    if (
      this.props.location.sp &&
      this.props.vallisaariVisitorsAt === undefined
    ) {
      this.props.loadMinimumVisitors(this.props.location, this.state.date);
    }
    if (
      this.props.vallisaariVisitorsAt != this.state.minValue ||
      (this.state.minValue === undefined &&
        this.props.vallisaariVisitorsAt !== undefined)
    ) {
      this.setState({ minValue: this.props.vallisaariVisitorsAt });
    }
  }

  render() {
    const t = k => this.props.location.translate(this.props.location, k);
    const content = [];
    if (this.props.errorMessage) {
      content.push(
        <div className="bk_errorMessage" style={{ display: "block" }}>
          {this.props.errorMessage}
        </div>
      );
    } else if (!this.props.vallisaariReport && this.props.location.sp) {
      this.reloadData(true);
    } else if (
      this.props.vallisaariReportCreated &&
      this.props.vallisaariReportCreated.reference == this.state.latestRequest
    ) {
      console.log("adding ok message");
      content.push(
        <div className="bk_okMessage" style={{ display: "block" }}>
          {this.props.vallisaariReportCreated.okMessage
            ? this.props.vallisaariReportCreated.okMessage
            : t("reportSaved")}
        </div>
      );
      if (this.state.handledRequest !== this.state.latestRequest) {
        this.reloadData(true);
        this.setState({ handledRequest: this.state.latestRequest });
      }
    }
    if (!this.props.jwt) {
      content.push(<CircleLoader />);
    } else {
      content.push(
        <div
          className="harbour-and-season-container"
          style={{ fontSize: "1em", border: "dashed", padding: "20px" }}
        >
          <center>
            <h3>{t("visitors-content-header")}</h3>
          </center>
          <center>
            <span>
              *
              {t("visitorCount") +
                (this.state.minValue ? " min. " + this.state.minValue : "")}
            </span>
            <input
              className="small-input"
              placeholder={t("visitorCount.placeholder")}
              value={this.state.visitors}
              onChange={e => this.setState({ visitors: e.target.value })}
            />
            <span>*{t("date")}</span>
            <span style={{ padding: "10px" }}>
              <DateSelector
                language={this.props.location.language}
                value={this.state.date}
                isEdit={this.state.editDate}
                enableEditCallback={() => this.setState({ editDate: true })}
                callback={v => {
                  this.setState({
                    date: v,
                    endDate: this.state.endDate.subtract(
                      this.state.date.diff(v, "days"),
                      "days"
                    ),
                    editDate: false
                  });
                  this.props.loadMinimumVisitors(
                    this.props.location,
                    this.state.date
                  );
                }}
                isValidDate={v =>
                  moment().isAfter(v) &&
                  moment()
                    .subtract(2, "months")
                    .isBefore(v)
                }
              />
            </span>
            <span>{t("endDate")}</span>
            <span style={{ padding: "10px" }}>
              <DateSelector
                language={this.props.location.language}
                value={this.state.endDate}
                isEdit={this.state.editEndDate}
                enableEditCallback={() => this.setState({ editEndDate: true })}
                callback={v =>
                  this.setState({ endDate: v, editEndDate: false })
                }
                isValidDate={v =>
                  moment(v).isAfter(this.state.date) &&
                  moment()
                    .subtract(2, "months")
                    .isBefore(v)
                }
              />
            </span>
            <br />
            <span>{t("visitorDescription")}</span>
            <input
              placeholder={t("visitorDescription.placeholder")}
              value={this.state.description}
              onChange={e => this.setState({ description: e.target.value })}
            />
          </center>
          <center>
            <BkModifyButton
              buttonTitle={t("save-button")}
              disabled={this.state.visitors < this.state.minValue}
              callback={() => this.saveData()}
            />
          </center>
        </div>
      );
    }

    if (Array.isArray(this.props.vallisaariReport)) {
      this.appendReportTable(
        t,
        this.props.vallisaariReport,
        this.state.start <= 0
          ? undefined
          : () =>
              this.setState({
                start:
                  this.state.start < this.state.pageSize
                    ? 0
                    : this.state.start - this.state.pageSize,
                latestRequest: undefined
              }),
        !this.props.vallisaariReport ||
          this.props.vallisaariReport.length < this.state.pageSize
          ? undefined
          : () =>
              this.setState({
                start: this.state.start + this.state.pageSize,
                latestRequest: undefined
              }),
        content
      );
    }

    return (
      <React.Fragment>
        <BookkaaHeader
          {...this.props.headerProps}
          location={this.props.location}
          disableProfile={true}
        />
        <BkContentArea maxWidth="1000px">{content}</BkContentArea>
      </React.Fragment>
    );
  }

  appendReportTable(t, report, previousCb, nextCb, content) {
    content.push(
      <ReportTable
        t={t}
        report={report}
        previousCb={previousCb}
        nextCb={nextCb}
      />
    );
  }

  reloadData(loadReport) {
    console.log("Loading data");
    if (loadReport) {
      this.props.getVsReport(
        this.props.location,
        this.state.start,
        this.state.pageSize
      );
    } else {
      this.props.loadVallisaari(this.props.location);
    }
  }

  saveData() {
    const ref = Math.floor(Math.random() * 100000 + 1);
    this.setState({ latestRequest: ref });
    this.props.enterVsReport(this.props.location, {
      reference: ref,
      visitors: this.state.visitors,
      description: this.state.description,
      date: this.state.date.format("DD.MM.YYYY"),
      endDate: this.state.endDate.format("DD.MM.YYYY")
    });
  }

  componentDidMount() {
    this.props.ensureLoggedIn(
      this.props.jwt,
      this.props.location.ctx,
      this.props.location.language,
      "saari"
    );
  }
}
export default VsEnterData;

VsEnterData.propTypes = {
  vallisaariData: PropTypes.object,
  location: PropTypes.object.isRequired
};
