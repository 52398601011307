import { Map, Set } from "immutable";
import { registerPromise } from "../services/register";
import  * as loginService from "../services/login";

export const REGISTER_VISIBLE = "registerDialogVisible";
export const LOGIN_VISIBLE = "loginDialogVisible";
export const LOGIN_JWT = "jwt";
export const LOGIN_ERROR = "loginError";
export const LOGIN_USER = "user";
export const LOGIN_CTX = "lastLoginCtx";

export const BUSY_LOGIN = "login";
export const BUSY_REGISTER = "register";
export const BUSY_FORGOT = "forgot";

const defaultState = Map({
  jwt: undefined,
  user: undefined,
  loginDialogVisible: false,
  registerDialogVisible: false,
  busy: Set()
});

export const ensureLoggedIn = (
  jwt,
  ctx,
  language,
  serviceEndpoint,
  loginEmail,
  otpLogin
) => {
  console.log("ensureLoggedIn duck called");
  return {
    type: "ENSURE_LOGIN",
    payload: {
      promise: loginService.checkLoginPromise(jwt, ctx, language, serviceEndpoint, {
        loginEmail: loginEmail,
        otpLogin: otpLogin
      })
    }
  };
};

export const checkLoggedIn = (
  jwt,
  ctx,
  language,
  serviceEndpoint,
  loginEmail
) => {
  return {
    type: "CHECK_LOGIN",
    payload: {
      promise: loginService.checkLoginPromise(jwt, ctx, language, serviceEndpoint),
      loginEmail: loginEmail
    }
  };
};

export const closeLogin = () => {
  return {
    type: "CLOSE_LOGIN"
  };
};

export const doLogin = (email, password, ctx, language, serviceEndpoint) => {
  return {
    type: "DO_LOGIN",
    payload: {
      promise: loginService.loginPromise(email, password, ctx, language, serviceEndpoint)
    }
  };
};

export const doLogout = location => {
  return {
    type: "DO_LOGOUT",
    payload: {
      promise: loginService.logout(location.ctx, location.language, location.endpoint)
    }
  };
};

export const doRegister = (user, ctx, language, serviceEndpoint) => {
  return {
    type: "DO_REGISTER",
    payload: {
      promise: registerPromise(user, ctx, language, serviceEndpoint)
    }
  };
};

export const openForgotPassword = (currentUrl, language, email) => {
  if (currentUrl && language && email) {
    return {
      type: "FORGOT_PASSWORD",
      payload: {
        promise: loginService.forgotPassword(currentUrl, language, email)
      }
    };
  }
  return { type: "OPEN_FORGOT_PASSWORD" };
};

export const confirmForgotPassword = (language, email, pin, password) => {
  return {
    type: "CONFIRM_FORGOT_PASSWORD",
    payload: {
      promise: loginService.confirmForgotPassword(language, email, pin, password)
    }
  };
};

export const otpLogin = (language, email, pin, sessionId, ctx, serviceEndpoint) => {
  return {
    type: "OTP_LOGIN",
    payload: {
      promise: loginService.otpLogin(language, email, pin, sessionId, ctx, serviceEndpoint)
    }
  };
};

export const openRegisterDialog = email => {
  return { type: "OPEN_REGISTER", payload: { email: email } };
};

export const openLoginDialog = () => {
  return { type: "OPEN_LOGIN" };
};

export default function loginReducer(state = defaultState, action) {
  const { type, payload } = action;
  switch (type) {
    case "OPEN_LOGIN":
      return state
        .set(LOGIN_VISIBLE, true)
        .set("forgotPassword", false)
        .set("forgotPasswordLinkSent", false)
        .set(REGISTER_VISIBLE, false)
        .remove(LOGIN_ERROR);
    case "OPEN_REGISTER":
      let visible = true;
      if (payload && payload.email) {
        visible = payload.email;
      }
      return state
        .set(LOGIN_VISIBLE, false)
        .set("forgotPassword", false)
        .set(REGISTER_VISIBLE, visible)
        .remove(LOGIN_ERROR);
    case "OPEN_FORGOT_PASSWORD": // Clicked "forgot password", no email entered
      return state
        .set(REGISTER_VISIBLE, false)
        .set("forgotPassword", true)
        .set("forgotPasswordLinkSent", false)
        .remove(LOGIN_ERROR);
    case "DO_REGISTER_PENDING":
      return state.set("busy", state.get("busy").add(BUSY_REGISTER));
    case "FORGOT_PASSWORD_FULFILLED":
      // case "DO_REGISTER_FULFILLED":
      return state
        .set(LOGIN_VISIBLE, true)
        .set(REGISTER_VISIBLE, false)
        .set("forgotPasswordLinkSent", true)
        .set("forgotPassword", true);
    case "OTP_LOGIN_FULFILLED": // intentionally fall through
    case "CONFIRM_FORGOT_PASSWORD_FULFILLED":
      if (payload.errorMessage) {
        return state.set(LOGIN_ERROR, payload.errorMessage);
      } else if (payload.loginData) {
        return state
          .set(LOGIN_USER, payload.loginData.user)
          .set(LOGIN_JWT, payload.loginData.JWT)
          .set(LOGIN_VISIBLE, false)
          .set(REGISTER_VISIBLE, false)
          .remove(LOGIN_ERROR);
      } else {
        return state.set(LOGIN_ERROR, "Toiminto epäonnistui");
      }
    case "DO_REGISTER_FULFILLED":
      if (payload.errorMessage) {
        return state.set(LOGIN_ERROR, payload.loginError);
      } else if (payload.user) {
        return state
          .set(LOGIN_USER, payload.user)
          .set(LOGIN_JWT, payload.JWT)
          .set(LOGIN_VISIBLE, true)
          .set(REGISTER_VISIBLE, false)
          .set("forgotPasswordLinkSent", true)
          .set("forgotPassword", true)
          .set("busy", state.get("busy").remove(BUSY_REGISTER));
      } else {
        return state
          .set(LOGIN_ERROR, "Rekisteröityminen epäonnistui")
          .set("busy", state.get("busy").remove(BUSY_REGISTER));
      }
    case "CLOSE_LOGIN":
      return state.set(LOGIN_VISIBLE, false);
    case "DO_LOGOUT_FULFILLED":
      return state
        .remove("forgotPasswordLinkSent")
        .remove("forgotPassword")
        .remove(LOGIN_USER)
        .remove(LOGIN_CTX)
        .remove(LOGIN_JWT);
    case "DO_LOGIN_PENDING": // TODO: spinner
      return state.set("busy", state.get("busy").add(BUSY_LOGIN));
    case "DO_LOGIN_FULFILLED":
      if (payload.loginError) {
        return state
          .set(LOGIN_ERROR, payload.loginError)
          .set("busy", state.get("busy").remove(BUSY_LOGIN));
      } else if (payload.otpLogin && payload.sessionId) {
        // step-up needed
        return state.set("loginData", payload);
      } else if (payload.jwt) {
        return state
          .set(LOGIN_USER, payload.user)
          .set(LOGIN_JWT, payload.jwt)
          .set(LOGIN_CTX, payload.ctx)
          .set(LOGIN_ERROR, undefined)
          .set(LOGIN_VISIBLE, false)
          .set("busy", state.get("busy").remove(BUSY_LOGIN));
      } else {
        return state.set(LOGIN_ERROR, "Kirjautuminen epäonnistui");
      }
    case "ENSURE_LOGIN_PENDING":
    case "CHECK_LOGIN_PENDING":
      return state.set("loginCheckOngoing", true);
    case "ENSURE_LOGIN_FULFILLED":
    case "CHECK_LOGIN_FULFILLED":
      if (payload.jwt) {
        return state
          .set(LOGIN_USER, payload.user)
          .set(LOGIN_JWT, payload.jwt)
          .set(LOGIN_CTX, payload.ctx)
          .set(LOGIN_ERROR, undefined)
          .set(LOGIN_VISIBLE, false)
          .set("loginCheckOngoing", false);
      } else {
        console.log("Clearing logged status");
        const st = state
          .set(LOGIN_JWT, undefined)
          .set(LOGIN_CTX, undefined)
          .set("loginCheckOngoing", false);
        if (type === "ENSURE_LOGIN_FULFILLED") {
          console.log("Login data / otpLogin: " + payload.otpLogin);
          return st.set(LOGIN_VISIBLE, true).set("loginData", {
            email: payload.loginEmail,
            otpLogin: payload.otpLogin
          });
        } else {
          return st;
        }
      }
    case "ASK_CREDENTIALS":
      return state.set(LOGIN_VISIBLE, true);
    case "CLEAR_ALL_ERRORS":
      return state.remove("error").remove(LOGIN_ERROR);

    default:
      return state;
  }
}
