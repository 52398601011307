import React from "react";
import { CircleLoader } from "react-spinners";
import { genericGet, genericPost } from "../../services/axiosApi";
import { AreaSelect } from "../AreaSelect";
import BookkaaHeader from "../BookkaaHeader";
import { EnumSelect } from "../EnumSelect";
import BkModifyButton from "../layout/BkModifyButton";
import LoginForm from "../LoginForm";
import LoginRequired from "../LoginRequired";
import BkTab from "../layout/BkTab";
import OkErrorMessage from "../layout/OkErrorMessage";
import swal from "@sweetalert/with-react";

const ADHOC_SEND="send";
const ADHOC_QUERY="query";

class AdhocMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      areaId: undefined,
      delivery: "EMAIL",
      selectedFile: null,
      attachments: undefined,
      loadingAttachments: true,
      tab: ADHOC_SEND
    };

    this.submit = this.submit.bind(this);
    this.search = this.search.bind(this);
    this.getContent = this.getContent.bind(this);
    this.loadAttachments = this.loadAttachments.bind(this);
    this.sendAttachment = this.sendAttachment.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.changeTab = this.changeTab.bind(this);
    this.showReceivers = this.showReceivers.bind(this);

    props.getAreas(props.location.language);
    this.loadAttachments();

    props.ensureLoggedIn(
      this.props.jwt,
      "booking-web",
      props.location.language,
      "seasonAdmin"
    );
  }
  loadAttachments() {
    const l = this.props.location;
    return genericGet(
      l.language,
      `a${l.area}/seasonAdmin/users/email/attachments`
    )
      .then(ret => {
        this.setState({ attachments: ret.attachments, loadAttachments: false });
      })
      .catch(() => {
        this.setState({ attachments: undefined, loadAttachments: false });
      });
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.areas && !this.state.sellerId && this.props.location.area) {
      this.props.areas.forEach(a => {
        // eslint-disable-next-line eqeqeq
        if (a.id == this.props.location.area) {
          this.setState({ sellerId: a.seller });
        }
      });
    }
    if (prevState && prevState.areaId != this.state.areaId) {
      genericGet(
        "fi",
        "subareas/codes/" + this.state.areaId,
        "booking-web"
      ).then(ret => {
        if (ret.errorMessage) {
          this.setState({
            errorMessage: ret.errorMessage,
            subareas: undefined
          });
        } else {
          this.setState({ subareas: ret });
        }
      });
    }
  }
  search() {
    const location = this.props.location;
    const params = [];
    params.push(`?sellerId=${this.state.sellerId}`);
    if (this.state.areaId) {
      params.push(`&areaId=${this.state.areaId}`);
    }
    if (this.state.pier) {
      params.push(`&pier=${this.state.pier}`);
    }
    if (this.state.status) {
      params.push(`&status=${this.state.status}`);
    }
    genericGet(
      location.language,
      `a${location.area}/seasonAdmin/users/email${params.join("")}`
    ).then(ret => {
      this.setState({
        errorMessage: ret.errorMessage,
        data: ret
      });
    });
  }
  submit() {
    const location = this.props.location;
    const data = { ...this.state.data };
    data.content = this.state.content;
    data.delivery = this.state.delivery;
    data.sellerId = this.state.sellerId;
    data.attachment = this.state.attachment;

    genericPost(
      location.language,
      `a${location.area}/seasonAdmin/users/email`,
      data
    ).then(ret => {
      this.setState({
        okMessage: ret.okMessage,
        errorMessage: ret.errorMessage,
        data: ret.okMessage ? {} : this.state.data
      });
      setTimeout(() => this.setState({ okMessage: undefined }), 7000);
    });
  }

  render() {
    const location = this.props.location;
    const content = this.getContent();
    return (
      <React.Fragment>
        <LoginRequired {...this.props.loginProps} location={location} />
        <BookkaaHeader
          location={location}
          {...this.props.headerProps}
          disableProfile={true}
        />
        <LoginForm location={location} {...this.props.loginProps} />
        <div className="-container">{content}</div>
      </React.Fragment>
    );
  }

  // On file select (from the pop up)
  onFileChange(event) {
    // Update the state
    this.setState({ selectedFile: event.target.files[0] });
  }

  sendAttachment() {
    const formData = new FormData();

    // Update the formData object
    formData.append(
      "attachment",
      this.state.selectedFile,
      this.state.selectedFile.name
    );

    return genericPost(
      this.props.location.language,
      `a${this.props.location.area}/seasonAdmin/users/email/attachment`,
      formData,
      "booking-web",
      "multipart/form-data"
    ).then(ret =>
      this.loadAttachments().then(() => {
        this.setState({
          okMessage: ret.okMessage,
          errorMessage: ret.errorMessage,
          selectedFile: ret.okMessage ? null : this.state.selectedFile,
          attachments: ret.okMessage ? ret.attachments : this.state.attachments,
          addAttachment: false
        });
        setTimeout(() => this.setState({ okMessage: undefined }), 7000);
      })
    );
  }

  getContent() {
    const location = this.props.location;
    const t = key => location.translate(location, key);
    if (!this.props.areas) {
      return <CircleLoader />;
    } else if (this.state.okMessage || this.state.errorMessage) {
      return (<OkErrorMessage okMessage={this.state.okMessage} errorMessage={this.state.errorMessage} 
        clearOk={()=>this.setState({okMessage:undefined})} clearError={()=>this.setState({errorMessage:undefined})}/>);
    } else if (this.state.addAttachment) {
      return (
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "75px",
            border: "1px solid lightgray",
            borderRadius: "15px",
            backgroundColor: "#eeeeee"
          }}
        >
          <center>
            <input
              type="file"
              onChange={event =>
                this.setState({ selectedFile: event.target.files[0] })
              }
            />
          </center>
          <center>
            <BkModifyButton
              callback={this.sendAttachment}
              buttonTitle="Upload"
            />
            <BkModifyButton
              callback={() => this.setState({ addAttachment: false })}
              buttonTitle={t("cancel-button")}
            />
          </center>
        </div>
      );
    }
    const content = [];

    if (this.state.tab == ADHOC_SEND) {
      content.push(
        <center className="medium-size-input-parent">
          <div style={{ width: "375px" }}>
            <AreaSelect
              emptyLabel={t("filter.allHarbours")}
              areas={this.props.areas}
              selectedSellerId={this.state.sellerId}
              selectedAreaId={this.state.areaId}
              cb={v => {
                this.setState({
                  // eslint-disable-next-line eqeqeq
                  areaId: v == t("filter.allHarbours") ? undefined : v
                });
              }}
            />
          </div>
        </center>
      );

      content.push(
        <center>
          <select
            className="medium-size-input"
            disabled={!this.state.areaId}
            onChange={e => {
              this.setState({ pier: e.target.value });
            }}
            value={this.state.pier}
          >
            <option value="">{t("filter.allPiers")}</option>
            {this.state.subareas
              ? this.state.subareas.map(sa => {
                  return <option>{sa}</option>;
                })
              : ""}
          </select>
        </center>
      );
      content.push(
        <center className="medium-size-input-parent">
          <EnumSelect
            emptyLabel={t("filter.allPaymentStatuses")}
            location={this.props.location}
            enumType="ReservationStatus"
            filter={v => !["REPLACED", "TO_BE_INVOICED", "RETURNED", "SEND_PAYMENT_LINK", "SEND_PAYMENT_REMINDER", "CANCELLED"].includes(v)}
            selected={this.state.status}
            cb={v => {
              this.setState({
                status: v == t("filter.allPaymentStatuses") ? "" : v
              });
            }}
          />
        </center>
      );
      content.push(
        <center className="medium-size-input-parent">
          <BkModifyButton
            buttonTitle={t("search")}
            callback={this.search}
            disabled={!this.state.sellerId}
          />
        </center>
      );
      if (this.state.data && this.state.data.customers) {
        content.push(<center>Viesti:</center>);
        content.push(
          <center>
            <textarea
              className="medium-size-input"
              value={this.state.content}
              onChange={e =>
                this.setState({
                  content: e.target.value
                })
              }
            />
          </center>
        );
        content.push(<center>Liite:</center>);
        content.push(
          <center>
            <select
              className="medium-size-input"
              disabled={
                !this.state.attachments || this.state.delivery !== "EMAIL"
              }
              onChange={e => {
                this.setState({ attachment: e.target.value });
              }}
              value={this.state.attachment}
            >
              <option value=""> - </option>
              {this.state.attachments
                ? this.state.attachments.map(sa => {
                    return (
                      <option
                        value={sa.id}
                        selected={sa.id === this.state.attachment}
                      >
                        {sa.name}
                      </option>
                    );
                  })
                : ""}
            </select>
            <BkModifyButton
              callback={() => this.setState({ addAttachment: true })}
              title={t("button-new")}
            />
          </center>
        );
        content.push(
          <center>
            <EnumSelect
              location={this.props.location}
              enumType="DeliveryType"
              selected={this.state.delivery}
              cb={v => {
                this.setState({
                  delivery: v
                });
              }}
            />
          </center>
        );
        content.push(
          <center>
            <BkModifyButton
              buttonTitle={t("send")}
              callback={this.submit}
              disabled={!this.state.content}
            />
          </center>
        );
        const table = [];
        this.state.data.customers.forEach(c => {
          table.push(
            <tr>
              <td>
                {c.firstName} {c.lastName}
              </td>
              <td>{c.email}</td>
              <td>{c.harbour}</td>
              <td>{c.pier}</td>
              <td>{c.place}</td>
            </tr>
          );
        });
        content.push(
          <table className="bk-list">
            <tr>
              <th>{t("name")}</th>
              <th>{t("email")}</th>
              <th>{t("area")}</th>
              <th>{t("pier")}</th>
              <th>{t("place")}</th>
            </tr>
            {table}
          </table>
        );
      }
    } else if (this.state.tab == ADHOC_QUERY && this.state.previouslySent) {
      const table = [];
      this.state.previouslySent.messages.forEach(r => 
        table.push(<tr onClick={() => this.showReceivers(r.receivers)}>
          <td>{r.sentAt}</td>
          <td>{r.message}</td>
          <td>{r.receivers.length}</td>
        </tr>)
      );
      content.push(
        <table className="bk-list">
          <tr>
            <th>Lähetetty</th>
            <th>Viesti</th>
            <th>Vastaanottajien määrä</th>
          </tr>
          {table}
        </table>
      )
    }
    return (
      <BkTab location={this.props.location} selected={this.state.tab} callback={(tab) => this.changeTab(tab)} options={[ADHOC_SEND, ADHOC_QUERY]}>
        <br/>
        {content}
      </BkTab>
    );
  }

  showReceivers(list) {
    const location = this.props.location;
    const t = key => location.translate(location, key);
    console.log("show: " + JSON.stringify(list));

    swal({
      title: "Vastaanottajat",
      icon: "info",
      content: (
        <div style={{ overflow: "scroll" }}>
          {list.map(l => <p>{l.address}</p>)}
        </div>
      ),
      buttons: {
        close: t("close-button"),
      }
    });
  }

  changeTab(tab) {
    if (tab === ADHOC_QUERY) {
      genericGet(this.props.location.language, `seasonAdmin/sent/${this.state.sellerId}`)
        .then(r => {
          this.setState({ errorMessage: r.errorMessage, previouslySent: r, tab: ADHOC_QUERY});
        })
    } else {
      this.setState({ tab: ADHOC_SEND});
    }
  }
}
export default AdhocMessages;
