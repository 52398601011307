import React from "react";
import moment from "moment";
import DateRangeSelector from "../boatparking/DateRangeSelector";
import { CircleLoader } from "react-spinners";
import BkModifyButton from "../layout/BkModifyButton";
import { genericGet, genericPost } from "../../services/axiosApi";

class Report extends React.Component {
  constructor(props) {
    super(props);
    const l = this.props.location;
    this.state = {
      from: moment()
        .subtract(1, "month")
        .startOf("month"),
      until: moment()
        .subtract(1, "month")
        .endOf("month"),
      sellerId: undefined,
      areaId: l.area ? l.area : undefined
    };
    if (!this.props.areas) {
      this.props.getAreas(l.language);
    }
    if (!this.props.sellers) {
      this.props.getSellers(l.language);
    }
    this.sellerFromArea = this.sellerFromArea.bind(this);
    this.generate = this.generate.bind(this);
    this.showData = this.showData.bind(this);
  }

  interval = null;

  generate() {
    const f = moment(this.state.from).format("YYYY-MM-DD");
    const u = moment(this.state.until).format("YYYY-MM-DD");
    const s = this.state.sellerId ? this.state.sellerId : "";
    const a = this.state.areaId ? this.state.areaId : "";
    const customParams = this.props.customParams ? this.props.customParams : "";
    const report = this.props.report ? this.props.report : "payouts";
    genericPost(
      this.props.location.language,
      `adminReporting/${report}?from=${f}&until=${u}&sellerId=${s}&areaId=${a}${customParams}`,
      {}
    ).then(ret => {
      this.setState({ reportOrderId: ret.reportOrderId, ready: undefined });
      this.interval = setInterval(() => this.checkReport(this), 1000);
    });
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = null;
  }

  checkReport(th) {
    if (th.state.ready) {
      console.log("Already ready");
      return;
    }
    genericGet(
      th.props.location.language,
      `adminReporting/report/${th.state.reportOrderId}/status`,
      "booking-web",
      5000
    ).then(ret => {
      if (th.state.ready) {
        console.log("Already ready");
      } else if (ret.errorStatus == 202) {
        // ok, not ready yet (ACCEPTED)
      } else if (ret.errorStatus == 404) {
        // not found, error! TODO
        th.setState({ reportOrderId: undefined });
        clearInterval(th.interval);
        th.interval = null;
      } else if ((ret.errorStatus = 204 || Array.isArray(ret))) {
        const reportOrderId = this.state.reportOrderId;
        th.setState({
          data: ret.errorStatus ? undefined : ret,
          reportOrderId: undefined,
          ready: reportOrderId
        });
        clearInterval(th.interval);
        th.interval = null;
      } else {
        console.log("Error status: " + ret.errorStatus);
      }
    });
  }

  showData() {
    genericGet(
      this.props.location.language,
      `adminReporting/report/${this.state.ready}/data`,
      "booking-web",
      60000
    ).then(ret => {
      if (Array.isArray(ret)) {
        this.setState({
          data: ret
        });
      } else {
        console.log("Error status: " + ret.errorStatus);
        this.setState({ ready: undefined });
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (
      (!prevProps.areas || !prevProps.sellers) &&
      this.props.areas &&
      this.props.sellers
    ) {
      if (this.state.areaId && !this.state.sellerId) {
        this.setState({ sellerId: this.sellerFromArea(this.state.areaId) });
      }
    }
  }

  sellerFromArea(areaId) {
    let seller = undefined;
    this.props.areas.forEach(a => {
      if (a.id === areaId) {
        seller = a.seller;
      }
    });
    return seller;
  }

  // TODO receive props: set sellerId based on areaId

  render() {
    const l = this.props.location;
    const t = key => l.translate(l, key);

    if (!this.props.areas || !this.props.sellers) {
      console.log("No areas/sellers");
      return <CircleLoader />;
    }

    console.log(
      `Selected seller ${this.state.sellerId}, area: ${this.state.areaId}`
    );

    let seller = undefined;

    if (this.props.sellers.length > 1) {
      const sellers = [];
      sellers.push(<option value="" />);
      this.props.sellers.forEach(s => {
        if (this.state.sellerId == s.id) {
          sellers.push(
            <option value={s.id} selected="selected">
              {s.name}
            </option>
          );
        } else {
          sellers.push(<option value={s.id}>{s.name}</option>);
        }
      });
      seller = (
        <React.Fragment>
          <span>{t("seller")}</span>
          <select
            onChange={e =>
              this.setState({ sellerId: e.target.value, areaId: undefined })
            }
          >
            {sellers}
          </select>
        </React.Fragment>
      );
    }

    let area = undefined;
    if (this.props.areas.length > 1) {
      const areas = [];
      areas.push(<option value="" />);
      this.props.areas.forEach(a => {
        if (this.state.sellerId && a.seller != this.state.sellerId) {
          // filter out
        } else if (this.state.areaId == a.id) {
          areas.push(
            <option
              value={a.id}
              selected="selected"
            >{`${a.name} (${a.code})`}</option>
          );
        } else {
          areas.push(<option value={a.id}>{`${a.name} (${a.code})`}</option>);
        }
      });
      area = (
        <React.Fragment>
          <span>{t("area")}</span>
          <select
            onChange={e => {
              const areaId = e.target.value;
              const sellerId = this.sellerFromArea(areaId);
              this.setState({ areaId: areaId, sellerId: sellerId });
            }}
          >
            {areas}
          </select>
        </React.Fragment>
      );
    }

    const content = [];
    content.push(
      <center>
        {seller}
        {area}
      </center>
    );

    if (this.props.extraFilters) {
      content.push(this.props.extraFilters);
    }

    content.push(
      <center>
        <DateRangeSelector
          pastDateAllowed={true}
          beforeStartDate={<div>{t("report.from")}</div>}
          beforeEndDate={<div>{t("report.until")}</div>}
          editMode={true}
          location={this.props.location}
          start={this.state.from}
          end={this.state.until}
          startDateCallback={(s, e) => {
            this.setState({
              from: s,
              until: e
            });
          }}
          endDateCallback={(e, s) =>
            this.setState({
              until: e,
              from: s
            })
          }
        />
      </center>
    );

    content.push(
      <center>
        <BkModifyButton
          buttonTitle={t("orderReport")}
          callback={this.generate}
        />
        <BkModifyButton
          buttonTitle={t("show")}
          callback={this.showData}
          disabled={!this.state.ready}
        />
        {this.state.ready && (
          <BkModifyButton
            buttonTitle={t("loadCsv")}
            callback={() =>
              window.open(
                "/booking-web/fi/adminReporting/report/" +
                  this.state.ready +
                  ".csv",
                "_blank"
              )
            }
            disabled={!this.state.ready}
          />
        )}
      </center>
    );
    content.push(<hr />);

    if (this.state.data) {
      content.push(this.props.renderData(this.state.data));
    } else if (this.state.reportOrderId) {
      content.push(<CircleLoader />);
    }

    return content;
  }
}

export default Report;
