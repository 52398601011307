import React from "react";
import { CircleLoader } from "react-spinners";
import { genericDelete, genericGet, genericPost } from "../../services/axiosApi";
import BookkaaHeader from "../BookkaaHeader";
import BkModifyButton from "../layout/BkModifyButton";
import BkTab from "../layout/BkTab";
import OkErrorMessage from "../layout/OkErrorMessage";
import TableAndForm from "../layout/TableAndForm";
import LoginForm from "../LoginForm";
import LoginRequired from "../LoginRequired";

class HarbourMessages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: undefined,
      selectedAttachment: undefined,
      messageOrders: false,
      adhocMessages: false,
      possibleParameters: undefined,
      tab: "SELLER"
    };
    this.selectAttachmentCallback = this.selectAttachmentCallback.bind(this);
    this.templateSelectCallback = this.templateSelectCallback.bind(this);
    this.uploadAttachment = this.uploadAttachment.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);
    this.loadTemplates = this.loadTemplates.bind(this);
    this.msgTranslate = this.msgTranslate.bind(this);

    props.ensureLoggedIn(
      this.props.jwt,
      "booking-web",
      props.location.language,
      "messages"
    );

    props.ensureLoggedIn(
      this.props.jwt,
      "messagecenter-web",
      props.location.language,
      "templates"
    );

  }

  componentDidMount() {
    this.loadTemplates();
    genericGet(this.props.location.language, "templates/translations_gen.json", "messagecenter-web").then(r => {
      if (r.texts) {
        this.setState({ msgTexts: r.texts });
      }
    })
  }

  loadTemplates() {
    const l = this.props.location;
    return genericGet(l.language, `a${l.area}/sellers/current`).then(s => {
      if (s.code) {
        return genericGet(l.language, `areas/${l.area}`).then(a => {
          return genericGet(l.language, `msgs/${s.code}/${a.code}`, "messagecenter-web", 15000).then(r => {
            this.setState({ errorMessage: r.errorMessage, templates: r.errorMessage ? [] : r, selectedTemplate: undefined});
          });
        });
      }
    })
  }

  selectAttachmentCallback(item) {
    this.setState({ selectedAttachment: item });
  }

  templateSelectCallback(item) {
    const l = this.props.location;
    return genericGet(l.language, "templates/" + item.type + "/parameterTypes", "messagecenter-web").then(ret => {
      this.setState({ 
        selectedTemplate: item, 
        possibleParameters: ret, 
        editTitle: item.shortMessage, 
        editMessage: item.longMessage,
        editBCC: item.bcc,
        showHelp: false
       });
    });
  }

  uploadAttachment(data) {
    const l = this.props.location;
    const formData = new FormData();
    formData.append("attachment", data, data.name);
    const id = this.state.selectedAttachment
      ? this.state.selectedAttachment.id
      : 0;
    const templateId = this.state.selectedTemplate.id;
    return genericPost(
      l.language,
      `templateAttachments/${templateId}/${id}/data`,
      formData,
      "messagecenter-web",
      "multipart/form-data"
    ).then(r => {
      this.setState({
        selectedTemplate: undefined,
        showHelp: false
      });
    });
  }
  deleteAttachment() {
    return genericDelete(
      this.props.location.language,
      "templateAttachments/" + this.state.selectedAttachment.id,
      "messagecenter-web"
    ).then(() => {
      this.setState({
        selectedTemplate: undefined
      });
    });
  }

  msgTranslate(key) {
    if (
      this.state.msgTexts &&
      this.state.msgTexts[key]
    ) {
      return this.state.msgTexts[key];
    } else {
      return key;
    }
  }  

  render() {
    const location = this.props.location;
    const t = key => location.translate(location, key);

    if (this.state.templates === undefined) {
      return <CircleLoader />;
    }
    const attachmentsLoc = {
      language: this.props.location.language,
      endpoint: "templateAttachments",
      ctx: "messagecenter-web",
      translations: this.props.location.translations,
      translate: this.props.location.translate
    };

    const content = [];

    console.log(`Area: ${location.area}`);
    let beforeForm = [];
    const beforeGroupForm = [];
    if (this.state.possibleParameters) {
      const help = [];
      if (this.state.showHelp) { 
        if (this.state.selectedTemplate && this.state.selectedTemplate.type === "COMPANY_FOOTER") {
          help.push(<p><strong>{this.msgTranslate("messageParameters.help.footer")}</strong></p>);
        } else {
          help.push(<p><strong>{this.msgTranslate("messageParameters.help")}</strong></p>);
          Object.keys(this.state.possibleParameters)
            .forEach(k => help.push(<React.Fragment><br /><span>{k} : {this.state.possibleParameters[k]}</span></React.Fragment>));
          help.push(<hr />);
        }
      }
      beforeGroupForm.push(<span onClick={()=>this.setState({showHelp: !this.state.showHelp})} style={{ fontSize: "24px" }}>&#9432;</span> )
      beforeGroupForm.push(<div>{help}</div>);
    }
    const UPLOAD_ATTACHMENT_BUTTON = {
      supportsMulti: false,
      callback: this.uploadAttachment,
      fileUpload: true,
      title: t("upload-attachment"),
      disabledCallback: b => false
    };
    const DELETE_ATTACHMENT_BUTTON = {
      supportsMulti: false,
      callback: this.deleteAttachment,
      title: t("delete-button"),
      disabledCallback: b => !this.state.selectedAttachment
    };

    let attachmentListButtons = [
      UPLOAD_ATTACHMENT_BUTTON,
      DELETE_ATTACHMENT_BUTTON
    ];

    // check if there are stored (id > 0) AREA templates
    const areaTemplates = this.state.templates && this.state.templates.map(t => t.id ? t.userGroup : "").findIndex((v,i,a) => v && (v.indexOf("AREA_") != -1)) != -1;

    const templateMenu = [];
    const tpl = this.state.templates.map(tpl => {
            if (tpl.userGroup.indexOf(this.state.tab + "_") == -1) {
              return "";
            } else {
              return (<div style={{
                          fontWeight: this.state.selectedTemplate === tpl ? "bold" : "normal",
                          borderColor: tpl.id > 0 ? "#4472c4" : "#9bd6fc" 
                        }} 
                  onClick={() => this.templateSelectCallback(tpl)}>
                {this.msgTranslate(tpl.type)}
              </div>);
            }
          });

    if (areaTemplates || this.state.areaEnabled) {
      if (!areaTemplates) {
        templateMenu.push(<label onClick={() => this.setState({areaEnabled: false})}><input type="checkbox" checked={true}/>{t("enableAreaTemplates")}</label>);
        templateMenu.push(<br/>);
      }
      templateMenu.push(
      <BkTab location={location} options={[ "SELLER", "AREA"]} selected={this.state.tab} callback={a => this.setState({tab: a, selectedTemplate: undefined})}>
        <div className="flex-div">{tpl}</div>
      </BkTab>);
    } else {
      templateMenu.push(<label onClick={() => this.setState({areaEnabled: true})}><input type="checkbox"/>{t("enableAreaTemplates")}</label>);
      templateMenu.push(<br/>);
      templateMenu.push(<div className="flex-div">{tpl}</div>);
    }


    content.push(
      <React.Fragment>
        <h2>{this.msgTranslate("messageTemplates.title")}</h2>
        <OkErrorMessage 
              okMessage={this.state.okMessage} 
              errorMessage={this.state.errorMessage} 
              clearOk={() => this.setState({ okMessage: undefined})}
              clearError={() => this.setState({ errorMessage: undefined})} />
        {templateMenu}
        {this.state.selectedTemplate && (
          <table>
            <tr><td colSpan="2">{beforeGroupForm}</td></tr>
            {(this.state.selectedTemplate.deliveryType === "EMAIL" && this.state.selectedTemplate.type != "COMPANY_FOOTER") && (<tr>
              <td>Otsikko</td><td><input value={this.state.editTitle} className="msgTemplateInput" onChange={e => this.setState({editTitle: e.target.value})}/></td>
            </tr>)}
            <tr>
              <td>Viestipohja</td><td><textarea value={this.state.editMessage} className="msgTemplateTextarea" onChange={e => this.setState({editMessage: e.target.value})}/></td>
            </tr>
            {(this.state.selectedTemplate.deliveryType === "EMAIL"  && this.state.selectedTemplate.type != "COMPANY_FOOTER") && (<tr>
              <td>Kopio sähköpostiin</td><td><input value={this.state.editBCC} className="msgTemplateInput" onChange={e => this.setState({editBCC: e.target.value})}/></td>
            </tr>)}
            <tr>
              <td></td><td><BkModifyButton buttonTitle={t("save-button")} callback={() => {
                const tmpl = this.state.selectedTemplate;
                if (this.state.selectedTemplate.deliveryType === "EMAIL"  && this.state.selectedTemplate.type != "COMPANY_FOOTER") {
                  tmpl.shortMessage = this.state.editTitle;
                  tmpl.bcc = this.state.editBCC;
                }
                tmpl.longMessage = this.state.editMessage;
                return genericPost(location.language, "msgs", tmpl, "messagecenter-web").then(r => {
                  this.setState({ okMessage: r.okMessage, errorMessage: r.errorMessage }, () => this.loadTemplates());
                });
              }} />
              <BkModifyButton buttonTitle={t("delete-button")} disabled={!this.state.selectedTemplate.id} callback={() => {
                const tmpl = this.state.selectedTemplate;
                return genericDelete(location.language, "msgs/" + tmpl.id, "messagecenter-web").then(r => {
                  this.setState({ okMessage: r.okMessage, errorMessage: r.errorMessage}, () => this.loadTemplates());
                })
              }} /></td>
            </tr>
          </table>
        )
        }
        {(this.state.selectedTemplate && this.state.selectedTemplate.id > 0) && (
          <div>
          <h2>{this.msgTranslate("messageTemplateAttachments.title")}</h2>
          <TableAndForm
              selectCallback={this.selectAttachmentCallback}
              itemsBeforeForm={beforeForm}
              buttons={attachmentListButtons}
              formButtonArray={[]}
              multiSelect={false}
              {...this.props}
              endpoint={attachmentsLoc.endpoint}
              location={attachmentsLoc}
              search={
                this.state.selectedTemplate
                  ? `template.id=${this.state.selectedTemplate.id}`
                  : undefined
              }
              preSaveCallback={data => {
                return {
                  ...data,
                  templateId: this.state.selectedTemplate.id
                };
              }}
            />
          </div>
        )}
      </React.Fragment>
    );
    return (
      <React.Fragment>
        <LoginRequired {...this.props.loginProps} location={location}>
          <BookkaaHeader location={location} {...this.props.headerProps} />
          <LoginForm location={location} {...this.props.loginProps} />
          {content}
        </LoginRequired>
      </React.Fragment>
    );
  }
}

const TemplateTable = (props) => {
  const { list } = props;
  const rows = [];
  list.forEach(row => {

  });
  return (<table className="">{rows}</table>);
}

export default HarbourMessages;
