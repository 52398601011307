import React from "react";
import PropTypes from "prop-types";
import BkModifyButton from "../layout/BkModifyButton";
import LoginRequired from "../LoginRequired";
import LoginForm from "../LoginForm";
import BookkaaHeader from "../BookkaaHeader";
import BkContentArea from "../layout/BkContentArea";
import Watch from "../reservation/Watch";
import { Map } from "immutable";
import WatchCalendar from "../reservation/WatchCalendar";
import AutoComplete from "react-autocomplete";
import { shallowEqual } from "recompose";
import Datatable from "../datatable/Datatable";

class WatchAdmin extends Watch {
  constructor(props) {
    super(props);
    this.reserve = this.reserve.bind(this);
    this.renderAdminDay = this.renderAdminDay.bind(this);
    super.isValidDate = this.isValidDate;
    super.render = this.render.bind(this);
    this.autoCompleteChange = this.autoCompleteChange.bind(this);
    this.autoCompleteSelect = this.autoCompleteSelect.bind(this);
    this.toggleOnlyFree = this.toggleOnlyFree.bind(this);
    let r = this.getReservations(props);
    let d = super.getProds(props);
    this.state = {
      reservations: Map(r),
      dates: Map(d),
      key: 1,
      onlyFree: false,
      customers: [],
      customersNr: 0
    };
    this.id1 = `toggle_${Math.random()
      .toString()
      .replace(/0\./, "")}`;
    this.id2 = `toggle_${Math.random()
      .toString()
      .replace(/0\./, "")}`;
  }

  toggleOnlyFree() {
    this.setState({ onlyFree: !this.state.onlyFree });
  }

  getReservations(props) {
    let prods = {};
    if (props.availableProducts && props.availableProducts instanceof Array) {
      props.availableProducts.forEach(p => {
        if (p.reservedProducts) {
          prods[`${p.at}`] = p.reservedProducts;
        }
      });
    } else if (props.availableProducts) {
      console.log("Not an array: " + JSON.stringify(props.availableProducts));
    } else {
      console.log("No products");
    }
    return prods;
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!shallowEqual(nextProps, this.props)) {
      let prods = this.getProds(nextProps);
      let ress = this.getReservations(nextProps);
      console.log(
        `Will receive props products => ${prods.length}, reservations => ${ress.length}`
      );
      if (
        nextProps.customers &&
        nextProps.customers.reference >= this.state.customersNr
      ) {
        this.setState({
          customers: nextProps.customers.items,
          customersNr: nextProps.customers.reference
        });
      } else if (!nextProps.customers) {
        this.setState({
          customers: []
        });
      } else if (nextProps.customers.reference < this.state.customersNr) {
        console.info("Skipped search result that is older than current");
      }
      this.setState({
        dates: this.state.dates.merge(Map(prods)),
        reservations: this.state.reservations.merge(Map(ress))
      });
      if (this.props.jwt !== nextProps.jwt) {
        this.setState({
          key: this.state.key + 1
        });
      }
    }
  }
  reserve() {
    let l = this.props.location;
    console.log("Reserve called! Current date: " + this.state.selectedDate);
    this.props.guardPatrolReserve(l.area, l.language, {
      type: "GUARD_WATCH",
      selectedDate: this.state.selectedDate
    });
  }

  isValidDate(currentDate, selectedDate) {
    return true;
  }

  componentDidMount() {
    this.props.ensureLoggedIn(
      this.props.jwt,
      this.props.location.ctx,
      this.props.location.language,
      "patrol"
    );
  }

  render() {
    let location = this.props.location;

    let errorDiv = [];
    if (this.props.reservationError) {
      errorDiv.push(
        <div className="bk_errorMessage" style={{ display: "block" }}>
          {this.props.reservationError}
        </div>
      );
    }

    let headers = [];
    if (this.props.headers) {
      headers.push(
        <BookkaaHeader
          location={location}
          {...this.props.headerProps}
          headerSelectorContent="Vahtivuorojen ylläpito"
        />
      );
      headers.push(
        <LoginForm location={location} {...this.props.loginProps} />
      );
      headers.push(
        <LoginRequired location={location} {...this.props.loginProps} />
      );
    }

    let dtLocation = {
      area: location.area,
      language: location.language,
      endpoint: "patrol",
      ctx: "booking-web"
    };

    let content = (
      <React.Fragment>
        {headers}
        <BkContentArea>
          {errorDiv}
          <div className="bk-divTable">
            <div className="bk-firstColumn">
              <WatchCalendar
                key={this.state.key}
                isValidDate={this.isValidDate}
                renderDay={this.renderAdminDay}
                dateChanged={moment => this.setState({ selectedDate: moment })}
                getAvailableProducts={this.props.getAvailableProducts}
                location={this.props.location}
              />
            </div>
            <ReservedWatches
              reserved={this.state.reservations.get(
                `${this.state.selectedDate}`
              )}
              free={this.state.dates.get(`${this.state.selectedDate}`)}
              date={this.state.selectedDate}
              customers={this.state.customers}
              autoCompleteChange={this.autoCompleteChange}
              autoCompleteSelect={this.autoCompleteSelect}
              autoCompleteValue={this.state.autoCompleteValue}
              cancelReservation={data =>
                this.props.cancelReservation(
                  location.area,
                  location.language,
                  data
                )
              }
              reserve={(code, admin) =>
                this.props.guardPatrolReserve(
                  location.area,
                  location.language,
                  {
                    type: "GUARD_WATCH",
                    selectedDate: this.state.selectedDate,
                    customer: admin ? null : this.state.selectedCustomer,
                    adminTxt: admin ? this.state.autoCompleteValue : undefined,
                    productCode: code
                  }
                )
              }
            />
          </div>
          <div className="bk-patrolSearchTable">
            <a
              href={`/booking-web/${this.props.location.language}/${this.props.location.area}/patrol/report.csv`}
              target="_blank"
              rel="noopener noreferrer"
              type="text/csv"
              download
            >
              Vartijatietojen lataus
            </a>
            <input
              type="checkbox"
              value="onlyFree"
              onChange={this.toggleOnlyFree}
              id={this.id1}
            />
            <label htmlFor={this.id1}>Vain vuorottomat</label>

            <Datatable
              {...this.props}
              search={this.state.onlyFree ? "onlyFree=true" : undefined}
              reloadTable={this.state.onlyFree ? "onlyFree" : "all"}
              location={dtLocation}
              selectCallback={() => {}}
              onRef={ref => this.setState({ datatable: ref })}
              openLoginDialog={this.props.openLoginDialog}
              dtButtons={["csv", "excel", "print"]}
            />
          </div>
        </BkContentArea>
      </React.Fragment>
    );

    return content;
  }
  renderAdminDay(props, currentDate, selectedDate) {
    let info = [];
    let reserved = this.state.reservations.get(`${currentDate}`);
    if (reserved && reserved.length > 0) {
      info.push(
        <div class="reservedProductsOnDate">{reserved.length} varattu</div>
      );
    }
    let prods = super.renderDay(this.state, props, currentDate, selectedDate);
    info.push(prods);
    return info;
  }

  autoCompleteChange(event) {
    let val = event.target.value;
    this.setState({ autoCompleteValue: val });
    this.props.searchCustomer(
      this.props.location.area,
      this.props.location.language,
      val,
      true
    );
  }
  autoCompleteSelect(item) {
    console.log(`Selected customer ${item}`);
    let c = JSON.parse(item);
    this.setState({
      selectedCustomer: c,
      autoCompleteValue: `${c.firstName} ${c.lastName} (${c.email})`
    });
  }
}

export default WatchAdmin;

WatchAdmin.propTypes = {
  ensureLoggedIn: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  location: {
    ctx: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    area: PropTypes.string.isRequired
  }
};

const ReservedWatches = props => {
  if (!props.date) {
    return null; // no date selected
  }
  if (!props.reserved && !props.free) {
    console.log(
      "No free or reserved products on " +
        props.date.format() +
        " / " +
        props.date
    );
    return null;
  }

  let items = [];
  props.reserved.forEach(r => {
    items.push(
      <React.Fragment>
        <tr>
          <td>{r.email}</td>
          <td>
            {r.firstName} {r.lastName}
          </td>
        </tr>
        <tr>
          <td colspan="2">{r.adminTxt}</td>
        </tr>
        <tr>
          <td>{r.placeName}</td>
          <td>
            <BkModifyButton
              buttonTitle="Vapauta"
              callback={() =>
                props.cancelReservation({
                  customerId: r.customerId,
                  productId: r.productId,
                  date: props.date
                })
              }
            />
          </td>
        </tr>
      </React.Fragment>
    );
  });
  let free = [];
  if (props.free && props.free.length > 0) {
    let r = props.free[0];
    free.push(
      <React.Fragment>
        <tr>
          <td>
            <span>Asiakas (nimi, sähköposti):</span>
            <AutoComplete
              value={props.autoCompleteValue}
              onChange={props.autoCompleteChange}
              onSelect={props.autoCompleteSelect}
              items={props.customers ? props.customers : []}
              getItemValue={item => `${JSON.stringify(item)}`}
              renderItem={(item, isHighlighted) => (
                <div
                  style={{ background: isHighlighted ? "lightgray" : "white" }}
                >
                  <span entityid={item.id}>
                    {`${item.firstName} ${item.lastName} (${item.email})`}
                  </span>
                </div>
              )}
            />
          </td>
          <td>{r}</td>
          <td>
            <BkModifyButton
              buttonTitle="Varaa"
              callback={() => props.reserve(r)}
            />
          </td>
          <td>
            <BkModifyButton
              buttonHover="Voit antaa kommentin hakukenttään ja se tallennetaan varauksen yhteyteen"
              buttonTitle="Merkitse varatuksi"
              callback={() => props.reserve(r, true)}
            />
          </td>
        </tr>
      </React.Fragment>
    );
  }

  return (
    <div className="bk-centerColumn">
      <h2>{props.date.format("DD.MM.YYYY")}</h2>
      <h3>Varaukset</h3>
      <table>{items}</table>
      <h3>Vapaat vuorot</h3>
      <table>{free}</table>
    </div>
  );
};
