import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { CircleLoader } from "react-spinners";

const Reservation = props => {
  const { uic, location } = props;
  const t = key => location.translate(location, key);
  let className = "";
  let content = undefined;
  if (uic.reservationStatus === "PAID") {
    className = "paid";
  }
  className = "";
  content = <React.Fragment>{uic.placePrice} €</React.Fragment>;
  return <div className={className}>{content}</div>;
};

export const GuestReservationCalendar = props => {
  const { location, reservationCalendar, start } = props;

  if (!reservationCalendar || !reservationCalendar.codeToDays) {
    return (
      <center>
        <CircleLoader />
      </center>
    );
  }

  const rows = [];
  let even = false;
  for (var place in reservationCalendar.codeToDays) {
    const reservations = [];
    if (rows.length === 0) {
      const headerRow = [<th />];
      for (var i = 0; i < reservationCalendar.codeToDays[place].length; i++) {
        const day = moment(start).add(i, "days");
        const cls =
          day.isoWeekday() === 6 || day.isoWeekday() === 7
            ? "sticky weekend"
            : "sticky";
        headerRow.push(<th className={cls}>{day.format("DD.MM.")}</th>);
      }
      rows.push(<tr className="sticky">{headerRow}</tr>);
    }
    const pushReservation = (title, resp, idx, colspan) => {
      const resDivs = [];
      if (title) {
        resDivs.push(<div className="small">{title}</div>);
      }
      const res = [...resp];
      res.forEach(uic =>
        resDivs.push(<Reservation uic={uic} location={location} />)
      );
      reservations.push(<td colspan={colspan}>{resDivs}</td>);
    };
    let placeStr = undefined;
    let placeSizeStr = undefined;
    reservationCalendar.codeToDays[place].forEach((puic, idx) => {
      const rss = reservationCalendar.codeToDaysReservations[place][idx];
      const tit = puic.metricDescription;
      placeStr = puic.placeTypeStr;
      placeSizeStr = puic.placeWidth
        ? `${puic.placeLength}x${puic.placeWidth}x${puic.placeDepth}`
        : undefined;

      pushReservation(tit, rss, idx, 1);
    });
    rows.push(
      <tr className={even ? "td-even" : "td-odd"}>
        <th>
          <span className="small">
            <span className="small">{place}</span>
            {placeStr && <span className="small"> / {placeStr}</span>}
            {placeSizeStr && <span className="small"> / {placeSizeStr}</span>}
          </span>
        </th>
        {reservations}
      </tr>
    );
    even = !even;
  }
  return (
    <center>
      <table className="reservationCalendar guestReservationCalendar bk-parking-list">
        {rows}
      </table>
    </center>
  );
};

export default GuestReservationCalendar;

GuestReservationCalendar.propTypes = {
  reservationCalendar: PropTypes.object.isRequired,
  location: {
    ctx: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    area: PropTypes.string.isRequired
  }
};
