import ax from "./axiosApi";
import PropTypes from "prop-types";

export const createUpdate = (data, location, okCallback, errorCallback) => {
  let ep;
  if (location.area) {
    ep = `/${location.ctx}/${location.language}/a${location.area}/${location.endpoint}/createUpdate`;
  } else {
    ep = `/${location.ctx}/${location.language}/${location.endpoint}/createUpdate`;
  }
  return ax()
    .post(ep, data, {
      withCredentials: true
    })
    .then(ret => {
      okCallback(ret.data);
    });
};

createUpdate.propTypes = {
  data: PropTypes.any.isRequired,
  location: {
    ctx: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired
  },
  okCallback: PropTypes.func.isRequired
};
