import React from "react";
import PropTypes from "prop-types";

class BkModifyButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.state = {
      busy: props.busy === undefined ? false : props.busy
    };
  }
  componentDidUpdate(prevProps) {
    if (this.props.busy === undefined) {
      // do nothing
    } else if (this.props.busy && !prevProps.busy && !this.state.busy) {
      this.setState({ busy: true });
    } else if (!this.props.busy && prevProps.busy && this.state.busy) {
      this.setState({ busy: false });
    }
  }
  handleClick(event) {
    console.log("Button clicked => " + this.props.callback);
    let ret = this.props.callback(this.props.cbParameter, event);
    if (ret && typeof ret.then === "function") {
      console.log("Setting busy flag");
      this.setState({ busy: true });
      var thisComponent = this;
      ret.then(() => {
        console.log("Finished, clearing busy flag");
        thisComponent.setState({ busy: false });
      });
    } else {
      console.log("Callback doesn't return a promise: " + ret);
    }
  }
  render() {
    let style = {
      backgroundRepeat: "no-repeat",
      //backgroundAttachment: "fixed",
      backgroundPosition: "center center"
    };
    if (this.state.busy) {
      style.backgroundImage = 'url("/ajax-loader.gif")';
    }
    return (
      <button
        disabled={this.props.disabled || this.state.busy}
        className={`ui-button ui-corner-all ui-widget ${
          this.props.disabled ? "ui-state-disabled" : ""
        }`}
        style={style}
        onClick={this.handleClick}
        title={this.props.buttonHover ? this.props.buttonHover : ""}
      >
        {this.props.buttonTitle ? this.props.buttonTitle : "Muokkaa"}
      </button>
    );
  }
}
export default BkModifyButton;

/**
 *
 * @param {*} butts array of objects with properties: title, disabledCallback. See SeasonCustomer.js.
 * @param {*} cbData second parameter to disabledCallback (first parameter being the button specification)
 * @param {*} cmp component, whose reloadData callback is called when button callback is called (optional)
 */
export const getButtons = (butts, cbData, cmp) => {
  if (!butts) {
    return [];
  }
  let arr = [];
  for (let i = 0; i < butts.length; i++) {
    if (butts[i] && butts[i].title && butts[i].callback) {
      let disabled = butts[i].disabledCallback
        ? butts[i].disabledCallback(butts[i], cbData)
        : false;
      let cb = d => {
        if (butts[i].callback) {
          const res = butts[i].callback(cbData);
          Promise.resolve(res).then(v => {
            if (v && cmp && cmp.reloadData) {
              cmp.reloadData({});
            }
          });
        }
      };
      if (butts[i].fileUpload) {
        arr.push(
          <span>
            <label for={butts[i].title}>{butts[i].title}</label>
            <input
              name={butts[i].title}
              type="file"
              accept={butts[i].fileExtensions}
              disabled={disabled}
              title={butts[i].title}
              onChange={e => {
                butts[i].callback(e.target.files[0]);
                if (cmp && cmp.reloadData) {
                  cmp.reloadData({});
                }
              }}
            />
          </span>
        );
      } else {
        arr.push(
          <BkModifyButton
            callback={cb}
            buttonTitle={butts[i].title}
            disabled={disabled}
            cbParameter={cbData}
          />
        );
      }
    }
  }
  return arr;
};

BkModifyButton.propTypes = {
  callback: PropTypes.func.isRequired,
  cbParameter: PropTypes.any,
  buttonTitle: PropTypes.string,
  buttonHover: PropTypes.string,
  busy: PropTypes.bool,
  disabled: PropTypes.bool
};
