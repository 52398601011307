import { Map } from "immutable";
import React from "react";
import { CircleLoader } from "react-spinners";
import Customer from "../components/boatparking/Customer";
import BkContentArea from "../components/layout/BkContentArea";
import BkModifyButton from "../components/layout/BkModifyButton";
import { genericPost } from "../services/axiosApi";

class KokkolaForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: Map(),
      saveDisabled: true,
      saving: false,
      saved: false
    };
    props.getTranslations("booking-web", "fi", "reserve");
    this.renderAccount = this.renderAccount.bind(this);
    this.save = this.save.bind(this);
    this.updateCustomer = this.updateCustomer.bind(this);
  }
  save() {
    genericPost("fi", "userform", this.state.customer).then(r => {
      if (!r.errorStatus) {
        this.setState({ saving: false, saved: true });
      } else {
        this.setState({
          saving: false,
          errorMessage: r.errorMessage
            ? r.errorMessage
            : "Tallennuksessa tapahtui virhe. Tarkista arvot ja yritä uudestaan."
        });
      }
    });
  }
  render() {
    const l = this.props.location; // shortcut
    const t = key => l.translate(l, key);

    if (t("close-button") === "close-button") {
      setTimeout(() => {
        if (t("close-button") === "close-button") {
          this.props.getTranslations("booking-web", l.language, "reserve");
        }
      }, 2);
      return <CircleLoader />;
    }

    return this.renderAccount(t);
  }

  renderAccount(t) {
    return (
      <BkContentArea>
        <center style={{ marginRight: "50px" }}>
          <div className="bkProfile-leftColumn">
            <div className="togglecontainer">
              <section>
                <div className="single_toggle">
                  <div className="bk-special-heading">
                    <img src="/kokkola.png" alt="" style={{ width: "150px" }} />
                    <h3 className="column-title">Omat venepaikkatiedot</h3>
                  </div>
                  {this.state.saved && (
                    <div className="bk_okMessage">
                      Tiedot tallennettu onnistuneesti. Voit sulkea selaimen.
                    </div>
                  )}
                  {!this.state.saved && (
                    <React.Fragment>
                      {this.state.errorMessage && (
                        <div className="bk_errorMessage display">
                          {this.state.errorMessage}
                        </div>
                      )}
                      <br style={{ clear: "both" }} />
                      <div className="bk_placeDetails">
                        <p style={{ textAlign: "left" }}>
                          Täytä alla oleviin kenttiin yhteys- ja venetietonne.
                          Pakolliset kentät on merkitty tähdellä (*). Lähetämme
                          myöhemmin lomakkeella ilmoitettuun sähköpostiin
                          paikkakohtaisen varauslinkin, jonka kautta voit maksaa
                          venepaikkasi. Lomakkeen tiedot eivät sido ottamaan
                          paikkaa vastaan.
                        </p>
                      </div>
                      <Customer
                        t={t}
                        autocomplete={true}
                        updateCustomer={(field, value) =>
                          this.updateCustomer(field, value)
                        }
                        stateCustomer={this.state.customer}
                        renderAsTable={true}
                        showAddress={true}
                        requireAddress={true}
                        repeatEmail={true}
                        askBoatSize={true}
                        boatRegister={true}
                        boatOptional={true}
                        {...this.props}
                      />

                      <div className="bk_placeDetails">
                        <center>
                          <label>*Satama</label>
                        </center>
                        <center>
                          <select
                            onInput={e =>
                              this.updateCustomer("harbour", e.target.value)
                            }
                            onChange={e =>
                              this.updateCustomer("harbour", e.target.value)
                            }
                          >
                            <option value="">- valitse -</option>
                            <option>Suntinsuu</option>
                            <option>Asuntomessualue</option>
                            <option>Trullevin kalasatama</option>
                            <option>Trullevin pienevenesatama</option>
                            <option>Mansikkakari</option>
                            <option>Karhin kalasatama</option>
                            <option>Tankar</option>
                            <option>Ohtakari</option>
                            <option>Rytikari</option>
                            <option>Poroluodonkari (Krunni)</option>
                          </select>
                        </center>
                      </div>
                      <br />
                      <BkModifyButton
                        disabled={this.state.saveDisabled}
                        busy={this.state.saving}
                        buttonTitle="Lähetä"
                        callback={this.save}
                      />
                    </React.Fragment>
                  )}
                </div>
              </section>
            </div>
            <div
              className="bk-guest-rental-footer"
              style={{ position: "static" }}
            >
              <a
                href="https://www.kokkola.fi/kokkolan-kaupunki/tietosuoja/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Tietosuoja
              </a>{" "}
              | veneily@kokkola.fi
            </div>
          </div>
        </center>
      </BkContentArea>
    );
  }
  updateCustomer(fieldName, value) {
    const c = this.state.customer.set(fieldName, value);
    this.setState({
      customer: c,
      saveDisabled:
        this.requiredDataMissing(c) || c.get("email") !== c.get("repeatEmail")
    });
  }
  requiredDataMissing(c) {
    console.log(JSON.stringify(c));
    return (
      !c.get("harbour") ||
      !c.get("firstName") ||
      !c.get("lastName") ||
      !c.get("mobile") ||
      !c.get("email") ||
      !c.get("streetAddress") ||
      !c.get("town") ||
      !c.get("postOffice")
    );
  }
}

export default KokkolaForm;
