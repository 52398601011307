import React from "react";
import AutoComplete from "react-autocomplete";
import BkModifyButton from "../layout/BkModifyButton";
import BkContentArea from "../layout/BkContentArea";
import ImageMapper from "react-image-mapper";
import { CircleLoader } from "react-spinners";
import { Route } from "react-router";
import Customer from "./Customer";
import moment from "moment";
import DateRangeSelector from "./DateRangeSelector";
import { Map } from "immutable";
import { Conditions } from "../payment/Conditions";
import GuestReservationCalendar from "./GuestReservationCalendar";
import { genericDelete, genericPost } from "../../services/axiosApi";
import { DateSelector } from "./DateSelector";
import reservationService from "../../services/reservation";
import { shallowEqual } from "recompose";
import LanguageSelection from "./LanguageSelection";

export const uiState = {
  /** area: select harbour */
  area: "area",
  /** select between picnic place and guest reservation */
  dayPlace: "dayPlace",
  /** enter boat size if required */
  dayPlaceSize: "dayPlaceSize",
  /** enter start and end dates or duration in days */
  dates: "dates",
  /** subarea: selecting place or parking area */
  subarea: "subarea",
  /** customer: enter/check customer details */
  customer: "customer",
  /** confirm: final check, go directly to payment (or go back/cancel) */
  confirm: "confirm",
  /** confirmed: do not render anything, just forward to Bambora */
  pay: "pay",
  /** ??? */
  payment: "payment"
};

class BoatParking extends React.Component {
  constructor(props) {
    super(props);
    let pier = undefined;
    if (props.location.file && props.location.file.length < 3) {
      pier = props.location.file;
    }
    let acv = undefined;
    const w = props.boatParkWiz;
    if (w && w.areaCode && w.areaName) {
      if (!w.days) {
        acv = `${w.areaCode}: ${w.areaName}`;
      }
    }
    this.state = {
      language: props.location.language,
      areas: undefined,
      subareas: undefined,
      modalIsOpen: false,
      customer: {},
      customerComplete: false, // required fields set
      data: w
        ? w
        : {
            start: this.props.guest ? moment() : undefined
          },
      errorMessage: undefined,
      areaCode: w && w.areaCode ? w.areaCode : undefined,
      autoCompleteValue: acv,
      pier: pier,
      phase: uiState.area,
      conditionsApproved: false,
      end: moment().add(1, "days")
    };
    // without this we don't have the right "this" reference in the callback
    this.setSubarea = this.setSubarea.bind(this);
    this.autoCompleteChange = this.autoCompleteChange.bind(this);
    this.autoCompleteSelect = this.autoCompleteSelect.bind(this);
    this.continue = this.continue.bind(this);
    this.renderDayPlace = this.renderDayPlace.bind(this);
    this.renderDates = this.renderDates.bind(this);
    this.retry = this.retry.bind(this);
    this.renderSubarea = this.renderSubarea.bind(this);
    this.renderDaysOrDaily = this.renderDaysOrDaily.bind(this);
    this.checkLanguageChange = this.checkLanguageChange.bind(this);
    this.updateCustomer = this.updateCustomer.bind(this);
    this.askStart = this.askStart.bind(this);
    this.askOnlyStart = this.askOnlyStart.bind(this);
    this.askStartEnd = this.askStartEnd.bind(this);
    this.setDataValue = this.setDataValue.bind(this);
    this.setDataValues = this.setDataValues.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.boatParkWizard = this.boatParkWizard.bind(this);
  }

  endpoint = "boatparking";
  ctx = "booking-web";

  controller = new AbortController();

  boatParkWizard(area, language, data) {
    console.log("start: " + moment(data.start).toString());
    return reservationService.boatParkWizard(area, language, data, { signal: this.controller.signal }).then(w => {
      var minDate = undefined;
      if (this.state.data.reset && w.reference === this.state.data.reference) {
        var start = moment();
        if (this.props.guest) {
          if (w && w.guestPlacesActive) {
            w.start = moment(w.guestPlacesActive);
            console.log("setting start to guestPlacesActive: " + w.guestPlacesActive + ", which is: " + w.start.toString());
            minDate = w.start;
          } else {
            w.start = moment();
          }
          start = w.start;
          console.log("set start: " + moment(start).toString() + ", guestPlacesActive: " + w.guestPlacesActive);
        } else {
          w.start = undefined;
        }
        var end = moment(start).add(1, "days");
        this.setState({ data: w, customer: {}, customerComplete: false, boatParkWiz: w, end: end, minDate: minDate });
        console.log("Updated state.data to: " + JSON.stringify(w));
      } else { 
        if (this.state.data.reset) {
          console.log(`Reset, but ref not match: ${w.reference} / ${this.state.data.reference}`);
        }
    
        if (!shallowEqual(this.state.boatParkWiz, w)) {
          if (w.guestPlacesActive) {
            minDate = moment(w.guestPlacesActive);
          }

          var end = this.state.end;
          var start;
          if (!w.start && this.props.guest) {
            const start = minDate ? minDate : moment();
            end = moment(start).add(1, "days")
          } else {
            start = moment(w.start);
            if (this.state.end.isBefore(start)) {
              end = moment(start).add(1, "days")
            }
          }
          const nw = {...w, start: start };
          this.setState({ data: nw, boatParkWiz:  nw, end: end, minDate: minDate });
        }
      }
    });
  }

  updateWindowDimensions() {
    if (this.state.winWidth != window.innerWidth || this.state.winHeight != window.innerHeight) {
      this.setState({
        winWidth: window.innerWidth,
        winHeight: window.innerHeight
      });
    }
  }
  componentDidMount() {
    let loc = this.props.location;
    if (loc.area) {
      this.props.getTranslations(
        this.ctx,
        loc.language,
        `a${loc.area}/${this.endpoint}`
      );
    } else {
      console.log("No area!");
    }
    this.boatParkWizard(loc.area, loc.language, this.state.data);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }
  checkLanguageChange(props, prevProps) {
    const l = props.location;
    const p = prevProps.location;
    if (
      p.area !== l.area ||
      p.language !== l.language ||
      prevProps.jwt !== this.props.jwt
    ) {
      const ep = `a${l.area}/${this.endpoint}`;
      props.getTranslations(this.ctx, l.language, ep);
    }
    this.setState({ languageSelectionOpen: false });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  continue(phase, nextPhase) {
    let data = this.state.data;

    if (nextPhase == uiState.dates && data.subareaCode) {
      this.setDataValue("subareaCode", undefined);
      data.subareaCode = undefined;
      this.retry(data);
    } else if (
      phase == uiState.subarea &&
      !data.subareaCode &&
      this.state.boatParkWiz.subareas
    ) {
      // note: state is mutated, but doesn't hurt here
      data.subareaCode = this.state.boatParkWiz.subareas[0].code;
    } else if (nextPhase == uiState.pay) {
      this.setState({ redirecting: true });
      return;
    }
    if (this.state.customerComplete) {
      data["customer"] = this.state.customer;
    }
    if (this.state.boatParkWiz.orderNr) {
      data["orderNr"] = this.state.boatParkWiz.orderNr;
    }
    let subareas = this.state.subareas;
    if (this.state.boatParkWiz.subareas) {
      subareas = this.state.boatParkWiz.subareas;
    }
    let dayPlacesDisabled = this.state.dayPlacesDisabled;
    let loc = this.props.location;
    if (phase == uiState.confirm && nextPhase == uiState.payment) {
      data.confirmed = true;
      // do not call wizard, only causes duplicate reservation
    } else if (nextPhase == uiState.dates) {
      // no request
    } else if (nextPhase == uiState.area || nextPhase == uiState.dayPlace) {
      dayPlacesDisabled = undefined;
    } else {
      data.confirmed = false;
      this.boatParkWizard(loc.area, loc.language, data);
    }
    this.setState({
      phase: nextPhase,
      subareas: subareas,
      dayPlacesDisabled: dayPlacesDisabled
    });
  }

  retry(data) {
    let loc = this.props.location;
    this.boatParkWizard(loc.area, loc.language, data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.checkLanguageChange(nextProps, this.props);
    if (nextProps.orderNumber && !nextProps.orderData) {
      console.debug(
        `Order number: ${nextProps.orderNumber} data: ${nextProps.orderData}`
      );
      // check payment status
      nextProps.checkOrder(nextProps.location.language, nextProps.orderNumber);
    }

    const w = nextProps.boatParkWiz;
    // eslint-disable-next-line prettier/prettier
    if (w && w.areas && w.areaCode && (!this.state.autoCompleteValue || (this.state.data && !this.state.data.areaCode))) {
      if (w.areas.length > 0) {
        let data = {
          ...this.state.data
        };
        w.areas.forEach(area => {
          if (area.code === w.areaCode) {
            data.areaCode = area.code;
            this.setState({
              autoCompleteValue: `${data.areaCode}: ${area.name}`,
              data: data
            });
          }
        });
      }
    }
  }

  setDataValue(key, value) {
    let data = {
      ...this.state.data
    };
    data[key] = value;
    // eslint-disable-next-line prettier/prettier
    if (!data.areaCode && this.state.boatParkWiz && this.state.boatParkWiz.areaCode) {
      data.areaCode = this.state.boatParkWiz.areaCode;
    }
    const areas = this.state.boatParkWiz.areas
      ? this.state.boatParkWiz.areas
      : this.state.areas;
    this.setState({ data: data, areas: areas });
  }

  setDataValues(obj, state) {
    let data = {
      ...this.state.data
    };
    for (const key in obj) {
      const value = obj[key];
      data[key] = value;
    }
    // eslint-disable-next-line prettier/prettier
    if (!data.areaCode && this.state.boatParkWiz && this.state.boatParkWiz.areaCode) {
      data.areaCode = this.state.boatParkWiz.areaCode;
    }
    const areas = this.state.boatParkWiz.areas
      ? this.state.boatParkWiz.areas
      : this.state.areas;
    this.setState({ data: data, areas: areas, ...state });
  }

  setSubarea(value, imageUrl, imageMap) {
    let data = {
      ...this.state.data
    };
    console.log("setting subarea: " + value);
    data.subareaCode = value;
    this.setState({ data: data, guestMapLink: imageUrl, guestMap: imageMap });
  }

  autoCompleteChange(event) {
    let val = event.target.value;
    console.debug("change: " + val);
    this.setState({ autoCompleteValue: val });
  }

  updateCustomer(field, value) {
    let c = {
      ...this.state.customer
    };

    c[field] = value;

    let customerComplete =
      c.firstName &&
      c.lastName &&
      c.mobile &&
      c.email &&
      c.boatName &&
      c.boatType &&
      c.email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))+$/
      );

    this.setState({ customer: c, customerComplete: customerComplete });
  }

  autoCompleteSelect(item) {
    console.log(`Selected ${item}`);
    let c = JSON.parse(item);
    let data = {
      ...this.state.data
    };
    data.areaCode = c.code;
    this.setState({
      data: data,
      autoCompleteValue: c.name ? `${c.code}: ${c.name}` : c.code
    });
  }

  render() {
    if (!this.state.boatParkWiz) {
      return null;
    }
    if (this.state.language !== this.props.location.language) {
      console.log("language mismatch");
    }

    const w = this.state.boatParkWiz;

    let errorMessage = "";
    if (
      this.state.errorMessage ||
      this.props.paymentError ||
      (w && w.errorMessage)
    ) {
      console.log(
        `error: ${this.state.errorMessage}, paymentError: ${this.props.paymentError}`
      );
      errorMessage = this.state.errorMessage
        ? this.state.errorMessage
        : this.props.paymentError
        ? this.props.paymentError
        : w.errorMessage;
    }
    const t = key => this.props.location.translate(this.props.location, key);

    let content = <div />;
    let footer = [];

    let buttonTitle = t("continue");
    let header = t("phase1Title");

    let nextPhase = uiState.subarea;

    let canContinue = false;
    if (w && errorMessage) {
      header = "";
      if (w) {
        content = (
          <center>
            <p>{errorMessage}</p>
            <BkModifyButton
              buttonTitle={t("tryAgain")}
              callback={() => this.retry(w.requestData)}
            />
          </center>
        );
      } else {
        content = errorMessage;
      }
    } else if (this.props.orderNumber) {
      const o = this.props.orderData;
      if (!o) {
        header = "";
        content = <CircleLoader />;
      } else {
        header = o.title;
        content = (
          <React.Fragment>
            <p>{o.text}</p>
            <p>{o.paymentDetails}</p>
          </React.Fragment>
        );

        if (o.status === "PAID") {
          buttonTitle = undefined;
        } else if (
          o.status === "CANCELLED_BY_PROVIDER" ||
          o.status === "NOT_PAID"
        ) {
          canContinue = true;
          buttonTitle = t("startOver");
        }
      }
      // eslint-disable-next-line eqeqeq
    } else if (this.state.redirecting || this.state.phase == uiState.payment) {
      header = t("phase5Title");
      if (w.payRedirectUrl) {
        window.location.href = this.state.boatParkWiz.payRedirectUrl;
      }
      content = <p>{t("redirecting")}</p>;
      nextPhase = uiState.pay;
      content = <CircleLoader />;
    } else if (this.state.phase == uiState.confirm) {
      nextPhase = uiState.payment;
      header = t("phase4Title");
      if (!w.totalPrice) {
        console.debug("No totalPrice: " + w.totalPrice);
        content = <CircleLoader />;
      } else {
        const conditions = [];
        if (w.conditionUrl) {
          conditions.push(
            <center>
              <Conditions
                t={t}
                agreedOn={w && w.customer ? w.customer.agreedOn : undefined}
                toggleAgree={() =>
                  this.setState({
                    conditionsApproved: !this.state.conditionsApproved
                  })
                }
                cartId={w.orderId}
                agreed={this.state.conditionsApproved}
                language={this.props.location.language}
                url={w.conditionUrl}
              />
            </center>
          );
        }
        content = (
          <div>
            <center className="header">{t("starts")}</center>
            <center>{w.actualFrom}</center>
            <center className="header">{t("ends")}</center>
            <center>{w.actualUntil}</center>
            <center className="header">{t("place")}</center>
            <center>{w.placeDescription}</center>
            <center className="header">{t("price")}</center>
            <center>{w.totalPrice.toFixed(2)} eur</center>
            {w.productInformation && <center className="header">{t("productInformation")}</center>}
            {w.productInformation && <center>{w.productInformation}</center>}
            {conditions}
          </div>
        );
        footer.push(<br />);
        footer.push(
          <img
            src="https://static.vismapay.com/pay_banners/row.png"
            style={{ width: "100%", maxWidth: "550px" }}
            alt="Payform payment logos"
          />
        );
        canContinue =
          this.state.conditionsApproved ||
          !w.conditionUrl ||
          (w.customer && !!w.customer.agreedOn);
      }
      buttonTitle = t("buttonPay");
    } else if (this.state.phase == uiState.area) {
      canContinue = this.phaseDone(uiState.area, this.state, this.props);
    } else if (this.state.phase == uiState.dayPlaceSize) {
      if (w.askSize) {
        content = askSize(t, this.state.data, this.setDataValue);
        canContinue = this.state.data.width && this.state.data.length;
      } else {
        this.continue(this.state.phase, nextPhase);
      }
      nextPhase = uiState.subarea;
    } else if (this.state.phase == uiState.dayPlace) {
      content = this.renderDayPlace(w, t);
      nextPhase = uiState.dates;
      canContinue = false;
      buttonTitle = undefined;
    } else if (this.state.phase == uiState.dates) {
      // render area input
      content = this.renderDates(w, t, this.state.areas);
      canContinue = this.phaseDone(uiState.dates, this.state, this.props);
      nextPhase = uiState.subarea;
    } else if (this.state.phase == uiState.subarea) {
      header = t("phase2Title");
      // render subarea input
      ({ canContinue, content, footer } = this.renderSubarea(w, t));
      nextPhase = uiState.customer;
    } else if (this.state.phase == uiState.customer) {
      header = t("phase3Title");
      // render customer input
      canContinue = this.state.customerComplete;
      content = (
        <Customer
          t={t}
          updateCustomer={this.updateCustomer}
          stateCustomer={Map(this.state.customer)}
          autocomplete={true}
        />
      );
      nextPhase = uiState.confirm;
    }
    console.log("Phase: " + this.state.phase);

    let languages = <LanguageSelection
      language={this.props.location.language}
      open={this.state.languageSelectionOpen} 
      getTranslations={(lang) => this.props.getTranslations(this.ctx, lang, `a${this.props.location.area}/${this.endpoint}`)} 
      toggleOpen={() =>
        this.setState({
          languageSelectionOpen: !this.state.languageSelectionOpen
        })
      }
      getLink={(lang) => `/${this.props.guest ? "guest" : "park"}/${lang}/${this.props.location.area}`}
    />;

    const ph1done = this.phaseDone(uiState.area, this.state, this.props);
    const ph1_1done = this.phaseDone(uiState.dayPlace, this.state, this.props);
    const ph1_2done = this.phaseDone(uiState.dates, this.state, this.props);
    const ph2done = this.phaseDone(uiState.subarea, this.state, this.props);
    const ph3done = this.state.customerComplete;
    const ph4done = this.state.redirecting;

    const selectedDateAndSize =
      this.props.guest && this.state.phase == uiState.subarea && w.start ? (
        <span className="small">
          {moment(w.start).format("DD.MM.YYYY") + " - "}
          {moment(w.start)
            .add(w.days, "days")
            .format("DD.MM.YYYY") + ","}
          {t("boat") + " "}
          {w.length} x {w.width} x {w.depth} m
        </span>
      ) : (
        undefined
      );

    let step = 1;
    const steps = (
      <ol className="progtrckr">
        <li className={ph1done ? "progtrckr-done" : "progtrckr-doing"}>
          <em>{step++}</em>
          <span>
            <div>{t("phase1TitleShort")}</div>
          </span>
        </li>
        <li
          className={
            ph2done
              ? "progtrckr-done"
              : this.state.phase == uiState.subarea
              ? "progtrckr-doing"
              : "progtrckr-todo"
          }
        >
          <em>{step++}</em>
          <span>
            <div>{t("phase2TitleShort")}</div>
          </span>
        </li>
        <li
          className={
            ph3done
              ? "progtrckr-done"
              : this.state.phase == uiState.customer
              ? "progtrckr-doing"
              : "progtrckr-todo"
          }
        >
          <em>{step++}</em>
          <span>
            <div>{t("phase3TitleShort")}</div>
          </span>
        </li>
        <li
          className={
            this.state.redirecting || this.state.phase == uiState.confirm
              ? "progtrckr-done"
              : "progtrckr-todo"
          }
        >
          <em>{step++}</em>
          <span>
            <div>{t("phase4TitleShort")}</div>
          </span>
        </li>
        <li className="progtrckr-todo">
          <em>{step++}</em>
          <span>
            <div>{t("phase5TitleShort")}</div>
          </span>
        </li>
      </ol>
    );

    const button = buttonTitle ? (
      this.props.orderNumber ? (
        <Route
          render={({ history }) => (
            <BkModifyButton
              buttonTitle={buttonTitle}
              callback={() =>
                history.push(
                  `/${this.props.guest ? "guest" : "park"}/${
                    this.props.location.language
                  }/${this.props.location.area}`
                )
              }
            />
          )}
        />
      ) : (
        <BkModifyButton
          disabled={!canContinue}
          buttonTitle={buttonTitle}
          callback={() => this.continue(this.state.phase, nextPhase)}
        />
      )
    ) : (
      <div />
    );

    const warningMessage =
      this.state.phase != uiState.dates && w.warning ? (
        <div className="bk_errorMessage display">{w.warning}</div>
      ) : (
        undefined
      );
    const actionContentClass =
      this.state.phase == uiState.subarea
        ? "action-content sticky"
        : "action-content";

    const ph = this.state.phase;
    const backLink = (ph == uiState.subarea ||
      ph == uiState.customer ||
      ph == uiState.confirm) && (
      <div class="small">
        <a
          onClick={() => {
            this.controller.abort();
            const ref = "r" + Math.floor(Math.random() * 100000);
            const data = {
              reset: true,
              reference: ref,
              start: this.state.data.start
            };
            genericDelete(
              this.props.location.language,
              "boatparking/cancel/" + this.state.boatParkWiz.orderNr
            ).then(() => {
              this.retry(data);
              this.setState({
                phase:
                  this.state.boatParkWiz.dayPlaces && this.props.guest
                    ? uiState.dayPlace
                    : uiState.dates,
                data: data
              });
            });
          }}
        >
          &#xab; {t("startOver")}
        </a>
      </div>
    );
    return (
      <React.Fragment>
        <BkContentArea>
          <center>
            <div className="harbour-and-season-container">
              <div className="bookkaaLogoHeader">{languages}</div>
              <div className="wiz-header">
                <h3>{header}</h3>
              </div>
              <div className="harbour-title">
                <center>{w.areaName}</center>
              </div>
              <div className="step-progress">{steps}</div>
              {selectedDateAndSize}
              {warningMessage}
              <div className={actionContentClass}>
                {content}
                <center>{button}</center>
                {backLink}
              </div>
              <br />
              {footer}
            </div>
          </center>
          <div className="bk-guest-rental-footer">
            &copy; 2024 bookkaa.fi |&nbsp;
            <a
              href="http://www.satamapaikka.com/kayttoehdot2/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("conditionsLink")}
            </a>
          </div>
        </BkContentArea>
      </React.Fragment>
    );
  }
  phaseDone(phase, state, props) {
    let done = false;
    const w = this.state.boatParkWiz ? this.state.boatParkWiz : this.state.data;
    if (phase == uiState.area) {
      done = !!w.areaCode;
      if (done && this.state.phase == uiState.area) {
        this.setState({
          phase: props.guest && w.dayPlaces ? uiState.dayPlace : uiState.dates
        });
      }
    } else if (phase == uiState.dayPlace) {
      done = !props.guest || state.dayPlacesDisabled;
    } else if (phase == uiState.dates) {
      done =
        state.data.areaCode &&
        (!w.askSize ||
          (state.data.width && state.data.length && state.data.depth)) &&
        ((!props.guest &&
          (state.data.dayParking ||
            (state.data.days && state.data.days > 0))) ||
          state.data.start);
      const diff = state.end.diff(state.data.start, "days");
      console.log(
        `Size: ${state.data.width} x ${state.data.width} x ${
          state.data.depth
        }, start: ${moment(state.data.start).format("YYYY-MM-DD")
        }, end: ${moment(state.end).format("YYYY-MM-DD")
        }, diff: ${diff
        }, days: ${state.data.days
        }, park: ${state.data.dayParking
        }, area: ${state.data.areaCode}`
      );
      if (
        props.guest &&
        state.data.days !== diff &&
        state.data.start &&
        state.end
      ) {
        if (diff > 0 && !state.data.dayParking) {
          this.setDataValue("days", diff);
        }
      }
      done = done && (diff > 0 || !props.guest);
    } else if (phase === uiState.subarea) {
      done = this.state.data.subareaCode;
    }
    done = !!done;
    console.log(
      `phaseDone for ${phase} (guest=${!!props.guest}) returning ${done}`
    );
    return done;
  }

  renderSubarea(w, t) {
    const sa = w.subareas ? w.subareas : this.state.subareas;
    const l = this.props.location;

    if (!sa) {
      let canContinue = false;
      let content = <CircleLoader />;
      let footer = undefined;
      return { canContinue, content, footer };
    }

    if (this.state.showAlternatives && this.props.guestCalendar) {
      let footer = undefined;
      let content = (
        <React.Fragment>
          <center>
            <a onClick={() => this.setState({ showAlternatives: false })}>
              {t("back")}
            </a>
          </center>
          <GuestReservationCalendar
            reservationCalendar={this.props.guestCalendar}
            location={this.props.location}
          />
        </React.Fragment>
      );
      let canContinue = // TODO: if all places selected OR "confirm not all days" checked
        this.props.guestCalendar.codeToDays &&
        this.props.guestCalendar.codeToDays.length > 0;
      return { canContinue, content, footer };
    }

    let content = undefined;
    let footer = undefined;
    let canContinue = false;

    // guest place: render as list with more details
    let defaultImageUrl = undefined;
    let defaultImageArea = undefined;
    const activePlaceCodes = [];
    sa.forEach(n => {
      activePlaceCodes.push(n.code);
      let imageUrl = undefined;
      let imageMap = undefined;
      if (w.wholeArea) {
        defaultImageUrl = `/booking-web/${l.language}/images/${l.area}_${w.wholeArea.subarea}.png?h=${w.wholeArea.hash}`;
        defaultImageArea = w.wholeArea;
      }
      if (w.areaImageMaps) {
        w.areaImageMaps.forEach(aim => {
          if (aim.subareaCode === n.subarea) {
            imageUrl = `/booking-web/${l.language}/images/${l.area}_${aim.subarea}.png?h=${aim.hash}`;
            imageMap = aim;
          }
        });
      }
    });
    if (
      !this.state.data.subareaCode &&
      activePlaceCodes.length > 0 &&
      activePlaceCodes[0]
    ) {
      // this.setDataValue("subareaCode", activePlaceCodes[0]);
    }
    canContinue = !!this.state.data.subareaCode;

    const img = this.state.guestMapLink
      ? this.state.guestMapLink
      : defaultImageUrl;
    const map = this.state.guestMapLink
      ? this.state.guestMap
      : defaultImageArea;

    /* <div style={{ float: "left" }} ref={ref => (maps[i].ref = ref)}> */
    // onMouseEnter={(area, _idx, event) => this.enterArea(area, event)}
    // onMouseLeave={area => this.leaveArea(area)}

    const sareas = [];

    const alternatives = [];

    if (
      false && // TODO: disabled for now
      this.props.guestCalendar &&
      this.props.guestCalendar.codeToDays &&
      this.props.guestCalendar.codeToDays.length > 0
    ) {
      alternatives.push(
        <center>
          <a onClick={() => this.setState({ showAlternatives: true })}>
            {t("alternatives")}
          </a>
        </center>
      );
    }

    let priceInfo = undefined;
    sareas.push(<option value="">{t("select")}</option>);
    sa.forEach(n => {
      // parking place: render as selection list
      let freeInfo = n.freeInfo
        ? ` (${n.freeInfo})`
        : this.props.guest
        ? ` (${n.length} x ${n.width} x ${n.depth} m)`
        : n.price
        ? ": " + n.price
        : "";
      let selected = n.code === this.state.data.subareaCode ? "selected" : "";
      if (n.code === this.state.data.subareaCode && n.typeStr) {
        priceInfo = n.typeStr + " " + n.price;
      }
      sareas.push(
        <option value={n.code} selected={selected}>
          {n.code}
          {freeInfo}
        </option>
      );
    });
    content = (
      <div>
        <center>{t("selectPlace")}</center>
        <center>
          <select onChange={event => this.setSubarea(event.target.value)}>
            {sareas}
          </select>
        </center>
        <center className="small">{priceInfo}</center>
        {alternatives}
      </div>
    );

    if (map && img) {
      let minW = map.imageWidth > 350 ? 350 : map.imageWidth;
      if (
        this.state.winWidth &&
        this.state.winWidth > 800 &&
        map.imageWidth > 350
      ) {
        minW =
          map.imageWidth > this.state.winWidth - 100
            ? this.state.winWidth - 100
            : map.imageWidth;
      }
      var divider = map.imageWidth / minW;

      const filteredMap = { ...map };
      filteredMap.areas = map.areas.filter(a =>
        activePlaceCodes.includes(a.code)
      );

      footer = (
        <center>
          <div className="bk-parking-map">
            <ImageMapper
              active={true}
              width={map.imageWidth / divider}
              imgWidth={map.imageWidth}
              src={img}
              map={filteredMap}
              onClick={area =>
                activePlaceCodes.includes(area.code) &&
                this.setSubarea(area.code, img, map)
              }
            />
          </div>
        </center>
      );
    }

    return { canContinue, content, footer };
  }

  renderDayPlace(w, t) {
    console.log("Rendering day place");
    return (
      <React.Fragment>
        <div className="title">
          <center>{t("dayOrGuestTitle")}</center>
        </div>
        <div className="title">
          <center style={{ marginTop: "15px" }}>
            <BkModifyButton
              buttonTitle={t("dayPlace")}
              disabled={!w.dayPlacesActive}
              callback={() => {
                const data = { ...this.state.data };
                data.dayParking = true;
                this.setState({ data: data }, () =>
                  this.continue(uiState.dayPlace, uiState.dayPlaceSize)
                );
              }}
            />
            <BkModifyButton
              buttonTitle={t("guestPlaceButton")}
              callback={() => {
                this.setState({ dayPlacesDisabled: true }, () =>
                  this.continue(uiState.dayPlace, uiState.dates)
                );
              }}
            />
          </center>
        </div>
      </React.Fragment>
    );
  }

  renderDates(w, t, stateAreas) {
    if (!w.areas && !stateAreas) {
      return <CircleLoader />;
    }

    if (w.reservationUnits && w.reservationUnits.length > 0) {
      console.log("wiz has units!");
    }

    const areas = w.areas ? w.areas : stateAreas;
    const size =
      this.props.guest || w.askSize
        ? askSize(t, this.state.data, this.setDataValue)
        : undefined;
    const datesOrDays = [];
    if (this.props.guest) {
      datesOrDays.push(this.askStart(t, this.state.data, w));
    }
    datesOrDays.push(this.renderDaysOrDaily(t, w));
    return (
      <div>
        {!w.areaCode || !w.areaName ? (
          <React.Fragment>
            <center>{t("enterHarbour")}</center>
            <center>
              <AutoComplete
                value={this.state.autoCompleteValue}
                onChange={this.autoCompleteChange}
                onSelect={this.autoCompleteSelect}
                items={areas}
                getItemValue={item => `${JSON.stringify(item)}`}
                renderItem={(item, isHighlighted) => (
                  <div
                    style={{
                      background: isHighlighted ? "lightgray" : "white"
                    }}
                  >
                    <span
                      entityid={item.id}
                    >{`${item.code}: ${item.name}`}</span>
                  </div>
                )}
              />
            </center>
          </React.Fragment>
        ) : (
          <React.Fragment />
        )}
        {datesOrDays}
        {size}
      </div>
    );
  }

  renderDaysOrDaily(t, w) {
    const dayPlaces = [];
    if (
      w.dayPlaces &&
      !w.parkingPlaces &&
      !this.props.guest &&
      !this.state.dayPlacesDisabled
    ) {
      if (!this.state.data.dayParking || this.state.data.days !== 0) {
        this.setDataValues({
          dayParking: true,
          days: 0
        });
      }
    } else if (
      w.dayPlaces &&
      w.parkingPlaces &&
      !this.props.guest &&
      !this.state.dayPlacesDisabled
    ) {
      dayPlaces.push(
        <div className="title">
          <center
            style={{
              marginTop: "15px",
              marginLeft: "-15px",
              marginRight: "-15px"
            }}
          >
            <div style={{ textAlign: "left", width: "max-content" }}>
              <input
                type="radio"
                name="dayOrPark"
                value="day"
                checked={this.state.data.dayParking}
                onChange={e =>
                  this.setDataValues({
                    dayParking: e.target.value === "day",
                    days: e.target.value === "day" ? 0 : 1
                  })
                }
              />
              {w.dayPlaceText}
              {!!w.dayPlaceText2 && (
                <React.Fragment>
                  <br />
                  <span style={{ marginLeft: "60px" }}>{w.dayPlaceText2}</span>
                </React.Fragment>
              )}
              <br />
              <input
                type="radio"
                name="dayOrPark"
                value="night"
                checked={!this.state.data.dayParking}
                onChange={e =>
                  this.setDataValues({
                    dayParking: e.target.value === "day",
                    days: e.target.value === "day" ? 0 : 1
                  })
                }
              />
              {t("dayPlace_parkingPlace")}
            </div>
          </center>
        </div>
      );
    }
    if (w.parkingPlaces && !this.props.guest) {
      return (
        <React.Fragment>
          {dayPlaces}
          <center>{t("days")}</center>
          <center>
            <input
              value={
                this.state.data.dayParking ? "" : this.state.data.days
              }
              type="number"
              min="1"
              max={w.maxDays}
              disabled={this.state.data.dayParking}
              onChange={event => this.setDataValue("days", event.target.value)}
            />
          </center>
        </React.Fragment>
      );
    } else {
      return dayPlaces;
    }
  }

  askStart(t, data, w) {
    let hours = false;
    let endDate = false;
    let dayPlace = false;
    if (w.reservationUnits && w.reservationUnits.length > 0) {
      w.reservationUnits.forEach(ru => {
        if (!hours && ru.type === 'HOURS') {
          hours = ru;
        }
        if (!endDate && ru.useEndDate) {
          endDate = ru;
        }
        if (!dayPlace && ru.onlyDuringDay) {
          dayPlace = ru;
        }
      });
    } else {
      endDate = true;
    }
    if (endDate && !hours && !dayPlace) {
      if (!data.selectedUnit && endDate.type) {
        this.setDataValue("selectedUnit", endDate);
      }
      return this.askStartEnd(t, data);
    } else if (!endDate && dayPlace) {
      if (!data.selectedUnit) {
        this.setDataValue("selectedUnit", this.state.endDateEnabled ? endDate : dayPlace);
      }
      return <center>{this.askOnlyStart(t, data, dayPlace)}</center>;
    } else if (endDate && dayPlace) {
      if (!data.selectedUnit) {
        this.setDataValue("selectedUnit", this.state.endDateEnabled ? endDate : dayPlace);
      }
      // note: selectedUnit logic the other way around when clicking checkbox than above, because 
      // this.state.endDateEnabled is old value that is changed on the click
      const endDateWasEnabled = this.state.endDateEnabled;
      const cb = <label><input type="checkbox" checked={!endDateWasEnabled} 
                    onClick={e => this.setDataValues(
                      { selectedUnit: !endDateWasEnabled ? endDate : dayPlace,
                        days: endDateWasEnabled ? 1 : data.days },
                      { endDateEnabled: !endDateWasEnabled,
                        end: endDateWasEnabled ? moment(data.start).add(1, "day") : this.state.end
                      }) } />{dayPlace.description}</label>
      if (this.state.endDateEnabled) {
        const vals = [];
        vals.push(this.askStartEnd(t, data));
        vals.push(<center>{cb}</center>);
        return vals;
      } else {
        return (
          <center>
              {this.askOnlyStart(t, data, dayPlace)}
              {cb}
          </center>
        );
      }
    } else {
      console.log("ERROR!");
      return <center />;
    }

  }

  askOnlyStart(t, data, unit) {
    return <DateSelector
              language={this.props.location.language}
              value={data.start}
              isEdit={this.state.editStartDate}
              callback={(v) =>{
                console.log("Start date: " + v + " end: " + moment(v).add(1, "day"));
                this.setDataValues(
                  { start: moment(v).startOf("day"), days: 1, selectedUnit: unit }, 
                  { editStartDate: false, end: moment(v).startOf("day").add(1, "day") }
                );
              }}
              enableEditCallback={() => this.setState({editStartDate: true})}
            />;
  }

  askStartEnd(t, data) {
    return (
      <React.Fragment>
        <center>
          <DateRangeSelector
            location={this.props.location}
            disabled={data.dayParking}
            minDate={this.state.minDate}
            start={data.dayParking ? moment() : data.start}
            end={data.dayParking ? moment() : this.state.end}
            startDateCallback={
              data.dayParking
                ? undefined
                : (s, e) => {
                    const days = e.diff(s, "days");
                    this.setState({ end: e });
                    this.setDataValues({ start: s, days: days });
                  }
            }
            endDateCallback={
              data.dayParking
                ? undefined
                : (e, s) => {
                    const days = e.diff(s, "days");
                    this.setState({ end: e });
                    this.setDataValues({ start: s, days: days });
                  }
            }
            beforeStartDate={
              <span style={{ display: "block" }}>{t("starts")}</span>
            }
            beforeEndDate={
              <span style={{ display: "block" }}>{t("ends")}</span>
            }
          />
        </center>
      </React.Fragment>
    );
  }
}

export const askSize = (t, data, setter) => {
  const inputProps = {
    size: "4",
    placeholder: "0,00",
    className: "small-input",
    inputmode: "decimal",
    onKeyPress: e => {
      if (e.key && !/[0-9,.]/.test(e.key)) {
        e.preventDefault();
      } else if (
        (e.key === "." || e.key === ",") &&
        (e.target.value.includes(".") || e.target.value.includes(","))
      ) {
        e.preventDefault();
      }
    }
  };
  return (
    <React.Fragment>
      <center className="title">{t("boatSize")}</center>
      <center style={{ marginTop: "-10px" }}>
        <div style={{ display: "inline-block" }}>
          <center>{t("boatLength")}</center>
          <input
            {...inputProps}
            value={data.length}
            onChange={e => setter("length", e.target.value.replace(/,/, "."))}
          />
        </div>
        x
        <div style={{ display: "inline-block" }}>
          <center>{t("boatWidth")}</center>
          <input
            {...inputProps}
            value={data.width}
            onChange={e => setter("width", e.target.value.replace(/,/, "."))}
          />
        </div>
        x
        <div style={{ display: "inline-block" }}>
          <center>{t("boatDepth")}</center>
          <input
            {...inputProps}
            value={data.depth}
            onChange={e => setter("depth", e.target.value.replace(/,/, "."))}
          />
        </div>
      </center>
    </React.Fragment>
  );
}

export default BoatParking;
