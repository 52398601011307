import React from "react";
import BkModifyButton from "../layout/BkModifyButton";

export const ReservationsTable = props => {
  const {
    t,
    reservations,
    openDetails,
    cancelReservation,
    extended,
    orderType,
    paymentMethod
  } = props;

  console.log("Rendering reservations: " + JSON.stringify(reservations));

  let rows = [];
  let odd = true;
  let sum = 0;
  let sumDays = 0;
  let sumFees = 0;
  const rl = reservations && Array.isArray(reservations) ? reservations : [];
  if (rl.length === 0) {
    rows.push(
      <tr>
        <td colspan="3">- ei rivejä -</td>
      </tr>
    );
  }
  rl.forEach(e => {
    if (
      (!orderType || orderType == e.orderType) &&
      (!paymentMethod || paymentMethod == e.reservationStatus)
    ) {
      sum += e.totalPrice ? e.totalPrice : e.placePrice;
      sumDays += parseInt(e.days);
      if (e.bookingFee && e.bookingFee > 0.0) {
        sumFees += e.bookingFee;
      }
      const cn = odd ? "td-odd" : "td-even";
      odd = !odd;
      let fromUntil = extended ? (
        <React.Fragment>
          <td className={cn}>{e.validFrom}</td>
          <td className={cn}>{e.validUntil}</td>
          <td className={cn} style={{ textAlign: "right" }}>
            {e.days}
          </td>
          <td className={cn}>{e.orderType}</td>
          <td className={cn} style={{ textAlign: "right" }}>
            {formatFloat(e.totalPrice ? e.totalPrice : e.placePrice)}
          </td>
          <td className={cn} style={{ textAlign: "right" }}>
            {formatFloat(e.bookingFee)}
          </td>
          <td className={cn}>
            {e.reservationStatus == "PAID"
              ? t("payment.web")
              : t("payment.admin")}
          </td>
        </React.Fragment>
      ) : (
        <React.Fragment />
      );
      rows.push(
        <tr>
          <td className={cn}>{e.parkArea}</td>
          <td className={cn}>{e.customer}</td>
          <td className={cn}>{e.boat}</td>
          {fromUntil}
          <td className={cn}>
            {openDetails ? (
              <BkModifyButton
                buttonTitle={t("open")}
                callback={() => openDetails(e)}
              />
            ) : (
              <React.Fragment />
            )}
            {cancelReservation ? (
              <BkModifyButton
                buttonTitle={t("cancelReservation")}
                callback={() => cancelReservation(e)}
              />
            ) : (
              <React.Fragment />
            )}
          </td>
        </tr>
      );
    }
  });
  if (extended) {
    rows.push(
      <tr>
        <td className="sum" />
        <td className="sum">{t("report.total")}</td>
        <td className="sum" />
        <td className="sum" />
        <td className="sum" />
        <td className="sum" style={{ textAlign: "right" }}>
          {sumDays}
        </td>
        <td className="sum" />
        <td className="sum" style={{ textAlign: "right" }}>
          {formatFloat(sum)}
        </td>
        <td className="sum" style={{ textAlign: "right" }}>
          {formatFloat(sumFees)}
        </td>
        <td className="sum" />
        <td className="sum" />
      </tr>
    );
  }
  let fromUntilHead = extended ? (
    <React.Fragment>
      <th>Alkoi</th>
      <th>Päättyi</th>
      <th>{t("nights")}</th>
      <th>{t("orderType")}</th>
      <th>{t("total")}</th>
      <th>Bookkaan toimitusmaksu (alv 0%)</th>
      <th>Maksutapa</th>
    </React.Fragment>
  ) : (
    <React.Fragment />
  );
  let table = (
    <table className="bk-parking-list">
      <tr>
        <th>{t("placeArea")}</th>
        <th>{t("customer")}</th>
        <th>Vene</th>
        {fromUntilHead}
        <th>Toiminnot</th>
      </tr>
      {rows}
    </table>
  );
  return table;
};

function formatFloat(num) {
  if (num && num.toFixed) {
    return num.toFixed(2).replace(".", ",");
  }
  return num;
}
