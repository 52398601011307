import React from "react";

export const SeasonSelect = props => {
    const { seasons, cb, selected } = props;
    if (!seasons) {
      console.log("No seasons!");
    }
    if (!selected || (seasons.length === 1 && selected !== seasons[0].seasonId)) {
      cb(seasons[0].seasonId);
    }
    const vals = [];
    seasons.forEach(season => {
      const txt = season.validFrom + " - " + season.validUntil;
      if (selected === season.seasonId) {
        vals.push(
          <option value={season.seasonId} selected="selected" title={season.name}>
            {txt}
          </option>
        );
      } else if (!season.pricingOnly) {
        vals.push(
          <option value={season.seasonId} title={season.name}>
            {txt}
          </option>
        );
      }
    });
    return <select onChange={e => cb(e.target.value)}>{vals}</select>;
  };
  