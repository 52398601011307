import React from "react";
import { PlaceDetails } from "../places/ProductDetails";
import BkModifyButton from "../layout/BkModifyButton";
import { genericDelete } from "../../services/axiosApi";
import { PlaceHistory } from "./PlaceHistory";

export class PlaceDetailsRenderer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editData: undefined,
      guest: !props.season && !props.addOn,
      season: props.season,
      editMode: props.editMode,
      emailEditable: false,
      mobileEditable: false
    };
    this.renderPlaceDetails = this.renderPlaceDetails.bind(this);
    this.copyPlaceDetails = this.copyPlaceDetails.bind(this);
  }

  render() {
    return this.renderPlaceDetails(
      this.props.simple,
      this.props.placeDetails,
      this.props.openedPlaceDetails
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
    this.timeout = undefined;
  }

  componentDidUpdate(prevProps) {
    const errorMessage =
      this.props.errorMessage &&
      prevProps.errorMessage !== this.props.errorMessage
        ? this.props.errorMessage
        : this.state.errorMessage;

    if (prevProps.editMode !== this.props.editMode) {
      this.setState({
        editMode: this.props.editMode,
        errorMessage: errorMessage
      });
    } else if (errorMessage && errorMessage !== this.state.errorMessage) {
      this.setState({
        errorMessage: errorMessage
      });
    }
  }

  renderPlaceDetails(simple, details, openedPlaceDetails) {
    const l = this.props.location; // shortcut
    const t = key => l.translate(l, key);
    if (
      !details ||
      (openedPlaceDetails && openedPlaceDetails.placeId !== details.placeId)
    ) {
      console.log(`No details for ${openedPlaceDetails.placeId}`);
      return "";
    }
    const keep =
      this.state.editData &&
      this.state.editMode &&
      this.state.editData.placeId === details.placeId;
    const editData = keep
      ? this.state.editData
      : this.copyPlaceDetails(openedPlaceDetails);
    if (
      !this.state.editData ||
      this.state.editData.placeId !== editData.placeId
    ) {
      this.setState({
        editData: editData,
        emailEditable: this.isEmailEditable(editData.holder),
        mobileEditable: this.isMobileEditable(editData.holder)
      });
    }
    const seasons = details && details.seasons ? details.seasons : [];
    const subareas = details && details.subareas ? details.subareas : [];
    const priceGroups =
      details && details.priceGroups ? details.priceGroups : [];
    const error = this.state.errorMessage ? (
      <div className="bk_errorMessage" style={{ display: "block" }}>
        {this.state.errorMessage}
      </div>
    ) : (
      undefined
    );
    if (this.state.errorMessage) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(
        () => this.setState({ errorMessage: undefined }),
        15000
      );
    }

    let hasFreeProducts = false;
    if (!this.state.editMode && !editData.placeId) {
      editData.width = "";
      editData.heigth = "";
      editData.length = "";
      editData.name = "";
    }
    editData.products.forEach(pr => {
      if (!this.state.editMode && !pr.productId) {
        pr.priceGroup = undefined;
        pr.reservations = [];
        pr.price = "";
        pr.name = "";
        pr.typeStr = "";
        pr.vatPercentage = "";
        pr.addons = [];
      }
      if (pr.parkingPlace || !pr.reservations || pr.reservations.length === 0) {
        hasFreeProducts = true;
      }
    });

    if (seasons.length === 0 || subareas.length === 0) {
      return (
        <React.Fragment>
          <h1>{t("missing-to-edit-info")}</h1>
          <BkModifyButton buttonTitle={t("close-button")}  callback={this.props.closeCallback} />
        </React.Fragment>);
    }

    if (seasons.length === 0) {
      console.log(
        "No seasons, not rendering place details: " + JSON.stringify(editData)
      );
      return "";
    }

    const buttons = [];

    if (this.state.editMode) {
      buttons.push(
        <BkModifyButton
          busy={this.props.saveOngoing}
          disabled={this.props.saveOngoing}
          buttonTitle={t("save-button")}
          callback={() => this.props.savePlaceAndProducts(this.state.editData)}
        />
      );
      if (this.props.user && this.props.user.admin) {
        buttons.push(
          <BkModifyButton
            buttonTitle={t("add-product-button")}
            disabled={this.props.saveOngoing}
            callback={() => {
              this.state.editData.products.push({
                code: this.state.editData.code,
                season: seasons[0],
                addons: []
              });
              this.setState({ editData: { ...this.state.editData } });
            }}
          />
        );
      }
      if (this.props.user && (this.props.user.allowedTasks.indexOf("AREA_MANAGE") != -1)) {
        buttons.push(
          <BkModifyButton
            buttonTitle={t("delete-place-button")}
            disabled={!editData.placeId || this.props.saveOngoing}
            callback={() =>
              genericDelete(
                l.language,
                "seasonAdmin/place/" + editData.placeId
              ).then(res => {
                if (!res.errorStatus) {
                  this.props.closeCallback();
                } else {
                  this.setState({ errorMessage: res.errorMessage });
                }
              })
            }
          />
        );
      }
      //} else if (this.state.editData.placeType && !this.state.editData.placeId) {
      // place not created, only on map
    } else {
      buttons.push(
        <React.Fragment>
          {!simple && this.props.user && (
            <BkModifyButton
              buttonTitle={t("modify-button")}
              disabled={
                (!editData.placeType && editData.placeId) ||
                this.props.saveOngoing
              }
              callback={() => {
                const pd = this.copyPlaceDetails(openedPlaceDetails);
                if (!pd.subareaId) {
                  pd.subareaId = this.props.subareaId;
                }
                this.setState({
                  editMode: true,
                  editData: pd,
                  emailEditable: this.isEmailEditable(pd.holder),
                  mobileEditable: this.isMobileEditable(pd.holder)
                });
                if (this.props.editModeCallback) {
                  this.props.editModeCallback(true);
                }
              }}
            />
          )}
          {this.state.guest && this.props.guestReserveCallback && (
            <BkModifyButton
              buttonTitle={t("reserve-button")}
              disabled={
                !editData.placeType ||
                !hasFreeProducts ||
                this.props.saveOngoing
              }
              callback={this.props.guestReserveCallback}
            />
          )}
        </React.Fragment>
      );
    }
    if (this.props.buttons) {
      this.props.buttons.forEach(b => buttons.push(b));
    }

    return (
      <React.Fragment>
        <center>
          <div className="bk_placeDetails">
            {error}
            <PlaceDetails
              location={l}
              addOn={this.props.addOn}
              editMode={this.state.editMode}
              simpleMode={simple}
              season={this.state.season}
              guest={this.state.guest}
              customerEditable={this.props.customerEditable}
              data={editData}
              seasons={seasons}
              subareas={subareas}
              priceGroups={priceGroups}
              cb={data => {
                this.setState({ editData: data });
              }}
              cancelReservation={this.props.cancelReservation}
              toggleGuest={on => {
                this.setState({ guest: on });
              }}
              toggleSeason={on => {
                this.setState({ season: on });
              }}
              emailEditable={this.state.emailEditable}
              mobileEditable={this.state.mobileEditable}
              addonPaymentRequest={this.props.addonPaymentRequest}
            />
            <br />
            {buttons}
            {this.state.editMode && (
              <BkModifyButton
                buttonTitle={t("cancel-button")}
                callback={() => {
                  this.setState({ editMode: false });
                  if (this.props.editModeCallback) {
                    this.props.editModeCallback(false);
                  }
                }}
              />
            )}
            {!this.state.editMode && (
              <BkModifyButton
                buttonTitle={t("close-button")}
                disabled={this.props.saveOngoing}
                callback={this.props.closeCallback}
              />
            )}
            {this.state.season && (
              <center>
                <PlaceHistory
                  location={l}
                  place={editData}
                  saveOngoing={this.props.saveOngoing}
                />
              </center>
            )}
          </div>
        </center>
      </React.Fragment>
    );
  }

  isEmailEditable(holder) {
    return (
      !holder.customerId ||
      !holder.email ||
      (holder.reservationStatus !== "PAYMENT_LINK_SENT" &&
        holder.reservationStatus !== "SEND_PAYMENT_LINK")
    );
  }
  isMobileEditable(holder) {
    return (
      !holder.customerId ||
      !holder.mobile ||
      (holder.reservationStatus !== "PAYMENT_LINK_SENT" &&
        holder.reservationStatus !== "SEND_PAYMENT_LINK")
    );
  }
  copyPlaceDetails(area) {
    const details = this.props.placeDetails;
    const place =
      details && details.placeId === area.placeId ? details : undefined;

    const defaultPlaceType = this.props.defaults
      ? this.props.defaults.placeType
      : "BB_SHAFT";
    const dpt = this.props.season ? "PLACE_SEASON" : "PLACE_GUEST";
    const defaultProductType = this.props.defaults
      ? this.props.defaults.productType
      : dpt;

    const editData = {};
    const products = [];
    editData.products = products;
    editData.placeTypeStr = area.placeTypeStr;
    editData.code = area.code;
    editData.subareaId = area.subareaId;
    if (details.holder) {
      editData.holder = { ...details.holder };
    } else {
      editData.holder = {};
    }
    if (place && place.placeId) {
      if (place.products) {
        this.copyProductData(place.products).forEach(e => products.push(e));
      }
      editData.placeId = place.placeId;
      editData.areaId = place.areaId;
      editData.placeType = place.placeType;
      editData.placeTypeStr = place.placeTypeStr;
      editData.width = place.width;
      editData.length = place.length;
      editData.depth = place.depth;
      editData.name = place.name;
      editData.internalComment = place.internalComment;
      editData.code = place.code;
      editData.subarea = place.subarea;
      editData.subareaId = place.subareaId;
      editData.multipleProducts = place.multipleProducts;
      if (place.deposits && Array.isArray(place.deposits)) {
        console.log("copying deposits");
        editData.deposits = place.deposits.map(v => {
          return { returned: false, ...v };
        });
      }
    } else {
      editData.placeId = area.placeId;
      if (!area.placeId) {
        editData.width = this.props.defaults ? this.props.defaults.width : "";
        editData.length = this.props.defaults ? this.props.defaults.length : "";
        editData.depth = this.props.defaults ? this.props.defaults.depth : "";
        editData.name = this.props.defaults ? this.props.defaults.name : "";
        // eslint-disable-next-line prettier/prettier
        editData.multipleProducts = this.props.defaults ? this.props.defaults.multipleProducts : false;
        // not created
        const prds = this.copyProductData(
          this.props.defaults && this.props.defaults.products
            ? this.props.defaults.products
            : [{}]
        );
        prds.forEach(prd => {
          prd.reservations = undefined;
          prd.productId = undefined;
          prd.code = area.code;
          prd.type = prd.type ? prd.type : defaultProductType;
          prd.addons.forEach(a => (a.active = false));
          products.push(prd);
        });
      }
    }
    if (!editData.areaId) {
      editData.areaId = this.props.location.area;
    }
    if (!editData.placeType) {
      editData.placeType = defaultPlaceType;
    }
    return editData;
  }

  copyProductData(list) {
    if (!list) {
      return [{}];
    }
    const arr = [];
    list.forEach(e =>
      arr.push({
        productId: e.productId,
        type: e.type,
        typeStr: e.typeStr,
        name: e.name,
        season: e.season ? e.season : {},
        price: e.price,
        currentPrice: e.price,
        code: e.code,
        vatPercentage: e.vatPercentage,
        parkingPlace: e.parkingPlace,
        reservations: e.reservations ? [...e.reservations] : [],
        priceGroup: e.priceGroup ? { ...e.priceGroup } : undefined,
        reservationUnit: e.reservationUnit ? e.reservationUnit : "NIGHT",
        pricingUnit: e.pricingUnit ? e.pricingUnit : "NIGHT",
        bookable: e.bookable,
        addons: e.addons ? [...e.addons] : [],
        dayPlaceDuringDay: e.dayPlaceDuringDay,
        parkingIfNoReservation: e.parkingIfNoReservation,
        minimumPaidUnits: e.minimumPaidUnits,
        minimumBoatLength: e.minimumBoatLength,
        maximumBoatLength: e.maximumBoatLength
      })
    );
    return arr;
  }
}
