import React from "react";

export const EnumSelect = props => {
  // eslint-disable-next-line prettier/prettier
  const { location, cb, enumType, selected, filter, emptyLabel, multiple } = props;
  const len = enumType.length;
  const vals = [];
  if (emptyLabel) {
    if (selected) {
      vals.push(<option value={undefined}>{emptyLabel}</option>);
    } else {
      vals.push(
        <option value={undefined} selected="selected">
          {emptyLabel}
        </option>
      );
    }
  }
  for (var k in location.translations.texts) {
    if (k.startsWith(enumType + ".")) {
      const keyValue = k.substring(len + 1);
      if (!keyValue || filter && !filter(keyValue)) {
        continue;
      }
      if (!selected && vals.length == 0) {
        console.log("No selected, calling the cb with: " + keyValue);
        cb(keyValue);
      }
      if (selected === keyValue) {
        vals.push(
          <option value={keyValue} selected="selected">
            {location.translations.texts[k]}
          </option>
        );
      } else {
        vals.push(
          <option value={keyValue}>{location.translations.texts[k]}</option>
        );
      }
    }
  }
  return (
    <select multiple={multiple} onChange={e => cb(e.target.value)}>
      {vals}
    </select>
  );
};
