/* eslint-disable eqeqeq */
import React from "react";

const BkCustomerFooter = props => {
  let gdprLink = undefined;
  if (props.harbours && props.area) {
    props.harbours.forEach(a => {
      if (a.id == props.area) {
        gdprLink = a.gdprLink;
      }
    });
    console.log(
      "Harbours: " +
        JSON.stringify(props.harbours) +
        ", area: " +
        props.area +
        ", link: " +
        gdprLink
    );
  }
  return (
    <React.Fragment>
      <div style={{ clear: "both", width: "100%", height: "5px" }} />
      <div className="bk-footer">
        <div className="container">
          <center>
            {gdprLink && (
              <div>
                <p>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={gdprLink}
                    className="gdprLink"
                    style={{ position: "relative", overflow: "hidden" }}
                  >
                    {props.gdprTitle}
                  </a>
                </p>
              </div>
            )}
            <span className="seperator extralight-border" />
          </center>
        </div>
      </div>
    </React.Fragment>
  );
};
export default BkCustomerFooter;
