export const customStyles = {
  content: {
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10%",
    maxWidth: "100%",
    width: "auto !important",
    display: "inline-block",
    height: "auto !important",
    maxHeight: "100%",
    marginBottom: "auto",
    zIndex: 9999
  }
};

export const modalContentDiv = {
  content: {
    overflow: "auto",
    borderBottom: "1px solid",
    marginBottom: "5px"
  }
};

export const fullPageModalStyles = {
  content: {
    width: "90%",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "10vh",
    marginBottom: "10vh",
    zIndex: 9999
  }
};

export const fullPageModalContentDiv = {
  overflowY: "auto",
  height: "80vh",
  borderBottom: "1px black solid",
  marginBottom: "5px",
  paddingBbottom: "5px"
};
