import React from "react";
import { CircleLoader } from "react-spinners";
import { genericGet } from "../../services/axiosApi";
import BookkaaHeader from "../BookkaaHeader";
import BkModifyButton from "../layout/BkModifyButton";
import TableAndForm from "../layout/TableAndForm";
import LoginForm from "../LoginForm";
import LoginRequired from "../LoginRequired";

class MessageOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: undefined,
      messageContents: undefined
    };
    this.selectCallback = this.selectCallback.bind(this);
    this.openMessage = this.openMessage.bind(this);

    if (!props.areas) {
      props.getAreas(props.location.language);
    }

    props.ensureLoggedIn(
      this.props.jwt,
      "messagecenter-web",
      props.location.language,
      "order"
    );
  }

  selectCallback(item) {
    this.setState({ selectedItem: item, messageContents: undefined });
  }
  openMessage() {
    const l = this.props.location;
    const item = this.state.selectedItem;
    genericGet(
      l.language,
      "order/" + item.id + "/contents",
      "messagecenter-web",
      30000
    ).then(ret => {
      this.setState({
        errorMessage: ret.errorMessage,
        messageContents: ret.contents,
        messageTitle: ret.title
      });
    });
  }

  render() {
    const location = { ...this.props.location };
    const t = key => location.translate(location, key);

    if (!this.props.areas) {
      return <CircleLoader />;
    } else if (location.area && !this.state.areaCode) {
      this.props.areas.forEach(a => {
        if (a.id == location.area) {
          this.setState({ areaCode: a.code });
        }
      });
    }
    location.area = undefined;

    const content = [];

    const CONTENTS_BUTTON = {
      supportsMulti: false,
      callback: this.openMessage,
      title: "Näytä viesti",
      disabledCallback: b => !this.state.selectedItem
    };

    if (this.state.messageContents) {
      content.push(
        <p>
          <br />
          <br />
          {t("messageUnformatted")}:
          <br />
          <br />
          <div style={{ border: "3px solid black" }}>
            {this.state.messageContents}
          </div>
          <br />
          <hr />
          <br />
          {t("messageTitle")}:
          <hr />
          <br />
          <div
            style={{ border: "3px solid black" }}
            dangerouslySetInnerHTML={{ __html: this.state.messageTitle }}
          ></div>
          <br />
          <hr />
          <br />
          {t("messageFormatted")}:
          <br />
          <br />
          <div
            style={{ border: "3px solid black" }}
            dangerouslySetInnerHTML={{ __html: this.state.messageContents }}
          ></div>
        </p>
      );
      content.push(
        <p>
          <hr></hr>
        </p>
      );
      content.push(
        <p>
          <BkModifyButton
            buttonTitle={t("close-button")}
            callback={() => this.setState({ messageContents: undefined })}
          />
        </p>
      );
    } else {
      content.push(
        <React.Fragment>
          <h2>{t("messageOrders.title")}</h2>
          <TableAndForm
            selectCallback={this.selectCallback}
            buttons={[CONTENTS_BUTTON]}
            formButtonArray={[]}
            multiSelect={false}
            {...this.props}
            endpoint="order"
            location={location}
            search={
              this.state.areaCode
                ? `areaCode=${this.state.areaCode}`
                : undefined
            }
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <LoginRequired {...this.props.loginProps} location={location}>
          <BookkaaHeader location={location} {...this.props.headerProps} />
          <LoginForm location={location} {...this.props.loginProps} />
          <div style={{ marginLeft: "20px", marginRight: "20px" }}>
            {content}
          </div>
        </LoginRequired>
      </React.Fragment>
    );
  }
}

export default MessageOrders;
