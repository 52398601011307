import React from "react";
import BkModifyButton from "../layout/BkModifyButton";
import BkContentArea from "../layout/BkContentArea";
import { CircleLoader } from "react-spinners";
import Link from "react-router-dom/Link";
import { Route } from "react-router";
import { genericPost } from "../../services/axiosApi";

class ShitSystem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: undefined,
      mobile: undefined,
      data: {},
      errorMessage: undefined
    };
    // without this we don't have the right "this" reference in the callback
    this.continue = this.continue.bind(this);
    this.checkLanguageChange = this.checkLanguageChange.bind(this);
  }

  ctx = "booking-web";

  componentDidMount() {
    let loc = this.props.location;
    if (loc.area) {
      this.props.getTranslations(this.ctx, loc.language, `a${loc.area}/septic`);
    } else {
      console.log("No area!");
    }
    if (!this.state.data.areaName) {
      const data = this.state.data;
      data.orderNumber = this.props.orderNumber; // in case already paid
      this.setState({ data: data }, this.continue);
    }
  }
  componendDidUpdate(prevProps) {
    this.checkLanguageChange(this.props, prevProps);
    const d = this.state.data;
    if (d && d.orderNumber) {
      if (d.paymentStatus === "WAITING_FOR_PAYMENT") {
        setTimeout(() => this.continue(), 1000);
      }
    }
  }
  checkLanguageChange(props, prevProps) {
    const l = props.location;
    const p = prevProps.location;
    if (
      p.area !== l.area ||
      p.language !== l.language ||
      prevProps.jwt !== this.props.jwt
    ) {
      props.getTranslations(this.ctx, l.language, `a${l.area}/septic`);
    }
    this.setState({ languageSelectionOpen: false });
  }

  continue() {
    let data = { ...this.state.data };
    const l = this.props.location;
    if (data.email) {
      data.confirm = true;
    } else if (this.validateEmail(this.state.email)) {
      data.email = this.state.email;
      data.mobile = this.state.mobile;
    }

    genericPost(l.language, `${l.area}/septic/wiz`, data).then(ret => {
      this.setState({
        data: ret.errorMessage ? this.state.data : ret,
        errorMessage: ret.errorMessage
      });
    });
  }

  validateEmail(email) {
    return (
      email &&
      email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))+$/
      )
    );
  }

  render() {
    let errorMessage = this.state.errorMessage
      ? this.state.errorMessage
      : this.props.paymentError;
    if (this.state.errorMessage || this.props.paymentError) {
      console.log(
        `error: ${this.state.errorMessage}, paymentError: ${this.props.paymentError}`
      );
    }
    const t = key => this.props.location.translate(this.props.location, key);

    let content = <div />;
    let footer = [];

    let buttonTitle = t("continue");
    let header = t("phase1Title");

    let canContinue = false;
    if (errorMessage) {
      header = "";
      content = errorMessage;
    } else if (this.props.orderNumber) {
      const o = this.state.data;
      if (!o || o.paymentStatus === "WAITING_FOR_PAYMENT") {
        header = "";
        content = <CircleLoader />;
      } else {
        header = t("phase3Title");
        content = (
          <React.Fragment>
            <p>{o.text}</p>
            <p>{o.paymentDetails}</p>
          </React.Fragment>
        );

        if (o.paymentStatus === "PAID") {
          buttonTitle = undefined;
        } else if (
          o.paymentStatus === "CANCELLED_BY_PROVIDER" ||
          o.paymentStatus === "NOT_PAID"
        ) {
          buttonTitle = t("startOver");
        }
      }
      // eslint-disable-next-line eqeqeq
    } else if (!this.state.data.email) {
      content = (
        <React.Fragment>
          <center>
            <label>{t("email")}</label>
          </center>
          <center>
            <input
              onChange={e => this.setState({ email: e.target.value })}
              value={this.state.email}
            />
          </center>
          <center>
            <label>{t("mobile")}</label>
          </center>
          <center>
            <input
              onChange={e => this.setState({ mobile: e.target.value })}
              value={this.state.mobile}
            />
          </center>
        </React.Fragment>
      );
      canContinue = this.validateEmail(this.state.email);
    } else if (this.state.data.paymentLink) {
      header = t("phase2Title");
      window.location.href = this.state.data.paymentLink;
      content = <p>{t("redirecting")}</p>;
      content = <CircleLoader />;
    } else if (
      this.validateEmail(this.state.email) &&
      this.state.data.totalPrice
    ) {
      header = t("phase2Title");
      if (!this.state.data.totalPrice) {
        console.debug("No totalPrice: " + this.state.data.totalPrice);
        content = <CircleLoader />;
      } else {
        content = (
          <div>
            <center className="header">{t("price")}</center>
            <center>{this.state.data.totalPrice.toFixed(2)} eur</center>
          </div>
        );
        footer.push(<br />);
        footer.push(
          <img
            src="https://static.vismapay.com/pay_banners/row.png"
            style={{ width: "100%", maxWidth: "550px" }}
            alt="Payform payment logos"
          />
        );
        canContinue = true;
      }
      buttonTitle = t("buttonPay");
    }
    let languages = this.language(this.props.location.area);

    let step = 1;
    const steps = (
      <ol className="progtrckr">
        <li
          className={
            this.state.data.paymentLink ? "progtrckr-done" : "progtrckr-doing"
          }
        >
          <em>{step++}</em>
          <span>
            <div>{t("phase1TitleShort")}</div>
          </span>
        </li>
        <li
          className={
            this.props.orderNumber
              ? "progtrckr-done"
              : this.state.data.paymentLink
              ? "progtrckr-doing"
              : "progtrckr-todo"
          }
        >
          <em>{step++}</em>
          <span>
            <div>{t("phase2TitleShort")}</div>
          </span>
        </li>
        <li
          className={this.props.orderData ? "progtrckr-done" : "progtrckr-todo"}
        >
          <em>{step++}</em>
          <span>
            <div>{t("phase3TitleShort")}</div>
          </span>
        </li>
      </ol>
    );

    const button = buttonTitle ? (
      this.props.orderNumber ? (
        <Route
          render={({ history }) => {
            return (
              <BkModifyButton
                buttonTitle={buttonTitle}
                callback={() =>
                  this.setState({ data: {} }, () =>
                    history.push(
                      `/septic/${this.props.location.language}/${this.props.location.area}`
                    )
                  )
                }
              />
            );
          }}
        />
      ) : (
        <BkModifyButton
          disabled={!canContinue}
          buttonTitle={buttonTitle}
          callback={() => this.continue()}
        />
      )
    ) : (
      <div />
    );

    const warningMessage = this.state.data.warning ? (
      <div className="bk_errorMessage display">{this.state.data.warning}</div>
    ) : (
      undefined
    );
    const actionContentClass = "action-content";
    const backLink = !this.props.orderNumber && (
      <div class="small">
        <a
          onClick={() => {
            this.setState({ data: { areaName: this.state.data.areaName } });
          }}
        >
          &#xab; {t("startOver")}
        </a>
      </div>
    );
    return (
      <React.Fragment>
        <BkContentArea>
          <center>
            <div className="harbour-and-season-container">
              <div className="bookkaaLogoHeader">{languages}</div>
              <div className="wiz-header">
                <h3>{header}</h3>
              </div>
              <div className="harbour-title">
                <center>{this.state.data.areaName}</center>
              </div>
              <div className="step-progress">{steps}</div>
              {warningMessage}
              <div className={actionContentClass}>
                <center>
                  {!this.state.data.paymentStatus &&
                    this.state.data.productName}
                </center>
                {content}
                <center>{button}</center>
                {backLink}
              </div>
              <br />
              {footer}
            </div>
          </center>
          <div className="bk-guest-rental-footer">
            &copy; 2021 bookkaa.fi |&nbsp;
            <a
              href="http://www.satamapaikka.com/kayttoehdot2/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("conditionsLink")}
            </a>
          </div>
        </BkContentArea>
      </React.Fragment>
    );
  }
  language(area) {
    if (this.state.languageSelectionOpen) {
      const ep = `a${area}/septic`;
      return (
        <div className="languageSelector ls-Open">
          <ol>
            <li>
              <Link
                to={`/septic/fi/${area}`}
                onclick={() => this.props.getTranslations(this.ctx, "fi", ep)}
              >
                <div>FI</div>
              </Link>
            </li>
            <li>
              <Link
                to={`/septic/sv/${area}`}
                onclick={() => this.props.getTranslations(this.ctx, "sv", ep)}
              >
                <div>SV</div>
              </Link>
            </li>
            <li>
              <Link
                to={`/septic/en/${area}`}
                onclick={() => this.props.getTranslations(this.ctx, "en", ep)}
              >
                <div>EN</div>
              </Link>
            </li>
          </ol>
        </div>
      );
    } else {
      return (
        <div
          className="languageSelector ls-Closed"
          onClick={() =>
            this.setState({
              languageSelectionOpen: !this.state.languageSelectionOpen
            })
          }
        >
          {this.props.location.language.toUpperCase()}
        </div>
      );
    }
  }
}

export default ShitSystem;
