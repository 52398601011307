import React from "react";
import { genericDelete, genericGet, genericPost } from "../../services/axiosApi";
import BookkaaHeader from "../BookkaaHeader";
import TableAndForm from "../layout/TableAndForm";
import LoginForm from "../LoginForm";
import LoginRequired from "../LoginRequired";

class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedGroup: undefined,
      selectedItem: undefined,
      selectedAttachment: undefined,
      messageOrders: false,
      adhocMessages: false,
      possibleParameters: undefined
    };
    this.selectCallback = this.selectCallback.bind(this);
    this.selectAttachmentCallback = this.selectAttachmentCallback.bind(this);
    this.groupSelectCallback = this.groupSelectCallback.bind(this);
    this.deleteParameter = this.deleteParameter.bind(this);
    this.openOrders = this.openOrders.bind(this);
    this.openAdhoc = this.openAdhoc.bind(this);
    this.uploadAttachment = this.uploadAttachment.bind(this);
    this.deleteAttachment = this.deleteAttachment.bind(this);

    props.ensureLoggedIn(
      this.props.jwt,
      "messagecenter-web",
      props.location.language,
      "templates"
    );
  }

  selectCallback(item) {
    this.setState({ selectedItem: item });
  }

  selectAttachmentCallback(item) {
    this.setState({ selectedAttachment: item });
  }

  groupSelectCallback(item) {
    const l = this.props.location;
    genericGet(l.language, l.endpoint + "/" + item.type + "/parameterTypes", l.ctx).then(ret => {
      this.setState({ selectedGroup: item, possibleParameters: ret });
    })
  }

  uploadAttachment(data) {
    const l = this.props.location;
    const formData = new FormData();
    formData.append("attachment", data, data.name);
    const id = this.state.selectedAttachment
      ? this.state.selectedAttachment.id
      : 0;
    const templateId = this.state.selectedGroup.id;
    genericPost(
      l.language,
      `templateAttachments/${templateId}/${id}/data`,
      formData,
      "messagecenter-web",
      "multipart/form-data"
    ).then(r => {
      this.setState({
        selectedGroup: undefined
      });
    });
  }
  deleteAttachment() {
    genericDelete(
      this.props.location.language,
      "templateAttachments/" + this.state.selectedAttachment.id,
      "messagecenter-web"
    ).then(() => {
      this.setState({
        selectedGroup: undefined
      });
    });
  }
  deleteParameter() {
    genericDelete(
      this.props.location.language,
      "templateParameters/tp/" +
        this.state.selectedGroup.id +
        "_" +
        this.state.selectedItem.parameter,
      "messagecenter-web"
    ).then(() => {
      this.setState({
        selectedGroup: undefined
      });
    });
  }
  openOrders() {
    this.setState({
      messageOrders: true,
      adhocMessages: false
    });
  }

  openAdhoc() {
    this.setState({
      adhocMessages: true,
      messageOrders: false
    });
  }

  render() {
    const location = this.props.location;
    const t = key => location.translate(location, key);

    const paramsLoc = {
      language: this.props.location.language,
      endpoint: "templateParameters",
      ctx: "messagecenter-web",
      translations: this.props.location.translations,
      translate: this.props.location.translate
    };
    const attachmentsLoc = {
      language: this.props.location.language,
      endpoint: "templateAttachments",
      ctx: "messagecenter-web",
      translations: this.props.location.translations,
      translate: this.props.location.translate
    };

    const content = [];

    if (this.state.messageOrders) {
      // TODO: navigate to message orders
    } else if (this.state.adhocMessages) {
      // TODO: navigate to adhoc messages
    } else {
      console.log(`Area: ${location.area}`);
      let beforeForm = [];
      const beforeGroupForm = [];
      if (this.state.possibleParameters) {
        const help = [];
        if (this.state.showHelp) { 
          Object.keys(this.state.possibleParameters)
            .forEach(k => help.push(<React.Fragment><br /><span>{k} : {this.state.possibleParameters[k]}</span></React.Fragment>));
          help.push(<hr />);
        }
        beforeGroupForm.push(<span onClick={()=>this.setState({showHelp: !this.state.showHelp})}>&#9432;</span> )
        beforeGroupForm.push(<div>{help}</div>);
      }
      const ORDERS_BUTTON = {
        supportsMulti: false,
        callback: this.openOrders,
        title: t("message-orders-button"),
        disabledCallback: b => !this.state.selectedGroup
      };
      const DELETE_BUTTON = {
        supportsMulti: false,
        callback: this.deleteParameter,
        title: t("delete-button"),
        disabledCallback: b =>
          !this.state.selectedItem || !this.state.selectedItem.parameter
      };
      const ADHOC_BUTTON = {
        supportsMulti: false,
        callback: this.openAdhoc,
        title: t("adhoc-messages-button"),
        disabledCallback: b => false
      };
      const UPLOAD_ATTACHMENT_BUTTON = {
        supportsMulti: false,
        callback: this.uploadAttachment,
        fileUpload: true,
        title: t("upload-attachment"),
        disabledCallback: b => false
      };
      const DELETE_ATTACHMENT_BUTTON = {
        supportsMulti: false,
        callback: this.deleteAttachment,
        title: t("delete-button"),
        disabledCallback: b => !this.state.selectedAttachment
      };

      let templateListButtons = [ORDERS_BUTTON];
      let parameterListButtons = [DELETE_BUTTON];
      let attachmentListButtons = [
        UPLOAD_ATTACHMENT_BUTTON,
        DELETE_ATTACHMENT_BUTTON
      ];

      content.push(
        <React.Fragment>
          <h2>{t("messageTemplates.title")}</h2>
          <TableAndForm
            selectCallback={this.groupSelectCallback}
            itemsBeforeForm={beforeGroupForm}
            buttons={templateListButtons}
            formButtonArray={[]}
            multiSelect={false}
            {...this.props}
            endpoint="templates"
            location={location}
          />
          <h2>{t("messageTemplateParameters.title")}</h2>
          {this.state.selectedGroup && (
            <TableAndForm
              selectCallback={this.selectCallback}
              itemsBeforeForm={beforeForm}
              buttons={parameterListButtons}
              formButtonArray={parameterListButtons}
              multiSelect={false}
              {...this.props}
              endpoint={paramsLoc.endpoint}
              location={paramsLoc}
              search={
                this.state.selectedGroup
                  ? `template.id=${this.state.selectedGroup.id}`
                  : undefined
              }
              preSaveCallback={data => {
                return {
                  ...data,
                  id: this.state.selectedGroup.id
                };
              }}
            />
          )}
          <h2>{t("messageTemplateAttachments.title")}</h2>
          {this.state.selectedGroup && (
            <TableAndForm
              selectCallback={this.selectAttachmentCallback}
              itemsBeforeForm={beforeForm}
              buttons={attachmentListButtons}
              formButtonArray={[]}
              multiSelect={false}
              {...this.props}
              endpoint={attachmentsLoc.endpoint}
              location={attachmentsLoc}
              search={
                this.state.selectedGroup
                  ? `template.id=${this.state.selectedGroup.id}`
                  : undefined
              }
              preSaveCallback={data => {
                return {
                  ...data,
                  templateId: this.state.selectedGroup.id
                };
              }}
            />
          )}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <LoginRequired {...this.props.loginProps} location={location}>
          <BookkaaHeader location={location} {...this.props.headerProps} />
          <LoginForm location={location} {...this.props.loginProps} />
          {content}
        </LoginRequired>
      </React.Fragment>
    );
  }
}

export default Messages;
