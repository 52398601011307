import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const MenuItem = props => {
  const { url, title, children } = props;
  const urlReplaced =
    !url || (!props.areaId && url.indexOf("{area") != -1)
      ? undefined
      : url
          .replace(/{area:select}/g, props.areaId)
          .replace(/{area}/g, props.areaId)
          .replace(/{language}/g, props.language);
  if (!urlReplaced && !children) {
    return <li />; // do not render menu without link & children
  }
  let chld = children ? (
    <ul>
      {children.map(item =>
        !item ? "" : item.title ? <MenuItem key={item.title} {...item} /> : item
      )}
    </ul>
  ) : (
    ""
  );
  if (urlReplaced) {
    return (
      <li>
        <Link to={urlReplaced}>{title}</Link>
        {chld}
      </li>
    );
  } else {
    return (
      <li>
        <a>{title}</a>
        {chld}
      </li>
    );
  }
};

class BookkaaMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filter: undefined };
  }

  componentDidUpdate(prevProps) {
    const l = this.props.location;
    const p = prevProps.location;
    if (
      l.ctx !== p.ctx ||
      p.area !== l.area ||
      p.sp !== l.sp ||
      p.language !== l.language ||
      p.endpoint !== l.endpoint ||
      prevProps.jwt !== this.props.jwt ||
      prevProps.areaId !== this.props.areaId
    ) {
      this.props.getTranslations(l.ctx, l.language, l.endpoint);
      if (l.area && l.sp) {
        this.props.getMenu(
          l.ctx,
          l.language,
          `s${l.sp}a${l.area}/${l.endpoint}`
        );
      } else {
        this.props.getMenu(l.ctx, l.language, l.endpoint);
      }
    }
  }

  componentDidMount() {
    const l = this.props.location;
    this.props.getTranslations(l.ctx, l.language, l.endpoint);
    if (l.area && l.sp) {
      this.props.getMenu(l.ctx, l.language, `s${l.sp}a${l.area}/${l.endpoint}`);
    } else {
      this.props.getMenu(l.ctx, l.language, l.endpoint);
    }
  }

  render() {
    const menuJson = this.props.menuJson;

    if (!menuJson || !menuJson.menu) {
      console.log("No menu to render");
      return (
        <div id="menu" className="bk-menu-bar">
          <div id="headerSelectorContainer" className="headerSelector" />
          <div id="headerMenu" className="headerMenu" />
        </div>
      );
    }

    let arr = [];
    Object.keys(menuJson.menu).forEach(function(key) {
      arr.push(menuJson.menu[key]);
    });
    const areaId = this.props.location.area
      ? this.props.location.area
      : menuJson.authorizedAreas && menuJson.authorizedAreas.length > 0
      ? menuJson.authorizedAreas[0].id
      : undefined;
    let menuData = (
      <React.Fragment>
        {arr.map(item => {
          if (item.url.indexOf("{area:select}") !== -1) {
            if (!menuJson.authorizedAreas) {
              return null;
            }
            let sellerId = undefined;
            let severalSellers = false;
            if (areaId) {
              menuJson.authorizedAreas.forEach(i => {
                if (sellerId && sellerId !== i.seller) {
                  severalSellers = true;
                }
                sellerId = i.seller;
              });
            }
            item.children = [];
            if (menuJson.authorizedAreas.length > 10) {
              item.children.push(
                <li>
                  <input
                    placeholder="filter"
                    onChange={e => this.setState({ filter: e.target.value })}
                    value={this.state.filter}
                    style={{ width: "100%" }}
                  />
                </li>
              );
            }
            const filter = this.state.filter;
            menuJson.authorizedAreas
              .map(i => {
                if (
                  filter &&
                  i.name &&
                  i.name.toLowerCase().indexOf(filter.toLowerCase()) === -1 &&
                  i.code.toLowerCase().indexOf(filter.toLowerCase()) === -1 &&
                  // eslint-disable-next-line prettier/prettier
                  !(severalSellers && i.sellerStr.toLowerCase().indexOf(filter.toLowerCase()) !== -1)
                ) {
                  return {};
                }
                if (severalSellers) {
                  return {
                    url: item.url.replace(/{area:select}/g, i.id),
                    title: i.sellerStr + " / " + i.code + ": " + i.name,
                    language: this.props.location.language
                  };
                }
                return {
                  url: item.url.replace(/{area:select}/g, i.id),
                  title: i.code + ": " + i.name,
                  language: this.props.location.language
                };
              })
              .forEach(i => {
                if (i.title) {
                  item.children.push(i);
                }
              });
          }
          return (
            <MenuItem
              key={item.title}
              {...item}
              areaId={this.props.location.area}
              language={this.props.location.language}
            />
          );
        })}
      </React.Fragment>
    );
    const headerMenuClass = !this.state.menuOpen
      ? "headerMenu"
      : "headerMenu headerMenuOpen";
    const burgerMenuClass = !this.state.menuOpen
      ? "burger_menu"
      : "burger_menu closed";

    return (
      <React.Fragment>
        <div id="menu" className="bk-menu-bar">
          {this.props.headerSelectorContent && (
            <div id="headerSelectorContainer" className="headerSelector">
              {this.props.headerSelectorContent}
            </div>
          )}
          <div id="headerMenu" className={headerMenuClass}>
            <div
              className={burgerMenuClass}
              onClick={() => this.setState({ menuOpen: !this.state.menuOpen })}
            >
              <div class="burger_bar1"></div>
              <div class="burger_bar2"></div>
              <div class="burger_bar3"></div>
            </div>
            <ul id="navigation" className="slimmenu">
              {menuData}
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default BookkaaMenu;

BookkaaMenu.propTypes = {
  getMenu: PropTypes.func.isRequired,
  loginDialogVisible: PropTypes.bool.isRequired,
  menuJson: PropTypes.any,
  areaId: PropTypes.number,
  location: {
    ctx: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired
  }
};
