import React from "react";
import PropTypes from "prop-types";
import Datetime from "react-datetime";
import "moment/locale/fi";

class WatchCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.isValidDate = this.isValidDate.bind(this);
    this.dateChanged = this.dateChanged.bind(this);
    this.onNavigateForward = this.onNavigateForward.bind(this);
    this.onNavigateBackward = this.onNavigateBackward.bind(this);
    this.renderDay = this.renderDay.bind(this);
    this.state = {
      visibleStartDate: undefined,
      visibleEndDate: undefined,
      reserving: false
    };
    // Datetime.moment(moment);
    Datetime.moment().locale(props.location.language);
  }

  componentDidMount() {
    const l = this.props.location;
    let data = {
      type: "GUARD_WATCH",
      startDate: this.state.visibleStartDate,
      endDate: this.state.visibleEndDate
    };
    this.props.getAvailableProducts(l.area, l.language, data).then(() => {
      console.log("finished");
    });
  }

  dateChanged(moment) {
    this.setState({
      selectedDate: moment ? moment.format() : undefined
    });
    if (this.props.dateChanged) {
      this.props.dateChanged(moment);
    }
  }

  onNavigateBackward(amount, type) {
    let startDate = this.state.visibleStartDate
      ? this.state.visibleStartDate.clone()
      : Datetime.moment();
    console.log(
      `navigate backward: ${amount} ${type} from ${startDate.format()}`
    );
    let endDate = startDate.clone();
    startDate = startDate.subtract(1, type);
    this.loadProducts(startDate, endDate, this);
  }

  onNavigateForward(amount, type) {
    let startDate = this.state.visibleStartDate
      ? this.state.visibleStartDate.clone()
      : Datetime.moment();
    console.log(
      `navigate forward: ${amount} ${type} from ${startDate.format()}`
    );
    startDate.add(1, type);
    let endDate = startDate.clone();
    endDate.add(1, type);
    this.loadProducts(startDate, endDate, this);
  }

  loadProducts(startDate, endDate, cmp) {
    cmp.setState({
      visibleEndDate: endDate,
      visibleStartDate: startDate
    });
    const l = cmp.props.location;
    let data = {
      type: "GUARD_WATCH",
      startDate: startDate,
      endDate: endDate
    };
    cmp.props.getAvailableProducts(l.area, l.language, data).then(() => {
      console.log("finished");
    });
  }

  isValidDate(currentDate, selectedDate) {
    if (this.props.isValidDate) {
      //console.log(`checking date validity: ${currentDate.format()} from props`);
      return this.props.isValidDate(currentDate, selectedDate);
    }
    //console.log(`checking date validity: ${currentDate.format()}`);
    var yesterday = Datetime.moment().subtract(1, "day");
    if (currentDate.isAfter(yesterday)) {
      return true;
    }
    return false;
  }

  render() {
    Datetime.moment().locale(this.props.location.language);
    return (
      <React.Fragment>
        <Datetime
          timeFormat={false}
          dateFormat="DD.MM.YYYY"
          input={false}
          open={true}
          onChange={this.dateChanged}
          onNavigateBack={this.onNavigateBackward}
          onNavigateForward={this.onNavigateForward}
          isValidDate={this.isValidDate}
          renderDay={this.renderDay}
        />
      </React.Fragment>
    );
  }
  renderDay(props, currentDate, selectedDate) {
    let info = [];
    if (this.props.renderDay) {
      info.push(this.props.renderDay(props, currentDate, selectedDate));
    }
    return (
      <td {...props}>
        {currentDate.date()}
        {info}
      </td>
    );
  }
}

export default WatchCalendar;

WatchCalendar.propTypes = {
  renderDay: PropTypes.func,
  location: {
    ctx: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    area: PropTypes.string.isRequired
  }
};
