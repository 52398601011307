import React from "react";

const AreaHeader = (props) =>  (
    <div className="harbour-title" style={{ marginLeft: "30px"}}>
      <h3
        style={{
          display: "inline-block",
          marginTop: "10px",
          marginBottom: "5px"
        }}
      >
        {props.areaName}
      </h3>
    </div>);

export default AreaHeader;