import React from "react";
import PropTypes from "prop-types";
import LoginForm from "./LoginForm";

class LoginRequired extends React.Component {
  componentDidUpdate(prevProps) {
    const l = this.props.location ? this.props.location : {};
    const p = prevProps.location ? prevProps.location : {};
    if (p.ctx !== l.ctx || this.props.jwt !== prevProps.jwt) {
      this.props.ensureLoggedIn(this.props.jwt, l.ctx, l.language, l.endpoint);
    }
  }

  componentDidMount() {
    if (this.props.ensureLoggedIn) {
      if (!this.props.location) {
        console.log("No location!");
      }
      const l = this.props.location ? this.props.location : {};
      if (!l.ctx || !l.endpoint) {
        console.log("No location.ctx or location.endpoint!");
        l.ctx = "um-web";
        l.endpoint = "login";
      }
      this.props.ensureLoggedIn(this.props.jwt, l.ctx, l.language, l.endpoint);
    } else {
      console.log("No ensureLoggedIn action");
    }
  }

  render() {
    if (!this.props.jwt || this.props.loginRequired) {
      if (this.props.doLogin) {
        return (
          <React.Fragment>
            <LoginForm {...this.props}></LoginForm>
          </React.Fragment>
        );
      } else {
        console.log("no loginProps");
        return <React.Fragment />;
      }
    }
    return <React.Fragment>{this.props.children}</React.Fragment>;
  }
}
export default LoginRequired;

LoginRequired.propTypes = {
  jwt: PropTypes.string,
  location: {
    ctx: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired
  }
};
