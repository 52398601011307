import moment from "moment";
import React from "react";
import { genericGet, genericPut } from "../../services/axiosApi";
import { DateSelector } from "../boatparking/DateSelector";
import BkModifyButton from "../layout/BkModifyButton";

class SeasonsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.getSeasons = this.getSeasons.bind(this);
    this.save = this.save.bind(this);
  }

  componentDidMount() {
    this.getSeasons();
  }

  componentDidUpdate() {
      this.getSeasons();
  }

  save() {
    const language = this.props.location.language;
    const area = this.props.location.area;
    const s = this.state.editSeason;
    genericPut(language, `parkingAdmin/${area}/season`, s).then(r => {
      if (Array.isArray(r)) {
        this.setState({ seasons: r, editSeason: undefined });
      } else {
        console.log("Failed to update season");
      }
    });
  }
  getSeasons() {
    const area = this.props.location.area;
    if (this.props.seasons !== undefined && this.props.seasons !== this.state.seasons) {
      this.setState({ seasons: this.props.seasons });
    } else if (this.props.seasons === undefined && (this.state.area !== area)) {
      const language = this.props.location.language;
      const type = this.props.guest ? "guest" : "season";
      genericGet(language, `parkingAdmin/${area}/seasons/${type}`).then(r => {
        if (Array.isArray(r)) {
          this.setState({ seasons: r, area: area });
        } else {
          console.log("Failed to get seasons");
        }
      });
    }
  }

  render() {
    const l = this.props.location; // shortcut
    const t = key => l.translate(l, key);

    const content = [];

    if (this.state.editSeason) {
      const s = this.state.editSeason;
      return (
        <div className="harbour-seasons-modal">
          <p>{t("seasons-edit-description")}</p>
          <table className="season-table">
            <tr>
              <th>{t("season-starts")}</th>
              <td>
                <DateSelector
                  isEdit={this.state.editFrom}
                  enableEditCallback={() => this.setState({ editFrom: true })}
                  language={l.language}
                  value={moment(s.validFrom, "DD.MM.YYYY hh:mm")}
                  callback={m =>
                    this.setState({
                      editFrom: false,
                      editSeason: {
                        ...s,
                        validFrom: m.format("DD.MM.YYYY 00:00")
                      }
                    })
                  }
                />
              </td>
              <th>{t("season-ends")}</th>
              <td>
                <DateSelector
                  isEdit={this.state.editUntil}
                  enableEditCallback={() => this.setState({ editUntil: true })}
                  language={l.language}
                  value={moment(s.validUntil, "DD.MM.YYYY hh:mm")}
                  callback={m =>
                    this.setState({
                      editUntil: false,
                      editSeason: {
                        ...s,
                        validUntil: m.format("DD.MM.YYYY 00:00")
                      }
                    })
                  }
                />
              </td>
            </tr>
            { !s.pricingOnly && 
            (<tr>
              <th>{t("season-sales-starts")}</th>
              <td>
                <DateSelector
                  isEdit={this.state.editSalesFrom}
                  enableEditCallback={() =>
                    this.setState({ editSalesFrom: true })
                  }
                  language={l.language}
                  value={
                    s.salesOpenFrom
                      ? moment(s.salesOpenFrom, "DD.MM.YYYY hh:mm")
                      : undefined
                  }
                  callback={m =>
                    this.setState({
                      editSalesFrom: false,
                      editSeason: {
                        ...s,
                        salesOpenFrom: m ? m.format("DD.MM.YYYY 00:00") : ""
                      }
                    })
                  }
                />
              </td>
              <th>{t("season-sales-ends")}</th>
              <td>
                <DateSelector
                  isEdit={this.state.editSalesUntil}
                  enableEditCallback={() =>
                    this.setState({ editSalesUntil: true })
                  }
                  language={l.language}
                  value={
                    s.salesOpenUntil
                      ? moment(s.salesOpenUntil, "DD.MM.YYYY hh:mm")
                      : undefined
                  }
                  callback={m =>
                    this.setState({
                      editSalesUntil: false,
                      editSeason: {
                        ...s,
                        salesOpenUntil: m ? m.format("DD.MM.YYYY 00:00") : ""
                      }
                    })
                  }
                />
              </td>
            </tr>)}
            <tr>
              <td colspan="4">
                <div style={{ float: "right" }}>
                  <BkModifyButton
                    buttonTitle={t("modify-button")}
                    callback={this.save}
                  />
                  <BkModifyButton
                    buttonTitle={t("close-button")}
                    callback={() => this.setState({ editSeason: undefined })}
                  />
                </div>
              </td>
            </tr>
          </table>
        </div>
      );
    } else {
      return (
        <div className="harbour-seasons">
          {this.state.seasons && (
            <table className="season-table">
              {this.state.seasons.map(s => (
                <tr>
                  <th>{s.name}</th>
                  <td>
                    {moment(s.validFrom, "DD.MM.YYYY hh:mm").format(
                      "DD.MM.YYYY"
                    )}
                  </td>
                  <td>
                    {moment(s.validUntil, "DD.MM.YYYY hh:mm").format(
                      "DD.MM.YYYY"
                    )}
                  </td>
                  <td>
                    <a
                      onClick={e => {
                        e.preventDefault();
                        this.setState({ editSeason: s });
                      }}
                      href="edit"
                    >
                      {t("modify-button")}
                    </a>
                  </td>
                </tr>
              ))}
            </table>
          )}
        </div>
      );
    }
  }
}

export default SeasonsComponent;
