import React from "react";
import Modal from "react-modal";
import Datatable from "../datatable/Datatable";
import AlpacaForm from "../alpaca/Form";
import { createUpdate } from "../../services/bkRest";
import BkModifyButton, { getButtons } from "./BkModifyButton";
import BkContentArea from "./BkContentArea";
import { customStyles } from "./ModalStyle";
import PropTypes from "prop-types";
import OkErrorMessage from "./OkErrorMessage";

class TableAndForm extends React.Component {
  constructor(props) {
    super(props);
    const loc = this.props.location;
    console.log(
      `Ctx: '${loc.ctx}' lang: '${loc.language}' ep: '${loc.endpoint}`
    );

    this.state = {
      data: null,
      modalIsOpen: props.modalIsOpen ? true : false,
      saveBusy: false
    };
    // without this we don't have the right "this" reference in the callback
    this.setSelectedObject = this.setSelectedObject.bind(this);
    this.afterOpenModal = this.afterOpenModal.bind(this);
    this.openCreate = this.openCreate.bind(this);
    this.openUpdate = this.openUpdate.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.saveData = this.saveData.bind(this);
    this.reloadData = this.reloadData.bind(this);
    this.reloadAlpacaSchema = this.reloadAlpacaSchema.bind(this);
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }
  afterOpenModal() {}

  closeModal() {
    this.setState({ modalIsOpen: false });
    if (this.props.closeDialogCallback) {
      this.props.closeDialogCallback();
    }
  }
  reloadAlpacaSchema() {
    this.form.reloadSchema();
  }

  componentDidCatch(e) {
    console.error("Failed: " + e);
  }

  datatable = null;

  render() {
    const fb = getButtons(this.props.formButtonArray, this.state.data, this);

    let loc = this.props.location;

    let multi = Array.isArray(this.state.data);

    let formButtons = [
      <BkModifyButton
        callback={this.saveData}
        buttonTitle="Tallenna"
        busy={this.state.saveBusy}
        cbParameter={this.state.data}
      />,
      ...fb,
      <BkModifyButton callback={this.closeModal} buttonTitle="Sulje" />
    ];
    const b = getButtons(this.props.buttons, this.state.data, this);
    let buttons = [
      <BkModifyButton
        callback={this.openCreate}
        buttonTitle="Luo uusi"
        supportsMulti={false}
        disabled={multi}
      />,
      <BkModifyButton
        callback={this.openUpdate}
        buttonTitle="Muokkaa"
        supportsMulti={false}
        disabled={multi || !this.state.data}
      />,
      ...b
    ];
    let alpacaEndpoint = this.props.alpacaEndpoint
      ? this.props.alpacaEndpoint
      : loc.endpoint;

    const ctx = this.props.ctx ? this.props.ctx : this.props.location.ctx;

    let formKey = ctx + this.props.location.language + alpacaEndpoint;

    return (
      <React.Fragment>
        <BkContentArea>
          {this.props.children}
          <Modal
            isOpen={this.state.modalIsOpen}
            onAfterOpen={this.afterOpenModal}
            onRequestClose={this.closeModal}
            style={customStyles}
            className="ui-widget-content"
            contentLabel=""
          >
            <OkErrorMessage errorMessage={this.state.errorMessage} okMessage={this.state.okMessage}
              clearError={()=>this.setState({errorMessage:undefined})} clearOk={()=>this.setState({okMessage:undefined})} />
            {this.props.itemsBeforeForm}
            <AlpacaForm
              key={formKey}
              errorMsg={this.state.errorMessage}
              ctx={loc.ctx}
              endpoint={alpacaEndpoint}
              language={loc.language}
              {...this.props}
              data={this.state.data}
              onRef={ref => {
                if (ref !== undefined) {
                  this.form = ref;
                  console.log("form ref updated: " + ref);
                }
              }}
            />
            {formButtons}
            {this.props.itemsAfterForm}
          </Modal>
          <Datatable
            openLoginDialog={this.props.openLoginDialog}
            multiSelect={this.props.multiSelect}
            {...this.props}
            selectCallback={this.setSelectedObject}
            onRef={ref => {
              console.log("datatable ref updated: " + ref);
              this.datatable = ref;
            }}
          >
            {buttons}
          </Datatable>
        </BkContentArea>
      </React.Fragment>
    );
  }
  openCreate() {
    this.setState({ modalIsOpen: true, data: {} });
  }
  openUpdate() {
    if (this.state.data) {
      this.setState({ modalIsOpen: true });
    }
  }
  saveData() {
    let data = this.form.getData();
    this.setState({ saveBusy: true });
    const cp = this;
    if (this.props.preSaveCallback) {
      data = this.props.preSaveCallback(data);
      if (!data) {
        console.log("Won't save, preSaveCallback returns no");
        return;
      }
    }
    createUpdate(data, this.props.location, this.reloadData)
      .then(ret => {
        cp.setState({
          saveBusy: false,
          errorMessage: ret ? ret.errorMessage : undefined
        });
        if (!ret || !ret.errorMessage) {
          this.reloadData();
        }
      })
      .catch(ex => {
        cp.setState({
          saveBusy: false,
          errorMessage:
            ex && ex.response && ex.response.data
              ? ex.response.data.errorMessage
              : "Failed"
        });
      });
  }
  reloadData() {
    console.log("reloading");
    if (this.datatable) {
      this.datatable.reload();
    } else {
      console.log("this.datatable not set!");
    }
    this.setState({ modalIsOpen: false });
    if (this.props.postSaveCallback) {
      this.props.postSaveCallback();
    }
  }
  setSelectedObject(obj) {
    console.log("Setting object: " + obj);
    this.setState(
      {
        data: obj
      },
      function() {
        if (this.props.selectCallback) {
          this.props.selectCallback(obj);
        }
        console.log("State changed: " + JSON.stringify(this.state.data));
      }
    );
  }
}
export default TableAndForm;

TableAndForm.propTypes = {
  buttons: PropTypes.array,
  formButtonArray: PropTypes.arrayOf(PropTypes.object),
  getDtSettings: PropTypes.func.isRequired,
  getDtColumns: PropTypes.func.isRequired,
  openLoginDialog: PropTypes.func.isRequired,
  location: {
    ctx: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired
  },
  itemsBeforeForm: PropTypes.any,
  itemsAfterForm: PropTypes.any,
  closeDialogCallback: PropTypes.func
};
