import React from "react";
import Modal from "react-modal/lib/components/Modal";
import { customStyles } from "../layout/ModalStyle";
import { CircleLoader } from "react-spinners";

export const ReservationModal = props => {
  const { reservation, isOpen, onClose, location } = props;
  const d = reservation;
  if (!d) {
    return "";
  }
  const t = key => location.translate(location, key);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={customStyles}
      className="ui-widget-content"
      contentLabel=""
    >
      <table className="bk-parking-list">
        <tr>
          <th>{t("placeArea")}</th>
          <td>{d.parkArea}</td>
          <th>{t("validUntil")}</th>
          <td>{d.validUntil}</td>
        </tr>
        <tr>
          <th>{t("report.firstName")}</th>
          <td>{d.firstName}</td>
          <th>{t("report.lastName")}</th>
          <td>{d.lastName}</td>
        </tr>
        <tr>
          <th>{t("report.email")}</th>
          <td>{d.email}</td>
          <th>{t("report.phone")}</th>
          <td>{d.mobile}</td>
        </tr>
        <tr>
          <th>{t("report.boatName")}</th>
          <td>{d.boatName}</td>
          <th>{t("report.boatType")}</th>
          <td>{d.boatType}</td>
        </tr>
      </table>
    </Modal>
  );
};
