import React from "react";
import BookkaaHeader from "../BookkaaHeader";
import LoginForm from "../LoginForm";
import LoginRequired from "../LoginRequired";
import Profile from "../Profile";
import AreaHeader from "./AreaHeader";
import { genericGet, genericPost } from "../../services/axiosApi";
import SubareaSelect, { ItemType } from "../SubareaSelect";
import BkModifyButton from "../layout/BkModifyButton";
import OkErrorMessage from "../layout/OkErrorMessage";

class Electricity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      outlets: []
    };
    this.selectCallback = this.selectCallback.bind(this);
    this.saveSelected = this.saveSelected.bind(this);
    this.reload = this.reload.bind(this);
    this.reload();
  }

  saveSelected() {
    const l = this.props.location;
    genericPost(l.language, `${l.area}/electricity`, this.state.selectedItem, "booking-web").then(r => {
      if (r.errorMessage) {
        this.setState({errorMessage: r.errorMessage});
      } else {
        this.setState({outlets: r.data, okMessage: r.okMessage});
      }
    });
  }

  reload() {
    const l = this.props.location;
    genericGet(l.language, `${l.area}/electricity/subareas`, "booking-web").then(r => this.setState({ subareas: r }));
    genericGet(l.language, `${l.area}/electricity/places`, "booking-web").then(r => this.setState({ places: r }));
    genericGet(l.language, `${l.area}/electricity/codeTypes`, "booking-web").then(r => this.setState({ codeTypes: r }));
    genericGet(l.language, `${l.area}/electricity`, "booking-web").then(r => {
      if (r.errorMessage) {
        this.setState({errorMessage: r.errorMessage});
      } else {
        this.setState({outlets: r});
      }
    })
  }
  selectCallback(area) {
    this.setState({ selectedItem: area });
  }

  render() {
    const location = { ...this.props.location };
    const t = key => location.translate(location, key);
    location.endpoint = "electricity";
    location.ctx = "booking-web";

    let beforeForm = [];
    const CLOSE_BUTTON = {
      supportsMulti: true,
      callback: this.props.closeCb,
      title: "Sulje"
    };

    let listButtonArray = [];
    if (this.props.closeCb) {
      listButtonArray.push(CLOSE_BUTTON);
    }

    const data = this.state.selectedItem;

    return (
      <React.Fragment>
        <LoginRequired {...this.props.location} {...this.props.loginProps} />
        <BookkaaHeader location={location} {...this.props.headerProps} />
        <LoginForm location={location} {...this.props.loginProps} />
        <Profile location={location} {...this.props.profileProps}>
          <AreaHeader areaName={this.props.areaName} />
          <OkErrorMessage okMessage={this.state.okMessage} errorMessage={this.state.errorMessage}
            clearOk={()=>this.setState({okMessage:undefined})} clearError={()=>this.setState({errorMessage:undefined})} />
          {data &&
          <React.Fragment>
            <ElectricOutlet t={t}
              data={data} 
              subareas={this.state.subareas}
              places={this.state.places}
              codeTypes={this.state.codeTypes}
              setCb={(newData) => this.setState({selectedItem: {...data, ...newData}})}
              saveCb={this.saveSelected}
              closeCb={() => this.setState({ selectedItem: undefined })}
            />
          </React.Fragment>}
          {!data &&
          <React.Fragment>
            <table>
              <tr></tr>
              {this.state.outlets.map(out => <ElectricOutletItem {...out} selectCallback={() => this.setState({selectedItem: out})}/>)}
            </table>
          </React.Fragment>}
        </Profile>
      </React.Fragment>
    );
  }
}

const ElectricOutlet = (props) => {
  const {t, data, setCb, saveCb, closeCb, subareas, places, codeTypes } = props;
  return (<div className="bk-list">
    <table className="display">
      <tr><th colSpan={2}>Sähköpistoke: {data.outletIdentification}</th></tr>
      <tr>
        <td>Automaattinen kohdennus paikkaan</td>
        <td><input type="checkbox" selected={data.mappingType == "AUTOMATIC"} onChange={() => 
          setCb({ mappingType: data.mappingType == "MANUAL" ? "AUTOMATIC" : "MANUAL" })} /></td>
      </tr>
      <tr>
        <td>Koodityyppi</td>
        <td>
          <AccessCodeTypeSelect t={t} 
            cb={data => setCb({accessCodeType: data ? { id: data } : undefined })} 
            codeTypes={codeTypes} 
            selected={data.accessCodeType ? data.accessCodeType.id : undefined}  />
        </td>
      </tr>
      <tr>
        <td>Paikat</td>
        <td>
          <PlaceSelect t={t} cb={data => setCb({places: data})} places={places} selected={data.places} />
        </td>
      </tr>
      <tr>
        <td>Laiturit</td>
        <td>
          <SubareaSelect style={{width: "150px"}}
            cb={(data) => setCb({subareas: data })} itemType={ItemType.ID}
            subareas={subareas} selected={data.subareas} multiple={true} />
        </td>
      </tr>
      <tr>
        <td colSpan={2}>
          <div style={{}}>
            <BkModifyButton buttonTitle={t("close-button")} callback={closeCb} />
            <BkModifyButton buttonTitle={t("modify-button")} callback={saveCb} />
          </div>
        </td>
      </tr>
    </table>
  </div>);
}

const AccessCodeTypeSelect = (props) => {
  const { selected, codeTypes } = props;
  return (<select multiple={false} style={{width: "150px"}}
    onChange={(e) => props.cb(e.target.value ? parseInt(e.target.value) : undefined)
  }>
    {codeTypes.map(ct => {
      const vals = [];
      vals.push(<option>---</option>);
      if (selected === ct.id) {
        vals.push(
          <option value={ct.id} selected="selected">
            {ct.name}
          </option>
        );
      } else {
        vals.push(<option value={ct.id}>{ct.name}</option>);
      }
      return vals;
    })}
  </select>);
}
const PlaceSelect = (props) => {
  const { selected } = props;
  return (<select multiple={true} style={{width: "150px"}}
    onChange={(e) => {
    let items = [];
    for (let i=0; i<e.target.options.length;i++) {
      if (e.target.options.item(i).selected) {
        items.push(e.target.options.item(i).value);
      }
    }
    props.cb(items);
  }}>
    {props.places.map(pl => {
      const vals = [];
      if (selected && selected.includes(pl.id)) {
        vals.push(
          <option value={pl.id} selected="selected">
            {pl.subareaCode + " : " + pl.code}
          </option>
        );
      } else {
        vals.push(<option value={pl.id}>{pl.subareaCode + " : " + pl.code}</option>);
      }
      return vals;
    })}
  </select>);
}

const ElectricOutletItem = (props) => {
  return (
  <tr onClick={props.selectCallback}>
    <td>{props.outletIdentification}</td>
    <td>{(Array.isArray(props.places) && props.places.length > 0) ? `${props.places.length} paikkaa`: ""}</td>
    <td>{(Array.isArray(props.subareas) && props.subareas.length > 0) ? `${props.subareas.length} laituria`: ""}</td>
  </tr>);
}

export default Electricity;
