import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { fullPageModalStyles } from "../layout/ModalStyle";
import BkModifyButton from "../layout/BkModifyButton";
import Customer from "../boatparking/Customer";
import { Map } from "immutable";
import DynamicMap from "../admin/DynamicMap";

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    const customer = this.props.customer ? Map(this.props.customer) : Map();
    this.state = {
      agreed: false,
      toggle: undefined,
      customer: customer,
      customerComplete: !this.requiredDataMissing(customer)
    };
    this.pay = this.pay.bind(this);
    this.cancel = this.cancel.bind(this);
    this.toggleAgree = this.toggleAgree.bind(this);
    this.updateCustomer = this.updateCustomer.bind(this);
    this.getConditions = this.getConditions.bind(this);

    this.id = `toggle_${Math.random()
      .toString()
      .replace(/0\./, "")}`;
    const l = props.location;
    props.getTranslations("booking-web", l.language, "reserve");
    for (let i = 0; i < this.props.products.length; i++) {
      let res = this.props.products[i];
      if (
        this.props.getImageMap &&
        res &&
        (res.type === "PLACE_SEASON" ||
          res.type === "PLACE_SHARE" ||
          res.type === "PLACE_GUEST")
      ) {
        this.props.getImageMap(
          this.props.location.language,
          res.id,
          null,
          "ONE_PLACE",
          null,
          null
        );
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.customer &&
      (this.props.customer.firstName || this.props.customer.lastName) &&
      (!prevProps.customer ||
        prevProps.customer.firstName !== this.props.customer.firstName ||
        prevProps.customer.lastName !== this.props.customer.lastName)
    ) {
      console.log("User logged in, resetting customer");
      const customer = this.props.customer ? Map(this.props.customer) : Map();
      this.setState({
        customer: customer,
        customerComplete: !this.requiredDataMissing(customer)
      });
    }
  }

  toggleAgree() {
    this.setState({ agreed: !this.state.agreed });
  }

  pay(lang, cartId) {
    let active = [];
    for (let i = 0; i < this.props.products.length; i++) {
      let res = this.props.products[i];
      if (res.active) {
        console.log("Product selected: " + res.description);
        active.push({id: res.id, amount: res.amount});
      }
    }
    this.props.pay(
      lang,
      cartId,
      active,
      this.state.customer.set("customerId", this.state.customer.get("id"))
    );
  }
  cancel() {
    this.props.closeShoppingCart();
  }

  render() {
    const l = this.props.location; // shortcut
    const t = key => l.translate(l, key);
    const customer = this.props.cartType === "ADDON_PAYMENT_LINK" ? <div/> : (
      <form autocomplete="on">
        <Customer
          autocomplete={true}
          t={t}
          updateCustomer={this.updateCustomer}
          stateCustomer={this.state.customer}
          renderAsTable={true}
          showAddress={true}
          requireAddress={this.props.cartType === "PAYMENT_LINK"}
          // eslint-disable-next-line prettier/prettier
          disableEmail={this.props.cartType === "PAYMENT_LINK" && (this.props.customer && this.props.customer.email)}
          askBoatSize={true}
          boatRegister={true}
        />
      </form>
    );
    let conditions = this.props.hasConditions;
    let checkbox = undefined;
    const conds = this.getConditions(l.language, this.props.cartId);
    if (conditions) {
      checkbox = (
        <React.Fragment>
          <input
            type="checkbox"
            value="agreeTerms"
            onChange={this.toggleAgree}
            id={this.id}
          />
          <label htmlFor={this.id}>{conds}</label>
        </React.Fragment>
      );
    }
    let additional = [];

    let products = [];
    for (let i = 0; i < this.props.products.length; i++) {
      let res = this.props.products[i];
      if (res.isAdditional) {
        if (res.active === undefined) {
          res.active = false;
        } else if (res.active) {
          products.push(
            <tr>
              <td>{res.reservationUnit === "MULTIPLE" && <span>{res.amount}</span>}</td>
              <td>{res.description}</td>
              <td>{res.paid ? t("paid") : `${res.amount ? (res.amount * res.totalPrice).toFixed(2) : res.totalPrice} €`}</td>
            </tr>
          );
        }
        additional.push(
          <div>
            {res.reservationUnit === "MULTIPLE" && 
              <input style={{width:"50px"}} type="number" min={0} value={res.amount} onChange={(e) => {
                if (res.canChange) {
                  res.amount = e.target.value >= 0 ? e.target.value : 0;
                  res.active = !!res.amount;
                  this.setState({ toggle: res.active });
                }
              }} />}
            {res.reservationUnit !== "MULTIPLE" && 
              <input
                type="checkbox"
                value={res.id}
                disabled={!res.canChange}
                checked={res.active}
                onClick={() => {
                  if (res.canChange) {
                    res.active = !res.active;
                    this.setState({ toggle: res.active });
                  }
                }}
              />}
            {res.description} {res.totalPrice} €
          </div>
        );
      } else {
        products.push(
          <tr>
            <td>{res.reservedTime}</td>
            <td>{res.description}</td>
            <td>{res.totalPrice} &euro;</td>
          </tr>
        );
      }
    }
    let errorDiv = [];
    if (this.props.paid) {
      errorDiv.push(
        <div className="bk_okMessage" style={{ display: "block" }}>
          {t("checkoutPaid")}
        </div>
      );
    }
    if (this.props.paymentError) {
      if (this.props.paymentError.errorMessage) {
        errorDiv.push(
          <div className="bk_errorMessage" style={{ display: "block" }}>
            {this.props.paymentError.errorMessage}
          </div>
        );
      } else {
        errorDiv.push(
          <div className="bk_errorMessage" style={{ display: "block" }}>
            Virhe maksussa
          </div>
        );
      }
    }

    const notPaidContent = this.props.paid ? (
      undefined
    ) : (
      <React.Fragment>
        <p>{this.props.cartType !== "ADDON_PAYMENT_LINK" ? t("checkoutProductsFooter") : ""}</p>
        {additional.length > 0 && <p>{t("checkoutAddonProductsHeader")}</p>}
        {additional}
        <p>{checkbox}</p>
        <p>{customer}</p>
        <BkModifyButton
          disabled={
            (this.props.cartType !== "ADDON_PAYMENT_LINK" || products.length === 0) && ((!this.state.agreed && conditions) || !this.state.customerComplete)
          }
          buttonTitle={t("buttonPay")}
          callback={() =>
            this.pay(this.props.location.language, this.props.cartId)
          }
        />
        <BkModifyButton
          buttonTitle={t("close-button")}
          callback={this.cancel}
        />
      </React.Fragment>
    );
    const paidContent = this.props.paid ? (
      <React.Fragment>
        <p>{t("checkoutPaid")}</p>
      </React.Fragment>
    ) : (
      undefined
    );

    let content = (
      <React.Fragment>
        <div className="ui-widget-content">
          <h1>{t("checkoutHeader")}</h1>
          {errorDiv}
          <p>{!this.props.paid && t("checkoutProductsHeader")}</p>
          <table className="paymentProducts">
            {products}
            {(this.props.shortDescription || this.props.description) && (
              <tr>
                <td colspan="3">
                  {this.props.shortDescription && (
                    <p>{this.props.shortDescription}</p>
                  )}
                  {this.props.description}
                </td>
              </tr>
            )}
          </table>
          <br />
          {notPaidContent}
          {paidContent}
          {this.props.imageMap && (
            <div className="bkTabContent">
              <DynamicMap {...this.props} t={t} />
            </div>
          )}
        </div>
      </React.Fragment>
    );

    if (this.props.useModal) {
      return (
        <React.Fragment>
          <Modal
            isOpen={true}
            onRequestClose={this.closeModal}
            style={fullPageModalStyles}
            className="ui-widget-content"
            contentLabel="Ostoskori"
          >
            {content}
          </Modal>
        </React.Fragment>
      );
    }
    return content;
  }

  getConditions(lang, cartId) {
    if (lang === "fi") {
      return (
        <React.Fragment>
          Hyväksyn{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`/booking-web/fi/payment/conditions/${cartId}`}
          >
            ehdot
          </a>
        </React.Fragment>
      );
    } else if (lang === "sv") {
      return (
        <React.Fragment>
          Godkänner{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`/booking-web/sv/payment/conditions/${cartId}`}
          >
            villkor
          </a>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          I accept the{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`/booking-web/en/payment/conditions/${cartId}`}
          >
            terms and conditions
          </a>
        </React.Fragment>
      );
    }
  }

  updateCustomer(fieldName, value) {
    const c = this.state.customer.set(fieldName, value);
    this.setState({
      customer: c,
      customerComplete: !this.requiredDataMissing(c)
    });
  }
  requiredDataMissing(c) {
    return (
      !c.get("firstName") ||
      !c.get("lastName") ||
      !c.get("mobile") ||
      !c.get("email") ||
      !c.get("boatName") ||
      !c.get("boatType") ||
      !c.get("boatLength") ||
      !c.get("boatWidth") ||
      (!c.get("boatDepth") && c.get("boatDepth") !== "0" && c.get("boatDepth") !== 0) ||
      !c.get("streetAddress") ||
      !c.get("town") ||
      !c.get("postOffice")
    );
  }
}

export default Checkout;

Checkout.propTypes = {
  ensureLoggedIn: PropTypes.func.isRequired,
  pay: PropTypes.func.isRequired,
  cancelPayment: PropTypes.func.isRequired,
  products: PropTypes.array.isRequired,
  customer: PropTypes.any.isRequired,
  location: {
    ctx: PropTypes.string.isRequired,
    endpoint: PropTypes.string.isRequired,
    language: PropTypes.string.isRequired
  }
};
