import React from "react";
import { CircleLoader } from "react-spinners";
import { ensureLoggedIn } from "../../ducks/login";
import { genericGet } from "../../services/axiosApi";
import BkModifyButton from "../layout/BkModifyButton";

export class PlaceHistory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      history: undefined,
      placeId: undefined,
      loading: false
    };

    this.loadHistory = this.loadHistory.bind(this);
  }

  loadHistory() {
    this.setState({ loading: true });
    genericGet(
      this.props.location.language,
      "seasonAdmin/place/" + this.props.place.placeId + "/history",
      "booking-web",
      50000
    ).then(
      ret => {
        this.setState({
          loading: false,
          history: ret ? ret.entries : undefined,
          placeId: ret ? ret.placeId : undefined
        });
      },
      reason => {
        this.setState({ loading: false, history: undefined });
      }
    );
  }

  render() {
    const t = key => this.props.location.translate(this.props.location, key);

    if (this.props.saveOngoing && this.state.history) {
      this.setState({ history: undefined });
    }

    if (this.state.loading) {
      return <CircleLoader />;
    }

    if (
      this.state.history === undefined ||
      this.props.place.placeId !== this.state.placeId
    ) {
      return (
        <BkModifyButton
          buttonTitle={t("load-history-button")}
          callback={this.loadHistory}
          disabled={this.props.saveOngoing}
          busy={this.state.loading}
        />
      );
    } else {
      const rows = [];
      this.state.history.forEach(h => {
        rows.push(
          <tr title={h.data}>
            <td>{h.timestamp}</td>
            {h.link && <td> <a target="_blank" rel="noopener noreferrer" href={h.link}>{h.typeStr}</a></td>}
            {!h.link && <td>{h.typeStr}</td>}
            <td>{h.customerName}</td>
          </tr>
        );
      });

      return (
        <React.Fragment>
          <center>
            <br />
          </center>
          <table>
            <tr>
              <th>Pvm</th>
              <th>Tapahtuma</th>
              <th>Asiakas</th>
            </tr>
            {rows}
          </table>
        </React.Fragment>
      );
    }
  }
}
