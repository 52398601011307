/* eslint-disable eqeqeq */
import React from "react";
import Select from "react-select";

export const AreaSelect = props => {
  // eslint-disable-next-line prettier/prettier
  const { cb, areas, selectedAreaId, selectedSellerId, emptyLabel, multiple } = props;
  const vals = [];
  let selected = undefined;
  if (emptyLabel) {
    const item = { value: "", label: emptyLabel };
    vals.push(item);
    if (!selectedAreaId) {
      selected = item;
    }
  }
  if (areas) {
    areas.forEach(a => {
      if (selectedSellerId && a.seller != selectedSellerId) {
        // filter out
      } else {
        const item = { value: a.id, label: `${a.name} (${a.code})` };
        if (selectedAreaId == a.id) {
          selected = item;
        }
        vals.push(item);
      }
    });
  }
  const customStyles = {
    menu: (provided, state) => ({
      ...provided,
      width: state.selectProps.width,
      padding: 2
    }),
    control: (provided, state) => ({
      ...provided,
      minHeight: 23,
      height: 23
    }),
    indicatorContainer: (provided, state) => ({
      color: "rgb(204, 204, 204)",
      display: "flex",
      transition: "color 150ms ease 0s",
      boxSizing: "border-box",
      padding: 2
    }),
    option: (provided, state) => ({
      ...provided,
      padding: 2
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";
      return { ...provided, opacity, transition, top: 8 };
    }
  };
  return (
    <Select
      classNamePrefix="selectArea"
      noOptionsMessage={() => emptyLabel}
      value={selected}
      options={vals}
      isMulti={multiple}
      styles={customStyles}
      onChange={e => {
        const areaId = e.value;
        const sellerId = sellerFromArea(areaId, areas);
        cb(areaId, sellerId);
      }}
    ></Select>
  );
};

const sellerFromArea = (areaId, areas) => {
  if (!areas || !areaId) {
    return undefined;
  }
  let seller = undefined;
  areas.forEach(a => {
    if (a.id === areaId) {
      seller = a.seller;
    }
  });
  return seller;
};
