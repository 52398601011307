import React from "react";
import Datetime from "react-datetime";
import { DateSelector } from "./DateSelector";
import moment from "moment";
import PropTypes from "prop-types";

class DateRangeSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      realtime: this.props.realtime,
      // eslint-disable-next-line prettier/prettier
      start: this.props.initialStart ? this.props.initialStart : moment().startOf("day"),
      // eslint-disable-next-line prettier/prettier
      end: this.props.initialEnd ? this.props.initialEnd : moment().startOf("day").add(1, "day")
    };
    Datetime.moment().locale(props.location.language);

    this.setStartDate = this.setStartDate.bind(this);
    this.setEndDate = this.setEndDate.bind(this);
    this.isValidDate = this.isValidDate.bind(this);
  }

  setStartDate(value) {
    let end = this.props.end ? this.props.end.startOf("day") : this.state.end;
    // eslint-disable-next-line prettier/prettier
    if (this.props.sameDayAllowed && !end.isSameOrAfter(value.startOf("day"))) {
      end = moment(value);
    } else if (end && !end.startOf("day").isAfter(value)) {
      end = moment(value).add(1, "days");
    }
    this.setState({ isStartDateOpen: false, end: end, start: value });
    if (this.props.startDateCallback) {
      this.props.startDateCallback(value, end);
    }
  }
  setEndDate(value) {
    // eslint-disable-next-line prettier/prettier
    let start = this.props.start ? this.props.start.startOf("day") : this.state.start;
    // eslint-disable-next-line prettier/prettier
    if (this.props.sameDayAllowed && start && !start.isSameOrBefore(value.startOf("day"))) {
      start = moment(value);
    } else if (start && !start.isBefore(value)) {
      start = moment(value).subtract(1, "days");
    }

    this.setState({ isEndDateOpen: false, end: value, start: start });
    if (this.props.endDateCallback) {
      this.props.endDateCallback(value, start);
    }
  }

  isValidDate(currentDate, selectedDate) {
    const now = moment().startOf("day");
    if (!this.props.pastDateAllowed && currentDate.isBefore(now)) {
      return false;
    }
    if (this.props.minDate && this.props.minDate.isAfter(currentDate)) {
      return false;
    }
    if (this.props.maxDate && this.props.maxDate.isBefore(currentDate)) {
      return false;
    }
    return true;
  }

  render() {
    if (this.state.error) {
      return (
        <div>
          <h2>Oh noes! Something went wrong: {this.state.error.message} </h2>
        </div>
      );
    }

    let start = this.props.start
      ? this.props.start.startOf("day")
      : this.state.start;
    let end = this.props.end
      ? this.props.end.startOf("day")
      : this.state.end
      ? this.state.end
      : moment()
          .startOf("day")
          .add(1, "day");

    const cb = this.props.realtimeCb;
    const rt = () => {
      console.log("Calling callback: " + cb);
      cb && cb();
      this.setState({ isStartDateOpen: false, isEndDateOpen: false });
    };
    if (
      this.props.realtime &&
      !this.state.isStartDateOpen &&
      !this.state.isEndDateOpen
    ) {
      return (
        <a onClick={() => this.setState({ isStartDateOpen: true })}>
          <div style={{ display: "inline-block" }}>{this.props.children}</div>
        </a>
      );
    }
    const childContent =
      this.state.isStartDateOpen || this.state.isEndDateOpen ? (
        <div>{this.props.children}</div>
      ) : (
        undefined
      );
    const endClass = this.state.isEndDateOpen
      ? "bk-selector-endDate bk-date-open"
      : "bk-selector-endDate";
    const startClass = this.state.isStartDateOpen
      ? "bk-selector-startDate bk-date-open"
      : "bk-selector-startDate";
    return (
      <React.Fragment>
        <a onClick={rt}>{childContent}</a>
        <div className={startClass}>
          {!this.state.isEndDateOpen && (
            <React.Fragment>
              {this.props.beforeStartDate}
              <DateSelector
                language={this.props.location.language}
                value={start}
                isEdit={this.state.isStartDateOpen}
                callback={this.setStartDate}
                enableEditCallback={
                  this.props.startDateCallback && !this.state.isEndDateOpen
                    ? () => this.setState({ isStartDateOpen: true })
                    : undefined
                }
                isValidDate={this.isValidDate}
              />
            </React.Fragment>
          )}
        </div>
        <div className="bk-selector-divider" />
        <div className={endClass}>
          {!this.state.isStartDateOpen && (
            <React.Fragment>
              {this.props.beforeEndDate}
              <DateSelector
                language={this.props.location.language}
                value={end}
                isEdit={this.state.isEndDateOpen}
                callback={this.setEndDate}
                enableEditCallback={
                  this.props.endDateCallback && !this.state.isStartDateOpen
                    ? () => this.setState({ isEndDateOpen: true })
                    : undefined
                }
                isValidDate={this.isValidDate}
              />
            </React.Fragment>
          )}
        </div>
      </React.Fragment>
    );
  }
  componentDidCatch(e) {
    this.setState((state, props) => {
      return {
        error: e
      };
    });
  }
}

export default DateRangeSelector;

DateRangeSelector.propTypes = {
  location: PropTypes.object.isRequired,
  startDateCallback: PropTypes.func,
  endDateCallback: PropTypes.func,
  start: PropTypes.object,
  end: PropTypes.object,
  realtime: PropTypes.bool,
  realtimeCb: PropTypes.func,
  pastDateAllowed: PropTypes.bool,
  minDate: PropTypes.object,
  maxDate: PropTypes.object
};
