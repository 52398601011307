import React from "react";
import { Route } from "react-router";
import BookkaaHeader from "../BookkaaHeader";
import TableAndForm from "../layout/TableAndForm";
import LoginForm from "../LoginForm";
import LoginRequired from "../LoginRequired";
import Profile from "../Profile";
import AccessCodes from "./AccessCodes";
import AreaImages from "./AreaImages";
import Pricing from "./Pricing";
import Seasons from "./Seasons";
import Subareas from "./Subareas";
import swal from "sweetalert";
import { genericGet, genericPost } from "../../services/axiosApi";
import AdminUsers from "./AdminUsers";
import BkModifyButton, { getButtons } from "../layout/BkModifyButton";

const ALL_FALSE_STATE = {
  imagesEdit: false,
  subareaEdit: false,
  seasonEdit: false,
  accessCodeEdit: false,
  pricingEdit: false,
  adminUsers: false
};

class Areas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: undefined,
      subareaEdit: false,
      seasonEdit: false,
      accessCodeEdit: false,
      pricingEdit: false,
      imagesEdit: false,
      adminUsers: false
    };
    this.selectCallback = this.selectCallback.bind(this);
    this.openSubareas = this.openSubareas.bind(this);
    this.openSeasons = this.openSeasons.bind(this);
    this.openAccessCodes = this.openAccessCodes.bind(this);
    this.openPricing = this.openPricing.bind(this);
    this.openImages = this.openImages.bind(this);
    this.addUser = this.addUser.bind(this);
    this.retreshConditions = this.retreshConditions.bind(this);
    this.uploadGuestConditions = this.uploadGuestConditions.bind(this);
    this.uploadSeasonConditions = this.uploadSeasonConditions.bind(this);
    this.upload = this.upload.bind(this);
  }

  retreshConditions() {
    const l = this.props.location;
    return genericGet(l.language, "/sellers/" + l.area + "/conditions").then(
      ret => {
        if (ret && ret.PLACE_SEASON) {
          this.setState({ conditions: ret });
        }
      }
    );
  }

  selectCallback(area) {
    this.setState({ selectedItem: area });
  }

  openImages() {
    this.setState({
      ...ALL_FALSE_STATE,
      imagesEdit: true
    });
  }

  openSubareas() {
    this.setState({
      ...ALL_FALSE_STATE,
      subareaEdit: true
    });
  }

  openSeasons() {
    this.setState({
      ...ALL_FALSE_STATE,
      seasonEdit: true
    });
  }

  openAccessCodes() {
    this.setState({
      ...ALL_FALSE_STATE,
      accessCodeEdit: true
    });
  }

  openPricing() {
    this.setState({
      ...ALL_FALSE_STATE,
      pricingEdit: true
    });
  }

  addUser() {
    this.setState({
      ...ALL_FALSE_STATE,
      adminUsers: true
    });
  }

  addUserx() {
    const l = this.props.location;
    const t = key => l.translate(l, key);
    return swal({
      title: t("areas.addUserTitle"),
      text: t("areas.addUserText"),
      icon: "info",
      content: {
        element: "input",
        attributes: {
          placeholder: t("areas.addUserEmail"),
          type: "email"
        }
      },
      buttons: [t("cancel-button"), "Ok"],
      dangerMode: true
    }).then(willDo => {
      if (willDo) {
        return genericGet(
          l.language,
          `users/find?email=${willDo}`,
          "um-web",
          5000
        ).then(ret => {
          if (ret && !ret.errorMessage && ret.id) {
            return genericPost(
              l.language,
              `users/${ret.id}/group/AREA`,
              this.state.selectedItem.code,
              "um-web"
            );
          } else {
            return swal("No user with email: " + willDo);
          }
        });
      } else {
        console.log("Cancelled");
      }
    });
  }

  uploadGuestConditions(data) {
    // TODO
  }
  uploadSeasonConditions(data) {
    // TODO
  }

  upload(data, guestSeason) {
    const l = this.props.location;
    const formData = new FormData();
    formData.append("conditions", data, data.name);
    const id = this.state.selectedItem.seller;
    genericPost(
      l.language,
      `sellers/${id}/conditions`,
      formData,
      "messagecenter-web",
      "multipart/form-data"
    ).then(r => {
      this.setState({
        selectedGroup: undefined
      });
    });
  }

  render() {
    const location = {
      language: this.props.location.language,
      endpoint: "areas",
      ctx: "booking-web",
      area: this.state.selectedItem ? this.state.selectedItem.id : undefined,
      translations: this.props.location.translations,
      translate: this.props.location.translate
    };
    const t = key => location.translate(location, key);
    const areaName = this.state.selectedItem ? this.state.selectedItem.name : "";
    if (this.state.subareaEdit) {
      return (
        <Subareas
          {...this.props}
          location={location}
          areaName={areaName}
          closeCb={() => {
            this.setState(ALL_FALSE_STATE);
            return true;
          }}
        />
      );
    } else if (this.state.seasonEdit) {
      location.endpoint = "seasons";
      return (
        <Seasons
          {...this.props}
          location={location}
          areaName={areaName}
          closeCb={() => {
            this.setState(ALL_FALSE_STATE);
            return true;
          }}
        />
      );
    } else if (this.state.accessCodeEdit) {
      return (
        <AccessCodes
          {...this.props}
          location={location}
          areaName={areaName}
          closeCb={() => {
            this.setState(ALL_FALSE_STATE);
            return true;
          }}
        />
      );
    } else if (this.state.pricingEdit) {
      return (
        <Pricing
          {...this.props}
          location={location}
          areaName={areaName}
          closeCb={() => {
            this.setState(ALL_FALSE_STATE);
            return true;
          }}
        />
      );
    } else if (this.state.imagesEdit) {
      return (
        <AreaImages
          {...this.props}
          location={location}
          areaName={areaName}
          closeCb={() => {
            this.setState(ALL_FALSE_STATE);
            return true;
          }}
        />
      );
    } else if (this.state.adminUsers) {
      return (
        <AdminUsers
          {...this.props}
          t={t}
          location={location}
          areaName={areaName}
          sellerCode={this.state.selectedItem.sellerCode}
          areaCode={this.state.selectedItem.code}
          closeCb={() => {
            this.setState(ALL_FALSE_STATE);
            return true;
          }}
        />
      );
    }

    console.log(`Area: ${location.area}`);
    let beforeForm = [];
    const SEASONS_BUTTON = {
      supportsMulti: false,
      callback: this.openSeasons,
      title: t("area.seasons"),
      disabledCallback: b => !this.state.selectedItem
    };
    const SUBAREAS_BUTTON = {
      supportsMulti: false,
      callback: this.openSubareas,
      title: t("area.piers"),
      disabledCallback: b => !this.state.selectedItem
    };
    const ACCESSCODES_BUTTON = {
      supportsMulti: false,
      callback: this.openAccessCodes,
      title: t("area.accessCodes"),
      disabledCallback: b => !this.state.selectedItem
    };
    const PRICING_BUTTON = {
      supportsMulti: false,
      callback: this.openPricing,
      title: t("area.pricing"),
      disabledCallback: b => !this.state.selectedItem
    };
    const IMAGES_BUTTON = {
      supportsMulti: false,
      callback: this.openImages,
      title: t("area.images"),
      disabledCallback: b => !this.state.selectedItem
    };
    const ADD_USER_BUTTON = {
      supportsMulti: false,
      callback: this.addUser,
      title: t("area.addUser"),
      disabledCallback: b => !this.state.selectedItem
    };
    const MESSAGETEMPLATES_BUTTON = {
      supportsMulti: false,
      callback: this.openAccessCodes,
      title: t("area.messageTemplates"),
      disabledCallback: b => !this.state.selectedItem
    };
    const MESSAGES_CALLBACK = () =>
    window.location.href=(
      `/admin/${this.props.location.language}/${
        this.state.selectedItem
          ? this.state.selectedItem.id
          : undefined
      }/messageOrders`
    );
    const MESSAGES_BUTTON = {
      supportsMulti: false,
      callback: MESSAGES_CALLBACK,
      title: t("area.messages"),
      disabledCallback: b => !this.state.selectedItem
    };

    let listButtonArray = [
      SEASONS_BUTTON,
      SUBAREAS_BUTTON,
      ACCESSCODES_BUTTON,
      PRICING_BUTTON,
      IMAGES_BUTTON,
      ADD_USER_BUTTON,
      MESSAGES_BUTTON
    ];
    let formButtonArray = [
      SEASONS_BUTTON,
      SUBAREAS_BUTTON,
      ACCESSCODES_BUTTON,
      PRICING_BUTTON,
      IMAGES_BUTTON,
      ADD_USER_BUTTON,
      MESSAGES_BUTTON
    ];

    const seasonConditionsLink = "";
    const guestConditionsLink = "";

    const conditionsButtons = getButtons([
      {
        supportsMulti: false,
        callback: this.uploadSeasonConditions,
        fileUpload: true,
        title: t("upload-season-conditions"),
        disabledCallback: b => false
      },
      {
        supportsMulti: false,
        callback: this.uploadGuestConditions,
        fileUpload: true,
        title: t("upload-guest-conditions"),
        disabledCallback: b => false
      }
    ]);

    return (
      <React.Fragment>
        <LoginRequired {...this.props.loginProps} location={location}>
          <BookkaaHeader location={location} {...this.props.headerProps} />
          <LoginForm location={location} {...this.props.loginProps} />
          <Profile location={location} {...this.props.profileProps}>
            <TableAndForm
              {...this.props}
              selectCallback={this.selectCallback}
              onRef={ref => (this.tableAndForm = ref)}
              itemsBeforeForm={beforeForm}
              buttons={listButtonArray}
              formButtonArray={formButtonArray}
              closeDialogCallback={this.dialogClosed}
              multiSelect={false}
              location={location}
            />
            {this.state.conditions && (
              <table>
                <tr>
                  <td>Kausipaikka-ehdot</td>
                  <td>
                    {this.state.conditions.PLACE_SEASON && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={seasonConditionsLink}
                      >
                        {t("terms")}
                      </a>
                    )}
                  </td>
                  <td>{conditionsButtons[0]}</td>
                </tr>
                <tr>
                  <td>Viearaspaikka-ehdot</td>
                  <td>
                    {this.state.conditions.PLACE_GUEST && (
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={guestConditionsLink}
                      >
                        {t("terms")}
                      </a>
                    )}
                  </td>
                  <td>{conditionsButtons[1]}</td>
                </tr>
              </table>
            )}
          </Profile>
        </LoginRequired>
      </React.Fragment>
    );
  }
}

export default Areas;
