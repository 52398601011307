import React from "react";
import BookkaaHeader from "../BookkaaHeader";
import BkModifyButton from "../layout/BkModifyButton";
import BkContentArea from "../layout/BkContentArea";

class PatrolLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      logEntry: ""
    };
    this.addLogEntry = this.addLogEntry.bind(this);
  }

  componentDidMount() {
    const l = this.props.location;
    this.props.getLogEntries(l.area, l.language, this.props.pwd);
  }

  addLogEntry() {
    const l = this.props.location;
    console.log("Adding log " + this.state.logEntry);
    this.props.addLogEntry(l.area, l.language, {
      pwd: this.props.pwd,
      entry: this.state.logEntry
    });
    this.setState({ logEntry: "" });
  }

  render() {
    if (!this.props.patrolLog) {
      console.log("Not initialized");
      return null;
    }
    let previousLogs = [];
    this.props.patrolLog.entries.forEach(element => {
      previousLogs.push(
        <tr>
          <td>{element.created}</td>
          <td>{element.log}</td>
        </tr>
      );
    });
    return (
      <React.Fragment>
        <BookkaaHeader
          {...this.props}
          disableLogin={true}
          headerSelectorContent={this.props.patrolLog.title}
        />
        <BkContentArea>
          <textarea
            className="responsive"
            rows="5"
            maxlength="2000"
            onChange={e => this.setState({ logEntry: e.target.value })}
            value={this.state.logEntry}
          />
          <BkModifyButton
            disabled={!this.state.logEntry}
            buttonTitle="Lisää merkintä"
            callback={this.addLogEntry}
          />
          <table>{previousLogs}</table>
        </BkContentArea>
      </React.Fragment>
    );
  }
}
export default PatrolLog;
