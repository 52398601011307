import React from "react";
import { Route } from "react-router";
import BookkaaHeader from "../BookkaaHeader";
import TableAndForm from "../layout/TableAndForm";
import LoginForm from "../LoginForm";
import LoginRequired from "../LoginRequired";
import Profile from "../Profile";
import swal from "sweetalert";
import { genericGet, genericPost } from "../../services/axiosApi";
import BkModifyButton, { getButtons } from "../layout/BkModifyButton";
import OkErrorMessage from "../layout/OkErrorMessage";

class BookkaaInternal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedItem: undefined,
    };
    this.selectCallback = this.selectCallback.bind(this);
  }

  selectCallback(item) {
    this.setState({ selectedItem: item });
  }

  accept() {
    return genericPost("", "internal/accept", this.state.selectedItem).then(r => this.setState({ okMessage: r.okMessage, errorMessage: r.errorMessage }));
  }

  render() {
    const location = {
      language: this.props.location.language,
      endpoint: "bookkaa",
      ctx: "booking-web",
      area: this.state.selectedItem ? this.state.selectedItem.id : undefined,
      translations: this.props.location.translations,
      translate: this.props.location.translate
    };
    const t = key => location.translate(location, key);

    const BUTTON_ACCEPT = {
      supportsMulti: false,
      callback: this.accept,
      title: "Kuittaa" 
    };

    let listButtonArray = [
    ];
    let formButtonArray = [
      BUTTON_ACCEPT
    ];

    return (
      <React.Fragment>
        <LoginRequired {...this.props.loginProps} location={location}>
          <BookkaaHeader location={location} {...this.props.headerProps} />
          <LoginForm location={location} {...this.props.loginProps} />
          <Profile location={location} {...this.props.profileProps}>
            <OkErrorMessage okMessage={this.state.okMessage} errorMessage={this.state.errorMessage} 
            clearOk={()=>this.setState({okMessage:undefined})} clearError={()=>this.setState({errorMessage:undefined})} />
            <TableAndForm
              {...this.props}
              selectCallback={this.selectCallback}
              onRef={ref => (this.tableAndForm = ref)}
              buttons={listButtonArray}
              formButtonArray={formButtonArray}
              closeDialogCallback={this.dialogClosed}
              multiSelect={false}
              location={location}
            />
          </Profile>
        </LoginRequired>
      </React.Fragment>
    );
  }
}

export default BookkaaInternal;
