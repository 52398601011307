import { Map } from "immutable";
import datatableService from "../services/datatable";

const defaultState = Map({
  columns: Map({}),
  settings: Map({})
});

export const getDtColumns = (ctx, language, serviceEndpoint, file) => {
  return {
    type: "GET_COLUMNS",
    payload: {
      promise: datatableService.getColumns(ctx, language, serviceEndpoint, file)
    },
    meta: {
      ctx: ctx,
      serviceEndpoint: serviceEndpoint,
      file: file,
      language: language
    }
  };
};

export const getDtSettings = (ctx, language, serviceEndpoint) => {
  return {
    type: "GET_SETTINGS",
    payload: {
      promise: datatableService.getLanguage(ctx, language, serviceEndpoint)
    },
    meta: {
      ctx: ctx,
      serviceEndpoint: serviceEndpoint,
      language: language
    }
  };
};
function mapKey(ctx, language, endpoint, file) {
  return ctx + "-" + language + "-" + endpoint + "-" + file;
}

export default function datatableReducer(state = defaultState, action) {
  const { type, payload, meta } = action;
  /*console.log(
    "datatableReducer: type: " + type + ", meta: " + JSON.stringify(meta)
  );*/
  switch (type) {
    case "GET_COLUMNS_FULFILLED":
      let ckey = mapKey(
        meta.ctx,
        meta.language,
        meta.serviceEndpoint,
        meta.file
      );
      let col;
      if (payload.error) {
        console.log("Error: " + payload.error);
        if (payload.error.response && payload.error.response.status === 403) {
          //return state.set("needLogin", true);
        }
        col = state.get("columns").set("error." + ckey, payload.error);
      } else {
        col = state
          .get("columns")
          .remove("error." + ckey)
          .set(ckey, payload);
      }
      return state.set("columns", col);
    case "GET_SETTINGS_FULFILLED":
      let skey = mapKey(meta.ctx, meta.language, meta.serviceEndpoint);
      let sett;
      if (payload.error) {
        console.log("Error: " + payload.error);
        if (payload.error.response && payload.error.response.status === 403) {
          //return state.set("needLogin", true);
        }
        sett = state.get("settings").set("error." + skey, payload.error);
      } else {
        sett = state
          .get("settings")
          .remove("error." + skey)
          .set(skey, payload);
      }
      return state.set("settings", sett);
    default:
      return state;
  }
}
